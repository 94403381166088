<template>
  <div>
    <p>&nbsp;</p>
    <p>
      <strong>{{ service.attributes.title[lang] }}</strong>
      <span v-if="service.attributes.mandatory">
        ({{
          $t('participation.configureRegistrationForm.equipment.mandatoryBooking', lang)
        }})
      </span>
    </p>

    <table class="table-equipment">
      <thead>
      <tr>
        <th v-if="service.attributes.amount_selectable">
          {{
            $t('participation.equipment.quantity', lang)
          }}
        </th>
        <th>{{ $t('participation.equipment.description', lang) }}</th>
        <th>{{ $t('participation.equipment.unit', lang) }}</th>
        <th></th>
        <th v-if="service.attributes.specifiable">
          {{
            service.attributes.specification_label[lang]
          }}
        </th>
        <th>{{ $t('participation.equipment.unitPrice', lang) }}</th>
        <th v-if="service.attributes.amount_selectable">
          {{ $t('participation.equipment.totalPrice', lang) }}
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in assigned" :key="key">
        <td v-if="service.attributes.amount_selectable">
          <v-text-field
              class="rounded-0 mt-1 text-center"
              v-model="item.attributes.amount"
              type="number"
              min="1"
              @change="updateMultiSelected(item)"
              :disabled="!isSubmittedForm"
              :label="$t('participation.equipment.quantity', lang)"
              style="width: 75px;"
              filled
              dense
          />
        </td>
        <td>
          <p>
            {{ service.attributes.title[lang] }}
          </p>
        </td>
        <td>
          <p>
            {{ service.attributes.unit[lang] }}
          </p>
        </td>
        <td>
          <v-icon
              size="20"
              style="cursor: pointer"
              class="primary--text"
              @click.prevent="$emit('description', service)"
          >
            fal fa-question-circle
          </v-icon>
        </td>
        <td v-if="service.attributes.specifiable">
          <v-text-field
              class="rounded-0 mt-1"
              v-model="item.attributes.specification"
              @change="updateMultiSelected(item)"
              :disabled="!isSubmittedForm"
              :label="service.attributes.specification_label[lang]"
              style="width: 250px;"
              filled
              dense
          />
        </td>
        <td>
          <p>{{ service.attributes.price | money }}</p>
        </td>
        <td v-if="service.attributes.amount_selectable">
          <p>{{ Number(service.attributes.price * item.attributes.amount).toFixed(2) }}</p>
        </td>
        <td>
          <v-btn color="secondary"
                 icon
                 :disabled="!isSubmittedForm"
                 @click="detachMultiSelected(item)"
          >
            <v-icon size="18">fal fa-trash-alt</v-icon>
          </v-btn>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td
            colspan="7"
            class="text-right"
        >
          <v-btn
              class="secondary--text"
              @click="attachMultiSelected(service)"
              :disabled="!isSubmittedForm"
              text
          >
            <v-icon
                class="secondary--text mr-2"
                size="18"
            >
              fal fa-plus
            </v-icon>
            {{ $t('forms.buttons.add', lang) }}
          </v-btn>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'MultiSelectableItemRow',

  mixins: [
    participationMode,
  ],

  props: {
    service: {
      type: Object,
    },

    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  computed: {
    assigned() {
      return this.participation?.relationships?.services?.filter((el) => {
        return el.attributes.tradefair_service_id === this.service.id
      })
    },
  },

  methods: {
    ...mapActions('participation', [
      'attachService',
      'updateService',
      'detachService',
    ]),

    attachMultiSelected() {
      return this.attachService({ participation: this.participation, service: this.service, data: { amount: 1 }})
    },

    updateMultiSelected(item) {
      this.updateService({ participation: this.participation, assigned: item, service: this.service, data: { amount: item.attributes.amount, specification: item.attributes.specification } })
    },

    detachMultiSelected(item) {
      if (this.service.mandatory && !(this.assigned?.length - 1)) {
        return;
      }

      this.detachService({ participation: this.participation, assigned: item })
    },
  },
}
</script>
