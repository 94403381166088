import { REGISTRATION_MODES } from '@/enums/registrationModes'
import {mapGetters} from "vuex";
import {BOOKING_TYPES} from "@/enums/bookingType";
import {PRICE_TYPES} from "@/enums/priceTypes";

export default {
  computed: {
    ...mapGetters('participation', [
        'stepsError',
        'participationFormLanguagePreview',
        'participationFormPricePreview',
    ]),

    registrationModes() {
      return [
        REGISTRATION_MODES.READONLY,
        REGISTRATION_MODES.PREVIEW,
        REGISTRATION_MODES.LIVE,
      ]
    },

    isPreviewMode() {
      return this.mode === REGISTRATION_MODES.PREVIEW
    },

    isReadonlyMode() {
      return this.mode === REGISTRATION_MODES.READONLY
    },

    isLiveMode() {
      return this.mode === REGISTRATION_MODES.LIVE
    },

    isSubmittedForm() {
      return this.isEditable && !this.isReadonlyMode
    },

    isStepsErrored() {
      return !!this.stepsError.find((el) => el == this.$options.name) || (this.form && !this.form.isValid);
    },

    isBookingTypePackage() {
      if (this.fair.attributes.booking_type_package && this.fair.attributes.booking_type_space) {
        return this.participation?.attributes?.booking_type === BOOKING_TYPES.PACKAGE;
      }

      return this.fair.attributes?.booking_type_package
    },

    isBookingTypeSpace() {
      if (this.fair.attributes.booking_type_package && this.fair.attributes.booking_type_space) {
        return this.participation?.attributes?.booking_type === BOOKING_TYPES.SPACE;
      }

      return this.fair.attributes?.booking_type_space
    },

    lang() {
      if (!this.isPreviewMode) {
        return this.$i18n.locale;
      }

      return this.participationFormLanguagePreview;
    },

    priceType() {
      return this.calculatePriceType(this.participation)
    },
  },

  methods: {
    isModeValid() {
      return this.registrationModes.includes(this.mode)
    },

    calculatePriceType(participation) {
      if (this.isPreviewMode) {
        return this.participationFormPricePreview;
      }

      var company = participation?.relationships?.company

      if (company
          && !participation.attributes.declared_less_then_five_participations
          && participation.attributes.declared_sponsored
          && company.attributes.declared_public_sector_institution
          && company.attributes.declared_public_sector_involvement
          && company.attributes.declared_public_sector_majority)
      {
        return PRICE_TYPES.PRICE_UNSPONSORED;
      }

      if (company
          && participation.attributes.declared_less_then_five_participations
          && participation.attributes.declared_sponsored
          && company.attributes.declared_public_sector_institution
          && company.attributes.declared_public_sector_involvement
          && company.attributes.declared_public_sector_majority)
      {
        return PRICE_TYPES.PRICE_SPONSORED;
      }

      return PRICE_TYPES.PRICE_PUBLICSECTOR;
    },
  },
}
