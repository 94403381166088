<template>
  <v-container class="pa-8" fluid>
    <v-row class="px-5">
      <v-checkbox :label="$t('notification.readNotifications')" v-model="filters.read"/>
      <v-btn
          color="secondary"
          class="elevation-0 ml-auto"
          :loading="form.loading"
          @click="handleReadAll"
      >
        {{ $t('notification.markAsReadAll') }}
      </v-btn>
    </v-row>

    <!-- TODO: maybe need make paginator -->
    <div v-for="(notification, i) in notifications" :key="i">
      <notification @read="handleRead" :notification="notification"></notification>
    </div>
  </v-container>
</template>

<script>
import Notification from '@/components/common/Notification/Notification'
import {mapActions} from "vuex";

export default {
  name: 'Notifications',

  components: {
    Notification,
  },

  data() {
    return {
      notifications: [],

      form: {
        loading: false,
      },

      filters: {
        read: false,
      }
    }
  },

  created() {
    this.$store.dispatch('auth/fetchUser')
      .then(() => {this.load()})
  },

  methods: {
    ...mapActions('notification', [
      'fetchNotifications',
    ]),

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get('/api/notifications', {
        params: {
          'languages': '*',
          'filter[read]': !this.filters.read ? false : null,
          'include': [],
        },
      })).data

      this.notifications = response.data

      this.form.loading = false
    },

    async handleRead(notification) {
      this.form.loading = true

      await this.$axios.post(`/api/notifications/${notification.id}/read`, {})

      this.load()

      this.fetchNotifications()
    },

    async handleReadAll() {
      this.loading = true

      await this.$axios.post(`/api/notifications/read-all`, {})

      this.load()

      this.fetchNotifications()
    }
  },

  watch: {
    'filters': {
      handler() {
        this.load()
      },
      deep: true
    },
  },
}
</script>
