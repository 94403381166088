import { $axios } from '@/boot/interceptor'
import { makeDefaultGetters } from '@/utilities/helpers/store'
import {SET_NOTIFICATIONS} from "@/store/mutation-types";

const defaultState = () => ({
  notifications: [],
})

const state = defaultState()

const actions = {
  async fetchNotifications({ commit}) {
    try {
      const response = await $axios.get('/api/notifications', {
        params: {
          'languages': '*',
          'filter[read]': false,
          'include': [],
        },
      })

      commit(SET_NOTIFICATIONS, response.data.data)

      return true
    } catch (error) {
      console.error('API error (fetch notifications): ', error)
      return false
    }
  },
}

const mutations = {
  [SET_NOTIFICATIONS] (state, notifications) {
    state.notifications = notifications
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
