<template>
    <v-progress-linear
      v-if="form.loading" 
      size="100"
      indeterminate
      color="primary"
    ></v-progress-linear>
  <v-container v-else-if="accreditation.id" class="pa-9" fluid>
    <v-card class="elevation-1 rounded-0 mb-5 pa-4">
      <v-card-title>
        <h3 class="primary--text">{{ $t('accreditation.title') }}</h3>
      </v-card-title>
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="6">
            <p>
              {{ $t('accreditation.description.authorizedToBookViaPlatform') }}
            </p>
            <p>
              {{ $t('accreditation.description.companyIdentityIsChecked') }}
            </p>
          </v-col>

          <v-col cols="6" class="pl-10">
            <v-list dense>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="primary--text" size="20">
                    fal fa-arrow-right
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <h3>{{ $t('accreditation.hints.enterCompanyDetails') }}</h3>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="primary--text" size="20">
                    fal fa-arrow-right
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <h3>{{ $t('accreditation.hints.uploadExcerpt') }}</h3>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="primary--text" size="20">
                    fal fa-arrow-right
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <h3>
                    {{ $t('accreditation.hints.publicSectorParticipationInformation') }}
                  </h3>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
        v-if="isStatusSet && !isNotCompleted"
        class="elevation-1 rounded-0 mb-5 pa-4"
    >
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12">
            <h3 class="text--primary">
              {{
                $t(`accreditation.statusTexts.${accreditation.attributes.status}`, {
                  date: accreditation.attributes.accreditation_approved_at 
                    ? formatDateByLocale(accreditation.attributes.accreditation_approved_at) 
                    : accreditation.attributes.accreditation_rejected_at
                    ? formatDateByLocale(accreditation.attributes.accreditation_rejected_at) 
                    : null,
                })
              }}
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="elevation-1 rounded-0 mb-5 pa-4" v-else>
      <v-card-text class="text--primary">
        <v-row>
          <v-col>
            <h3 class="font-weight-bold">
              {{
                $t(`settings.sections.accreditation.statuses.${accreditation.attributes.status}`)
              }}
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
        v-if="accreditation.attributes.accreditation_approved_at"
        class="elevation-1 rounded-0 mb-5 pa-4"
    >
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12">
            <h3>{{ $t('tables.accreditation.currentAccreditation') }}</h3>
          </v-col>
          <v-col cols="4">
            <p>
              <b>{{ $t('accreditation.steps.companyData.title') }}</b> <br/>
              {{ accreditation.attributes.company }}<br/>
              {{ accreditation.attributes.street }}<br/>
              <span v-if="accreditation.attributes.address_supplement">
                {{ accreditation.attributes.address_supplement }}<br/>
              </span>
              {{ accreditation.attributes.postalcode + ' ' + accreditation.attributes.city }}<br/>
              <span v-if="accreditation.relationships.federal_state">
                {{ accreditation.relationships.federal_state ? accreditation.relationships.federal_state.attributes.name[$i18n.locale] : '' }}<br/>
              </span>
              {{ accreditation.relationships.country?.translations[$i18n.locale].country }}<br/>
            </p>
            <p>
              <i class="fas fa-phone primary--text mr-3"/>
              {{ accreditation.attributes.phone }}<br/>
              <span v-if="accreditation.attributes.fax">
                <i class="fas fa-fax primary--text mr-3"/>
                {{ accreditation.attributes.fax }}<br/>
              </span>
              <i class="fas fa-envelope primary--text mr-3"/>
              {{ accreditation.attributes.email }}<br/>
              <i class="fas fa-home primary--text mr-3"/>
              {{ accreditation.attributes.website }}<br/>
            </p>
          </v-col>
          <v-col cols="4">
            <p>
              <b>{{ $t('accreditation.steps.registerExcerpt.title') }}</b>
              <br/>
              {{ $t('forms.fields.registryCourt') + ': ' }}
              {{
                !accreditation.attributes.registry_court
                    ? $t('accreditation.notAvailable')
                    : accreditation.attributes.registry_court
              }}

              <br/>
              {{ $t('forms.fields.registryNumber') + ': ' }}
              {{
                !accreditation.attributes.registry_number
                    ? $t('accreditation.notAvailable')
                    : accreditation.attributes.registry_number
              }}
              <br/>
              {{ $t('forms.fields.vatIdNo') + ': ' }}
              {{
                !accreditation.attributes.vat_id
                    ? $t('accreditation.notAvailable')
                    : accreditation.attributes.vat_id
              }}
            </p>
            <p
                v-if="accreditation.attributes.is_foreign_branch || accreditation.attributes.is_foreign_representation"
            >
              <v-icon class="primary--text mb-1 mr-3" size="14">
                fal
                {{ accreditation.attributes.is_foreign_branch ? 'fa-check' : 'fa-times' }}
                fa-fw
              </v-icon>
              <span>{{ $t('tables.accreditation.foreignBranch') }}</span
              ><br/>
              <v-icon class="primary--text mb-1 mr-3" size="14">
                fal
                {{ accreditation.attributes.is_foreign_representation ? 'fa-check' : 'fa-times' }}
                fa-fw
              </v-icon>
              <span>{{
                  $t('tables.accreditation.foreignRepresentation')
                }}</span>
              <br/><br/>
              <small>{{
                  $t('tables.accreditation.connectedCompany') + ': '
                }}</small
              ><br/>
              <span> {{ accreditation.attributes.related_company }} </span>
            </p>
          </v-col>
          <v-col cols="4">
            <p>
              <b>{{ $t('accreditation.steps.publicParticipation.title') }}</b>
            </p>
            <v-row>
              <v-col cols="auto">
                <v-icon class="primary--text" size="18">
                  fal
                  {{
                    accreditation.attributes.declared_public_sector_involvement
                        ? 'fa-times'
                        : 'fa-check'
                  }}
                  fa-fw
                </v-icon>
              </v-col>
              <v-col>
                {{ $t('accreditation.underPublicLaw.publicSectorInvolvement') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-icon class="primary--text" size="18">
                  fal
                  {{
                    accreditation.attributes.declared_public_sector_institution
                        ? 'fa-times'
                        : 'fa-check'
                  }}
                  fa-fw
                </v-icon>
              </v-col>
              <v-col>
                {{ $t('accreditation.underPublicLaw.publicSectorInstitution') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-icon class="primary--text" size="18">
                  fal
                  {{ accreditation.attributes.declared_public_sector_majority ? 'fa-times' : 'fa-check' }}
                  fa-fw
                </v-icon>
              </v-col>
              <v-col>
                {{ $t('accreditation.underPublicLaw.publicSectorMajority') }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-alert dense outlined type="warning">
              {{ $t('accreditation.alert') }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-stepper
        v-if="shouldAccreditationFormBeShown"
        v-model="step"
        class="elevation-1 rounded-0"
        non-linear
    >
      <v-stepper-header class="elevation-0">
        <v-stepper-step :complete="step > 1" step="1">
          {{ $t('accreditation.steps.companyData.title') }}
        </v-stepper-step>

        <v-divider/>

        <v-stepper-step :complete="step > 2" step="2">
          {{ $t('accreditation.steps.registerExcerpt.title') }}
        </v-stepper-step>

        <v-divider/>

        <v-stepper-step :complete="step > 3" step="3">
          {{ $t('accreditation.steps.publicParticipation.title') }}
        </v-stepper-step>

        <v-divider/>

        <v-stepper-step :complete="step > 4" step="4">
          {{ $t('accreditation.steps.conditions.title') }}
        </v-stepper-step>

        <v-divider/>

        <v-stepper-step step="5">
          {{ $t('accreditation.steps.complete.subTitle') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <company-and-contact-details v-if="!form.loading" :accreditation="accreditation" :accreditation_old="accreditation_old" @cancel="handleAccreditationCancel" @submit="next(); load()"/>
        </v-stepper-content>

        <v-stepper-content step="2">
          <register-excerpt :accreditation="accreditation" :accreditation_old="accreditation_old" @cancel="handleAccreditationCancel" @submit="next"/>
        </v-stepper-content>

        <v-stepper-content step="3">
          <public-participation :accreditation="accreditation" :accreditation_old="accreditation_old" @cancel="handleAccreditationCancel" @submit="next"/>
        </v-stepper-content>

        <v-stepper-content step="4">
          <conditions :accreditation="accreditation" :accreditation_old="accreditation_old" @cancel="handleAccreditationCancel" @submit="next"/>
        </v-stepper-content>

        <v-stepper-content step="5">
          <complete :accreditation="accreditation" :accreditation_old="accreditation_old" @cancel="handleAccreditationCancel" @submit="handleAccreditationSubmit"/>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <accreditation-submit-modal ref="modal"></accreditation-submit-modal>
  </v-container>
</template>

<script>
import CompanyAndContactDetails from '@/components/exhibitor/Company/CompanyAndContactDetails'
import RegisterExcerpt from '@/components/exhibitor/Accreditation/RegisterExcerpt'
import PublicParticipation from '@/components/exhibitor/Accreditation/PublicParticipation'
import Conditions from '@/components/exhibitor/Accreditation/Conditions'
import Complete from '@/components/exhibitor/Accreditation/Complete'
import AccreditationSubmitModal from '@/components/exhibitor/Accreditation/AccreditationSubmitModal'
import {ACCREDITATION_STATUSES} from '@/enums/accreditation'
import {mapGetters, mapActions} from 'vuex'
import date from '@/mixins/date'

export default {
  name: 'ExhibitorAccreditation',

  mixins: [date],

  components: {
    CompanyAndContactDetails,
    RegisterExcerpt,
    PublicParticipation,
    Conditions,
    Complete,
    AccreditationSubmitModal,
  },

  data() {
    return {
      accreditation: {},
      accreditation_old: {},

      form: {
        loading: false,
      },

      step: 1,
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    isStatusSet() {
      return typeof this.accreditation.attributes?.status !== 'undefined'
    },

    isNotCompleted() {
      return (
          this.isStatusSet &&
          this.accreditation.attributes?.status === ACCREDITATION_STATUSES.CARRY_OUT
      )
    },

    isApprovalPending() {
      return (
          this.isStatusSet &&
          this.accreditation.attributes?.status === ACCREDITATION_STATUSES.APPROVAL_PENDING
      )
    },

    shouldAccreditationFormBeShown() {
      return !this.isStatusSet || !this.isApprovalPending
    },
  },

  created() {
    this.load()
  },

  methods: {
    next() {
      this.step++
    },

    async load() {
      this.form.loading = true

      let response1 = (await this.$axios.get(`/api/companies/${this.user.companyId}?ignoreApprove=1`, {
        params: {
          'languages': '*',
          'include': [
            'country',
            'company',
            'federalState',
          ],
        },
      })).data

      let response2 = (await this.$axios.get(`/api/companies/${this.user.companyId}?ignoreApprove=0`, {
        params: {
          'languages': '*',
          'include': [
            'country',
            'company',
            'federalState',
          ],
        },
      })).data

      this.accreditation = response1.data
      this.accreditation_old = response2.data

      this.form.loading = false
    },

    handleAccreditationSubmit() {
      this.$refs.modal.show()
    },

    async handleAccreditationCancel() {
      await this.$axios.delete(`/api/accreditations/${this.accreditation.id}`)

      await this.load()

      this.step = 1
    },

    ...mapActions('accreditation', ['downloadDocument']),
  },
}
</script>
