import axios from 'axios'
import store from "@/boot/store";
import {SNACKBAR_TYPES} from "@/enums/snackbar";

axios.defaults.baseURL = process.env.VUE_APP_API_ROOT_URL

axios.interceptors.request.use((config) => {
    const token = store.state.auth.token

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})


const Interceptor = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        Vue.prototype.$axios = axios

        Vue.prototype.$snackbar = function (message, type = SNACKBAR_TYPES.SUCCESS) {
            /** TODO: migrate snackbar from vuex */
            store.commit('snackbar/SET_SNACKBAR', { message, type })
        }
    }
};

export const $axios = axios

export default Interceptor;