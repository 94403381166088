<template>
  <div>
    <v-stepper-step
      :step="step"
      :editable="isEditable"
      :rules="[() => !isStepsErrored]"
      @click="$emit('click')"
    >
      <span class="stepper-abstract" />
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{ $t('participation.checkAndSend.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">
        {{
          $t('participation.checkAndSend.error', lang)
        }}
      </small>
    </v-stepper-step>

    <v-stepper-content :step="step" class="pb-10">
      <v-textarea
        v-model="form.data.message"
        :label="$t('participation.checkAndSend.message')"
        :disabled="isReadonlyMode"
        rows="3"
        class="rounded-0"
        filled
        dense
      />
      <v-row>
        <v-col cols="12" class="pr-10">
          {{
            $t('participation.checkAndSend.registrationRules.registrationWillBeBindingIf', lang)
          }}
          <v-list dense>
            <v-list-item class="px-0">
              <v-list-item-icon class="mr-3">
                <v-icon size="18" class="primary--text"> fal fa-check </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                {{
                  $t('participation.checkAndSend.registrationRules.reviewCarriedOutByCompany',
                      lang
                  ).replaceAll('{companyName}', `${fair.relationships.organizer?.name}`)
                }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-0">
              <v-list-item-icon class="mr-3">
                <v-icon size="18" class="primary--text"> fal fa-check </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                {{
                  $t('participation.checkAndSend.registrationRules.paidDownPaymentInvoice', lang)
                }}
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider class="my-5" />

          <p>
            <strong class="secondary--text">
              {{
                $t('participation.checkAndSend.payment.totalCosts', lang)
              }}: {{ totalPrice || 0 | money }}
            </strong>
            <br />
            {{
              $t('participation.checkAndSend.payment.plusTaxes', lang)
            }}
          </p>
          <p class="text-small">
            <span
              v-html="$t('participation.checkAndSend.payment.taxesDescription', lang)"
            ></span>
          </p>
          <p v-if="fair.attributes.sponsor == SPONSORS.BMEL" class="text-small">
            <span
              v-html="$t('participation.checkAndSend.bmel', lang)"
            ></span>
          </p>
          <v-btn
            @click="handleSubmit"
            :disabled="!isSubmittedForm || (!isPreviewMode && (!company?.attributes?.accreditation_approved_at || !!company?.attributes?.accreditation_rejected_at))"
            :loading="form.loading"
            class="elevation-0 secondary"
          >
            {{ $t('forms.buttons.submit', lang) }}
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-alert
            v-if="(!company?.attributes?.accreditation_approved_at || !!company?.attributes?.accreditation_rejected_at) && company?.attributes.status"
            dense
            outlined
            type="warning"
          >
            {{
              $t(`settings.sections.accreditation.statuses.${company.attributes.status}`)
            }}
          </v-alert>
        </v-col>
      </v-row>

      <submit-success-modal :mode="mode" ref="submitSuccessModal"></submit-success-modal>
    </v-stepper-content>
  </div>
</template>

<script>
import { SPONSORS } from '@/enums/sponsors'
import { mapActions, mapGetters } from 'vuex'
import participationMode from '@/mixins/participationMode'
import SubmitSuccessModal from '@/components/exhibitor/Participation/Modals/SubmitSuccess'
import validation from '@/mixins/validate'

export default {
  name: 'CheckAndSend',

  components: {
    SubmitSuccessModal,
  },

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  mixins: [
    validation,
    participationMode,
  ],

  computed: {
    ...mapGetters('participation', ['stepsError', 'cost']),

    totalPrice() {
      return this.cost.total
    },
  },

  data() {
    return {
      form: {
        loading: false,
        data: {
          message: '',
        },
        validationRules: {
          message: {},
        },
        isValid: true,
      },

      SPONSORS,
    }
  },

  created() {
    this.form.data.message = this.participation?.attributes?.message
  },

  methods: {
    ...mapActions('participation', [
      'updateParticipation',
      'submitParticipation',
      'checkValidationSteps',
    ]),

    async handleSubmit() {
      if (await this.checkValidationSteps({ fair: this.fair, participation: this.participation })) {
        this.$emit('open', this.stepsError[0])

        return
      }

      if (this.isPreviewMode) {
        this.$emit('submit')
        this.$refs.submitSuccessModal.show()
        return
      }

      if (!(await this.updateParticipation({ fair: this.fair, participation: this.participation, data: this.getFormData() }))) {
        return
      }

      if (await this.submitParticipation({ participation: this.participation })) {
        this.$refs.submitSuccessModal.show()
        this.$emit('submit')
      }
    },
  },
}
</script>
