<template functional>
  <div
    v-if="props.messages"
    class="v-messages theme--light"
    :class="`${props.type}--text`"
  >
    <div class="v-messages__wrapper">
      <div
        v-for="message in props.messages"
        :key="message"
        class="v-messages__message"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      required: false,
    },
    type: {
      type: String,
      required: true,
    }
  },
}
</script>

