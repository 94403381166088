import { api } from '@/boot/api'
import {
  SET_COMPANY,
  SET_COMPANIES,
  SET_COMPANY_PREFERENCES,
  SET_ACCREDITATION,
  SET_REGISTER_EXTRACT,
  SET_UPLOAD_PROGRESS,
} from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({
  company: {},
  companies: [],
  companyPreferences: {
    tradeFairs: [],
    countries: [],
    industrySectors: [],
  },
  accreditation: {},
  registerExtract: '',
  upload: {
    loaded: 0,
    total: 0,
  },
})

const state = defaultState()

const actions = {
  async fetchCompany({ commit, rootGetters }, payload) {
    try {
      const user = rootGetters['auth/user']
      const isAdmin = rootGetters['auth/isAdmin']
      const isOrganizer = rootGetters['auth/isOrganizer']

      if (!isAdmin && !isOrganizer && !user.companyId && !payload?.id) {
        console.error('Company is not attached to the current user, therefore unable to fetch it')
        return false
      }

      const companyId = payload?.id ?? user.companyId
      const company = await api.get(`/companies/${companyId}?ignoreApprove=${ payload?.ignoreApprove ? 1 : 0}`)
      commit(SET_COMPANY, company)

      return true
    } catch (error) {
      console.error('API error (fetch company): ', error)
      return false
    }
  },

  async fetchCompanies({ commit, state }) {
    try {
      if (Object.keys(state.companies).length !== 0) {
        return false
      }

      const companies = await api.get('/companies')
      commit(SET_COMPANIES, companies)

      return true
    } catch (error) {
      console.error('API error (fetch companies): ', error)
      return false
    }
  },

  async deleteCompany({ rootGetters, dispatch }, companyId) {
    try {
      const isAdmin = rootGetters['auth/isAdmin']

      if (!isAdmin) {
        console.error('Available only for admin')
        return false
      }

      await api.delete(`/companies/${companyId}`)

      dispatch('fetchCompanies')

      return true
    } catch (error) {
      console.error('API error (Delete company): ', error)
      return false
    }
  },

  async fetchCompanyPreferences({ commit, rootGetters }) {
    try {
      const user = rootGetters['auth/user']
      if (!user.companyId) {
        return
      }

      const companyPreferences = await api.get(`/company-preferences/${user.companyId}`)
      commit(SET_COMPANY_PREFERENCES, companyPreferences)

      return true
    } catch (error) {
      console.error('API error (fetch company preferences): ', error)
      return false
    }
  },

  async updateCompanyPreferences({ dispatch }, payload) {
    try {
      await api.post(`/company-preferences`, payload, {
        snakifyOutgoing: false,
        showSuccessNotifications: true,
      })

      await dispatch('fetchCompanyPreferences')

      return true
    } catch (error) {
      console.error('API error (fetch company preferences): ', error)
      return false
    }
  },

  async createAccreditation({ commit, rootGetters }, data) {
    try {
      const user = rootGetters['auth/user']
      if (!user.companyId) {
        return
      }

      const accreditation = await api.post(
        '/accreditations',
        {
          ...data,
          companyId: user.companyId
        },
        {
          showSuccessNotifications: !data.isOnboarding,
        }
      )

      commit(SET_ACCREDITATION, accreditation)

      return true
    } catch (error) {
      console.error('API error (save accreditation): ', error)
      return false
    }
  },

  async updateAccreditation({ commit, state }, data) {
    try {
      const accreditation = await api.patch(`/accreditations/${data.id ?? state.accreditation.id}`, data, {
        showSuccessNotifications: true,
      })
      commit(SET_ACCREDITATION, accreditation)

      return true
    } catch (error) {
      console.error('API error (update accreditation): ', error)
      return false
    }
  },

  async uploadRegisterExcerpt({ commit, state }, { document, id }) {
    try {
      commit(SET_UPLOAD_PROGRESS, { loaded: 0, total: 0 })
      let formData = new FormData()
      formData.append('document', document, document.name)
      const config = {
        onUploadProgress: progressEvent => commit(
          SET_UPLOAD_PROGRESS,
          {
            loaded: progressEvent.loaded,
            total: progressEvent.total,
          }
        )
      }

      const documentPath = await api.post(
        `/accreditations/${id ?? state.accreditation.id}}/documents`,
        formData,
        {},
        config
      )
      commit(SET_REGISTER_EXTRACT, documentPath)

      return true
    } catch (error) {
      console.error('API error (upload accreditation register excerpt document): ', error)
      return false
    }
  },

  async submitAccreditation({ commit, state }) {
    try {
      const accreditation = await api.post(`/accreditations/${state.accreditation.id}/request`)
      commit(SET_ACCREDITATION, accreditation)

      return true
    } catch (error) {
      console.error('API error (submit accreditation): ', error)
      return false
    }
  },
}

const mutations = {
  [SET_COMPANY] (state, company) {
    state.company = company
  },

  [SET_COMPANIES] (state, companies) {
    state.companies = companies
  },

  [SET_COMPANY_PREFERENCES] (state, { tradeFairs, countries, industrySectors }) {
    state.companyPreferences.tradeFairs = tradeFairs
    state.companyPreferences.countries = countries
    state.companyPreferences.industrySectors = industrySectors
  },

  [SET_ACCREDITATION] (state, accreditation) {
    state.accreditation = accreditation
  },

  [SET_REGISTER_EXTRACT] (state, file) {
    state.registerExtract = file
  },

  [SET_UPLOAD_PROGRESS] (state, { loaded, total }) {
    state.upload.loaded = loaded
    state.upload.total = total
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,

  uploadedInPercent: state => parseInt(Math.round( ( state.upload.loaded / state.upload.total ) * 100 )),

  uploadedInMB: state => state.upload.loaded / 1024 / 1024,

  toUploadInMB: state => state.upload.total / 1024 / 1024,

  isCompanyFetched: state => Object.keys(state.company).length !== 0,

  isOnboardCompleted: (state) => Boolean(state.company.company && state.company.street && state.company.city),
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
