<template>
  <forgot-password-form
    @loginRequested="$emit('loginRequested')"
  />
</template>

<script>
import ForgotPasswordForm from '@/components/base/Auth/ForgotPasswordForm'

export default {
  name: 'ExhibitorForgotPassword',

  components: {
    ForgotPasswordForm,
  },
}
</script>
