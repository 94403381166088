<template>
  <v-app>
    <supervisor-header />

    <supervisor-menu-bar />

    <v-main class="grey lighten-4">
      <v-container fluid class="px-0 py-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SupervisorHeader from '@/components/supervisor/Header'
import SupervisorMenuBar from '@/components/supervisor/MenuBar'

export default {
  name: 'SupervisorApp',

  components: {
    SupervisorHeader,
    SupervisorMenuBar,
  },
}
</script>
