<template>
  <v-expansion-panel class="rounded-0">
    <v-expansion-panel-header>
      <h4>{{ $t('participation.configureRegistrationForm.equipment.title') }}</h4>
    </v-expansion-panel-header>
    <v-expansion-panel-content>

      <p v-html="$t('participation.configureRegistrationForm.equipment.optionalNotice')"></p>

      <v-sheet class="mb-5 pa-4" v-for="(item) in services" :key="item.id" outlined>
        <v-row>
          <v-col cols="10">
            <strong>{{ item.attributes.title[$i18n.locale] }}</strong>

            <p class="grey--text lighten-4">
              <small><i class="fal fa-eye"></i> {{ $t('participation.configureRegistrationForm.equipment.previewNotice')}}</small>
            </p>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn color="secondary" @click="edit(item)" icon>
              <v-icon size="18">fal fa-pencil</v-icon>
            </v-btn>
            <v-btn color="secondary" @click="remove(item)" icon>
              <v-icon size="18">fal fa-trash-alt</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">

            <table class="services">
              <tr>
                <td>
                  <v-chip v-if="!item.attributes.mandatory" small outlined>{{ $t('participation.configureRegistrationForm.equipment.optional') }}</v-chip>
                  <v-chip v-if="item.attributes.mandatory" class="red--text" small outlined>{{ $t('participation.configureRegistrationForm.equipment.mandatorySelection') }}</v-chip>
                </td>
                <td>
                  <strong>{{ item.attributes.title[$i18n.locale] }}</strong>
                  <div v-html="item.attributes.description[$i18n.locale]"></div>
                </td>
                <td>
                  <p>{{ item.attributes.unit[$i18n.locale] }}</p>
                </td>
                <td>
                  <v-text-field :label="$t('participation.configureRegistrationForm.amount') + ' ('+item.attributes.unit[[$i18n.locale]]+')'" v-if="item.attributes.amount_selectable" value="1" number readonly></v-text-field>
                  <v-checkbox v-if="!item.attributes.amount_selectable" class="mt-0" readonly></v-checkbox>
                </td>
                <td>
                  <v-text-field :label="$t('participation.configureRegistrationForm.priceQ')" v-model="item.attributes.price" readonly min="0" type="number"></v-text-field>
                </td>
                <td>
                  <v-text-field :label="$t('participation.configureRegistrationForm.equipment.additionalTextInputLabelling')" v-model="item.attributes.specification_label[$i18n.locale]" v-if="item.attributes.specifiable" readonly></v-text-field>
                </td>
                <td class="text-right">
                  <v-btn color="secondary" v-if="item.attributes.multiselectable" disabled icon>
                    <v-icon size="18">fal fa-trash-alt</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-if="item.attributes.multiselectable">
                <td colspan="7" class="text-right">
                  <v-btn color="secondary" disabled icon>
                    <v-icon size="18">fal fa-plus</v-icon>
                  </v-btn>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>

      </v-sheet>

      <v-row class="mx-0 my-2">
        <v-btn color="secondary" class="mb-5" @click="add" text outlined>
          <v-icon class="mr-2" size="18">fal fa-plus mr-2</v-icon>
          {{ $t('participation.configureRegistrationForm.equipment.addAnotherOption') }}
        </v-btn>
      </v-row>
      <v-btn color="secondary" class="px-10" elevation="0" @click="save">
        {{ $t('forms.buttons.continue') }}
      </v-btn>
      <organizer-customize-services-edit ref="edit" :fair="fair" @submit="load"></organizer-customize-services-edit>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import OrganizerCustomizeServicesEdit from '@/components/organizer/Application/Customize/CustomizeRegistrationServicesEdit';

export default {
  name: 'CustomizeRegistrationServices',

  components: {
    OrganizerCustomizeServicesEdit
  },

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      services: [],

      form: {
        loading: false,

        data: {},
      },
    }
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/trade-fairs/${this.fair.id}/services`, {
        params: {
          'languages': '*',
          'filter[fee]': false,
          'include': [],
        },
      })).data

      this.services = response.data

      this.form.loading = false
    },

    async remove(item) {
      if(confirm(this.$t('participation.configureRegistrationForm.equipment.alertDeleteOption'))) {
        await this.$axios.delete(`/api/trade-fairs/${this.fair.id}/services/${item.id}`, {})

        this.$snackbar(this.$t('common.successApiMessage'))

        this.load()
      }
    },

    edit(item) {
      this.$refs.edit.show(item)
    },

    add() {
      this.$refs.edit.show()
    },

    save() {
      this.$emit('submit')
    }
  },
}
</script>

<style scoped>
  table.services { border-collapse: collapse; }
  table.services tr td { vertical-align: top; border-top: 1px solid #E9E9E9; padding: .75rem 1rem .5rem 0; }
</style>
