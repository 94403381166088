export default {
  fairs: {
    findTradeFairParticipations: 'Messebeteiligungen finden',
    tradeFairName: 'Messename',
    country: 'Land',
    year: 'Jahr',
    branch: 'Branche',
  },
  sponsor: {
    bmwi: 'Bundesministerium für Wirtschaft und Klimaschutz',
    bmel: 'Bundesministerium für Ernährung und Landwirtschaft',
  }
};