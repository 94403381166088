import errors from '@/store/modules/errors'
import auth from '@/store/modules/auth'
import company from '@/store/modules/company'
import accreditation from '@/store/modules/accreditation'
import participation from '@/store/modules/participation'
import snackbar from '@/store/modules/snackbar'
import countries from '@/store/modules/countries'
import states from '@/store/modules/states'
import sectors from '@/store/modules/sectors'
import tradefair from '@/store/modules/tradefair'
import help from '@/store/modules/help'
import organizer from '@/store/modules/organizer'
import association from '@/store/modules/association'
import languages from '@/store/modules/languages'
import users from '@/store/modules/users'
import notes from '@/store/modules/notes'
import notification from '@/store/modules/notification'

const storeConfig = {
  modules: {
    errors,
    auth,
    participation,
    snackbar,
    countries,
    states,
    sectors,
    company,
    tradefair,
    help,
    organizer,
    languages,
    accreditation,
    association,
    users,
    notes,
    notification
  },
}

export default storeConfig
