import { api } from '@/boot/api'
import { $axios } from '@/boot/interceptor'
import { SET_ACCREDITATIONS } from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({
  accreditations: [],
})

const state = defaultState()

const actions = {
  async fetchAccreditations({ commit}) {
    try {
      const response = await $axios.get('/api/accreditations', {
        params: {
          'languages': '*',
          'include': [],
        },
      })

      commit(SET_ACCREDITATIONS, response.data.data)

      return true
    } catch (error) {
      console.error('API error (fetch accreditations): ', error)
      return false
    }
  },

  async downloadDocument(...payload) {
    try {
     await api.post(`/accreditations/${payload[1]}/download`,
       {},
       {file: {name: 'register_extract_' + payload[1], type: 'pdf'}},
       {responseType: 'blob'}
     )

      return true
    } catch (error) {
      console.error('API error (fetch accreditations): ', error)
      return false
    }
  },
}

const mutations = {
  [SET_ACCREDITATIONS] (state, accreditations) {
    state.accreditations = accreditations
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
