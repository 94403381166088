<template>
  <v-expansion-panel class="elevation-1 rounded-0">
    <v-expansion-panel-header>
      <h3>{{ $t('participation.link.onlineRegistrationLink') }}</h3>
    </v-expansion-panel-header>

    <v-expansion-panel-content v-if="!isLoading">
      <p>{{ $t('participation.link.useRegistrationLink') }}</p>
      <small class="small">{{ $t('common.link') }}</small>
      <br />
      <code>{{ fair.included.registration_url }}</code>
      <br />
      <br />
      <small class="small">{{ $t('common.fullLinkTag') }}</small>
      <br />
      <code>&lt;a href=&quot;{{ fair.included.registration_url }}&quot;
        target=&quot;_blank&quot;&gt;{{ fair.attributes.name_display }}&lt;/a&gt;</code>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    fair: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
}
</script>
