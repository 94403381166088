import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'
import { getCurrentLocale } from '@/utilities/helpers/i18n'

import '@fortawesome/fontawesome-pro/css/all.css'

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#004E77',
        secondary: '#1AA8C0',
        accent: '#b51c1c',
        error: '#b51c1c',
        info: '#1AA8C0',
        success: '#46864a',
        warning: '#e7b10f',
      },
    },
  },

  defaultAssets: false,

  icons: {
    iconfont: 'fa',
    values: {
      complete: 'fal fa-check',
      cancel: 'fal fa-xmark',
      close: 'fal fa-xmark',
      delete: 'fal fa-circle-xmark',
      clear: 'fal fa-times',
      success: 'fal fa-circle-check',
      info: 'fal fa-info',
      warning: 'fal fa-circle-exclamation',
      error: 'fal fa-triangle-exclamation',
      prev: 'fal fa-angle-left',
      next: 'fal fa-angle-right',
      checkboxOn: 'fas fa-square-check',
      checkboxOff: 'fal fa-square',
      checkboxIndeterminate: 'fal fa-square',
      delimiter: '...', // for carousel
      sort: 'fal fa-arrow-down',
      expand: 'fal fa-angle-down',
      menu: 'fal fa-bars',
      subgroup: '...',
      dropdown: 'fal fa-angle-down',
      radioOn: 'fas fa-circle-check',
      radioOff: 'far fa-circle',
      edit: 'fas fa-pen-to-square',
      ratingEmpty: 'fal fa-star',
      ratingFull: 'fas fa-star',
      ratingHalf: 'far fa-star-half',
      loading: 'far fa-spinner',
      first: 'fal fa-angles-left',
      last: 'fal fa-angles-right',
      unfold: '...',
      file: 'fal fa-file'
    }
  },

  lang: {
    locales: { de, en },
    current: getCurrentLocale(),
  },

})

Vue.use(Vuetify)
