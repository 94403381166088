<template>
  <v-container class="d-flex pa-0" style="min-height: 90vh;" fluid no-gutters>
    <v-row>
      <v-col cols="12" class="pa-4 pr-6 mt-3">
        <router-view @submit="load" :fairs="fairs" :participations="participations" :loading="form.loading"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ExhibitorParticipation',

  data() {
    return {
      fairs: [],

      participations: [],

      form: {
        loading: false,
      },
    }
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      this.loadFairs()
      this.loadParticipations()
    },

    async loadFairs() {
      let page = 1
      let response = null

      do {
        response = (await this.$axios.get('/api/trade-fairs', {
          params: {
            'languages': '*',
            'include': [
              'country',
              'industrySectors',
              'organizer',
            ],
            'page[number]': page,
            'page[size]': 500,
            'sort': '',
          },
        })).data
        page += 1

        this.fairs = this.fairs.concat(response.data)
      } while (response.data?.length)
    },

    async loadParticipations() {
      let page = 1
      let response = null

      do {
        response = (await this.$axios.get('/api/company/participations', {
          params: {
            'languages': '*',
            'include': [
              'tradeFair',
              'tradeFair.country',
              'tradeFair.industrySectors',
              'tradeFair.organizer',
            ],
            'sort': '',
            'page[number]': page,
            'page[size]': 500,
          },
        })).data
        page += 1

        this.participations = this.participations.concat(response.data)
      } while (response.data?.length)
    },
  }
}
</script>
