<template>
  <v-container class="pa-9" fluid>
    <v-card class="elevation-1 rounded-0">

      <v-card-title>
        <h4 class="primary--text">{{ $t('help.manageEntries') }}</h4>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row>

          <v-col>
            <v-text-field
              v-model="search"
              :label="$t('help.textSearchOrID')"
            />
          </v-col>

          <v-col class="text-right">
            <v-btn
              class="mt-4"
              @click="showCreateArticleModal"
              icon
            >
              <v-icon size="20" color="secondary">fal fa-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-data-table
          :loading="$wait.is(loaders.fetchHelpArticles)"
          :headers="headers"
          :items="articles"
          :search="search"
          item-key="id"
          single-expand
          show-expand
          @item-expanded="handleArticleExpanding"
      >

        <template v-slot:item.active="{ item }">
          <v-icon v-if="item.active" size="16" class="primary--text">fal fa-fw fa-check</v-icon>
          <v-icon v-if="!item.active" size="16" class="primary--text">fal fa-fw fa-times</v-icon>
        </template>

        <template v-slot:item.headline="{ item }" >
          {{ item.translations[$i18n.locale].headline }}
        </template>

        <template v-slot:item.abstract="{ item }" >
          <div v-html="item.translations[$i18n.locale].abstract.replace(/<\/?[^>]+(>|$)/g, ' ').slice(0, 50)" class="text-truncate"/>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu open-on-click bottom left offset-x>
            <template v-slot:activator="{ on }">
              <v-btn
                  color="secondary"
                  icon
                  v-on="on"
              >
                <v-icon>fal fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="showDeleteArticleModal(item)"
                >{{ $t('help.action.deleteEntry') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:expanded-item="{}">
          <td :colspan="headers.length" class="elevation-1 px-1 grey lighten-5">
            <v-container fluid>
              <v-row>

                <v-col cols="12" class="px-7 pb-0">
                  <v-switch
                    v-model="form.data.active"
                    :label="$t('help.form.entryActive')"
                  />
                </v-col>

                <v-col cols="6" class="px-7">
                  <v-text-field
                    class="rounded-0"
                    :label="$t('help.form.headlineDE')"
                    v-model="form.data.headline.de"
                    :error-messages="errors.headline.de"
                    filled
                    dense
                  />
                  <label class="v-label theme--light">{{ $t('help.form.summaryDE') }}
                  <vue-editor
                    :placeholder="$t('help.form.summaryDE')"
                    v-model="form.data.abstract.de"
                    :error-messages="errors.abstract.de"
                  />
                  </label>
                  <br />
                  <label class="v-label theme--light">{{ $t('help.form.textDE') }}
                    <vue-editor
                      :placeholder="$t('help.form.textDE')"
                      v-model="form.data.text.de"
                      :error-messages="errors.text.de"
                    />
                  </label>
                </v-col>

                <v-col cols="6" class="px-7">
                  <v-text-field
                    class="rounded-0"
                    :label="$t('help.form.headlineEN')"
                    v-model="form.data.headline.en"
                    :error-messages="errors.headline.en"
                    filled
                    dense
                  />
                  <label class="v-label theme--light">{{ $t('help.form.summaryEN') }}
                  <vue-editor
                    :placeholder="$t('help.form.summaryEN')"
                    v-model="form.data.abstract.en"
                    :error-messages="errors.abstract.en"
                  /></label>
                  <br />
                  <label class="v-label theme--light">{{ $t('help.form.textEN') }}
                  <vue-editor
                    :placeholder="$t('help.form.textEN')"
                    v-model="form.data.text.en"
                    :error-messages="errors.text.en"
                  />
                  </label>
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn
                    color="secondary"
                    class="elevation-0 mr-5"
                    @click="handleArticleUpdate"
                    :loading="$wait.is(loaders.updateArticle)"
                  >
                    {{ $t('common.save') }}
                  </v-btn>
                </v-col>

              </v-row>
            </v-container>
          </td>
        </template>

      </v-data-table>

    </v-card>

    <confirmation-modal
      ref="confirmationArticleDeleteModal"
      @confirm="handleArticleDelete"
      :loading="$wait.is(loaders.deleteArticle)"
      :async-hide="true"
    >
      {{ $t('common.generalConfirmation', { action: $t('help.action.deleteEntry').toLowerCase() } )}}
    </confirmation-modal>

    <create-article-modal
      ref="createArticleModal"
    />

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FETCH_HELP_ARTICLES, UPDATE_HELP_ARTICLE, DELETE_HELP_ARTICLE } from '@/store/loading-types'
import { mapWaitingActions } from 'vue-wait'
import validation from '@/mixins/validate'
import ConfirmationModal from '@/components/base/ConfirmationModal'
import CreateArticleModal from '@/components/admin/Help/Modals/CreateHelp'
import { VueEditor } from "vue2-editor";

export default {
  name: 'AdminHelp',

  mixins: [
    validation,
  ],

  components: {
    ConfirmationModal,
    CreateArticleModal,
    VueEditor
  },

  data() {
    return {
      activeArticleId: null,
      search: '',
      form: {
        data: {
          active: '',
          headline: {
            de: '',
            en: ''
          },
          abstract: {
            de: '',
            en: ''
          },
          text: {
            de: '',
            en: ''
          },
        },
        validationRules: {
          active: {},
          headline: {
            de: {},
            en: {}
          },
          abstract: {
            de: {},
            en: {}
          },
          text: {
            de: {},
            en: {}
          },
        },
      },
      loaders: {
        getTradeFairs: FETCH_HELP_ARTICLES,
        updateArticle: UPDATE_HELP_ARTICLE,
        deleteArticle: DELETE_HELP_ARTICLE,
      },
    }
  },

  computed: {
    ...mapGetters('help', [
      'articles'
    ]),

    headers() {
      return [
        {text: '', value: 'data-table-expand'},
        {text: '#ID', sortable: true, value: 'id'},
        {text: this.$t('help.table.active'), sortable: true, value: 'active'},
        {text: this.$t('help.table.headline'), sortable: true, value: 'headline'},
        {text: this.$t('help.table.summary'), sortable: true, value: 'abstract', width: '250px'},
        {text: '', sortable: true, value: 'actions', align: 'right'}
      ]
    }
  },

  created() {
    this.fetchHelpArticles()
  },

  methods: {
    ...mapActions('help', [
      'fetchHelpArticles',
      'updateArticle',
      'deleteArticle',
    ]),

    ...mapWaitingActions('help', {
      fetchHelpArticles: FETCH_HELP_ARTICLES,
      updateArticle: UPDATE_HELP_ARTICLE,
      deleteArticle: DELETE_HELP_ARTICLE,
    }),

    showCreateArticleModal() {
      this.$refs.createArticleModal.show()
    },

    handleArticleExpanding({ item: article, value: isOpened }) {
      if (!isOpened) {
        this.clearFormData()
      } else {

        this.activeArticleId = article.id

        let data = {
          active: article.active,
          headline: {
            de: article.translations.de.headline,
            en: article.translations.en.headline,
          },
          abstract: {
            de: article.translations.de.abstract,
            en: article.translations.en.abstract,
          },
          text: {
            de: article.translations.de.text,
            en: article.translations.en.text,
          },
        };

        this.setInitialFormData(data)
      }

    },

    showDeleteArticleModal(article) {
      this.activeArticleId = article.id
      this.$refs.confirmationArticleDeleteModal.show()
    },

    async handleArticleDelete() {
      await this.deleteArticle({id: this.activeArticleId})
      this.activeArticleId = null
      this.$refs.confirmationArticleDeleteModal.hide()
    },

    transformDataForUpdate() {
      const data = this.getFormData()
      return {
        active: data.active,
        en: {
          headline: data.headline.en,
          abstract: data.abstract.en,
          text: data.text.en,
        },
        de: {
          headline: data.headline.de,
          abstract: data.abstract.de,
          text: data.text.de,
        }
      }
    },

    handleArticleUpdate() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.updateArticle({ data: this.transformDataForUpdate(), id: this.activeArticleId })
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
