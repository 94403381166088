<template>
  <v-app>
    <v-container class="mx-3" fluid>
      <div class="language-picker">
        <language-picker />
      </div>
      <v-row style="min-height: 100vh" class="pa-0 my-0">
        <v-col cols="6" class="primary d-flex align-center">
          <v-row justify="center">
            <v-col cols="8">
              <div class="text-center">
                <v-icon :size="75" class="white--text mb-10">
                  fal fa-sign-in
                </v-icon>
              </div>

              <p class="white--text">{{ $t('registration.userAccess') }}</p>
              <p class="white--text">{{ $t('registration.userCan') }}</p>

              <v-list class="text-white" style="background: none">
                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>{{ $t('registration.possibilities.findFairs') }}</h3>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>
                      {{ $t('registration.possibilities.earlyRegister') }}
                    </h3>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>
                      {{ $t('registration.possibilities.bookParticipation') }}
                    </h3>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>
                      {{ $t('registration.possibilities.informYourself') }}
                    </h3>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="px-10 d-flex align-center">
          <v-row justify="center">
            <v-col cols="10">
              <h1 class="mb-5 primary--text">{{ $t('registration.title') }}</h1>

              <p>{{ $t('registration.description') }}</p>

              <signup-form />
            </v-col>

            <v-col cols="10">
              <logos />
            </v-col>

            <v-col cols="12">
              <v-row justify="center">
                <documents-list />
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import SignupForm from '@/components/exhibitor/Auth/SignupForm'
import LanguagePicker from '@/components/base/LanguagePicker'
import DocumentsList from '@/components/common/Documents/List'
import Logos from '@/components/base/Logos/Logos'

export default {
  name: 'AppExhibitorSignup',

  components: {
    SignupForm,
    LanguagePicker,
    Logos,
    DocumentsList,
  },
}
</script>

<style scoped>
.language-picker {
  position: absolute;
  top: 10px;
  right: 10px;
}
.v-application .mx-3 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.container {
  padding: 0;
}
</style>
