<template>
  <v-dialog
    max-width="900"
    class="rounded-0"
    :value="display"
    @click:outside="hide"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text" v-if="service?.id">
          {{ $t('participation.configureRegistrationForm.equipment.editEquipment') }}
        </h4>
        <h4 class="primary--text" v-if="!service?.id">
          {{ $t('participation.configureRegistrationForm.equipment.addEquipment') }}
        </h4>
        <v-btn @click="hide" icon>
          <v-icon size="20" color="primary">fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <v-form class="text--primary">
          <v-row>
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.equipment.label_de')"
                :error-messages="errors.title"
                v-model="form.data.title.de"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.equipment.label_en')"
                :error-messages="errors.title"
                v-model="form.data.title.en"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <vue-editor
                  :placeholder="$t('participation.configureRegistrationForm.service_description_de')"
                  rows="3"
                  class="mb-2"
                  v-model="form.data.description.de"
              ></vue-editor>
              <validation-general-error class="mt-2" :errors="errors.description" />
            </v-col>
            <v-col cols="6">
              <vue-editor
                  :placeholder="$t('participation.configureRegistrationForm.service_description_en')"
                  rows="3"
                  class="mb-2"
                  v-model="form.data.description.en"
              ></vue-editor>
              <validation-general-error class="mt-2" :errors="errors.description" />
            </v-col>

            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.pricePerUnit')"
                type="number"
                :error-messages="errors.price"
                v-model="form.data.price"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                :label="$t('forms.fields.unit_de')"
                :error-messages="errors.unit"
                v-model="form.data.unit.de"
              ></v-text-field>
              <v-text-field
                :label="$t('forms.fields.unit_en')"
                :error-messages="errors.unit"
                v-model="form.data.unit.en"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              <v-switch
                v-model="form.data.mandatory"
                class="my-0"
                inset
                dense
              ></v-switch>
            </v-col>
            <v-col cols="11">
              {{ $t('participation.configureRegistrationForm.equipment.mandatoryBooking') }}<br />
              <small
                v-if="form.data.mandatory"
                v-html="$t('participation.configureRegistrationForm.equipment.mandatoryBookingMust')"
              ></small>
              <small
                v-if="!form.data.mandatory"
                v-html="$t('participation.configureRegistrationForm.equipment.mandatoryBookingCan')"
              ></small>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-switch
                v-model="form.data.multiselectable"
                class="my-0"
                inset
                dense
              ></v-switch>
            </v-col>
            <v-col cols="11">
              {{ $t('participation.configureRegistrationForm.equipment.multipleSelectionPossible') }}<br />
              <small
                v-if="!form.data.multiselectable"
                v-html="$t('participation.configureRegistrationForm.equipment.multipleSelectionPossibleOnlyOnce')"
              ></small>
              <small
                v-if="form.data.multiselectable"
                v-html="$t('participation.configureRegistrationForm.equipment.multipleSelectionPossibleSeveralTimes')"
              ></small>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-switch
                v-model="form.data.amount_selectable"
                class="my-0"
                inset
                dense
              ></v-switch>
            </v-col>
            <v-col cols="11">
              {{ $t('participation.configureRegistrationForm.equipment.amountSelectable') }}<br />
              <small v-if="form.data.amount_selectable">
                {{ $t('participation.configureRegistrationForm.equipment.amountSelectableText') }}
              </small>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-switch
                v-model="form.data.specifiable"
                class="my-0"
                inset
                dense
              ></v-switch>
            </v-col>
            <v-col cols="11">
              {{ $t('participation.configureRegistrationForm.equipment.additionalTextInput') }}<br />

              <small v-if="form.data.specifiable">
                {{ $t('participation.configureRegistrationForm.equipment.additionalTextInputNotice') }}
              </small>

              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-if="form.data.specifiable"
                    :label="$t('participation.configureRegistrationForm.equipment.additionalTextInputLabelling_de')"
                    v-model="form.data.specification_label.de"
                    :error-messages="errors.specification_label"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-if="form.data.specifiable"
                    :label="$t('participation.configureRegistrationForm.equipment.additionalTextInputLabelling_en')"
                    v-model="form.data.specification_label.en"
                    :error-messages="errors.specification_label"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="elevation-0 col-2" @click="hide" color="secondary" outlined text>
          {{ $t('participation.configureRegistrationForm.cancel') }}
        </v-btn>

        <v-btn
          color="secondary"
          @click="handleSubmit"
          elevation="0"
          class="elevation-0 col-2 ml-auto"
        >
          {{ $t('participation.configureRegistrationForm.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validation from '@/mixins/validate'
import { minValue, required } from 'vuelidate/lib/validators'
import { VueEditor } from 'vue2-editor'
import ValidationGeneralError from '@/components/base/ValidationGeneralError'
import {translation} from '@/utilities/helpers/validation'

export default {
  name: 'CustomizeRegistrationServicesEdit',

  mixins: [validation],

  components: {
    VueEditor,
    ValidationGeneralError
  },

  props: {
    fair: {
      type: Object,
    },
  },

  data() {
    return {
      display: false,

      service: {},

      form: {
        loading: false,

        data: {
          title: {},
          description: {},
          price: 0,
          fee: false,
          mandatory: false,
          amount_selectable: false,
          multiselectable: false,
          specifiable: false,
          specification_label: {},
          unit: {},
        },

        validationRules: {
          fee: {},
          mandatory: {},
          amount_selectable: {},
          multiselectable: {},
          specifiable: {},
          specification_label: {},
          unit: {
            translation: translation(),
          },
          title: {
            translation: translation(),
          },
          description: {
            translation: translation(),
          },
          price: {
            required: required,
            minValue: minValue(0),
          },
        },

        custom: {
          validationSubstitutions: {
            price: {
              minValue: {
                limit: 0,
              },
            },
          },
        },
      },
    }
  },

  methods: {
    show(item) {
      this.service = item

      this.setInitialFormData(JSON.parse(JSON.stringify(item?.attributes ?? {})))

      if (!item) {
        this.form.data.title = { de: '', en: ''}
        this.form.data.description = { de: '', en: ''}
        this.form.data.unit = { de: '', en: ''}
        this.form.data.specification_label = { de: '', en: ''}
        this.form.data.fee = this.form.data.fee ?? false
        this.form.data.mandatory = this.form.data.mandatory ?? false
        this.form.data.amount_selectable = this.form.data.amount_selectable ?? false
        this.form.data.multiselectable = this.form.data.multiselectable ?? false
        this.form.data.specifiable = this.form.data.specifiable ?? false
      }

      this.$v.$reset()
      this.display = true
    },

    hide() {
      this.display = false
    },

    async handleSubmit() {
      this.setValidationRules()
      this.resetServerErrors()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        if (this.service?.id) {
          await this.$axios.put(`/api/trade-fairs/${this.fair.id}/services/${this.service?.id}`, {
            ...this.getFormData(),
            fee: false,
          })
        } else {
          await this.$axios.post(`/api/trade-fairs/${this.fair.id}/services`, {
            ...this.getFormData(),
            fee: false,
          })
        }

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')

        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },

    setValidationRules() {
      if (this.form.data.specifiable) {
        this.form.validationRules.specification_label = { translation: translation() }
      } else {
        this.form.validationRules.specification_label = {}
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
