<template>
  <v-row>
    <v-col cols="6">
      <p>{{ $t('settings.sections.notificationSettings.categories.title') }}</p>
      <v-switch
        :label="$t('settings.sections.notificationSettings.categories.processesToParticipation')"
        value="true"
        color="secondary"
        class="text--primary my-0"
        dense
      >
      </v-switch>
      <v-switch
        :label="$t('settings.sections.notificationSettings.categories.informationOnReservedFairs')"
        value="true"
        color="secondary"
        class="text--primary my-0"
        dense
      >
      </v-switch>
      <v-switch
        :label="$t('settings.sections.notificationSettings.categories.informationOnFederalFairProgram')"
        value="true"
        color="secondary"
        class="text--primary my-0"
        dense
      >
      </v-switch>
      <v-switch
        :label="$t('settings.sections.notificationSettings.categories.newFeaturesAndAnnouncements')"
        value="true"
        color="secondary"
        class="text--primary my-0"
        dense
      >
      </v-switch>
      <v-switch
        :label="$t('settings.sections.notificationSettings.categories.accountAndSecurity')"
        value="true"
        color="secondary"
        class="text--primary my-0"
        dense
      >
      </v-switch>
    </v-col>
    <v-col cols="6">
      {{ $t('settings.sections.notificationSettings.recipients.title') }}
      <v-list>
        <v-list-item class="px-0">
          <v-list-item-icon>
            <v-icon size="16" color="primary">fal fa-fw fa-arrow-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>abc@testhausen.ag</v-list-item-content>
          <v-list-item-action>
            <v-btn
              small
              icon
            >
              <v-icon
                size="16"
                color="secondary"
              >
                fal fa-fw fa-trash-alt
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="px-0">
          <v-list-item-icon>
            <v-icon
              size="16"
              color="primary"
            >
              fal fa-fw fa-arrow-right
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>xyz@testhausen.ag</v-list-item-content>
          <v-list-item-action>
            <v-btn
              small
              icon
            >
              <v-icon
                size="16"
                color="secondary"
              >
                fal fa-fw fa-trash-alt
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <div class="text-right">
        <v-btn
          color="secondary"
          class="ml-auto"
          text
        >
          + {{ $t('settings.sections.notificationSettings.recipients.addRecipient') }}
        </v-btn>
      </div>

    </v-col>

    <v-col cols="12">
      <v-btn
        color="secondary"
        class="elevation-0"
      >
        {{ $t('common.save') }}
      </v-btn>
    </v-col>

  </v-row>
</template>

<script>
export default {
  name: 'NotificationsSettings',
}
</script>
