export default {
    header: {
        federalForeignTradeFairProgram: 'The Foreign Trade Fair Participation Programme of Germany',
        eventOverviewAndExhibitorRegistration: 'Event overview and exhibitor registration',
        helpAdviceAndSupport: 'Help, advice and support',
        yourBookmarkedTradeFairs: 'Your bookmarked trade fairs',
        yourRegistrationHasBeenConfirmed: 'Your registration has been confirmed',
        by: 'By',
        allNotifications: 'All notifications',
        changePassword: 'Change password',
        dataAndSettings: 'Data and settings',
        logOut: 'Logout',
        search: 'Search …',
        searchFavorite: 'Search for your favorite trade fair',
        noResults: 'There are no results for your query.'
    },
};
