<template>
  <div>
    <v-chip
      :color="chipProperties.chipColor"
      :outlined="chipProperties.isOutlined"
      small
    >
      {{ chipProperties.chipText }}
    </v-chip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currentDate: new Date(),
    }
  },
  props: {
    tradefair: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['isAssociation']),

    chipProperties() {
      const moment = require('moment')

      if (this.tradefair.flag_cancelled) {
        return {
          chipColor: 'red',
          chipText: this.$t('tables.tradeFair.statuses.canceled'),
          isOutlined: false,
        }
      }

      if (
        this.tradefair.end_date &&
        moment(this.tradefair.end_date).isBefore(moment(this.currentDate))
      ) {
        if (this.tradefair.summary_submitted_at || this.isAssociation) {
          return {
            chipColor: 'grey',
            chipText: this.$t('tables.participation.count.completed'),
            isOutlined: true,
          }
        } else {
          return {
            chipColor: 'red',
            chipText: this.$t('tables.tradeFair.statuses.summaryMissing'),
            isOutlined: true,
          }
        }
      }

      if (
        this.tradefair.flag_registration_is_open &&
        this.tradefair.registration_deadline &&
        moment(this.tradefair.registration_deadline).isAfter(
          moment(this.currentDate)
        )
      ) {
        return {
          chipColor: 'green',
          chipText: this.$t(
            'tables.tradeFair.statuses.registrationDeadlineRuns'
          ),
          isOutlined: false,
        }
      }
      if (
        this.tradefair.flag_registration_is_open &&
        this.tradefair.registration_deadline &&
        moment(this.tradefair.registration_deadline).isBefore(
          moment(this.currentDate)
        ) && moment(this.tradefair.start_date).isAfter(moment(this.currentDate))
      ) {
        return {
          chipColor: 'black',
          chipText: this.$t(
            'tables.tradeFair.statuses.registrationDeadlineExpired'
          ),
          isOutlined: true,
        }
      }

      if (
        !this.tradefair.flag_cancelled &&
        moment(this.tradefair.start_date).isBefore(moment(this.currentDate)) &&
        moment(this.tradefair.end_date).isAfter(moment(this.currentDate))
      ) {
        return {
          chipColor: 'purple',
          chipText: this.$t(
            'tables.tradeFair.statuses.tradeFairInProgress'
          ),
          isOutlined: true,
        }
      }

      return {
        chipColor: 'transparent',
        chipText: '',
        isOutlined: true,
      }
    },
  },
}
</script>
