import { api } from '@/boot/api'
import { SET_USERS, SET_USER, ADD_USER, SET_USER_ORGANIZER } from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({
  users: [],
})

const state = defaultState()

const actions = {
  async fetchUsers({ commit, state }) {
    try {
      if (state.users.length) {
        return true
      }

      const users = await api.get('/users')
      commit(SET_USERS, users)

      return true
    } catch (error) {
      console.error('API error (fetch users): ', error)
      return false;
    }
  },

  async createUser({ commit }, payload) {
    try {
      const user = await api.post('/users', payload)
      commit(ADD_USER, user)

      return user
    } catch (error) {
      console.error('API error (create user): ', error)
      return false;
    }
  },
}

const mutations = {
  [SET_USERS] (state, users) {
    state.users = users
  },

  [SET_USER] (state, userToUpdate) {
    const userIndex = state.users.findIndex(user => user.id === userToUpdate.id)
    state.users.splice(userIndex, 1, userToUpdate)
  },

  [ADD_USER] (state, user) {
    state.users.push(user)
  },

  [SET_USER_ORGANIZER] (state, organizer) {
    const userIndex = state.users.findIndex(user => user.id === organizer.id)
    state.users[userIndex].organizer = organizer
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
