export default {
  dashboard: {
    myTradeFairParticipations: 'Meine aktuellen Messeteilnahmen',
    tradeFairParticipationsOverview : 'Übersicht Messeteilnahmen',
    theseTradeFairParticipationsCouldBeInterestingForYou: 'Diese Messebeteiligungen könnten für Sie interessant sein',
    findMoreTradeFairs: 'Weitere Messen finden',
    projectOverview: 'Projektübersicht',
    tradeFair: 'Messe',
    team: 'Team',
    registrationPhaseIsRunning: 'Anmeldephase läuft',
    registrationDeadline: 'Anmeldeschluss',
    totalRegistrations: 'Anmeldungen gesamt',
    registrationUnconfirmed: 'Anmeldungen unbestätigt',
    interestedPersons: 'Interessenten',
    defineTeam: 'Team festlegen',
    toTheProject: 'Zum Projekt',
    showPastEvents: 'Vergangene Veranstaltungen anzeigen',
    supervisor: {
      title: 'Online-Ausstelleranmeldung',
      description: {
        welcome: 'Willkommen auf der Anmeldeplattform für die Auslandsmesseprogramme des Bundes. Hier erhalten Sie eine Übersicht der Ihnen zugeordneten Veranstaltungen.',
        questions: 'Bitte wenden Sie sich bei Fragen zur Nutzung dieser Plattform gerne an das Support-Team unter der E-Mail-Adresse',
      },
      actions: {
        overviewParticipations: 'Übersicht Ihrer zugeordneten Messebeteiligungen',
        getLinks: 'Direktlinks zur Onlineanmeldung einer Veranstaltung abrufen',
        viewStatus: 'Anmeldestand und Interessenten einsehen',
      },
      exhibitionOverview: 'Messeübersicht',
    },
  },
};
