import Vue from 'vue';
import VueWait from 'vue-wait'

Vue.use(VueWait);

const vueWait = new VueWait({
  useVuex: true,
  vuexModuleName: 'vue-wait',
});

export default vueWait;
