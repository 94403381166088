export default {
  overview: {
    myTradeFairParticipationsOverview : 'Meine Messeteilnahmen – Übersicht',
    showFavorite: 'Vorgemerkte Messen anzeigen',
    showCompleted: 'Abgeschlossene Messebeteiligungen anzeigen',
    currentTradeFairParticipations: 'Aktuelle Messeteilnahmen',
    overview: 'Übersicht',
    noted: 'Vorgemerkt',
    closed: 'Abgeschlossen',
  },
}