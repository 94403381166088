<template>
  <v-container class="pa-9" fluid>
    <v-card>
      <v-row>
        <v-col cols="7">
          <v-card-text>
            <p>
              {{ $t('help.notes.help') }}
            </p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="elevation-1 rounded-0">
      <v-card-title>
        <h4 class="primary--text">
          {{ $t('help.notes.noteNew') }} {{ company.company }}
        </h4>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.data.noteSubject"
              :error-messages="errors.noteSubject"
              :label="$t('help.notes.noteSubject')"
            ></v-text-field>
            <vue-editor
              v-model="form.data.note"
              :error-messages="errors.note"
              :label="$t('help.notes.note')"
            />
            <v-btn class="mt-5" @click="handleCreate">
              {{ $t('common.save') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="mt-5">
            <h2 class="primary--text mb-4">
              {{ $t('help.notes.noteFor') }} {{ company.company }}
            </h2>
            <v-divider />
          </v-col>
          <v-col>
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, i) in form.notes" :key="i">
                <v-expansion-panel-header>
                  <h3>{{ item.title }}</h3>
                  <small class="text-right me-5">
                    {{ item.authorFirstName }} {{ item.authorLastName }},
                    {{ item.createdAt | dateTimeByLocale }}
                  </small>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="item.noteSubject"
                    :label="$t('help.notes.noteSubject')"
                    :disabled="!isAvailableToEdit(item)"
                  ></v-text-field>
                  <vue-editor
                    v-model="item.note"
                    value="Note"
                    :label="$t('help.notes.note')"
                    :disabled="!isAvailableToEdit(item)"
                  ></vue-editor>
                  <small class="float-right"
                    >{{ $t('help.notes.noteBy') }}: {{ item.authorFirstName }}
                    {{ item.authorLastName }},
                    {{ item.createdAt | dateTimeByLocale }}</small
                  ><br />
                  <v-btn
                    v-if="isAvailableToEdit(item)"
                    @click="handleSave(item)"
                  >
                    {{ $t('common.save') }}
                  </v-btn>
                  <v-btn
                    v-if="isAvailableToEdit(item)"
                    class="ml-5"
                    @click="handleDelete(item)"
                  >
                    {{ $t('common.delete') }}
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import validation from '@/mixins/validate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Notes',

  mixins: [validation],

  props: {
    company: {
      type: Object,
    },
  },

  data() {
    return {
      form: {
        notes: [],
        data: {
          note: null,
          noteSubject: null,
        },
        validationRules: {
          note: {
            required,
          },
          noteSubject: {
            required,
          },
        },
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['user', 'isAdmin']),
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },

  methods: {
    ...mapActions('company', ['fetchCompany']),

    ...mapActions('notes', [
      'fetchNotes',
      'showNote',
      'createNote',
      'updateNote',
      'deleteNote',
    ]),

    isAvailableToEdit(item) {
      return item.author.id === this.user.id || this.isAdmin
    },

    async load() {
      this.form.notes = (
        await this.fetchNotes({ companyId: this.$route.params.id })
      ).map(function (el) {
        return {
          title: el.noteSubject,
          ...el,
        }
      })
    },

    async handleSave(item) {
      await this.updateNote({
        id: item.id,
        payload: { note: item.note, noteSubject: item.noteSubject },
      })

      await this.load()
    },

    async handleCreate() {
      await this.createNote({
        payload: { ...this.form.data, companyId: this.$route.params.id },
      })

      this.form.data.note = null
      this.form.data.noteSubject = null

      await this.load()
    },

    async handleDelete(item) {
      await this.deleteNote({ id: item.id })

      await this.load()
    },
  },

  watch: {
    company() {
      this.load()
    },
  },
}
</script>
