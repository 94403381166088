import { SET_ERRORS, SET_ERROR, RESET_ERRORS } from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({
  errors: {},
})

const state = defaultState()

const actions = {
  setErrors({ commit }, errors = {}) {
    commit(SET_ERRORS, errors)
  },

  setError({ commit }, error = {}) {
    commit(SET_ERROR, error)
  },
}

const mutations = {
  [SET_ERRORS] (state, errors) {
    state.errors = errors
  },

  [SET_ERROR] (state, { type, message }) {
    state.errors[type] = message
  },

  [RESET_ERRORS] (state) {
    state.errors = {}
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
