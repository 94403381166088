<template>
  <v-dialog v-model="display" max-width="1000">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('participation.subExhibitors.addSubExhibitors', lang) }}
        </h4>
        <v-btn color="primary" @click="hide" icon>
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        {{ $t('participation.subExhibitors.addSubExhibitorsSubtitle', lang) }}
      </v-card-text>
      <v-divider/>
      <v-container>
        <v-form>
          <v-row>
            <v-col cols="6" class="px-7 pb-0 mt-5">
              <v-list-item-title class="font-weight-bold">
                {{
                  $t('participation.subExhibitors.title', lang)
                }}
              </v-list-item-title>
              <v-text-field
                  v-model="form.data.company"
                  :error-messages="errors.company"
                  :label="$t('forms.fields.company', lang) | required"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.street"
                  :error-messages="errors.street"
                  :label="$t('forms.fields.street', lang) | required"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.postalcode"
                  :error-messages="errors.postalcode"
                  :label="$t('forms.fields.postalcode', lang) +
                    (isRequiredPostalCode ? '*' : '')"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.city"
                  :error-messages="errors.city"
                  :label="$t('forms.fields.city', lang) | required"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-autocomplete
                  v-model="form.data.country_id"
                  :error-messages="errors.country_id"
                  :label="$t('forms.fields.country', lang) | required"
                  :items="prioritizedCountries"
                  :item-text="`translations.${lang}.country`"
                  item-value="id"
                  :loading="form.loading"
                  class="rounded-0"
                  filled
              />

              <v-select
                  v-model="form.data.federal_state"
                  :error-messages="errors.federal_state"
                  :label="$t('forms.fields.federalState', lang)"
                  :items="statesList"
                  :item-text="`translations.${$i18n.locale}.name`"
                  item-value="id"
                  :loading="form.loading"
                  :disabled="!isRequiredFederalState"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.phone"
                  :error-messages="errors.phone"
                  :label="$t('forms.fields.phone', lang) | required"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.fax"
                  :error-messages="errors.fax"
                  :label="$t('forms.fields.fax', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  v-model="form.data.email"
                  :error-messages="errors.email"
                  :label="$t('forms.fields.email', lang) | required"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.website"
                  :error-messages="errors.website"
                  :label="$t('forms.fields.website', lang) | required"
                  @blur="formatWebsite"
                  class="rounded-0"
                  filled
                  dense
              />
            </v-col>

            <v-col cols="6" class="px-7 pb-0 mt-5">
              <v-list-item-title class="font-weight-bold">
                {{
                  $t('participation.subExhibitors.contactPerson', lang)
                }}
              </v-list-item-title>
              <v-select
                  v-model="form.data.contact_gender"
                  :items="genders"
                  item-value="type"
                  item-text="label"
                  :label="$t('forms.fields.contactGender', lang) | required"
                  :error-messages="errors.contact_gender"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.contact_title"
                  :error-messages="errors.contact_title"
                  :label="$t('forms.fields.contactTitle', lang)"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.contact_firstname"
                  :error-messages="errors.contact_firstname"
                  :label="$t('forms.fields.contactFirstname', lang) | required"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.contact_name"
                  :error-messages="errors.contact_name"
                  :label="$t('forms.fields.contactName', lang) | required"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.contact_email"
                  :error-messages="errors.contact_email"
                  :label="$t('forms.fields.contactEmail', lang) | required"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.contact_phone"
                  :error-messages="errors.contact_phone"
                  :label="$t('forms.fields.contactPhone', lang) | required"
                  class="rounded-0"
                  filled
                  dense
              />
            </v-col>
          </v-row>

          <v-row class="justify-space-between ma-5">
            <v-btn
                class="elevation-0 col-2"
                :loading="form.loading"
                @click="hide"
            >
              {{ $t('common.cancel', lang) }}
            </v-btn>
            <v-btn
                class="elevation-0 col-2 secondary ml-auto"
                :loading="form.loading"
                @click="handleSubmit"
            >
              {{ $t('common.save', lang) }}
            </v-btn>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {required, email, url, minLength} from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import {GENDERS} from '@/enums/genders'
import {addProtocolIfMissing} from '@/utilities/helpers/urls'
import participationMode from "@/mixins/participationMode"

export default {
  name: 'CreateSubExhibitor',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },
  },

  mixins: [
    validation,
    participationMode,
  ],

  data() {
    return {
      display: false,
      coexhibitor: null,

      form: {
        loading: false,

        additionalParams: {
          media_entry: false,
          data_provided_by_coexhibitor: false,
        },

        data: {
          id: null,
          company: '',
          street: '',
          postalcode: '',
          city: '',
          country_id: null,
          federal_state: '',
          phone: '',
          fax: '',
          email: '',
          website: '',
          contact_title: '',
          contact_gender: null,
          contact_firstname: '',
          contact_name: '',
          contact_email: '',
          contact_phone: '',
        },

        validationRules: {
          id: {},
          company: {
            required,
          },
          street: {
            required,
          },
          postalcode: {},
          city: {
            required,
          },
          country_id: {
            required,
          },
          federal_state: {},
          phone: {
            required,
          },
          fax: {},
          email: {
            required,
            email,
          },
          website: {
            required,
            url,
          },
          contact_title: {},
          contact_gender: {
            required,
          },
          contact_firstname: {
            required,
          },
          contact_name: {
            required,
          },
          contact_email: {
            required,
            email,
          },
          contact_phone: {
            required,
          },
        },

        custom: {
          validationSubstitutions: {
            postalcode: {
              minLength: {
                length: 4
              }
            },
            federal_state: {
              maxLength: {
                length: 2,
              },
            },
            country_id: {
              required: {
                field: this.$t('forms.fields.country', this.lang)
              }
            }
          },
        },
      },
    }
  },

  computed: {
    ...mapGetters('states', ['states']),

    ...mapGetters('countries', ['prioritizedCountries']),

    isRequiredPostalCode() {
      return this.form.data.country_id === this.prioritizedCountries.find((el) => el.iso31662 == 'DE')?.id
    },

    isRequiredFederalState() {
      return this.form.data.country_id === this.prioritizedCountries.find((el) => el.iso31662 == 'DE')?.id
    },

    statesList() {
      let states = this.states

      states.push({
        id: 9999,
        name: null,
        translations: {
          en: {
            name: 'Not specified',
          },
          de: {
            name: 'Keine Angabe',
          }
        }
      })

      return states
    },

    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`, this.lang),
      }))
    },
  },

  created() {
    this.fetchCountries()
    this.fetchStates()
  },

  methods: {
    ...mapActions('states', ['fetchStates']),

    ...mapActions('countries', ['fetchCountries']),

    ...mapActions('participation', ['createCoExhibitor', 'updateCoExhibitor']),

    show(coexhibitor) {
      this.coexhibitor = coexhibitor

      this.$v.$reset()
      this.setInitialFormData(coexhibitor?.attributes ?? {})

      this.form.data.federal_state = this.form.data.federal_state?.id

      this.display = true
    },

    hide() {
      this.display = false
    },

    async handleSubmit() {
      this.setValidationRules()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.form.loading = true
        if (this.coexhibitor?.id) {
          await this.updateCoExhibitor({
            participation: this.participation,
            coexhibitor: {...this.getFormData(), id: this.coexhibitor.id}
          })
        } else {
          await this.createCoExhibitor({participation: this.participation, coexhibitor: this.getFormData()})

          this.$emit('submit')
        }

        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },

    formatWebsite({target: {value}}) {
      this.form.data.website = addProtocolIfMissing(value)
    },

    setValidationRules() {
      if (this.isRequiredFederalState) {
        this.form.validationRules.federal_state = {required: required};
        this.form.validationRules.postalcode = {required: required, minLength: minLength(4)};
      } else {
        this.form.validationRules.federal_state = {};
        this.form.validationRules.postalcode = {};
      }
    },
  },

  watch: {
    'form.data.country_id'() {
      if (!this.isRequiredFederalState) {
        this.form.data.federal_state = null
      }
    }
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
