<template>
  <v-container class="pa-9" fluid>
    <v-card class="elevation-1 rounded-0">
      <v-card-title>
        <h4 class="primary--text">
          {{ $t('notification.sendNotification') }}
        </h4>
      </v-card-title>

      <v-card-text class="text--primary">

        <p>{{ $t('notification.description') }}</p>

        <v-text-field
            v-model="form.data.name"
            :error-messages="errors.name"
            :label="$t('forms.fields.name')"
            readonly
        />

        <v-select
            v-model="form.data.groups"
            :error-messages="errors.groups"
            :label="$t('forms.fields.groups')"
            :items="recipients"
            item-value="id"
            item-text="title"
            multiple
            chips
        />

        <v-text-field
            v-model="form.data.subject"
            :error-messages="errors.subject"
            :label="$t('forms.fields.subject')"
        />

        <vue-editor
            :placeholder="$t('forms.fields.body')"
            rows="6"
            class="mb-2"
            v-model="form.data.body"
        ></vue-editor>
        <validation-general-error class="mt-2" :errors="errors.body" />

        <vue-editor
            :placeholder="$t('forms.fields.signature')"
            rows="6"
            class="mb-2"
            v-model="form.data.signature"
        ></vue-editor>
        <validation-general-error class="mt-2" :errors="errors.signature" />
      </v-card-text>

      <v-divider/>

      <v-card-actions class="py-3 px-4">
        <v-btn
            color="secondary"
            class="elevation-0"
            @click="handleNotificationSending"
            :loading="form.loading"
        >
          {{ $t('forms.buttons.send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import validation from '@/mixins/validate'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import ValidationGeneralError from "../../components/base/ValidationGeneralError.vue";

export default {
  name: 'OrganizerSendNotification',
  components: {ValidationGeneralError},

  mixins: [
    validation,
  ],

  data() {
    return {
      form: {
        loading: false,
        fair: null,

        data: {
          groups: [],
          name: '',
          subject: '',
          body: '',
          signature: '',
        },

        validationRules: {
          groups: {
            required,
          },
          name: {
            required,
          },
          subject: {
            required,
          },
          body: {
            required,
          },
          signature: {
            required,
          },
        },
      },

      recipients: [
        {
          id: 1,
          title: this.$t('notification.interestedPersons'),
        },
        {
          id: 2,
          title: this.$t('notification.allParticipants'),
        },
        {
          id: 3,
          title: this.$t('notification.confirmedParticipants'),
        },
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    ...mapGetters('organizer', ['tradeFairID']),
  },

  async created() {
    await this.load()
  },

  methods: {
    async load() {
      let response = (await this.$axios.get(`/api/trade-fairs/${this.tradeFairID}`, {
        params: {
          'languages': '*',
          'include': [
            'organizer',
          ],
        },
      })).data
      this.fair = response.data

      this.form.data.name = this.fair.attributes.name_display ?? this.fair.attributes.name
      this.form.data.subject = this.fair.attributes.name_display ?? this.fair.attributes.name
      this.form.data.signature = '<p>' + this.$t(`common.genders.${this.user.gender}`) + ' ' + this.user.fullName + '</p>' +
          '<p>' + 'Phone: ' + (this.user.phone ?? '') + '</p>' +
          '<p>' + this.user.email + '</p>' +
          '<p>' + this.user.organizer.name +'</p>'
    },


    async handleNotificationSending() {
      this.resetServerErrors()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        let response = await this.$axios.post(`/api/notifications`, { ...this.form.data, trade_fair_id: this.tradeFairID })

        this.$snackbar(this.$t('notification.sendNotificationCount', { count: response.data.data.count }))
        this.$v.$reset()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
