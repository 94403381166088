<template>
  <v-dialog
      max-width="900"
      class="rounded-0"
      :value="display"
      @click:outside="hide"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 v-if="form.data.id" class="primary--text">
          {{ $t('participation.configureRegistrationForm.exhibitionArea.editExhibitionArea') }}
        </h4>
        <h4 class="primary--text" v-else>
          {{ $t('participation.configureRegistrationForm.exhibitionArea.addExhibitionArea') }}
        </h4>
        <v-btn @click="hide" icon>
          <v-icon size="20" color="primary">fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pt-4">
        <v-form>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  :label="$t('participation.configureRegistrationForm.exhibitionArea.title_de')"
                  :error-messages="errors.name"
                  v-model="form.data.name.de"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  :label="$t('participation.configureRegistrationForm.exhibitionArea.title_en')"
                  :error-messages="errors.name"
                  v-model="form.data.name.en"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="elevation-0 col-2" @click="hide" color="secondary" text outlined>
          {{ $t('participation.configureRegistrationForm.cancel') }}
        </v-btn>

        <v-btn
            color="secondary"
            @click="handleSubmit"
            class="elevation-0 col-2 ml-auto"
        >
          {{ $t('participation.configureRegistrationForm.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'

export default {
  name: 'OrganizerCustomizeExhibitionareasEdit',

  mixins: [validation],

  props: {
    fair: {
      type: Object,
    },
  },

  data() {
    return {
      display: false,

      area: {},

      form: {
        loading: false,

        data: {
          name: {de: '', en: ''},
        },

        validationRules: {
          name: {
            required: required,
          },
        },
      },
    }
  },

  methods: {
    show(item) {
      this.area = item

      this.setInitialFormData(JSON.parse(JSON.stringify(item?.attributes ?? {})))

      if (!item) {
        this.form.data.name = {de: '', en: ''}
      }

      this.$v.$reset()
      this.display = true
    },

    hide() {
      this.display = false
    },

    async handleSubmit() {
      this.resetServerErrors()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        if (this.area?.id) {
          await this.$axios.put(`/api/trade-fairs/${this.fair.id}/areas/${this.area?.id}`, {
            ...this.getFormData(),
          })
        } else {
          await this.$axios.post(`/api/trade-fairs/${this.fair.id}/areas`, {
            ...this.getFormData(),
          })
        }

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')

        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
