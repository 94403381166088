<template>
  <v-app-bar
      class="elevation-1"
      app
      clipped-left
      elevation="1"
      fixed
      color="white"
      height="80"
  >
    <v-img
        src="/img/logos/Made-in-Germany.svg"
        max-height="60"
        max-width="80"
        class="mx-6"
        style="cursor: pointer"
        contain
        @click="redirectToHomePage"
    />

    <v-toolbar-title class="align-center pl-10">
      <h4 class="primary--text">
        {{ $t('header.federalForeignTradeFairProgram') }}
      </h4>
      <h6>{{ $t('header.eventOverviewAndExhibitorRegistration') }}</h6>
    </v-toolbar-title>

    <v-spacer/>

    <language-picker/>

    <documents-menu/>

    <v-btn color="secondary" elevation="0" class="mr-5 ml-10" to="/" v-if="!user.email">
      {{ $t('auth.logIn') }}
    </v-btn>

    <v-menu open-on-click v-if="user.email">
      <template v-slot:activator="{ on }">
        <v-btn
            text
            v-on="on"
            class="text--primary"
        >
          <span class="text-capitalize font-weight-regular text--primary">{{ user.email }}</span>
          <v-icon
              right
              size="22"
              class="ml-4 primary--text"
          >
            fas fa-fw fa-user-circle
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="handleLogout">
          <v-list-item-title>{{ $t('header.logOut') }}</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-menu>
  </v-app-bar>
</template>

<script>
import LanguagePicker from '@/components/base/LanguagePicker'
import DocumentsMenu from '@/components/common/Documents/Menu'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PublicHeader',

  components: {
    LanguagePicker,
    DocumentsMenu,
  },

  computed: {
    ...mapGetters('auth', [
      'user',
      'startPage',
    ]),
  },

  methods: {
    ...mapActions('auth', [
      'logOut',
    ]),

    async redirectToHomePage() {
      await this.$router.push({ name: await this.startPage })
    },

    async handleLogout() {
      if (await this.logOut()) {
        window.location.href = await this.$router.resolve({ name: 'login' }).href
      }
    },
  },

  beforeDestroy() {
    localStorage.removeItem('vuex')
  },
}
</script>
