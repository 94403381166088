<template>
  <v-container
    class="pa-9"
    fluid
  >
    <v-card class="elevation-1 rounded-0">
      <v-card-title>
        <h4 class="primary--text">{{ $t('company.companies') }}</h4>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row align-content="end">
          <v-col>
            <v-text-field
              v-model="filters.query"
              :label="$t('forms.buttons.search')"
            />
          </v-col>
          <v-col>
            <v-btn class="secondary elevation-0 mt-4">{{ $t('forms.buttons.search') }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-data-table
          :headers="headers"
          :loading="form.loading"
          :items="fairs"
          :page.sync="form.page"
          :options.sync="options"
          :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
          :server-items-length="form.total"
      >
        <template v-slot:item.company_id="{ item }">
         {{ item.attributes.company_id }}
        </template>

        <template v-slot:item.accreditation_approved_at="{ item }">
          <v-icon size="17">fal {{ item.attributes.accreditation_approved_at && !item.attributes.accreditation_rejected_at ? 'fa-check' : 'fa-times' }}</v-icon>
        </template>

        <template v-slot:item.company="{ item }">
          <router-link :to="{ name: 'admin.company', params: { id: item.attributes.company_id } }">{{ item.attributes.company }}</router-link>
        </template>

        <template v-slot:item.city="{ item }">
          {{ item.attributes.city }}
        </template>

        <template v-slot:item.country="{ item }">
          {{ item.relationships.country?.translations ? item.relationships.country.translations[$i18n.locale].country : '' }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.relationships.company_model.created_at | dateByLocale }}
        </template>

        <template v-slot:item.participations="{ item }">
          {{ item.relationships.participations.length }}
        </template>

        <template v-slot:item.users="{ item }">
          {{ item.relationships.users.length }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            :to="{ name: 'admin.company', params: { id: item.attributes.company_id } }"
            color="secondary"
            icon
          >
            <v-icon>fal fa-arrow-circle-right</v-icon>
          </v-btn>
        </template>

      </v-data-table>

    </v-card>
  </v-container>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'AdminCompanies',

  data() {
    return {
      fairs: [],
      options: {},

      form: {
        total: 0,
        loading: false,
      },

      filters: {
        query: null,
      }
    }
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('common.id'),
          value: 'company_id',
          sortable: false,
        },
        {
          text: this.$t('company.accredited'),
          value: 'accreditation_approved_at',
          sortable: false,
        },
        {
          text: this.$t('company.company'),
          value: 'company',
          sortable: false,
        },
        {
          text: this.$t('company.city'),
          value: 'city',
          sortable: false,
        },
        {
          text: this.$t('forms.fields.country'),
          value: 'country',
          sortable: false,
        },
        {
          text: this.$t('company.registrationDate'),
          value: 'created_at',
          sortable: false,
        },
        {
          text: this.$t('participation.participations'),
          value: 'participations',
          sortable: false,
        },
        {
          text: this.$t('users.users'),
          value: 'users',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ];
    },
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get('/api/companies', {
        params: {
          'languages': '*',
          'filter[search]': this.filters.query,
          'include': [
            'country',
            'company',
          ],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      this.fairs = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },

    loadDebounce: debounce(function() {
      this.load()
    }, 500),
  },

  watch: {
    'options'() {
      this.load()
    },

    'filters': {
      handler() {
        this.options.page = 1

        this.loadDebounce()
      },
      deep: true
    },
  },
}
</script>
