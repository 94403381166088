<template>
  <v-expansion-panel class="elevation-1 rounded-0">
    <v-expansion-panel-header>
      <h3>{{ $t('tables.participation.form.dataAndInformationAboutTheFair') }}</h3>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col
          cols="6"
          class="white pr-5"
        >
          <p class="mb-0">
            <strong>
              {{ $t('tables.participation.form.mainOfferings') }}
            </strong>
          </p>
          <p>
            {{ fair.attributes.fair_focus[$i18n.locale] }}
          </p>
        </v-col>

        <v-col
          cols="6"
          class="pl-5"
        >
          <v-list
            class="pa-0"
            dense
          >
            <v-list-item
              :href="fair.attributes.website"
              target="_blank"
              class="px-0 mx-0"
            >
              <v-list-item-icon class="px-0 mx-0">
                <v-icon
                  size="18"
                  class="px-0 mx-0"
                >
                  fal fa-arrow-right secondary--text
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <h4 class="primary--text">{{ $t('tables.participation.form.websiteOfTheOrganizer') }}</h4>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :href="fair.attributes.link_auma_database[$i18n.locale]"
              target="_blank"
              class="px-0 mx-0"
            >
              <v-list-item-icon class="px-0 mx-0">
                <v-icon
                  size="18"
                  class="px-0 mx-0"
                >
                  fal fa-arrow-right secondary--text
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <h4 class="primary--text">{{ $t('tables.participation.form.informationInTheAUMATradeFairDatabase') }}</h4>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-col>

      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'TradeFairInformation',

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },
}
</script>
