export default {
  login: {
    federalForeignTradeFairProgram: 'Das Auslandsmesseprogramm des Bundes',
    eventOverviewAndExhibitorRegistration: 'Veranstaltungsübersicht und Ausstelleranmeldung',
    inCooperationWith: 'in Kooperation mit',
    description: {
      title: 'Die Teilnahme Ihres Unternehmens an deutschen Gemeinschaftsständen auf Messen weltweit.',
      worldwideOverview: 'Übersicht über die deutschen Gemeinschaftsstände auf Messen weltweit',
      attractiveConditions: 'Ihre Messeteilnahme zu attraktiven Konditionen',
      experiencedProcessing: 'Planung, Beratung und Abwicklung durch erfahrene Messegesellschaften von Deutschland aus',
      onlineBooking: 'Ihre Teilnahme an Auslandsmessen komfortabel online buchen',
    },
    loginForImplementationCompanies: 'Login für Durchführungsgesellschaften und Verbände',
    contactIfDontHaveAccessData: 'Wenn Sie noch keine Zugangsdaten haben oder Unterstützung benötigen, dann kontaktieren Sie uns bitte per E-Mail an ',
    resetPassword: 'Passwort zurücksetzen',
    reset: 'Rücksetzen',
  },
}
