import Vue from 'vue'
import { shorten, shortenHard } from '@/utilities/filters'
import i18n from '@/boot/i18n'


Vue.filter('date', function(value) {
  if (!value) {
    return ''
  }

  return Vue.moment(String(value)).format('DD/MM/YYYY')
})

Vue.filter('dateByLocale', function(value) {
  if (!value) {
    return ''
  }

  return Vue.moment(String(value)).format(i18n.locale === 'de' ? 'DD.MM.YYYY' : 'YYYY-MM-DD')
})

Vue.filter('dateTime', function(value) {
  if (!value) {
    return ''
  }

  return Vue.moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('time', function(value) {
  if (!value) {
    return ''
  }

  return Vue.moment(String(value)).format('HH:mm:ss')
})

Vue.filter('dateTimeByLocale', function(value) {
  if (!value) {
    return ''
  }

  return Vue.moment(String(value)).format(i18n.locale === 'de' ? 'DD.MM.YYYY HH:mm:ss' : 'YYYY-MM-DD HH:mm:ss')
})

Vue.filter('firstLetter', function(value, isUpperCased = false) {
  if (!value) {
    return ''
  }

  return isUpperCased ? value.charAt(0).toUpperCase() : value.charAt(0).toLowerCase()
})

Vue.filter('toUpperCase', function(value) {
  if (!value) {
    return ''
  }

  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('toLowerCase', function(value) {
  if (!value) {
    return ''
  }

  return value.charAt(0).toLowerCase() + value.slice(1)
})

Vue.filter('sizeToMeters', function(value) {
  if (!value) {
    return ''
  }

  if (value % 100 === 0) {
    return value / 100
  }

  return Math.floor(value / 100) + ',' + String((value % 100)).padStart(2, '0')
})

Vue.filter('shorten', shorten)

Vue.filter('shortenHard', shortenHard)

Vue.filter('money', function(value, locale = null, currency = '€', ) {
  if (!value && value !== 0) {
    return ''
  }

  locale = locale ?? i18n.locale;

  value = parseFloat(value).toLocaleString(locale == 'de' ? 'de-DE' : 'en-US', {minimumFractionDigits: 2});

  return !currency ? value : `${value} ${currency}`
})

Vue.filter('required', function(value) {
  return `${value}*`
})

Vue.filter('BtoKB', function(value) {
  return value / 1024
})

Vue.filter('BtoMB', function(value) {
  return value / 1024 / 1024
})

Vue.filter('twoDecimals', function(value) {
  return Math.round(Number((Math.abs(value) * 100).toPrecision(15))) / 100 * Math.sign(value);
})
