<template>
  <v-expansion-panel class="rounded-0">
    <v-expansion-panel-header>
      <h4>{{ $t('participation.configureRegistrationForm.general.title') }}</h4>
    </v-expansion-panel-header>
    <v-expansion-panel-content>

      <v-row>
        <v-col cols="6" class="pr-10">
          <v-checkbox :error-messages="errors.booking_type" v-model="form.data.booking_type_space"
                      :label="$t('participation.configureRegistrationForm.general.space')"></v-checkbox>
          <v-checkbox :error-messages="errors.booking_type" v-model="form.data.booking_type_package"
                      :label="$t('participation.configureRegistrationForm.general.package')"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-text-field :label="$t('participation.configureRegistrationForm.general.minParticipants')"
                        :error-messages="errors.min_participants"
                        v-model="form.data.min_participants" type="number"></v-text-field>

          <template v-if="form.data.booking_type_space">
            <v-text-field :label="$t('participation.configureRegistrationForm.general.spaceMinSize')"
                          :error-messages="errors.space_min_size"
                          v-model="form.data.space_min_size" type="number"></v-text-field>
            <v-text-field :label="$t('participation.configureRegistrationForm.general.spaceMaxSize')"
                          :error-messages="errors.space_max_size"
                          v-model="form.data.space_max_size" type="number"></v-text-field>
          </template>
        </v-col>
      </v-row>

      <v-row class="mt-5 mb-5">
        <v-col cols="12" class="mb-0 pb-0">
          <p><b>{{ $t('participation.configureRegistrationForm.general.nameAlternative') }}</b></p>
        </v-col>
        <v-col cols="6">
          <v-text-field
              clearable
              :label="$t('participation.configureRegistrationForm.general.nameAlternative')"
              counter="100"
              :error-messages="errors.name_alternative"
              v-model="form.data.name_alternative">
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-alert dense outlined type="warning">
            {{ $t('participation.configureRegistrationForm.general.nameAlternativeAlert') }}<br/>
            <small>
              {{ $t('participation.configureRegistrationForm.general.nameAlternativeClue') }}
            </small>
          </v-alert>
        </v-col>
      </v-row>

      <v-row class="mt-5 mb-5">
        <v-col cols="12" class="mb-0 pb-0">
          <p><b>{{ $t('participation.configureRegistrationForm.serviceDescription.generalServiceDescription') }}</b></p>
        </v-col>
        <v-col cols="6" class="mt-0 pt-0">
          <p class="my-2">{{ $t('participation.configureRegistrationForm.service_description_de') }}</p>
          <vue-editor
              :placeholder="$t('participation.configureRegistrationForm.serviceDescription.generalServiceDescriptionPlaceholder')"
              v-model="form.data.servicedescription_general['de']"
          />
        </v-col>
        <v-col cols="6" class="mt-0 pt-0">
          <p class="my-2">{{ $t('participation.configureRegistrationForm.service_description_en') }}</p>
          <vue-editor
              :placeholder="$t('participation.configureRegistrationForm.serviceDescription.generalServiceDescriptionPlaceholder')"
              v-model="form.data.servicedescription_general['en']"
          />
        </v-col>
      </v-row>

      <v-btn color="secondary" elevation="0" @click="handleSubmit">
        {{ $t('forms.buttons.saveAndContinue') }}
      </v-btn>
    </v-expansion-panel-content>
    <customize-registration-general-conflict-space-size-modal
        ref="conflict"
        @adjust="$emit('move', 'OrganizerCustomizeSpace')"
        @reset="$emit('reset')"
    >
    </customize-registration-general-conflict-space-size-modal>
  </v-expansion-panel>
</template>

<script>
import {required, minValue, maxValue} from 'vuelidate/lib/validators'
import {VueEditor} from 'vue2-editor'
import validation from '@/mixins/validate'
import CustomizeRegistrationGeneralConflictSpaceSizeModal
  from '@/components/organizer/Application/Customize/CustomizeRegistrationGeneralConflictSpaceSizeModal'

export default {
  name: 'CustomizeRegistrationGeneral',

  components: {
    VueEditor,
    CustomizeRegistrationGeneralConflictSpaceSizeModal,
  },

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  mixins: [
    validation,
  ],

  data() {
    return {
      form: {
        data: {
          name_alternative: null,
          booking_type_space: false,
          booking_type_package: false,
          min_participants: null,
          space_min_size: 0,
          space_max_size: 0,
          servicedescription_general: {},
        },
        validationRules: {
          name_alternative: {},
          booking_type_space: {},
          booking_type_package: {},
          min_participants: {
            required: required,
            minValue: minValue(1),
          },
          space_min_size: {
            required: required,
            minValue: minValue(0),
            maxValue: maxValue(0)
          },
          space_max_size: {
            required: required,
            minValue: minValue(1),
          },
          servicedescription_general: {},
        },
        custom: {
          validationSubstitutions: {
            min_participants: {
              minValue: {
                limit: 0
              },
            },
            space_min_size: {
              minValue: {
                limit: 0
              },
              maxValue: {
                limit: `'${this.$t('forms.fields.spaceMaxSize')}'`
              }
            },
            space_max_size: {
              minValue: {
                limit: `'${this.$t('forms.fields.spaceMinSize')}'`,
              },
            },
          },
        },
      }
    }
  },

  created() {
    this.setInitialFormData(this.fair.attributes)

    this.$emit('booking-types', {
      bookingTypeSpace: this.form.data.booking_type_space,
      bookingTypePackage: this.form.data.booking_type_package
    });
  },

  methods: {
    setValidationRules() {
      if (this.form.data.booking_type_package) {
        this.form.validationRules.space_min_size = {};
        this.form.validationRules.space_max_size = {};
      }

      if (this.form.data.booking_type_space) {
        this.form.validationRules.space_min_size.minValue = minValue(1);
        this.form.validationRules.space_min_size.maxValue = maxValue(this.form.data.space_max_size);
        this.form.validationRules.space_max_size.minValue = minValue(this.form.data.space_min_size);
      }
    },

    async checkSpacesLimit() {
      let spaces = (await this.$axios.get(`/api/trade-fairs/${this.fair.id}/spaces`, {
        params: {
          'languages': '*',
          'include': [],
        },
      })).data.data


      for (var space of spaces) {
        if (this.form.data.space_min_size > space.attributes.size_from || this.form.data.space_max_size < space.attributes.size_to) {
          return space;
        }
      }

      return false;
    },

    async handleSubmit() {
      this.setValidationRules();

      this.$v.$touch()

      if (!this.form.data.booking_type_space && !this.form.data.booking_type_package) {
        this.errors.bookingType = this.$t('participation.configureRegistrationForm.general.typesError');
        return
      }

      if (this.$v.$invalid) {
        return
      }

      if (this.form.data.booking_type_space && await this.checkSpacesLimit()) {
        this.$refs.conflict.show()
        this.errors.space_min_size = this.errors.space_max_size = this.$t('participation.configureRegistrationForm.general.spaceRangeError');
        return
      }

      this.form.loading = true
      try {
        await this.$axios.patch(`/api/trade-fairs/${this.fair.id}`, {
          ...this.getFormData(),
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('booking-types', {
          bookingTypeSpace: this.form.data.booking_type_space,
          bookingTypePackage: this.form.data.booking_type_package
        });
        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    }
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
