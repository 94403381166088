<template>
  <v-expansion-panel class="rounded-0">
    <v-expansion-panel-header>
      <h4>{{ $t('participation.configureRegistrationForm.fees.title') }}</h4>
    </v-expansion-panel-header>
    <v-expansion-panel-content>


      <div>
        <v-btn color="secondary" class="mb-5" @click="add" text outlined>
          <i class="fal fa-plus"></i>&nbsp;
          {{ $t('participation.configureRegistrationForm.addFee') }}
        </v-btn>
      </div>

      <v-sheet class="pa-4 mb-4" outlined>

        <p><strong>{{ $t('participation.configureRegistrationForm.fees.subExhibitorFee') }}</strong></p>
        <v-row>
          <v-col cols="6">
            <v-text-field :label="$t('participation.configureRegistrationForm.fees.perSubExhibitorFee')"
                          :hint="$t('participation.configureRegistrationForm.fees.perSubExhibitorIn')"
                          :error-messages="errors.coexhibitor_fee"
                          v-model="form.data.coexhibitor_fee" min="0" type="number"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field :label="$t('participation.configureRegistrationForm.fees.coexhibitorFeeTitle_de')"
                          :error-messages="errors.coexhibitor_fee_title"
                          v-model="form.data.coexhibitor_fee_title.de"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field :label="$t('participation.configureRegistrationForm.fees.coexhibitorFeeTitle_en')"
                          :error-messages="errors.coexhibitor_fee_title"
                          v-model="form.data.coexhibitor_fee_title.en"></v-text-field>
          </v-col>
        </v-row>

        <v-checkbox :label="$t('participation.configureRegistrationForm.fees.enterExternalFee')"
                    v-model="external"></v-checkbox>

        <v-row v-if="external">
          <v-col cols="6">
            <v-text-field :label="$t('forms.fields.coexhibitorExternalFee')"
                          :hint="$t('participation.configureRegistrationForm.fees.perSubExhibitorIn')"
                          :error-messages="errors.coexhibitor_external_fee"
                          v-model="form.data.coexhibitor_external_fee" min="0" type="number"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field :label="$t('forms.fields.coexhibitorExternalFeeTitle_de')"
                          :error-messages="errors.coexhibitor_external_fee_title"
                          v-model="form.data.coexhibitor_external_fee_title.de"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field :label="$t('forms.fields.coexhibitorExternalFeeTitle_en')"
                          :error-messages="errors.coexhibitor_external_fee_title"
                          v-model="form.data.coexhibitor_external_fee_title.en"></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="external">
          <v-col cols="6">
            <v-text-field :label="$t('forms.fields.coexhibitorExternalFee2')"
                          :hint="$t('participation.configureRegistrationForm.fees.perSubExhibitorIn')"
                          :error-messages="errors.coexhibitor_external_fee2"
                          v-model="form.data.coexhibitor_external_fee2" min="0" type="number"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field :label="$t('forms.fields.coexhibitorExternalFee2Title_de')"
                          :error-messages="errors.coexhibitor_external_fee2_title"
                          v-model="form.data.coexhibitor_external_fee2_title.de"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field :label="$t('forms.fields.coexhibitorExternalFee2Title_en')"
                          :error-messages="errors.coexhibitor_external_fee2_title"
                          v-model="form.data.coexhibitor_external_fee2_title.en"></v-text-field>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet class="pa-4 mb-4" v-for="item in services" :key="services.indexOf(item)" outlined>
        <v-row>
          <v-col cols="8" class="pb-0">
            <p>
              <strong>
                {{ $t('participation.configureRegistrationForm.fees.furtherCompulsoryFee') }}
                <span>({{ item.attributes.title[$i18n.locale] }})</span>
              </strong>
            </p>
          </v-col>
          <v-col cols="4" class="text-right pb-0">
            <v-btn color="secondary" @click="edit(item)" icon>
              <v-icon size="18">fal fa-pencil</v-icon>
            </v-btn>
            <v-btn color="secondary" @click="remove(item)" icon>
              <v-icon size="18">fal fa-trash-alt</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <small>{{ $t('participation.configureRegistrationForm.fees.perMainExhibitorFee') }}</small><br/>
            <p>{{ item.attributes.price | money }}</p>
          </v-col>
          <v-col cols="4">
            <small>{{ $t('participation.configureRegistrationForm.designation_de') }}</small><br/>
            <p>{{ item.attributes.title.de }}</p>
            <small>{{ $t('participation.configureRegistrationForm.fees.coexhibitorFeeDescription_de') }}</small><br/>
            <p v-html="item.attributes.description.de"></p>
          </v-col>
          <v-col cols="4">
            <small>{{ $t('participation.configureRegistrationForm.designation_en') }}</small><br/>
            <p>{{ item.attributes.title.en }}</p>
            <small>{{ $t('participation.configureRegistrationForm.fees.coexhibitorFeeDescription_en') }}</small><br/>
            <p v-html="item.attributes.description.en"></p>
          </v-col>
        </v-row>
      </v-sheet>

      <v-btn color="secondary" elevation="0" @click="save">
        {{ $t('forms.buttons.saveAndContinue') }}
      </v-btn>

    </v-expansion-panel-content>
    <customize-registration-fees-edit ref="edit" :fair="fair" @submit="load"></customize-registration-fees-edit>
  </v-expansion-panel>
</template>

<script>
import validation from "@/mixins/validate"
import {minValue, required} from "vuelidate/lib/validators"
import CustomizeRegistrationFeesEdit from "@/components/organizer/Application/Customize/CustomizeRegistrationFeesEdit"
import {translation} from "@/utilities/helpers/validation";

export default {
  name: 'CustomizeRegistrationFees',

  components: {
    CustomizeRegistrationFeesEdit
  },

  mixins: [
    validation,
  ],

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      services: [],

      external: false,

      form: {
        data: {
          coexhibitor_fee: null,
          coexhibitor_fee_title: {},
          coexhibitor_external_fee: null,
          coexhibitor_external_fee_title: {},
          coexhibitor_external_fee2: null,
          coexhibitor_external_fee2_title: {},
        },

        validationRules: {
          id: {},
          coexhibitor_fee: {
            required: required,
            minValue: minValue(0),
          },
          coexhibitor_fee_title: {
            translation: translation(),
          },
          coexhibitor_external_fee: {},
          coexhibitor_external_fee_title: {},
          coexhibitor_external_fee2: {},
          coexhibitor_external_fee2_title: {},
        }
      },

      custom: {
        validationSubstitutions: {
          coexhibitor_fee: {
            minValue: {
              limit: 0
            },
          },
        }
      },
    }
  },

  created() {
    this.setInitialFormData(this.fair.attributes)

    this.external = !!this.fair.attributes.coexhibitor_external_fee;

    this.load()
  },

  methods: {
    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/trade-fairs/${this.fair.id}/services`, {
        params: {
          'languages': '*',
          'filter[fee]': true,
          'include': [],
        },
      })).data

      this.services = response.data

      this.form.loading = false
    },


    async remove(item) {
      if (confirm(this.$t('participation.configureRegistrationForm.fees.alertDeleteFee'))) {
        await this.$axios.delete(`/api/trade-fairs/${this.fair.id}/services/${item.id}`, {})

        this.$snackbar(this.$t('common.successApiMessage'))

        this.load()
      }
    },

    edit(item) {
      this.$refs.edit.show(item)
    },

    add() {
      this.$refs.edit.show()
    },

    async save() {
      this.setValidationRules()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        await this.$axios.patch(`/api/trade-fairs/${this.fair.id}`, {...this.getFormData()})

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },

    setValidationRules() {
      if (this.external) {
        this.form.validationRules.coexhibitor_external_fee = {required: required}
        this.form.validationRules.coexhibitor_external_fee_title = {translation: translation()}
      } else {
        this.form.validationRules.coexhibitor_external_fee = {}
        this.form.validationRules.coexhibitor_external_fee_title = {}

        this.form.data.coexhibitor_external_fee = null
        this.form.data.coexhibitor_external_fee_title = {}
        this.form.data.coexhibitor_external_fee2 = null
        this.form.data.coexhibitor_external_fee2_title = {}
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
