<template>
  <v-container class="d-flex pa-0" style="min-height: 100%;" fluid no-gutters>
    <v-row>
      <v-col cols="3" class="pa-0">
        <v-navigation-drawer permanent class="grey lighten-5 px-0 py-4 ma-0" width="100%" height="100vw">
          <v-list>
            <v-list-item
              :to="{name: 'exhibitor.help', params: {id: category.id}}"
              v-for="category in categories"
              v-bind:key="category.id"
              v-on:click="setCurrentCategory(category.id)"
            >
              <v-list-item-icon class="mx-0">
                <v-icon size="16" class="primary--text ml-5">fal fa-arrow-right</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="pl-4"><h4>{{category.title}}</h4></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-col>

      <v-col cols="9" class="pa-7 mt-3">
        <v-card class="elevation-1 rounded-0 mb-5">
          <v-card-title>
            <h3 class="primary--text">{{ $t('common.support') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                {{ $t('dashboard.supervisor.description.questions') }}
                <a :href="`mailto:support@german-pavilion.com`">
                  support@german-pavilion.com
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="elevation-1 rounded-0 mb-5">
          <v-card-title>
            <h3 class="primary--text">{{ $t('help.helpAndAdvice') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="10">
                <v-autocomplete
                  :label="$t('help.search')"
                  :no-data-text="$t('common.noDataAvailable')"
                  v-model="searchArticles"
                  :items="getArticlesByCategory"
                  :filter="customSearch"
                  @click:clear="applySearch = false"
                  @change="applySearch = true"
                  spellcheck="false"
                  :item-text="`translations.${$i18n.locale}.headline`"
                  item-value="id"
                  multiple
                  clearable
                  small-chips
                >
                  <v-icon slot="prepend" size="18" class="primary--text">far fa-search</v-icon>
                  <template slot='item' slot-scope='{ item }'>
                    <div>
                      <span style="display: inline-block"><b>{{ item.translations[$i18n.locale].headline }}</b></span>&nbsp;
                      <span style="display: inline-block" v-html="item.translations[$i18n.locale].abstract" />&nbsp;
                      <span style="display: inline-block" v-html="item.translations[$i18n.locale].text" />&nbsp;
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-btn
                  class="mt-3 elevation-0 secondary rounded-0"
                  v-on:click="applySearch = true"
                >
                  {{ $t('help.search') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-expansion-panels flat>
          <v-expansion-panel
              v-for="article in getArticles"
              :key="article.id"
              class="elevation-1 rounded-0"
          >
            <v-expansion-panel-header>
              <h4>{{ article.translations[$i18n.locale].headline }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p v-html="article.translations[$i18n.locale].abstract" />
              <p v-html="article.translations[$i18n.locale].text" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {mapWaitingActions} from 'vue-wait'
  import {FETCH_FAQ_ARTICLES} from '@/store/loading-types'

  export default {
  name: 'ExhibitorHelp',
  data() {
    return {
      currentCategoryId: null,
      searchArticles: [],
      applySearch: false,
    }
  },
  methods: {
    ...mapWaitingActions('help', {
      fetchFaqArticles: FETCH_FAQ_ARTICLES,
    }),

    setCurrentCategory(id) {
      this.currentCategoryId = id
    },

    customSearch(item, queryText) {
      const data = item.headline.toLowerCase() +
                   item.abstract.toLowerCase() +
                   item.text.toLowerCase()

      const searchText = queryText.toLowerCase()

      return data.indexOf(searchText) > -1
    },

    async fetchArticles() {
      if (await this.fetchFaqArticles()) {
        const id = parseInt(this.$route.params.id)
        if (typeof this.categories.find(category => category.id === id) !== "undefined") {
          this.setCurrentCategory(id);
        } else if (typeof this.$route.params.id !== 'undefined') {
            await this.$router.push({name: 'exhibitor.helps'})
        }
      }
    },
  },
  computed: {
    ...mapGetters('help', [
      'faqs',
      'categories'
    ]),

    getArticlesByCategory: function () {
      if (this.currentCategoryId) {
        let currentCategoryId = this.currentCategoryId;

        return this.faqs.filter(function (article) {
          return article.category.id === currentCategoryId
        })
      }

      return []
    },

    getArticles: function () {
      if (this.currentCategoryId) {
        let currentCategoryId = this.currentCategoryId;
        let searchArticles = this.searchArticles

        let articles = this.faqs.filter(function (article) {
          return article.category.id === currentCategoryId
        })

        if (this.applySearch && this.searchArticles.length) {
          articles = articles.filter(function (article) {
            return searchArticles.includes(article.id)
          })
        }

        return articles
      }

      return []
    }
  },
  created() {
    this.fetchArticles()
  }
}
</script>
