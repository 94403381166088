export default {
  help: {
    helpAndAdvice: 'Hilfe und Ratgeber',
    search: 'Suche',
    manageEntries: 'Hilfe-Einträge verwalten',
    helpEntries: 'Hilfe-Einträge',
    manageAndEditArticles: 'Hilfe-Artikel verwalten und bearbeiten.',
    answerQuestions: 'Fragen & Antworten',
    manageAndEditTopicSections:
      'Themenrubriken, Fragen und Antworttexte verwalten und bearbeiten.',
    textSearchOrID: 'Textsuche oder ID',
    createNewCategory: 'Neue Kategorie erstellen',
    newEntry: 'Neuer Eintrag',
    table: {
      active: 'Aktiv',
      notActive: 'Nicht aktiv',
      headline: 'Headline',
      summary: 'Zusammenfassung',
    },
    action: {
      delete: 'Eintrag löschen',
      deleteEntry: 'Hilfeeintrag löschen',
    },
    form: {
      entryActive: 'Eintrag aktiv',
      titleEN: 'Titel englisch',
      titleDE: 'Titel deutsch',
      questionEN: 'Frage englisch',
      questionDE: 'Frage deutsch',
      headlineDE: 'Überschrift deutsch',
      headlineEN: 'Überschrift englisch',
      summaryDE: 'Zusammenfassung deutsch',
      summaryEN: 'Zusammenfassung englisch',
      textDE: 'Text deutsch',
      textEN: 'Text englisch',
    },
    modal: {
      germanEntry: 'Deutscher Eintrag:',
      englishEntry: 'Englischer Eintrag:',
      newCategoryTitle: 'Neue Kategorie erstellen',
      newCategorySubtitle:
        'Geben Sie die Titel der neuen Kategorie auf deutsch und englisch ein.',
      newEntryTitle: 'Neuen Eintrag erstellen',
      newEntrySubtitleTitle:
        'Erstellen Sie einen neuen zweisprachigen Eintrag mit einer kurzen Zusammenfassung.',
    },
    notes: {
      notes: 'Notizen',
      note: 'Notiz',
      noteSubject: 'Betreff der Notiz',
      noteNew: 'Neue Notiz für',
      noteFor: 'Notizen für',
      noteBy: 'Notiz von',
      company: 'Notizen zum Unternehmen',
      help: 'Hier können Sie Notizen zu einem Unternehmen anlegen und bearbeiten. Auf diese Notizen haben die beantragenden Wirtschaftsorganisationen, BMWK, BMEL, BAFA, Durchführungsgesellschaften sowie die Administratoren von leapfrog / w+t einen Lese- und Schreibzugriff. Entsprechend sehen Sie auch die eingetragenen Notizen der anderen Nutzer. Bitte legen Sie nur Notizen zum Unternehmen selbst an. Vermeiden Sie möglichst konkrete Daten zu Personen sowie organisatorische Sachbezüge zu einzelnen Messebeteiligungen.',
    },
  },
}
