<template>
  <v-dialog v-model="display" max-width="700">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('forms.buttons.addEntry', lang) }}
        </h4>
        <v-btn @click="hide" color="primary" icon>
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        {{
          $t('participation.exhibitionGoods.addGoodsSubtitle', lang)
        }}
      </v-card-text>
      <v-divider />
      <v-container>
        <v-form>
          <v-row>
            <v-col cols="12" class="px-7 pb-0 mt-5">
              <v-text-field
                v-model="form.data.title"
                :error-messages="errors.title"
                :label="$t('forms.fields.title', lang)"
                class="rounded-0"
                filled
              />
              <v-text-field
                v-model="form.data.weight"
                type="number"
                :error-messages="errors.weight"
                :label="$t('forms.fields.weight', lang)"
                :hint="$t('forms.fields.weightHint', lang)"
                class="rounded-0"
                filled
              />
            </v-col>
          </v-row>
          <v-row class="px-4">
            <v-col cols="4">
              <v-text-field
                v-model="form.data.height"
                type="number"
                :error-messages="errors.height"
                :label="$t('forms.fields.height', lang)"
                :hint="$t('forms.fields.heightHint', lang)"
                class="rounded-0"
                filled
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="form.data.width"
                type="number"
                :error-messages="errors.width"
                :label="$t('forms.fields.width', lang)"
                :hint="$t('forms.fields.widthHint', lang)"
                class="rounded-0"
                filled
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="form.data.depth"
                type="number"
                :error-messages="errors.depth"
                :label="$t('forms.fields.depth', lang)"
                :hint="$t('forms.fields.depthHint', lang)"
                class="rounded-0"
                filled
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                v-model="form.data.hints"
                :error-messages="errors.hints"
                :label="$t('forms.fields.hints', lang)"
                class="rounded-0"
                filled
              />
            </v-col>
          </v-row>
          <v-row class="px-7 pb-7">
            <v-btn
              class="elevation-0 col-2"
              :loading="form.loading"
              @click="hide"
            >
              {{ $t('common.cancel', lang) }}
            </v-btn>
            <v-btn
              class="elevation-0 col-2 ml-auto"
              color="secondary"
              :loading="form.loading"
              @click="handleSubmit"
            >
              {{ $t('common.save', lang) }}
            </v-btn>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { required, integer, minValue } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'CreateGoods',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  mixins: [validation, participationMode],

  data() {
    return {
      display: false,

      good: false,

      form: {
        loading: false,

        data: {
          id: null,
          title: '',
          weight: '',
          height: '',
          width: '',
          depth: '',
          hints: '',
        },

        validationRules: {
          id: {},
          title: {
            required,
          },
          weight: {
            required,
            integer,
            minValue: minValue(1),
          },
          height: {
            required,
            integer,
            minValue: minValue(1),
          },
          width: {
            required,
            integer,
            minValue: minValue(1),
          },
          depth: {
            required,
            integer,
            minValue: minValue(1),
          },
          hints: {},
        },

        custom: {
          validationSubstitutions: {
            weight: {
              minValue: {
                limit: 1,
              },
            },
            height: {
              minValue: {
                limit: 1,
              },
            },
            width: {
              minValue: {
                limit: 1,
              },
            },
            depth: {
              minValue: {
                limit: 1,
              },
            },
          },
        },
      },
    }
  },

  methods: {
    ...mapActions('participation', ['createGoodsItem', 'updateGoodsItem']),

    show(item) {
      this.good = item

      this.$v.$reset()
      this.setInitialFormData(JSON.parse(JSON.stringify(item?.attributes ?? {})))

      this.display = true
    },

    hide() {
      this.display = false
    },

    async handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      if (this.good?.id) {
        await this.updateGoodsItem({ participation: this.participation, good: { ...this.getFormData(), id: this.good.id } })
      } else {
        await this.createGoodsItem({ participation: this.participation, good: this.getFormData() })

        this.$emit('submit')
      }

      this.form.loading = false
      this.hide()
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
