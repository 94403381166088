<template>
  <v-card class="rounded-0 elevation-1 mb-5">
    <v-card-text class="text--primary">
      <p>
        {{ $t('participation.registrationIsNotCarriedOutOnline') }}
        <a
          :href="aumaLink"
          target="_blank"
        >
          {{ aumaLink }}
        </a>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PrintRegistrationOpen',

  props: {
    aumaLink: {
      type: String,
      required: true,
    },
  },
}
</script>
