<template>
  <div class="pt-5 mt-2">
    <v-row>
      <v-col cols="12">
        <v-autocomplete
            v-model="filters.names"
            :items="fairs"
            :loading="loading"
            :disabled="loading"
            item-text="attributes.name_display"
            item-value="id"
            :label="$t('fairs.tradeFairName')"
            :no-data-text="$t('common.noDataAvailable')"
            multiple
            clearable
            chips
            dense
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            v-model="filters.countries"
            :items="countries"
            :loading="loading"
            :disabled="loading"
            :item-text="`translations.${$i18n.locale}.country`"
            item-value="id"
            chips
            :label="$t('fairs.country')"
            multiple
            dense
            clearable
            background-color="transparent"
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            v-model="filters.years"
            :items="years"
            :loading="loading"
            :disabled="loading"
            chips
            :label="$t('fairs.year')"
            multiple
            dense
            clearable
            background-color="transparent"
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            v-model="filters.sectors"
            :items="sectors"
            :loading="loading"
            :disabled="loading"
            :item-text="`translations.${$i18n.locale}.name`"
            item-value="id"
            chips
            :label="$t('fairs.branch')"
            multiple
            flat
            dense
            clearable
        />
      </v-col>

    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'FairSearch',

  data() {
    return {
      loading: false,
      fairs: [],

      filters: {
        names: [],
        countries: [],
        years: [],
        sectors: [],
      },
    }
  },

  computed: {
    ...mapGetters('auth', {
      preferences: 'preferences',
    }),

    ...mapGetters('sectors', [
      'sectors',
    ]),

    countries() {
      let countries = []

      for (let fair of this.fairs) {
        if (!fair.relationships.country) {
          continue;
        }

        countries[fair.relationships.country.id] = fair.relationships.country
      }

      return countries
    },

    years() {
      let years = {}

      this.fairs.map(function (item) {
        years[item.attributes.year] = item.attributes.year
      })

      return Object.values(years)
    },
  },

  mounted() {
    this.filters.countries = this.preferences['search.fairs.countries'] ?? [];
    this.filters.years = this.preferences['search.fairs.years'] ?? [];
    this.filters.sectors = this.preferences['search.fairs.sectors'] ?? [];
  },

  created() {
    this.load()

    this.fetchSectors()
  },

  methods: {
    ...mapActions('sectors', [
      'fetchSectors'
    ]),

    ...mapActions('auth', [
      'fetchUserPreferences',
      'setUserPreferences',
      'getUserPreferences',
    ]),

    async load() {
      let response = (await this.$axios.get('/api/trade-fairs', {
        params: {
          'languages': '*',
          'include': [
            'industrySectors',
            'country',
            'associations',
          ],
          'fields': [
            'name',
            'name_alternative',
            'start_date',
            'country_id',
          ],
        },
      })).data

      this.fairs = response.data
    },

    handleInputChangeWithDelay: debounce(async function ({key, value}) {
      await this.setUserPreferences({key, value});
    }, 200),
  },

  watch: {
    'filters': {
      handler() {
        this.$emit('input', this.filters)
      },
      deep: true
    },

    'filters.countries'(value, oldValue) {
      if (!value.length && !oldValue.length) {
        return;
      }

      this.handleInputChangeWithDelay({key: 'search.fairs.countries', value});
    },

    'filters.years'(value, oldValue) {
      if (!value.length && !oldValue.length) {
        return;
      }

      this.handleInputChangeWithDelay({key: 'search.fairs.years', value});
    },

    'filters.sectors'(value, oldValue) {
      if (!value.length && !oldValue.length) {
        return;
      }

      this.handleInputChangeWithDelay({key: 'search.fairs.sectors', value});
    },
  }
}
</script>
