<template>
  <div>
    <v-stepper-step
        :step="step"
        :editable="isEditable"
        :rules="[() => !isStepsErrored]"
        @click="$emit('click')"
    >
      <span v-if="!isSpaceEmpty" class="stepper-abstract">
        {{ totalAssignedSize }} m<sup>2</sup>
        {{ assignedStandConstructionDescription }}

      </span>
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{
          $t('participation.exhibitionSpace.title', lang)
        }}
      </h3>
      <small v-if="isStepsErrored">
        {{
          $t('participation.stepsIsErrored', lang)
        }}
      </small>
    </v-stepper-step>

    <v-stepper-content :step="step" class="pb-10">
      <v-container fluid>
        <v-row>
          <v-col cols="6" class="pr-10">
            <v-radio-group
                v-model="form.data.withStandConstruction"
                :error-messages="errors.withStandConstruction"
                :readonly="isReadonlyMode"
            >

              <v-row>
                <v-col cols="auto">
                  <v-radio
                      :label="$t('participation.exhibitionSpace.withStandConstruction', lang)"
                      :value="true"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-radio
                      v-if="spacesWithoutStandConstruction.length"
                      :label="$t('participation.exhibitionSpace.withoutStandConstruction', lang)"
                      :value="false"
                  />
                </v-col>
              </v-row>

            </v-radio-group>

            <v-text-field
                v-model="form.data.size"
                :label="$t('participation.exhibitionSpace.hallSpace', lang)"
                :error-messages="errors.size"
                :hint="spaceSizeHint"
                :readonly="isReadonlyMode"
                type="number"
                min="0"
                step="1"
                class="rounded-0"
                filled
                dense
                @change="(val) => form.data.size = Math.round(val)"
            />
          </v-col>
          <v-col cols="6">
            <div v-if="isSpaceValid">
              <small>{{ $t('participation.exhibitionSpace.standSpacePrice', lang) }}</small>
              <div v-for="(space, index) in selectedSpaces" :key="index">
                <span v-if="index > 0">+ </span>
                <strong>{{
                    (getSpacePrice(space) * space.attributes.size).toFixed(2) | money(lang)
                  }}</strong>
                ({{ space.attributes.size }} m<sup>2</sup>
                {{ currentStandConstructionDescription }})
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pr-10">
            <table class="table-costs mb-5">
              <thead>
              <tr>
                <th colspan="3">
                  {{
                    $t('participation.exhibitionSpace.costOverview', lang)
                  }}
                  -
                  {{
                    $t('participation.exhibitionSpace.withStandConstruction', lang)
                  }}
                </th>
                <th>
                  {{ $t('participation.exhibitionSpace.allowedCoexhibitor', lang) }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="space in spacesWithStandConstruction"
                  :key="space.id"
              >
                <td class="no-wrap">{{ space.attributes.size_from }}-{{ space.attributes.size_to }} m<sup>2</sup></td>
                <td class="no-wrap">
                  {{
                    getSpacePrice(space) | money(lang)
                  }}
                  {{ $t('common.per', lang) }} m<sup
                >2</sup
                >
                </td>
                <td>
                  {{ space.attributes.title[lang] }}
                </td>
                <td>
                  <v-icon size="17">fal {{ space.attributes.allow_subexhibitor ? 'fa-check' : 'fa-times' }} </v-icon>
                </td>
              </tr>
              </tbody>
            </table>
          </v-col>
          <v-col cols="6">
            <table class="table-costs mb-5">
              <thead v-if="spacesWithoutStandConstruction.length">
              <tr>
                <th colspan="3">
                  {{
                    $t('participation.exhibitionSpace.costOverview', lang)
                  }}
                  -
                  {{
                    $t('participation.exhibitionSpace.withoutStandConstruction', lang)
                  }}
                </th>
                <th>
                  {{ $t('participation.exhibitionSpace.allowedCoexhibitor', lang) }}
                </th>
              </tr>
              </thead>
              <tbody v-if="spacesWithoutStandConstruction.length">
              <tr
                  v-for="space in spacesWithoutStandConstruction"
                  :key="space.id"
              >
                <td class="no-wrap">{{ space.attributes.size_from }}-{{ space.attributes.size_to }} m<sup>2</sup></td>
                <td class="no-wrap">
                  €
                  {{
                    getSpacePrice(space) | money(lang)
                  }}
                  {{ $t('common.per', lang) }} m<sup
                >2</sup
                >
                </td>
                <td>
                  {{ space.attributes.title[lang] }}
                </td>
                <td>
                  <v-icon size="17">fal {{ space.attributes.allow_subexhibitor ? 'fa-check' : 'fa-times' }} </v-icon>
                </td>
              </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="pr-10"
                 v-if="fair.attributes.servicedescription_with_construction[lang]?.length">
            <strong>
              {{
                $t('participation.configureRegistrationForm.serviceDescription.descriptionWithContruction', lang)
              }}
            </strong>
            <p v-html="fair.attributes.servicedescription_with_construction[lang]"></p>

          </v-col>

          <v-col cols="6"
                 v-if="fair.attributes.servicedescription_without_construction[lang]?.length">
            <strong>
              {{
                $t('participation.configureRegistrationForm.serviceDescription.descriptionWithoutContruction', lang)
              }}
            </strong>
            <p v-html="fair.attributes.servicedescription_without_construction[lang]"></p>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn
                class="elevation-0 secondary"
                :loading="form.loading"
                @click="handleSubmit"
                :disabled="!isSubmittedForm"
            >
              {{
                $t('forms.buttons.saveAndContinue', lang)
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-stepper-content>
  </div>
</template>

<script>
import {PRICE_TYPES} from '@/enums/priceTypes'
import {mapActions} from 'vuex'
import {required, minValue, maxValue} from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import participationStep from '@/mixins/participationStep'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'ExhibitionSpace',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  mixins: [validation, participationStep, participationMode],

  data() {
    return {
      form: {
        loading: false,

        additionalParams: {
          tradefairSpaceId: null,
          sizeAssigned: null,
          price: null,
        },

        data: {
          withStandConstruction: null,
          size: null,
        },

        validationRules: {
          withStandConstruction: {
            required,
          },
          size: {
            required,
          },
        },

        custom: {
          validationSubstitutions: {
            size: {},
          },
        },

        isValid: true,
      },
    }
  },

  computed: {
    assignedSpaces() {
      return this.participation?.relationships?.spaces ?? []
    },

    isSpaceEmpty() {
      return this.assignedSpaces ? Object.keys(this.assignedSpaces).length === 0 : true
    },

    isSpaceValid() {
      return (
          this.form.data.withStandConstruction !== null &&
          this.form.data.size &&
          !!this.selectedSpaces.length
      )
    },

    spacesWithStandConstruction() {
      return this.fair.relationships.spaces
          .filter((space) => space.attributes.construction_included)
          .sort((a, b) => a.sizeFrom - b.sizeFrom)
    },

    spacesWithoutStandConstruction() {
      return this.fair.relationships.spaces
          .filter((space) => !space.attributes.construction_included)
          .sort((a, b) => a.sizeFrom - b.sizeFrom)
    },

    areSpaceSizeLimitsPresent() {
      return this.fair.attributes.space_min_size && this.fair.attributes.space_max_size
    },

    spaceSizeHint() {
      var spaces = this.form.data.withStandConstruction ? this.spacesWithStandConstruction : this.spacesWithoutStandConstruction;

      if (!this.areSpaceSizeLimitsPresent || !spaces.length) {
        return ''
      }

      var sizeFrom = spaces[0].attributes.size_from;
      var sizeTo = spaces[spaces.length - 1].attributes.size_to;

      return (
          `(${this.$t('common.min', this.lang)}. ${
              sizeFrom
          }${this.$t('common.sqm', this.lang)} - ` +
          `${this.$t('common.max', this.lang)}. ${
              sizeTo
          }${this.$t('common.sqm', this.lang)})`
      )
    },

    selectedSpaces() {
      var selectedSpaces = [];
      var spaces = this.form.data.withStandConstruction ? this.spacesWithStandConstruction : this.spacesWithoutStandConstruction;

      var size = this.form.data.size;

      if (spaces.length && size < spaces[0].attributes.size_from) {
        return selectedSpaces;
      }

      for (var space of spaces) {
        if (size <= 0) {
          break;
        }

        var range = selectedSpaces.length ? space.attributes.size_to - space.attributes.size_from + 1 : space.attributes.size_to;

        selectedSpaces.push({
          ...space,
          attributes: {
            ...space.attributes,
            size: size - range < 0 ? size : range,
          },
          size: size - range < 0 ? size : range,
          tradefair_space_id: space.id
        });

        size -= range;
      }

      return selectedSpaces;
    },

    totalAssignedSize() {
      var size = 0;

      for (var space of this.assignedSpaces) {
        size += space.attributes.size;
      }

      return size;
    },

    currentStandConstructionDescription() {
      const status = this.form.data.withStandConstruction
          ? 'spacePerWithStandConstruction'
          : 'spacePerWithoutStandConstruction'
      return this.$t(`participation.exhibitionSpace.${status}`)
    },

    assignedStandConstructionDescription() {
      const status = this.assignedSpaces && this.assignedSpaces[0].attributes.construction_included
          ? 'spacePerWithStandConstruction'
          : 'spacePerWithoutStandConstruction'
      return this.$t(`participation.exhibitionSpace.${status}`)
    },
  },

  async created() {
    this.setSpaceSizesValidationRules()

    this.form.data.size = this.totalAssignedSize
    this.form.data.withStandConstruction = this.assignedSpaces[0]?.attributes?.construction_included
  },

  methods: {
    ...mapActions('participation', ['createSpace']),

    getSpacePrice(space) {
      switch (this.priceType) {
        case PRICE_TYPES.PRICE_PUBLICSECTOR:
          return space.attributes.price_publicsector
        case PRICE_TYPES.PRICE_UNSPONSORED:
          return space.attributes.price_unsponsored
        case PRICE_TYPES.PRICE_SPONSORED:
          return space.attributes.price_sponsored
        default:
          return space.attributes.price_sponsored
      }
    },

    setSpaceSizesValidationRules() {
      if (!this.areSpaceSizeLimitsPresent) {
        return
      }

      this.form.validationRules.size = {
        ...this.form.validationRules.size,
        minValue: minValue(this.fair.attributes.space_min_size),
        maxValue: maxValue(this.fair.attributes.space_max_size),
      }
      this.form.custom.validationSubstitutions.size = {
        minValue: {
          limit: this.fair.attributes.space_min_size,
        },
        maxValue: {
          limit: this.fair.attributes.space_max_size,
        },
      }
    },

    async handleSubmit() {
      if (!this.selectedSpaces.length || !this.checkIfFormFieldsAreValid()) {
        this.form.isValid = false
        this.errors.size = this.$t('participation.exhibitionSpace.sizeError', this.lang)
        return
      }

      this.form.loading = true
      this.form.isValid = true

      if (await this.createSpace({ participation: this.participation, spaces: this.selectedSpaces })) {
        this.form.loading = false
        this.$emit('submit')
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
