<template>
  <div>
    <v-stepper-step
        :step="step"
        :editable="isEditable"
        :rules="[() => !isStepsErrored]"
        @click="$emit('click')"
    >
      <span class="stepper-abstract" v-if="chosen"
            v-html="$t('participation.servicePackage.title', lang) + ' ' +  chosen.attributes.title[lang]">
      </span>
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{ $t('participation.servicePackage.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">{{ $t('participation.stepsIsErrored', lang) }}</small>
    </v-stepper-step>

    <v-stepper-content
        :step="step"
        class="pb-10"
    >
      <v-row>
        <v-col cols="11">
          <v-radio-group
              v-if="fair.relationships.packages.length"
              v-model="form.data.package"
              :error-messages="errors.servicePackage"
              :readonly="isReadonlyMode"
          >
            <v-simple-table class="table-packages">
              <tr
                  v-for="servicePackage in fair.relationships.packages"
                  :key="servicePackage.id"
              >
                <td>
                  <v-radio :value="servicePackage.id"/>
                </td>
                <td>
                  <strong v-html="servicePackage.attributes.title[lang]"></strong><br>
                  <span class="mb-4">
                    {{ $t('participation.configureRegistrationForm.allowSubexhibitorCheckbox') }}:
                    {{ servicePackage.attributes.allow_subexhibitor ? $t('common.yes') : $t('common.no') }}
                  </span>
                  <br/>
                  <br/>
                  <span v-html="servicePackage.attributes.services[lang]"></span>
                </td>
                <td nowrap>
                  <strong>{{ getPackagePrice(servicePackage) | money(lang) }}</strong>
                </td>
              </tr>
            </v-simple-table>
          </v-radio-group>
          <span v-else>
            {{ $t('participation.servicePackage.thereIsNoServicePackage', lang) }}
          </span>
        </v-col>

        <submit-button
            :is-readonly="!isSubmittedForm"
            :is-loading="form.loading"
            @clicked="handleSubmit"
        />
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import {PRICE_TYPES} from '@/enums/priceTypes'
import {requiredIf} from 'vuelidate/lib/validators'
import {mapActions} from 'vuex'
import SubmitButton from '@/components/exhibitor/Participation/SubmitButton'
import validation from '@/mixins/validate'
import participationStep from '@/mixins/participationStep'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'ServicePackage',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  components: {
    SubmitButton,
  },

  mixins: [
    validation,
    participationStep,
    participationMode,
  ],

  data() {
    return {
      form: {
        loading: false,

        data: {
          package: null,
        },

        validationRules: {
          package: {
            requiredIf: requiredIf(function () {
              return this.fair?.relationships?.packages.length
            })
          },
        },

        isValid: true,
      },
    }
  },

  computed: {
    chosen() {
      return this.participation?.relationships?.package
    },
  },

  created() {
    this.setInitialFormData({package: this.participation.relationships?.package?.id})
  },

  methods: {
    ...mapActions('participation', [
      'attachPackage',
    ]),

    getPackagePrice(packaged) {
      switch (this.priceType) {
        case PRICE_TYPES.PRICE_PUBLICSECTOR:
          return packaged.attributes.price_publicsector
        case PRICE_TYPES.PRICE_UNSPONSORED:
          return packaged.attributes.price_unsponsored
        case PRICE_TYPES.PRICE_SPONSORED:
          return packaged.attributes.price_sponsored
        default:
          return packaged.attributes.price_sponsored
      }
    },

    async handleSubmit() {
      if (!this.fair?.relationships?.packages.length) {
        this.$emit('submit')
        return
      }

      if (!this.checkIfFormFieldsAreValid()) {
        this.form.isValid = false
        return
      }

      this.form.loading = true
      this.form.isValid = true

      if (await this.attachPackage({ participation: this.participation, packag: this.fair.relationships.packages.find((el) => el.id === this.form.data.package) })) {
        this.$emit('submit')
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
