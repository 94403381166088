import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lang from '@/i18n/index'
import { getCurrentLocale } from '@/utilities/helpers/i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: getCurrentLocale(),
  messages: lang,
})

export default i18n
