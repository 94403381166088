<template>
  <v-expansion-panel class="elevation-1 rounded-0">
    <v-expansion-panel-header>
        <h3>{{ $t('tradefairsummary.title') }}</h3>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <v-btn :disabled="!fair.attributes.summary_generated_file" @click="downloadTradeFairSummary({ tradeFairId: fair.id })" color="secondary" style="text-transform: none">
        <v-icon size="16" class="mr-2"> fal fa-file-pdf </v-icon>
        {{ $t('tradefairsummary.submitted.downloadTradefairSummary') }}
      </v-btn>
      <v-btn @click="downloadTradeFairSummaryMedia({ tradeFairId: fair.id })" color="secondary" class="ml-5" style="text-transform: none">
        <v-icon size="16" class="mr-2"> fal fa-file-image </v-icon>
        {{ $t('tradefairsummary.submitted.downloadTradefairImages') }}
      </v-btn>
      <v-row class="mt-5">
            <v-container class="fill-height" fluid>
              <v-row>
                <v-col v-for="(item, i) in images" cols="4" :key="i">
                  <v-card elevation="2">
                    <v-img
                        :src="item.blob"
                        max-height="125"
                        contain
                    >
                    </v-img>
                    <v-card-text>
                      <v-col
                          cols="12"
                          class="d-flex align-center py-0 px-0 mt-4"
                      >
                        <v-text-field
                            v-model="item.name"
                            :label="$t('tradefairsummary.images.charaterization')"
                            disabled
                        ></v-text-field>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TradeFairSummary',

  props: {
    participations: {
      type: Array,
      required: false,
      default: () => [],
    },

    fair: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('auth', ['isAssociation', 'isSupervisor']),
  },

  data() {
    return {
      images: [],
    }
  },

  created() {
    this.loadImages()
  },

  methods: {
    ...mapActions('organizer', [
      'downloadTradeFairSummary',
      'downloadTradeFairSummaryMedia',
      'getTradeFairSummaryMedia',
    ]),

    async loadImages() {
      let images = []
      for (let media of this.fair.relationships.summary_images) {
        images.push({
          id: media.id,
          name: media.name,
          blob: await this.getTradeFairSummaryMedia({ tradeFairId: this.fair.id, id: media.id })
        })
      }

      this.images = images
    },
  },
}
</script>
>
