<template>
  <v-card class="rounded-0 elevation-1 mb-5">
    <v-row>
      <v-col cols="6">
        <v-card-text class="text--primary">
          <h3>{{ $t('participation.onlineRegistration') }}</h3>
          <p v-if="isDeadlineRunning">
            {{ $t('participation.deadlineStatuses.running', { date: formattedDeadlineDate }) }}
          </p>
          <p v-else>
            {{ $t('participation.deadlineStatuses.expired', { date: formattedDeadlineDate }) }}
          </p>
          <p>
            <v-icon
                size="16"
                class="secondary--text mr-4"
            >
              fal fa-link
            </v-icon>
            {{ $t('participation.link.useRegistrationLink') }}
          </p>
          <v-row>
            <v-col cols="10">
              <v-text-field
                  :label="$t('participation.link.onlineRegistrationLink')"
                  :value="fair.included.registration_url"
                  readonly
              />
            </v-col>
            <v-col cols="1">
              <v-btn
                  class="mt-4"
                  icon
                  v-clipboard="fair.included.registration_url"
                  @success="handleCopySuccess"
              >
                <v-icon
                    size="20"
                    class="secondary--text"
                >
                  fal fa-clipboard
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col cols="6">
        <v-card-text v-if="isDeadlineRunning" class="text--primary">
          <h3>{{ $t('participation.notifyHeader') }}</h3>
          <p>
            {{ $t('participation.notifyText') }}
          </p>
          <v-btn
              class="mt-4"
              color="secondary"
              @click="handleNotify"
          >
            {{ $t('participation.notifyButton') }}
          </v-btn>
          <div v-if="fair.attributes.notified_at" class="mt-2">
            {{
              $t('participation.notifyCount', {
                amount: fair.attributes.notified_count,
                date: $options.filters.dateByLocale(fair.attributes.notified_at),
                time: $options.filters.time(fair.attributes.notified_at)
              })
            }}
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'RegistrationLink',

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      form: {
        loading: false,
      }
    }
  },

  computed: {
    isDeadlineRunning() {
      return this.$moment(this.fair.attributes.registration_deadline).isAfter()
    },

    formattedDeadlineDate() {
      return this.$options.filters.dateByLocale(this.fair.attributes.registration_deadline)
    }
  },

  methods: {
    handleCopySuccess() {
      this.$snackbar(this.$t('common.copiedSuccessfully'))
    },

    async handleNotify() {
      this.form.loading = true

      await this.$axios.post(`/api/trade-fairs/${this.fair.id}/notify`, { })

      this.$snackbar(this.$t('common.successApiMessage'))

      this.$emit('submit')

      this.form.loading = false
    }
  },
}
</script>
