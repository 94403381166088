<template>
  <v-data-table
      :headers="headers"
      :loading="form.loading"
      :items="fairs"
      :page.sync="form.page"
      :options.sync="options"
      :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
      :server-items-length="form.total"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      show-expand
  >
    <template v-slot:item.is_favorite="{ item }">
      <v-simple-checkbox
          v-model="item.included.is_favorite"
          :ripple="false"
          :disabled="form.loading || participation(item) && !isAvailableToFavorite({ status: participation(item).status })"
          v-on:click="say({ tradeFair: item, participation: participation(item) })"
      />
    </template>

    <template v-slot:item.name="{ item }">
      <router-link v-if="typeof item.route !== 'undefined'" :to="item.route" class="text-decoration-none">
        <h3>{{ item.attributes.name_display }}</h3>
      </router-link>
      <h3 v-else>{{ item.attributes.name_display }}</h3>
    </template>

    <template v-slot:item.registration_deadline="{ item }">
      <span v-if="item.attributes.registration_deadline">
        {{ item.attributes.registration_deadline | dateByLocale }}
      </span>
      <span v-else>
        {{ $t('tables.tradeFair.notYetKnown') }}
      </span>
    </template>

    <template v-slot:item.start_date="{ item }">
      {{ item.attributes.start_date_display[$i18n.locale] }}
    </template>

    <template v-slot:item.end_date="{ item }">
      <span>{{ item.attributes.end_date | dateByLocale }}</span>
    </template>

    <template v-slot:item.city="{ item }">
      <span>{{ item.attributes.city }}</span>
    </template>

    <template v-slot:item.country="{ item }">
      <span>{{ item.relationships.country?.translations[$i18n.locale].country }}</span>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="elevation-1 px-1 grey lighten-5">
        <v-container fluid>
          <v-row>

            <v-col cols="12">
              <h4 v-html="item.attributes.name_display"></h4>
            </v-col>

            <v-col cols="6">
              <small>{{ $t('tables.tradeFair.mainOffering') }}</small>
              <p>
                {{ item.attributes.fair_focus[$i18n.locale] }}
              </p>

              <small>{{ $t('tables.tradeFair.industryFocus') }}</small>
              <p>
                <span
                    v-for="sector in item.relationships.sectors"
                    :key="sector.id"
                >
                  {{ sector.translations[$i18n.locale].name }}<br>
                </span>
              </p>

              <v-btn
                  class="secondary elevation-0 mt-4"
                  :to="{ name: 'exhibitor.fair.view', params: { id: item.id } }"
                  small
              >
                {{ $t('tables.tradeFair.informationAndRegistration') }}
              </v-btn>
            </v-col>

            <v-col cols="6">

              <table class="facts">
                <tbody>
                <tr>
                  <td class="pl-0">
                    <small>{{ $t('tables.tradeFair.participationForm') }}</small>
                  </td>
                  <td>
                    {{ item.attributes.type.type[$i18n.locale] }}
                    <HelpButton :icon-size="18" :help-entry-id="4"/>
                  </td>
                </tr>
                <tr v-if="item.relationships.organizer?.email">
                  <td class="d-flex pl-0 align-start">
                    <small>{{ $t('tables.tradeFair.execution') }}</small>
                  </td>
                  <td class="pt-0 align-start">
                    {{ item.relationships.organizer.name }}
                    <br/>
                    <span v-if="item.relationships.organizer.phone">
                      {{ $t('tables.tradeFair.phone') }}: {{ item.relationships.organizer.phone }}<br/>
                    </span>
                    <span v-if="item.relationships.organizer.fax">
                      {{ $t('tables.tradeFair.fax') }}: {{ item.relationships.organizer.fax }}<br/>
                    </span>
                    <NormalizeUrl :email="item.relationships.organizer.email" :website="item.relationships.organizer.website"></NormalizeUrl>
                  </td>
                </tr>
                <tr v-if="item.attributes.contact_email">
                  <td class="d-flex pl-0 align-start">
                    <small>{{ $t('tables.tradeFair.projectManager') }}</small>
                  </td>
                  <td class="pt-0 align-start">
                    {{ item.attributes.contact_name }}<br/>
                    <a :href="`mailto:${item.attributes.contact_email}`">{{ item.attributes.contact_email }}</a><br/>
                    {{ item.attributes.contact_phone }}<br/>
                  </td>
                </tr>
                <tr v-if="item.attributes.website">
                  <td class="pl-0">
                    <small>{{ $t('tables.tradeFair.organizerWebsite') }}</small>
                  </td>
                  <td><a :href="item.attributes.website" target="_blank">{{ item.attributes.website }}</a></td>
                </tr>
                <tr>
                  <td class="pl-0">
                    <small>{{ $t('tables.tradeFair.detailedTradeFairInformation') }}</small>
                  </td>
                  <td>
                    <a
                        :href="item.attributes.link_auma_database"
                        target="_blank"
                    >
                      {{ $t('tables.tradeFair.AUMATradeFairDatabase') }}
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </td>
    </template>
    <template slot="no-data">
      {{ $t('common.noDataAvailable') }}
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {PARTICIPATION_STATUS} from "@/enums/participationStatus";
import {mapWaitingActions} from "vue-wait";
import {FETCH_PREFERRED_TRADE_FAIRS} from "@/store/loading-types";
import NormalizeUrl from '@/components/base/NormalizeUrl.vue';
import {is} from "vue-wait/src/utils";

export default {
  name: 'FairList',

  components: {
    NormalizeUrl,
  },

  props: {
    filters: Object
  },

  data() {
    return {
      fairs: [],
      options: {},

      form: {
        total: 0,
        loading: false,
      },
    }
  },

  computed: {
    ...mapGetters('auth', {
      preferences: 'preferences',
    }),

    ...mapGetters('participation', {
      participations: 'participations',
    }),

    headers() {
      return [
        {
          text: '',
          value: 'data-table-expand'
        },
        {
          text: this.$t('tables.tradeFair.tradeFair'),
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('tables.tradeFair.city'),
          value: 'city',
          sortable: true
        },
        {
          text: this.$t('tables.tradeFair.country'),
          value: 'country',
          sortable: false
        },
        {
          text: this.$t('tables.tradeFair.startAt'),
          value: 'start_date',
          sortable: true,
          dataType: "Date"
        },
        {
          text: this.$t('tables.tradeFair.endAt'),
          value: 'end_date',
          sortable: true,
          dataType: "Date"
        },
        {
          text: this.$t('tables.tradeFair.registrationDeadline'),
          value: 'registration_deadline',
          sortable: true,
          dataType: "Date"
        },
        {
          text: this.$t('tables.tradeFair.bookmarked'),
          value: 'is_favorite',
          sortable: false
        }
      ]
    }
  },

  created() {
    this.load()

    this.fetchParticipations()
    this.fetchPreferredTradeFairs()
  },

  methods: {
    is,
    ...mapActions('participation', [
      'setNotedTradeFair',
      'unsetNotedTradeFair',
      'fetchParticipations',
      'fetchPreferredTradeFairs',
    ]),

    ...mapActions('auth', [
      'setUserPreferences',
    ]),

    ...mapWaitingActions('participation', {
      fetchPreferredTradeFairs: FETCH_PREFERRED_TRADE_FAIRS,
    }),

    participation(tradeFair) {
      return this.participations.find((el) => el.tradeFair.id === tradeFair.id)
    },

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get('/api/trade-fairs', {
        params: {
          'languages': '*',
          'filter[tradefair_id]': this.filters?.names,
          'filter[years]': this.filters?.years,
          'filter[country_id]': this.filters?.countries,
          'filter[sector_id]': this.filters?.sectors,
          'include': [
            'exhibitionAreas',
            'industrySectors',
            'country',
            'packages',
            'services',
            'spaces',
            'organizer',
            'associations',
          ],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      this.fairs = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },

    async say({participation, tradeFair}) {
      if (this.form.loading) {
        return
      }

      if (participation && !this.isAvailableToFavorite({status: participation.status})) {
        return
      }

      this.form.loading = true
      if (tradeFair.included.is_favorite) {
        if (await this.setNotedTradeFair(tradeFair.id)) {
          await this.fetchParticipations()
        }
      } else {
        if (await this.unsetNotedTradeFair(tradeFair.id)) {
          await this.fetchParticipations()
        }
      }
      this.form.loading = false
    },

    isAvailableToFavorite({status}) {
      if ([
        PARTICIPATION_STATUS.REJECTED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.REPROCESSED,
        PARTICIPATION_STATUS.APPROVED,
        PARTICIPATION_STATUS.WAITING
      ].includes(status)) {
        return false
      }

      return true
    },
  },

  watch: {
    'options'() {
      this.load()
    },

    'filters': {
      handler() {
        this.options.page = 1

        this.load()
      },
      deep: true
    },
  },
}
</script>
