<template>
  <v-container class="pa-9" fluid>

    <v-card class="elevation-1 rounded-0 mb-5 primary--text">
      <v-card-title>
        <h3>{{ $t('settings.dataAndSettings') }}</h3>
      </v-card-title>
      <v-card-text>
        <p class="text--primary">{{ $t('settings.manageSettings') }}</p>
      </v-card-text>
    </v-card>

    <v-expansion-panels
      class="mb-10 elevation-0 rounded-0"
      :value="panels"
      multiple
      flat
    >

      <v-expansion-panel class="elevation-1 rounded-0">
        <v-expansion-panel-header>
          <h3>{{ $t('settings.sections.userCredentials.title') }}</h3>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <user-credentials-settings />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="elevation-1 rounded-0">
        <v-expansion-panel-header>
          <h3>{{ $t('forms.fields.contactTradeFairParticipation') }}</h3>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <contact-form />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="elevation-1 rounded-0">
        <v-expansion-panel-header>
          <h3>{{ $t('settings.sections.companyData.title') }} / {{ $t('settings.sections.accreditation.title') }}</h3>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <accreditation-settings />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
        v-if="shouldNotificationsSettingsBeShown"
        class="elevation-1 rounded-0"
      >
        <v-expansion-panel-header>
          <h3>{{ $t('settings.sections.notificationSettings.title') }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <notifications-settings />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="elevation-1 rounded-0">
        <v-expansion-panel-header>
          <h3>{{ $t('settings.sections.profileOfInterest.title') }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <interest-profile-settings />
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

  </v-container>
</template>

<script>
import UserCredentialsSettings from '@/components/exhibitor/Settings/UserCredentials'
import AccreditationSettings from '@/components/exhibitor/Settings/Accreditation'
import NotificationsSettings from '@/components/exhibitor/Settings/Notifications'
import InterestProfileSettings from '@/components/exhibitor/Settings/InterestProfile'
import ContactForm from "@/components/exhibitor/Company/ContactForm.vue";

export default {
  name: 'ExhibitorSettings',

  components: {
    ContactForm,
    UserCredentialsSettings,
    AccreditationSettings,
    NotificationsSettings,
    InterestProfileSettings,
  },

  data() {
    return {
      panels: [],
      shouldNotificationsSettingsBeShown: false,
    }
  },
}
</script>
