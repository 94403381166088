<template>
  <v-app style="background-color: #f9f9f9">
    <public-header />
    <v-main>
      <v-container class="py-10">
        <v-sheet class="pa-5 pa-md-10 elevation-1">
          <v-btn
              color="secondary"
              class="float-right"
              elevation="0"
              icon
              @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          >
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>

          <h1 class="mb-5">{{ $t('common.serverErrors') }}</h1>
          <p v-html="$t('common.serverErrorsText')"></p>
        </v-sheet>
      </v-container>
    </v-main>
    <v-footer>
      <logos />
    </v-footer>
  </v-app>
</template>

<script>
import Logos from '@/components/base/Logos/Logos'
import PublicHeader from '@/components/public/Header.vue'

export default {
  components: { Logos, PublicHeader },

  methods: {
    hasHistory() {
      return window.history.length > 2
    },
  },
}
</script>
