<template>
  <v-dialog v-model="isShown" max-width="600">
    <v-card>
      <v-card-title style="justify-content: space-between">
        <h4 class="primary--text">
          {{ $t('participation.configureRegistrationForm.rejectWithMessageModal.title') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider />
      <v-form>
        <v-container class="px-7">
          <v-row>
            <v-col cols="12" class="mb-0 pb-0">
                {{ $t('participation.configureRegistrationForm.rejectWithMessageModal.body') }}
            </v-col>
            <v-col cols="12" class="mb-0 pb-0">
              <v-textarea class="mb-0 pb-0"
                            v-model="form.data.deniedNote"
                            :label="$t('participation.configureRegistrationForm.rejectWithMessageModal.label')"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="mb-2 px-2">
            <v-btn class="col-3 mr-auto" @click="hide" color="secondary" text outlined>
              {{ $t('participation.configureRegistrationForm.cancel') }}
            </v-btn>

            <v-btn
                color="secondary"
                @click="handleSubmit"
                class="col-3 ml-auto"
            >
              {{ $t('participation.configureRegistrationForm.apply') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import validation from '@/mixins/validate'

export default {
  name: 'ReturnParticipantMessageModal',

  mixins: [validation],

  data() {
    return {
      isShown: false,
      callback: null,
      form: {
        data: {
          deniedNote: ''
        }
      },
    }
  },

  methods: {
    show(callback) {
      this.callback = callback
      this.form.data.deniedNote = null

      this.isShown = true
    },

    hide() {
      this.isShown = false
    },

    handleSubmit() {
      this.isShown = false

      this.callback(this.form.data.deniedNote)
    },
  },
}
</script>
