import { api } from '@/boot/api'
import {
  SET_ORGANIZER,
  SET_ORGANIZERS,
  SET_TRADE_FAIRS,
  SET_TEMPLATES,
  SET_TRADE_FAIR,
  SET_TRADE_FAIR_ID,
  SET_USERS,
  SET_USER,
  REMOVE_USER,
  ADD_USER,
  SET_TRADE_FAIR_FORM_LOADED,
  SET_TRADE_FAIR_PACKAGES,
  SET_TRADE_FAIR_AREAS,
  SET_TRADE_FAIR_SPACES,
  SET_TRADE_FAIR_SERVICES,
  UPDATE_TRADE_FAIR_PACKAGES,
  UPDATE_TRADE_FAIR_AREAS,
  UPDATE_TRADE_FAIR_SPACES,
  UPDATE_TRADE_FAIR_SERVICES,
  DELETE_TRADE_FAIR_PACKAGES,
  DELETE_TRADE_FAIR_AREAS,
  DELETE_TRADE_FAIR_SPACES,
  DELETE_TRADE_FAIR_SERVICES,
  CREATE_TRADE_FAIR_PACKAGES,
  CREATE_TRADE_FAIR_AREAS,
  CREATE_TRADE_FAIR_SPACES,
  CREATE_TRADE_FAIR_SERVICES
} from '@/store/mutation-types'
import { USER_ROLES } from '@/enums/userRole'
import { makeDefaultGetters } from '@/utilities/helpers/store'
import axios from "axios";
import {getCurrentLocale} from "@/utilities/helpers/i18n";

const defaultState = () => ({
  organizer: {},
  tradeFairID: null,
  isFormDataLoaded: false,
  organizers: [],
  tradeFairs: [],
  packages: [],
  spaces: [],
  areas: [],
  services: [],
  users: [],
  templates: [],
})

const state = defaultState()

const actions = {
  async extractFromUser({ commit, state, rootGetters }) {
    try {
      const user = rootGetters['auth/user']

      if (Object.keys(state.organizer).length !== 0 && user.organizerId === state.organizer.id) {
        return
      }

      commit(SET_ORGANIZER, user.organizer)
      if (user.organizer.users && user.organizer.users.length) {
        commit(SET_USERS, user.organizer.users)
      }

      return true
    } catch (error) {
      console.error('API error (fetch organizer): ', error)
      return false
    }
  },

  async setCurrentTradeFair({ commit, dispatch }, id) {
    try {
      commit(SET_TRADE_FAIR_ID, id)
      commit(SET_TRADE_FAIR_FORM_LOADED, false)

      dispatch('fetchCurrentTradeFair', id)
      localStorage.setItem('organizer.tradeFair', id);

      return true
    } catch (error) {
      console.error('Error (set current Trad fair): ', error)
      return false
    }
  },

  async fetchTradeFairs({ commit, state, dispatch }, payload) {
    try {
      if (state.tradeFairs.length && (typeof payload === 'undefined' || payload.force !== true)) {
        return true
      }

      const fairs = await api.get('/trade-fairs')

      commit(SET_TRADE_FAIRS, fairs)

      if (!state.tradeFairID && fairs.length) {
        let tradeFairLocal = localStorage.getItem('organizer.tradeFair')

        if (fairs.find((el) => el.id == tradeFairLocal)) {
          dispatch('setCurrentTradeFair', Number(tradeFairLocal))
        }
      }

      return true
    } catch (error) {
      console.error('API error (fetch all trade fairs): ', error)
      return false
    }
  },

  async fetchCurrentTradeFair({ commit, state}) {
    try {
      const tradeFair = await api.get(`/trade-fairs/${state.tradeFairID}`)

      commit(SET_TRADE_FAIR, tradeFair)
      commit(SET_TRADE_FAIR_PACKAGES, tradeFair.packages)
      commit(SET_TRADE_FAIR_AREAS, tradeFair.exhibitionAreas)
      commit(SET_TRADE_FAIR_SPACES, tradeFair.spaces)
      commit(SET_TRADE_FAIR_SERVICES, tradeFair.services)

      return true
    } catch (error) {
      console.error('API error (fetch trade fair): ', error)
      return false
    }
  },

  async fetchUsers({ commit, state }) {
    try {
      const users = await api.get('/users')
      commit(SET_USERS, users)

      if (Object.keys(state.organizer).length === 0 && users.length) {
        commit(SET_ORGANIZER, users[0].organizer)
      }

      return true
    } catch (error) {
      console.error('API error (fetch users): ', error)
      return false;
    }
  },

  async updateOrganizer({ commit, state }, payload) {
    try {
      const organizer = await api.patch(`/organizers/${state.organizer.id}`, payload, { showSuccessNotifications: true })
      commit(SET_ORGANIZER, organizer)

      return true
    } catch (error) {
      console.error('API error (update organizer): ', error)
      return false;
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async notifyTradeFair({ dispatch }, { id }) {
    try {
      await api.post(`/trade-fairs/${id}/notify`, null, { showSuccessNotifications: true })

      await dispatch('fetchCurrentTradeFair')

      return true
    } catch (error) {
      console.error('API error (notify interested companies): ', error)
      return false;
    }
  },

  async setTradeFairDeadline({ commit, state }, payload) {
    try {
      const tradeFair = await api.patch(`/trade-fairs/${state.tradeFairID}`, payload, {
        showSuccessNotifications: true,
      })

      commit(SET_TRADE_FAIR, tradeFair)

      return true
    } catch (error) {
      console.error('API error (update trade fair registration deadline): ', error)
      return false
    }
  },

  async openRegistration({ commit }, payload) {
    try {
      const tradeFair = await api.post(`/trade-fairs/${payload.tradeFairId}/open-registration`, null, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(SET_TRADE_FAIR, tradeFair)

      return true
    } catch (error) {
      console.error('API error (open trade fair registration): ', error)
      return false
    }
  },

  async fetchTemplates({ commit, state }) {
    try {
      if (state.templates.length) {
        return true
      }

      const templates = await api.get('/registration-templates')
      commit(SET_TEMPLATES, templates)

      return true
    } catch (error) {
      console.error('API error (fetch templates): ', error)
      return false;
    }
  },

  async acceptTemplate({ state, dispatch }, templateId) {
    try {
      await api.put(`/trade-fairs/${state.tradeFairID}/apply-registration-template/${templateId}`)

      await dispatch('fetchCurrentTradeFair')

      return true
    } catch (error) {
      console.error('API error (use template): ', error)
      return false;
    }
  },

  async fetchOrganizers({ commit, state }) {
    try {
      if (state.organizers.length) {
        return true
      }

      const organizers = await api.get('/organizers')
      commit(SET_ORGANIZERS, organizers)

      return true
    } catch (error) {
      console.error('API error (fetch organizers): ', error)
      return false;
    }
  },

  async updateTradeFair({ commit }, payload) {
    try {
      if (payload.tradeFair) {
        commit(SET_TRADE_FAIR, payload.tradeFair)
        commit(SET_TRADE_FAIR_FORM_LOADED, true)
        return true
      }

      if (!payload.id) {
        console.error(`Invalid Trade Fair ID: ${payload.id}`)
        return false
      }

      const tradeFair = await api.patch(`/trade-fairs/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(SET_TRADE_FAIR, tradeFair)

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async createTradeFairSpace({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      let result = await api.post(`/trade-fairs/${payload.tradeFairId}/spaces`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(CREATE_TRADE_FAIR_SPACES, result)

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async updateTradeFairSpace({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      if (!payload.id) {
        console.error(`Invalid Trade Fair Space ID: ${payload.id}`)
        return false
      }

      let result = await api.put(`/trade-fairs/${payload.tradeFairId}/spaces/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(UPDATE_TRADE_FAIR_SPACES, result)

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async deleteTradeFairSpace({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      if (!payload.id) {
        console.error(`Invalid Trade Fair Space ID: ${payload.id}`)
        return false
      }

      await api.delete(`/trade-fairs/${payload.tradeFairId}/spaces/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(DELETE_TRADE_FAIR_SPACES, payload)

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async createTradeFairPackage({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Package ID: ${payload.tradeFairId}`)
        return false
      }

      let result = await api.post(`/trade-fairs/${payload.tradeFairId}/packages`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(CREATE_TRADE_FAIR_PACKAGES, result)

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async updateTradeFairSummary({ dispatch }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      let result = await api.put(`/trade-fairs/${payload.tradeFairId}/summary`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      dispatch('fetchCurrentTradeFair')

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async sendTradeFairSummary({ dispatch }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      let result = await api.put(`/trade-fairs/${payload.tradeFairId}/summary-send`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      dispatch('fetchCurrentTradeFair')

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadTradeFairSummary({  }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      await api.put(`/trade-fairs/${payload.tradeFairId}/summary-download`,
          {},
          {file: {name: 'Trade fair summary', type: 'pdf'}},
          {responseType: 'blob'}
      )

    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadTradeFairSummaryMedia({  }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      await api.put(`/trade-fairs/${payload.tradeFairId}/summary-download-media`,
          {},
          {file: {name: 'Trade fair summary media', type: 'zip'}},
          {responseType: 'blob'}
      )

    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async storeTradeFairSummaryMedia({ dispatch }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      let formData = new FormData()
      formData.append('file', payload.file)

      if (payload.name) {
        formData.append('name', payload.name)
      }

      await api.post(`/trade-fairs/${payload.tradeFairId}/summary-media`,
          formData,
          {},
      )

      await dispatch('fetchCurrentTradeFair')

    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async getTradeFairSummaryMedia({ rootGetters }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      let result = await axios.get(process.env.VUE_APP_API_ROOT_URL + `/api/trade-fairs/${payload.tradeFairId}/summary-media/${payload.id}`, {
        headers: { 'Authorization': 'Bearer ' + rootGetters['auth/token'] },
        responseType: 'arraybuffer'
      })

      return `data:image/png;base64, ${Buffer.from(result.data).toString('base64')}`
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async updateTradeFairSummaryMedia({  }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      let result = await api.put(`/trade-fairs/${payload.tradeFairId}/summary-media/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async deleteTradeFairSummaryMedia({ dispatch }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      let result = await api.delete(`/trade-fairs/${payload.tradeFairId}/summary-media/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      await dispatch('fetchCurrentTradeFair')

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async updateTradeFairPackage({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      if (!payload.id) {
        console.error(`Invalid Trade Fair Package ID: ${payload.id}`)
        return false
      }

      let result = await api.put(`/trade-fairs/${payload.tradeFairId}/packages/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(UPDATE_TRADE_FAIR_PACKAGES, result)

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async deleteTradeFairPackage({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      if (!payload.id) {
        console.error(`Invalid Trade Fair Package ID: ${payload.id}`)
        return false
      }

      await api.delete(`/trade-fairs/${payload.tradeFairId}/packages/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(UPDATE_TRADE_FAIR_PACKAGES, payload)

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async createTradeFairService({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Service ID: ${payload.tradeFairId}`)
        return false
      }

      let result = await api.post(`/trade-fairs/${payload.tradeFairId}/services`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(CREATE_TRADE_FAIR_SERVICES, result)

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async updateTradeFairService({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      if (!payload.id) {
        console.error(`Invalid Trade Fair Service ID: ${payload.id}`)
        return false
      }

      let result = await api.put(`/trade-fairs/${payload.tradeFairId}/services/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(UPDATE_TRADE_FAIR_SERVICES, result)

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async deleteTradeFairService({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      if (!payload.id) {
        console.error(`Invalid Trade Fair Service ID: ${payload.id}`)
        return false
      }

      await api.delete(`/trade-fairs/${payload.tradeFairId}/services/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(DELETE_TRADE_FAIR_SERVICES, payload)

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async createTradeFairExhibitionArea({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade ExhibitionArea ID: ${payload.tradeFairId}`)
        return false
      }

      let result = await api.post(`/trade-fairs/${payload.tradeFairId}/areas`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(CREATE_TRADE_FAIR_AREAS, result)

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async updateTradeFairExhibitionArea({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      if (!payload.id) {
        console.error(`Invalid Trade Fair ExhibitionArea ID: ${payload.id}`)
        return false
      }

      let result = await api.put(`/trade-fairs/${payload.tradeFairId}/areas/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(UPDATE_TRADE_FAIR_AREAS, result)

      return result
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  async deleteTradeFairExhibitionArea({ commit }, payload) {
    try {
      if (!payload.tradeFairId) {
        console.error(`Invalid Trade Fair ID: ${payload.tradeFairId}`)
        return false
      }

      if (!payload.id) {
        console.error(`Invalid Trade Fair ExhibitionArea ID: ${payload.id}`)
        return false
      }

      await api.delete(`/trade-fairs/${payload.tradeFairId}/areas/${payload.id}`, payload, {
        showSuccessNotifications: true,
        showErrorNotifications: true,
      })

      commit(DELETE_TRADE_FAIR_AREAS, payload)

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadTradeFairParticipants({  }, { id, name }) {
    try {
      if (!id) {
        console.error(`Invalid Trade Fair ID: ${id}`)
        return false
      }

      await api.get(`/trade-fairs/${id}/export-participant?lang=${getCurrentLocale()}`,
          {},
          {file: {name: name ?? 'participants', type:  'xlsx'}},
          {responseType: 'blob'}
      )

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadTradeFairInterestedCompanies({  }, { id, name }) {
    try {
      if (!id) {
        console.error(`Invalid Trade Fair ID: ${id}`)
        return false
      }

      await api.get(`/trade-fairs/${id}/export-interested-company`,
          {},
          {file: {name: name ?? 'interested-company', type:  'xlsx'}},
          {responseType: 'blob'}
      )

      return true
    } catch (error) {
      console.error('API error (update trade fair): ', error)
      return false
    }
  },
}

const mutations = {
  [SET_ORGANIZER] (state, organizer) {
    state.organizer = organizer
  },

  [SET_TRADE_FAIR] (state, tradeFair) {
    const fairIndex = state.tradeFairs.findIndex(fair => fair.id === tradeFair.id)
    if (fairIndex !== -1) {
      state.tradeFairs.splice(fairIndex, 1, tradeFair)
    } else {
      state.tradeFairs.push(tradeFair)
    }
  },

  [SET_TRADE_FAIR_ID] (state, id) {
    state.tradeFairID = id
  },

  [SET_TRADE_FAIRS] (state, tradeFairs) {
    state.tradeFairs = tradeFairs
  },

  [SET_TEMPLATES] (state, templates) {
    state.templates = templates
  },

  [SET_USERS] (state, users) {
    state.users = users
  },

  [SET_USER] (state, userToUpdate) {
    const userIndex = state.users.findIndex(user => user.id === userToUpdate.id)
    state.users.splice(userIndex, 1, userToUpdate)
  },

  [REMOVE_USER] (state, userId) {
    const userIndex = state.users.findIndex(user => user.id === userId)
    state.users.splice(userIndex, 1)
  },

  [ADD_USER] (state, user) {
    state.users.push(user)
  },

  [SET_ORGANIZERS] (state, organizers) {
    state.organizers = organizers
  },

  [SET_TRADE_FAIR_FORM_LOADED] (state, value) {
    state.isFormDataLoaded = !!value
  },

  [SET_TRADE_FAIR_PACKAGES] (state, packages) {
    state.packages = packages
  },

  [SET_TRADE_FAIR_AREAS] (state, areas) {
    state.areas = areas
  },

  [SET_TRADE_FAIR_SPACES] (state, spaces) {
    state.spaces = spaces
  },

  [SET_TRADE_FAIR_SERVICES] (state, services) {
    state.services = services
  },

  [UPDATE_TRADE_FAIR_PACKAGES] (state, packag) {
    var index = state.packages.findIndex((el) => el.id == packag.id)

    if (index != -1) {
      state.packages.splice(index, 1, packag)
    }
  },

  [UPDATE_TRADE_FAIR_AREAS] (state, area) {
    var index = state.areas.findIndex((el) => el.id == area.id)

    if (index != -1) {
      state.areas.splice(index, 1, area)
    }
  },

  [UPDATE_TRADE_FAIR_SPACES] (state, space) {
    var index = state.spaces.findIndex((el) => el.id == space.id)

    if (index != -1) {
      state.spaces.splice(index, 1, space)
    }
  },

  [UPDATE_TRADE_FAIR_SERVICES] (state, service) {
    var index = state.services.findIndex((el) => el.id == service.id)

    if (index != -1) {
      state.services.splice(index, 1, service)
    }
  },

  [DELETE_TRADE_FAIR_PACKAGES] (state, packag) {
    var index = state.packages.findIndex((el) => el.id == packag.id)

    if (index != -1) {
      state.packages.splice(index, 1)
    }
  },

  [DELETE_TRADE_FAIR_AREAS] (state, area) {
    var index = state.areas.findIndex((el) => el.id == area.id)

    if (index != -1) {
      state.areas.splice(index, 1)
    }
  },

  [DELETE_TRADE_FAIR_SPACES] (state, space) {
    var index = state.spaces.findIndex((el) => el.id == space.id)

    if (index != -1) {
      state.spaces.splice(index, 1)
    }
  },

  [DELETE_TRADE_FAIR_SERVICES] (state, service) {
    var index = state.services.findIndex((el) => el.id == service.id)

    if (index != -1) {
      state.services.splice(index, 1)
    }
  },

  [CREATE_TRADE_FAIR_PACKAGES] (state, packag) {
    state.packages.push(packag)
  },

  [CREATE_TRADE_FAIR_AREAS] (state, area) {
    state.areas.push(area)
  },

  [CREATE_TRADE_FAIR_SPACES] (state, space) {
    state.spaces.push(space)
  },

  [CREATE_TRADE_FAIR_SERVICES] (state, service) {
    state.services.push(service)
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,

  managers(state) {
    return state.users.filter(user => {
      const userRoles = user.roles.map(role => role.role)
      return userRoles.includes(USER_ROLES.PROJECT_MANAGER)
    })
  },

  currentTradeFair(state) {
    let currentFair = state.tradeFairs.find(fair => fair.id == state.tradeFairID)

    if (currentFair) {
      return currentFair
    }

    return { id: 0, name: ''}
  },

  isCorrectSpacesRanges(state, getters) {
    if (getters.currentTradeFair.bookingType !== 'space') {
      return true;
    }

    var spaces = state.spaces.sort((a, b) => a.sizeFrom - b.sizeFrom);

    var oldWith = null;
    var withConst = spaces.filter((el) => { return el.constructionIncluded === true; });
    for (var spaceWith of withConst) {
      if (oldWith && Number(oldWith.sizeTo) + 1 != spaceWith.sizeFrom) {
        return false;
      }

      oldWith = spaceWith;
    }

    var oldWithout = null;
    var withoutConst = spaces.filter((el) => { return el.constructionIncluded === false; });
    for (var spaceWithout of withoutConst) {
      if (oldWithout && Number(oldWithout.sizeTo) + 1 != spaceWithout.sizeFrom) {
        return false;
      }

      oldWithout = spaceWithout;
    }

    return true;
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
