<template>
  <v-app-bar
    class="elevation-1"
    app
    clipped-left
    elevation="1"
    fixed
    color="white"
    height="80"
  >
    <v-img
      src="/img/logos/Made-in-Germany.svg"
      max-height="60"
      max-width="80"
      class="mx-6"
      contain
    />

    <v-toolbar-title class="align-center pl-10">
      <h4 v-if="!$wait.is(loaders.getTradeFairs)" class="primary--text">
        {{ currentTradeFair.nameDisplay }}
      </h4>
    </v-toolbar-title>

    <v-spacer />

    <language-picker />

    <!-- TODO -->
    <v-menu
      :close-on-content-click="false"
      bottom
      offset-y
      class="elevation-1"
      max-height="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon>
          <v-icon size="20" class="primary--text"> fa fa-exchange </v-icon>
        </v-btn>
      </template>

      <v-list class="pa-5" style="min-width: 500px">
        <v-autocomplete
          :items="tradeFairs"
          :label="$t('dashboard.toTheProject')"
          item-key="id"
          item-text="nameDisplay"
          item-value="id"
          v-model="selectedTradeFair"
        ></v-autocomplete>
        <v-btn
          color="secondary"
          class="elevation-0"
          small
          @click="setCurrentTradeFair(selectedTradeFair)"
        >
          <v-icon size="18" class="mr-2">fal fa-exchange</v-icon>
          {{ $t('dashboard.toTheProject') }}
        </v-btn>
      </v-list>
    </v-menu>
    <!-- ENDTODO -->

    <notification-menu notification_link="organizer.notification" notifications_link="organizer.notifications"></notification-menu>

    <documents-menu class="ml-5" />

    <v-divider vertical class="ml-10 mr-5" />

    <v-menu open-on-click>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" class="text--primary">
          <span
            v-if="user.organizer"
            class="font-weight-regular text--primary"
            style="text-transform: none;"
          >
            {{ user.firstName }} {{user.lastName}}
          </span>
          <v-icon right size="22" class="ml-4 primary--text">
            fas fa-fw fa-user-circle
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item :to="{ name: 'organizer.settings' }">
          <v-list-item-title>{{
            $t('header.changePassword')
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'organizer.settings' }">
          <v-list-item-title>{{
            $t('header.dataAndSettings')
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleLogout">
          <v-list-item-title>{{ $t('header.logOut') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LanguagePicker from '@/components/base/LanguagePicker'
import DocumentsMenu from '@/components/common/Documents/Menu'
import { GET_TRADE_FAIRS } from '@/store/loading-types'
import NotificationMenu from "@/components/common/Notification/NotificationMenu.vue";

export default {
  name: 'OrganizerHeader',

  components: {
    NotificationMenu,
    LanguagePicker,
    DocumentsMenu,
  },

  data() {
    return {
      selectedTradeFair: null,
      loaders: {
        getTradeFairs: GET_TRADE_FAIRS,
      },
    }
  },

  computed: {
    ...mapGetters('participation', ['notedTradeFairs']),

    ...mapGetters('organizer', ['tradeFairs', 'currentTradeFair']),

    ...mapGetters('auth', ['user']),
  },

  methods: {
    ...mapActions('auth', ['logOut']),

    /* added */
    ...mapActions('organizer', ['setCurrentTradeFair']),
    /* added */

    async handleLogout() {
      await this.logOut()
      window.location.href = await this.$router.resolve({ name: 'login' }).href
    },
  },
}
</script>
