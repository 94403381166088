<template>
  <div>
    <v-stepper-step
        :step="step"
        :editable="isEditable"
        :rules="[() => !isStepsErrored]"
        @click="$emit('click')"
    >
      <span v-if="participation?.attributes?.billingaddress_company" class="stepper-abstract">
        {{
          $t('participation.billingAddress.invoiceAddress', lang)
        }}: {{ participation?.attributes.billingaddress_company }}
      </span>
      <h3 class="primary--text">
        {{
          $t('participation.billingAddress.title', lang)
        }}
      </h3>
    </v-stepper-step>

    <v-stepper-content :step="step" class="pb-10">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-checkbox
                v-model="form.data.differentAddressForSendingInvoices"
                :label="
                form.data.differentAddressForSendingInvoices
                  ? $t(
                      'forms.fields.differentAddressForSendingInvoicesSet',
                      lang
                    )
                  : $t(
                      'forms.fields.differentAddressForSendingInvoicesNotSet',
                      lang
                    )
              "
                :error-messages="errors.differentAddressForSendingInvoices"
                :readonly="isReadonlyMode"
                class="my-0"
            />
          </v-col>

          <v-col
              cols="6"
              class="pr-10"
              v-if="form.data.differentAddressForSendingInvoices"
          >
            <v-form>
              <v-text-field
                  v-model="form.data.company"
                  :label="$t('participation.billingAddress.company', lang)"
                  :error-messages="errors.company"
                  :readonly="isReadonlyMode"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-text-field
                  v-model="form.data.responsible"
                  :label="$t('participation.billingAddress.responsible', lang)"
                  :error-messages="errors.responsible"
                  :readonly="isReadonlyMode"
                  class="rounded-0"
                  filled
                  dense
              />

            </v-form>
          </v-col>

          <v-col v-if="form.data.differentAddressForSendingInvoices" cols="6">
            <v-text-field
                v-model="form.data.street"
                :label="$t('participation.billingAddress.street', lang)"
                :error-messages="errors.street"
                :readonly="isReadonlyMode"
                class="rounded-0"
                filled
                dense
            />

            <v-text-field
                v-model="form.data.postalcode"
                :label="$t('participation.billingAddress.postalcode', lang)"
                :error-messages="errors.postalcode"
                :readonly="isReadonlyMode"
                class="rounded-0"
                filled
                dense
            />

            <v-form>
              <v-text-field
                  v-model="form.data.city"
                  :label="$t('participation.billingAddress.city', lang)"
                  :error-messages="errors.city"
                  :readonly="isReadonlyMode"
                  class="rounded-0"
                  filled
                  dense
              />

              <v-autocomplete
                  v-model="form.data.country"
                  :error-messages="errors.country"
                  :label="$t('forms.fields.country', lang)"
                  :items="prioritizedCountries"
                  :item-text="`translations.${lang}.country`"
                  item-value="id"
                  filled
                  dense
                  class="rounded-0"
              />
            </v-form>

            <v-text-field
                v-model="form.data.vatIdNo"
                :error-messages="errors.vatIdNo"
                :label="$t('forms.fields.vatIdNo')"
                dense
                filled
            />
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn
                class="elevation-0 secondary"
                @click="handleSubmit"
                :disabled="!isSubmittedForm"
            >
              {{
                $t('forms.buttons.saveAndContinue', lang)
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-stepper-content>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {required, minLength} from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import participationStep from '@/mixins/participationStep'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'BillingAddress',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  mixins: [validation, participationStep, participationMode],

  data() {
    return {
      form: {
        loading: false,

        customFieldsMap: {
          differentAddressForSendingInvoices: 'billingaddress',
          company: 'billingaddress_company',
          responsible: 'billingaddress_contact',
          country: 'billingaddress_country',
          city: 'billingaddress_city',
          street: 'billingaddress_street',
          postalcode: 'billingaddress_postalcode',
          vatIdNo: 'billingaddress_vatid',
        },

        data: {
          differentAddressForSendingInvoices: false,
          company: '',
          responsible: '',
          country: '',
          city: '',
          street: '',
          postalcode: '',
          vatIdNo: '',
        },
        validationRules: {
          differentAddressForSendingInvoices: {},
          company: {
            required,
          },
          responsible: {},
          country: {
            required,
          },
          city: {
            required,
          },
          street: {
            required,
          },
          postalcode: {},
          vatIdNo: {
            required,
          },
        },

        custom: {
          validationSubstitutions: {
            postalcode: {
              minLength: {
                length: 5,
              },
            },
          },
        },

        isValid: true,
      },
    }
  },

  computed: {
    ...mapGetters('countries', ['prioritizedCountries']),

    isGermanySelected() {
      return this.form.data.country === this.prioritizedCountries.find((el) => el.iso31662 === 'DE')?.id
    }
  },

  created() {
    this.fetchCountries()

    this.setInitialFormData(this.participation?.attributes ?? {})
  },

  methods: {
    ...mapActions('countries', [
      'fetchCountries',
    ]),

    ...mapActions('participation', {
      updateParticipation: 'updateParticipation',
    }),

    async handleSubmit() {
      if (!this.form.data.differentAddressForSendingInvoices) {
        this.form.isValid = true

        this.clearFormData()
        this.form.data.differentAddressForSendingInvoices = false

        if (await this.updateParticipation({ fair: this.fair, participation: this.participation, data: this.getFormData() })) {
          this.$emit('submit')
        }

        return
      }

      if (this.isGermanySelected && this.form.data.postalcode.length < 5) {
        this.form.validationRules.postalcode = {minLength: minLength(5), required: required}
      } else {
        this.form.validationRules.postalcode = {}
      }

      if (!this.checkIfFormFieldsAreValid()) {
        this.form.isValid = false
        return
      }

      if (await this.updateParticipation({ fair: this.fair, participation: this.participation, data: this.getFormData() })) {
        this.$emit('submit')
      }

      this.form.isValid = true
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
