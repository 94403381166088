<template>
  <v-app>
    <v-container class="mx-3" fluid>
      <div class="language-picker">
        <language-picker />
      </div>
      <v-row style="min-height: 100vh" class="pa-0 my-0">
        <v-col cols="12" md="6" class="primary order-last order-md-first d-flex align-center">
          <v-row justify="center">
            <v-col cols="8" class="mt-10 mt-md-0">
              <p class="white--text">{{ $t('login.description.title') }}</p>

              <v-list class="text-white" style="background: none">
                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>{{ $t('login.description.worldwideOverview') }}</h3>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>{{ $t('login.description.attractiveConditions') }}</h3>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>{{ $t('login.description.experiencedProcessing') }}</h3>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>{{ $t('login.description.onlineBooking') }}</h3>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="px-10 d-flex align-center mt-15 mt-md-0">
          <v-row justify="center">
            <v-col cols="12" sm="10" class="mb-10">
              <div v-if="isVerifyEmail || verified">
                <div class="text-center">
                  <v-icon size="60" color="success" class="mb-5"
                    >fas fa-check-circle</v-icon
                  >
                </div>
                <h1 class="mb-3">{{ $t('auth.confirmEmailAddress') }}</h1>
                <p>{{ $t('auth.successfullyConfirmingYourEmail') }}</p>
                <v-btn
                  block
                  color="primary"
                  v-if="isVerifyEmail"
                  to="/exhibitor/onboard"
                  >Unternehmensprofil vervollständigen</v-btn
                >
                <v-btn color="primary" v-if="verified" to="/">{{
                  $t('auth.logIn')
                }}</v-btn>
              </div>
              <div v-else-if="!verified && !deleted">
                <div class="text-center">
                  <v-icon size="60" color="error" class="mb-5"
                    >fas fa-exclamation-triangle</v-icon
                  >
                </div>
                <h1 class="mb-3">{{ $t('auth.confirmEmailAddress') }}</h1>
                <p>
                  {{ $t('auth.emailAddressCouldNotBeConfirmed') }}
                  <a href="mailto:support@german-pavilion.com"
                    >support@german-pavilion.com</a
                  >.
                </p>
                <v-btn
                    block
                    color="primary"
                    class="my-2"
                    @click="handleResendEmail"
                    v-if="id"
                    :loading="$wait.is(loaders.resendEmailVerification)"
                >
                  {{ $t('auth.resendEmailAddress') }}
                </v-btn>
              </div>
              <div v-else-if="deleted">
                <div class="text-center">
                  <v-icon size="60" color="error" class="mb-5"
                  >fas fa-exclamation-triangle</v-icon
                  >
                </div>
                <h1 class="mb-3">{{ $t('auth.confirmEmailAddress') }}</h1>
                <p v-html="$t('auth.userAccountDeleted')"></p>
                <v-btn
                    block
                    color="primary"
                    class="my-2"
                    @click="handleResendEmail"
                    v-if="id"
                    :loading="$wait.is(loaders.resendEmailVerification)"
                >
                  {{ $t('auth.resendEmailAddress') }}
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" sm="10">
              <logos />
            </v-col>

            <v-col cols="10">
              <v-row justify="center" class="mt-5 mb-10">
                <documents-list />
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <snackbar ref="snackbar" />
  </v-app>
</template>

<script>
import LanguagePicker from '@/components/base/LanguagePicker'
import Logos from '@/components/base/Logos/Logos'
import DocumentsList from '@/components/common/Documents/List'
import { mapGetters, mapActions } from 'vuex'
import Snackbar from "@/components/base/Snackbar";
import {RESEND_EMAIL_VERIFICATION} from "@/store/loading-types";
import {mapWaitingActions} from "vue-wait";

export default {
  name: 'AppExhibitorVerify',

  components: {
    LanguagePicker,
    Logos,
    DocumentsList,
    Snackbar
  },

  data() {
    return {
      id: '',
      email: '',
      token: '',
      verified: false,
      deleted: false,
      isLogin: true,
      loaders: {
        resendEmailVerification: RESEND_EMAIL_VERIFICATION,
      }
    }
  },

  computed: {
    ...mapGetters('auth', ['user', 'isVerifyEmail']),
  },

  async mounted() {
    this.id = Number(this.$route.query.id);
    this.verified = Number(this.$route.query.verified);
    this.deleted = Number(this.$route.query.deleted);

    if (this.$route.query.url !== undefined) {
      window.location.href =
          process.env.VUE_APP_API_ROOT_URL + this.$route.query.url
    } else if (this.verified) {
      this.verified = true
      window.localStorage.setItem('verified', Date.now());
      if (this.user.id) {
        await this.$router.push({ name: await this.getRedirectRouteName() })
      }
    }
  },


  methods: {
    ...mapActions('auth', [
      'fetchUserInfo',
      'getRedirectRouteName',
      'resendEmailAddress'
    ]),

    ...mapWaitingActions('auth', {
      resendEmailVerification: RESEND_EMAIL_VERIFICATION,
    }),

    async handleResendEmail() {
      await this.resendEmailVerification({id: this.id});
    }
  },
}
</script>

<style scoped>
.language-picker {
  position: absolute;
  top: 10px;
  right: 10px;
}
.v-application .mx-3 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.container {
  padding: 0;
}
</style>
