import config from '@/config/index'
import store from '@/boot/store'

export const getCurrentLocale = () => {
  const recordInStore = getLocaleFromStore()
  if (recordInStore) {
    return recordInStore
  }
  const recordInLocalStorage = getLocaleFromLocalStorage()
  return recordInLocalStorage || config.defaultLocale
}

export const setLocaleFromLocalStorage = (language) => {
  localStorage.setItem('lang', language.locale);
}

const getLocaleFromStore = () => {
  return store.state.languages.language.locale
}
const getLocaleFromLocalStorage = () => {
  return localStorage.getItem('lang');
}
