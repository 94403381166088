<template>
  <logo
    :title="$t('common.organizer')"
    name="BMELBMWK"
    :src="`/img/logos/BMELBMWK_${$i18n.locale}.svg`"
  />
</template>

<script>
import Logo from '@/components/base/Logos/Logo'

export default {
  name: 'BMELBMWKLogo',

  components: {
    Logo,
  },
}
</script>
