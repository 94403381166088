<template>
  <v-container v-if="accreditation" fluid class="px-0">
    <v-row>
      <v-col cols="12">
        <h4>{{ $t('tables.accreditation.currentAccreditation') }}</h4>
      </v-col>
      <v-col cols="3">
        <p>
          <b>{{ $t('accreditation.steps.companyData.title') }}</b><br/>
          {{ accreditation.attributes.company }}<br/>
          {{ accreditation.attributes.street }}<br/>
          <span v-if="accreditation.attributes.address_supplement">
            {{ accreditation.attributes.address_supplement }}<br/>
          </span>
          {{ accreditation.attributes.postalcode + ' ' + accreditation.attributes.city }}<br/>
          <span v-if="accreditation.relationships.federal_state">
          {{ accreditation.relationships.federal_state.name[$i18n.locale] }}<br/>
          </span>
          {{ accreditation.relationships.country.translations[$i18n.locale].country }}<br/>
        </p>
        <p>
          <i class="fas fa-phone primary--text mr-3"/> {{
            accreditation.attributes.phone
          }}<br/>
          <span v-if="accreditation.attributes.fax">
            <i class="fas fa-fax primary--text mr-3"/> {{ accreditation.attributes.fax }}<br/>
          </span>
          <i class="fas fa-envelope primary--text mr-3"/> {{
            accreditation.attributes.email
          }}<br/>
          <i class="fas fa-home primary--text mr-3"/> {{
            accreditation.attributes.website
          }}<br/>
        </p>
      </v-col>
      <v-col cols="3">
        <p>
          <b>{{ $t('accreditation.steps.registerExcerpt.title') }}</b>
          <br/>
          {{ $t('forms.fields.registryCourt') + ': ' }}
          {{
            !accreditation.attributes.registry_court
                ? $t('accreditation.notAvailable')
                : accreditation.attributes.registry_court
          }}

          <br/>
          {{ $t('forms.fields.registryNumber') + ': ' }}
          {{
            !accreditation.attributes.registry_number
                ? $t('accreditation.notAvailable')
                : accreditation.attributes.registry_number
          }}
          <br/>
          {{ $t('forms.fields.vatIdNo') + ': ' }}
          {{
            !accreditation.attributes.vat_id ? $t('accreditation.notAvailable') : accreditation.attributes.vat_id
          }}
          <br/>
        </p>

        <p v-if="accreditation.attributes.is_foreign_branch || accreditation.attributes.is_foreign_representation">
          <v-icon class="primary--text mb-1 mr-3" size="14">
            fal
            {{ accreditation.attributes.is_foreign_branch ? 'fa-check' : 'fa-times' }}
            fa-fw
          </v-icon>
          <span>{{ $t('tables.accreditation.foreignBranch') }}</span
          ><br/>
          <v-icon class="primary--text mb-1 mr-3" size="14">
            fal
            {{ accreditation.attributes.is_foreign_representation ? 'fa-check' : 'fa-times' }}
            fa-fw
          </v-icon>
          <span>{{ $t('tables.accreditation.foreignRepresentation') }}</span>
          <br/><br/>
          <small>{{ $t('tables.accreditation.connectedCompany') + ': ' }}</small
          ><br/>
          <span> {{ accreditation.attributes.related_company }} </span>
        </p>
      </v-col>
      <v-col cols="6">
        <b>{{ $t('accreditation.steps.publicParticipation.title') }}</b
        ><br/>
        <p class="mt-3">
          <v-icon class="primary--text mb-1 mr-3" size="18">
            fal
            {{
              accreditation.attributes.declared_public_sector_involvement ? 'fa-times' : 'fa-check'
            }}
            fa-fw
          </v-icon>
          <span>{{
              $t('accreditation.underPublicLaw.publicSectorInvolvement')
            }}</span>
        </p>

        <p>
          <v-icon class="primary--text mb-1 mr-3" size="18">
            fal
            {{ accreditation.attributes.declared_public_sector_institution ? 'fa-times' : 'fa-check' }}
            fa-fw
          </v-icon>
          <span>{{
              $t('accreditation.underPublicLaw.publicSectorInstitution')
            }}</span>
        </p>

        <p>
          <v-icon class="primary--text mb-1 mr-3" size="18">
            fal
            {{
              accreditation.attributes.declared_public_sector_majority ? 'fa-times' : 'fa-check'
            }}
            fa-fw
          </v-icon>
          <span>{{
              $t('accreditation.underPublicLaw.publicSectorMajority')
            }}</span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('settings.sections.accreditation.description') }}
      </v-col>
      <v-col cols="6">
        <p class="font-weight-bold">
          {{ $t(`settings.sections.accreditation.statuses.${accreditation.attributes.status}`) }}
        </p>
        <v-btn
            :to="{ name: 'exhibitor.accreditation' }"
            color="secondary"
            class="elevation-0"
            small
        >
          {{
            $t(
                `settings.sections.accreditation.statuses.button.${accreditation.attributes.status}`
            )
          }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Accreditation',

  data() {
    return {
      accreditation: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      let response = (await this.$axios.get(`/api/companies/${this.user.companyId}?ignoreApprove=1`, {
        params: {
          'languages': '*',
          'include': [
            'country',
            'company',
          ],
        },
      })).data

      this.accreditation = response.data
    },
  }
}
</script>
