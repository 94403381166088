<template>
  <v-dialog v-model="isShown" max-width="800">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('participation.configureRegistrationForm.exhibitionSpace.conflictModalTitle') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <p class="mt-5">
          {{ $t('participation.configureRegistrationForm.exhibitionSpace.conflictModalBody') }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="hide"  text outlined>
          {{ $t('common.close') }}
        </v-btn>
        <v-spacer />
        <v-btn color="secondary" @click="handleReset" class="ml-auto" elevation="0">
          {{ $t('participation.configureRegistrationForm.exhibitionSpace.conflictModalReset') }}
        </v-btn>
        <v-btn color="secondary" @click="handleAdjust" class="ml-3" elevation="0">
          {{ $t('participation.configureRegistrationForm.exhibitionSpace.conflictModalAdjust') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'CustomizeRegistrationGeneralConflictSpaceSizeModal',

  data() {
    return {
      isShown: false,
    }
  },

  computed: {
    ...mapGetters('organizer', {
      currentTradeFair: 'currentTradeFair',
    }),
  },

  methods: {
    ...mapActions('organizer', [
      'deleteTradeFairSpace',
    ]),

    show() {
      this.isShown = true
    },

    hide() {
      this.isShown = false
    },

    async handleReset() {
      for (let space of [].concat(this.currentTradeFair.spaces)) {
        await this.deleteTradeFairSpace({tradeFairId: this.currentTradeFair.id, id: space.id})
      }

      this.hide()

      this.$emit('reset')
    },

    handleAdjust() {
      this.hide()

      this.$emit('adjust')
    },
  },
}
</script>
