<template>
  <interest-profile-form
    @next="$emit('submit')"
    :is-onboarding="true"
  >
    <template v-slot:header>
      <v-col
        cols="8"
        class="text-center"
      >
        <h3 class="mb-4">{{ $t('onboarding.steps.countriesAndIndustries.title') }}</h3>
        <p>{{ $t('onboarding.steps.countriesAndIndustries.description') }}</p>
      </v-col>
    </template>

    <template v-slot="scope">
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          color="primary"
          @click="scope.submit"
          class="mt-5"
          :loading="$wait.is(loaders.updateCompanyPreferences)"
        >
          {{ $t('common.continue') }}
        </v-btn>
      </v-col>
    </template>
  </interest-profile-form>
</template>

<script>
import InterestProfileForm from '@/components/exhibitor/Company/InterestProfileForm'
import {mapWaitingActions} from "vue-wait";
import {UPDATE_COMPANY_PREFERENCES} from "@/store/loading-types";

export default {
  name: 'InterestProfile',

  components: {
    InterestProfileForm,
  },

  data() {
    return {
      loaders: {
        updateCompanyPreferences: UPDATE_COMPANY_PREFERENCES,
      }
    }
  },

  methods: {
    ...mapWaitingActions('company', {
      updateCompanyPreferences: UPDATE_COMPANY_PREFERENCES,
    }),
  }
}
</script>
