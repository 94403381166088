import Vue from 'vue'

Vue.use(require('vue-moment'))

export default {
  methods: {
    formatDateTime(date) {
      return Vue.moment(String(date)).format('YYYY-MM-DD hh:mm:ss')
    },

    formatDateTimeByLocale(date) {
      return Vue.moment(String(date)).format(this.$i18n.locale === 'de' ? 'DD.MM.YYYY hh:mm:ss' : 'YYYY-MM-DD hh:mm:ss')
    },

    formatDate(date) {
      return Vue.moment(String(date)).format('DD/MM/YYYY')
    },

    formatDateByLocale(date) {
      return Vue.moment(String(date)).format(this.$i18n.locale === 'de' ? 'DD.MM.YYYY' : 'YYYY-MM-DD')
    },

    formatDateWithMonth(date) {
      return Vue.moment(String(date)).format('DD MMMM YYYY')
    },

    formatDateISO(date) {
      return Vue.moment(String(date)).format('YYYY-MM-DD')
    }
  },
}
