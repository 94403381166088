<template>
  <logo
    :title="$t('common.inCooperationWith')"
    :src="`/img/logos/auma_${$i18n.locale}.svg`"
    name="AUMA"
  />
</template>

<script>
import Logo from '@/components/base/Logos/Logo'

export default {
  name: 'AUMALogo',

  components: {
    Logo,
  },
}
</script>
