export default {
  tradefairsummary: {
    title: 'Trade fair summary',
    deadlineAlert:
      'The report shall be sent immediately after the fair. The report may only be used for internal purposes. Publication is not permitted.',
    tradeFairName: 'Trade fair name',
    date: 'Date',
    location: 'City, Country',
    venue: 'Venue',
    organizer: 'Organizer',
    contactPerson: 'Contact person',
    contactEmail: 'Contact Email',
    contactPhone: 'Contact phone',
    enterText: 'Click here, to enter text',
    exhibitors: {
      title: 'Exhibitors',
      total: 'Exhibitors in total',
      totalHA: 'Amount of German Pavilion parent exhibitors in total',
      thereofImporters: 'thereof Importers',
      totalUA: 'Amount of German Pavilion child exhibitors in total',
    },
    spaces: {
      title: 'Spaces',
      total: 'Space in total in ㎡',
      exhibitorSpace: 'Space for Exhibitors in ㎡',
      exhibitorSpaceHint: 'incl. free spaces if applicable',
    },
    participations: {
      title: 'Participations',
      whichHalls: 'Participation in which halls (Sector and ㎡)',
      whichStates: 'Participations from federal states',
      fiveBiggest: 'Five biggest foreign participations',
      germanCommunity: 'German participations',
      germanCommunityHint: 'Outside the German Community Stand',
    },
    visitors: {
      title: 'Visitors',
      preliminary: 'Preliminary number of visitors',
      foreign: 'thereof foreign',
      vip: 'VIP-Visitors at German Pavilion',
    },
    supportingEvents: {
      title: 'Supporting events',
      exhibitorEvening: 'Exhibitor evening',
      exhibitorEveningHint:
        'estimated amout of guest in total / VIPs / Importers',
      getTogether: 'Get together',
      showcooking: 'Showcooking',
      storeCheckTour: 'Store-Check-Tour',
      matchmaking: 'B2B-Matchmaking',
      flowristicShow: 'Flowristic Show',
      degustationCounter: 'Degustation counter',
      infoEvent: 'Info event',
      other: 'Other',
    },
    general: {
      title: 'General',
      openingBy: 'Opening by',
      reception: 'BMEL-/BMWK-Reception',
      architect: "Architect's office",
      commentsStandConstruction: 'Comments on the stand construction',
      commentsCatering: 'Comments on the catering',
      commentsCateringHint: 'Type, problems if applicable',
      commentsCooperation:
        'Comments on the cooperation with the trade fair organizer',
      recommendation: 'Recommendation of further participation and reasons',
      germanEffect:
        'Effect of the German joint stand compared to other joint stands',
      companyFeedback: 'Feedback from the exhibiting companies',
      supplementaryRemarks: 'Supplementary remarks',
    },
    images: {
      title: 'Image upload',
      alert:
        'Please submit one meaningful picture each of the lounge and the fairground as well as two meaningful pictures of the joint company stand.',
      charaterization: 'Image description',
      choseFiles: 'Chose files',
      hint: 'You can upload multiple images, but no more then 15, each file can be max 10 MB',
      upload: 'Upload',
      delete: 'Delete image'
    },
    checkAndSend: {
      title: 'Überprüfen und absenden',
      privacyPolicy:
        'I assure that none of the images uploaded by me violate the copyright and/or personal rights of third parties and that I alone am entitled to dispose of the rights to the published images without restriction and free of third-party rights. I further declare that I have not made or will make any future dispositions about the rights that conflict with these terms of use. I grant the site operator a non-exclusive right of use, unrestricted in terms of time and location, for all types of use for the publication on the online site www.german-pavilion.com in relation to the transmitted images. The site operator is granted the right to issue simple sublicenses. Furthermore, the site operator is granted the right to edit the images I have sent, which entitles the site operator to edit and/or convert the template using analog, digital or other editing options while respecting copyright.',
      alert:
        "Please check your entries conscientious! You can save your entries any number of times, but as soon as you 'Send' your trade fair summary it is indeed final",
    },
    submitted: {
      title: 'Your trade fair summary has been submitted',
      description:
        'You can no longer change your statements. You can download the summary PDF and review your submitted images.',
      downloadTradefairSummary: 'Download trade fair summary',
      downloadTradefairImages: 'Download all images',
      errorSummaryForm: 'Please correct form errors',
      errorFlagRegistrationIsOpen:
        'The trade fair must have been open for registration',
      errorTradeFairDate: 'The trade fair must have started or be in the past',
      errorFlagCancelled: 'The trade fair must not be cancelled',
      errorSummarySubmittedAt: 'You can only send the summary once',
      errorSummaryImages: 'At least four images must be uploaded',
      errorSummaryTooManyImages: 'More than 15 images must not be uploaded',
      errorSummaryTooSizeImages: 'Images must not be over 10 MB',
    },
  },
}
