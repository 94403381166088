<template>
  <div>
    <v-stepper-step
        :step="step"
        :editable="isEditable"
        :rules="[() => !isStepsErrored]"
        @click="$emit('click')"
    >
      <span class="stepper-abstract"/>
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{ $t('participation.termsAndConditions.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">{{ $t('participation.stepsIsErrored', lang) }}</small>
    </v-stepper-step>

    <v-stepper-content
        :step="step"
        class="pb-10"
    >
      <v-row>
        <v-col cols="10">
          <v-row>
            <v-col cols="auto">
              <v-checkbox
                  :readonly="isReadonlyMode"
                  v-model="form.data.declared_atb"
                  class="ml-2 mt-0"
              />
            </v-col>
            <v-col>
              <p class="mt-1">
                {{
                  $t('participation.termsAndConditions.list.undertakingOnlyAllowedProducts', lang)
                }}
              </p>
              <a :href="$t('common.atbLink')" target="_blank">
                {{
                  $t('tables.participation.form.generalConditionsOfParticipation')
                }}, PDF
              </a>
            </v-col>
          </v-row>

          <v-row class="pl-4 pt-2">
            <validation-general-error :errors="form.errors"/>
          </v-row>
        </v-col>

        <v-col
            cols="12"
            class="text-right"
        >
          <v-btn
              class="elevation-0 secondary"
              @click="handleSubmit"
              :disabled="!isSubmittedForm"
          >
            {{ $t('forms.buttons.saveAndContinue', lang) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import ValidationGeneralError from '@/components/base/ValidationGeneralError'
import participationMode from '@/mixins/participationMode'
import validation from '@/mixins/validate'

export default {
  name: 'TermsAndConditions',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  components: {
    ValidationGeneralError,
  },

  mixins: [
    validation,
    participationMode,
  ],

  data() {
    return {
      form: {
        loading: false,

        data: {
          declared_atb: false,
        },

        isValid: true,
        errors: [],
      },
    }
  },

  created() {
    this.form.data.declared_atb = this.participation?.attributes?.declared_atb
  },

  computed: {
    isAcceptedAll() {
      return Object.values(this.form.data).every(checkbox => !!checkbox)
    },
  },

  methods: {
    ...mapActions('participation', [
      'updateParticipation',
    ]),

    async handleSubmit() {
      this.form.errors = []
      if (!this.isAcceptedAll) {
        this.form.errors.push(this.$t('validation.checkboxRequired', { field: this.$t('participation.termsAndConditions.title', this.lang) }))
        this.form.isValid = false

        await this.updateParticipation({ fair: this.fair, participation: this.participation, data: this.getFormData() })

        return
      }

      this.form.isValid = true

      if (await this.updateParticipation({ fair: this.fair, participation: this.participation, data: this.getFormData() })) {
        this.$emit('submit')
      }
    },
  },
}
</script>
