export default {
  auth: {
    username: 'Benutzername',
    password: 'Passwort',
    passwordConfirmation: 'Passwort wiederholen',
    email: 'E-Mail-Adresse',
    emailUsername: 'E-Mail-Adresse (Benutzername)',
    company: 'Firma',
    logIn: 'Einloggen',
    register: 'Registrieren',
    forgotPassword: 'Passwort vergessen',
    passwordResetLinkSent: 'Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet',
    termsOfUse: 'Nutzungsbedingungen',
    termsOfUseAdoption: 'Ich habe die Nutzungsbedingungen und die Datenschutzerklärung zur Kenntnis genommen und erkläre mich damit einverstanden.',
    passwordChanged: 'Das Passwort wurde geändert',
    confirmEmailAddress: 'E-Mail-Adresse bestätigen',
    resendEmailAddress: 'Verifizierungs-E-Mail erneut senden',
    successfullyConfirmingYourEmail: 'Vielen Dank! Sie haben Ihre E-Mail-Adresse erfolgreich bestätigt.',
    emailAddressCouldNotBeConfirmed: 'Leider konnte Ihre E-Mail-Adresse nicht bestätigt werden. Bitte kontaktieren Sie unser Support-Team unter',
    userAccountDeleted: 'Ihr Account wurde gelöscht. Sie können Ihn selbst reaktivieren indem Sie Ihre E-Mail erneut verifizieren. Bei weiteren Fragen können Sie uns gerne eine E-Mail an <a href="mailto:support@german-pavilion.com">support@german-pavilion.com</a> senden',
  },
}
