<template>
  <v-container
      class="pa-8"
      fluid
  >
    <v-card class="elevation-1 rounded-0">
      <v-card-title>
        <h4 class="primary--text">{{ $t('tradeFair.tradeFairs') }}</h4>
      </v-card-title>

      <v-divider/>

      <fair-search v-model="filters"></fair-search>

      <v-divider/>

      <v-data-table
          :headers="headers"
          :loading="form.loading"
          :items="fairs"
          :page.sync="form.page"
          :options.sync="options"
          :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
          :server-items-length="form.total"
      >
        <template v-slot:item.start_date="{ item }">
          <span>{{ item.attributes.start_date_display[$i18n.locale] }}</span>
        </template>

        <template v-slot:item.registration_deadline="{ item }">
          <span
              v-if="item.attributes.registration_deadline"
          >
            {{ item.attributes.registration_deadline | dateByLocale }}
          </span>
          <span v-else>
            {{ $t('tables.tradeFair.notYetKnown') }}
          </span>
        </template>

        <template v-slot:item.city="{ item }">
          {{ item.attributes.city }}, {{ item.relationships.country?.translations[$i18n.locale]?.country }}
        </template>

        <template v-slot:item.sponsor="{ item }">
          {{ $t(`forms.sponsors.${item.attributes.sponsor}`) }}
        </template>

        <template v-slot:item.name="{ item }">
          <router-link :to="{ name: 'supervisor.fair', params: { id: item.id }}">
            {{ item.attributes.name_display }}
          </router-link>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
              :to="{ name: 'supervisor.fair', params: { id: item.id }}"
              color="secondary"
              icon>
            <v-icon>fal fa-arrow-circle-right</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.organizer="{ item }">
          <span v-if="item.relationships.organizer">
            {{ item.relationships.organizer.name }}
          </span>
          <span v-else>
            {{ $t('tables.tradeFair.notYetKnown') }}
          </span>
        </template>

        <template v-slot:item.association="{ item }">
          {{ item.relationships.associations.map((el) => el.translations[$i18n.locale].name).join(' / ') }}
        </template>

        <template v-slot:item.participation_count="{ item }">
          {{ item.included.participations_count }}
        </template>

        <template v-slot:item.interested_companies_count="{ item }">
          {{ item.included.interested_companies_count }}
        </template>

        <template v-slot:item.flag_registration_is_open="{ item }">
          <registration-status-chip
            :tradefair="item.attributes"
          />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import FairSearch from '@/components/supervisor/TradeFair/FairSearch'
import RegistrationStatusChip from '@/components/base/RegistrationStatusChip.vue';


export default {
  name: 'SupervisorFairs',

  components: {
    FairSearch,
    RegistrationStatusChip
  },

  data() {
    return {
      fairs: [],
      options: {},

      form: {
        total: 0,
        loading: false,
      },

      filters: null
    }
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('tables.tradeFair.name'),
          sortable: true,
          value: 'name',
        },
        {
          text: 'Status',
          sortable: false,
          value: 'flag_registration_is_open',
        },
        {
          text: this.$t('tables.tradeFair.registrationDeadline'),
          sortable: true,
          value: 'registration_deadline',
        },
        {
          text: this.$t('tables.tradeFair.beginning'),
          sortable: true,
          value: 'start_date',
        },
        {
          text: this.$t('tables.tradeFair.city') + ', ' + this.$t('tables.tradeFair.country'),
          sortable: true,
          value: 'city',
        },
        {
          text: this.$t('tables.tradeFair.program'),
          sortable: true,
          value: 'sponsor',
        },
        {
          text: this.$t('tradeFair.executionCompany'),
          sortable: false,
          value: 'organizer',
        },
        {
          text: this.$t('tables.tradeFair.association'),
          sortable: false,
          value: 'association',
        },
        {
          text: this.$t('participation.confirmed'),
          sortable: true,
          value: 'participation_count',
        },
        {
          text: this.$t('interest.interestedPersons'),
          sortable: true,
          value: 'interested_companies_count',
        },
        {
          text: '',
          sortable: false,
          value: 'action',
        }
      ];
    },
  },

  methods: {
    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get('/api/trade-fairs', {
        params: {
          'languages': '*',
          'filter[tradefair_id]': this.filters.names,
          'filter[sponsor]': this.filters.sponsors,
          'filter[years]': this.filters.years,
          'filter[future]': this.filters.future ? true : null,
          'include': [
            'country',
            'organizer',
            'associations',
            'participationsCount',
            'interestedCompaniesCount',
          ],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      this.fairs = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },
  },

  watch: {
    'options'() {
      if (!this.form.loading && !!this.filters) {
        this.load()
      }
    },

    'filters': {
      handler() {
        this.options.page = 1

        this.load()
      },
      deep: true
    },
  },
}
</script>
