<template>
  <v-dialog v-model="isShown" max-width="800">
    <v-card>
      <v-card-title style="justify-content: space-between">
        <h4 class="primary--text">{{ $t('help.modal.newCategoryTitle') }}</h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-card-text>
        {{ $t('help.modal.newCategorySubtitle')}}       
      </v-card-text>
      <v-divider />

      <v-form>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="px-7">
              <v-text-field
                :label="$t('help.form.titleDE')"
                v-model="form.data.title_de"
                :error-messages="errors.title_de"
              />
              <v-text-field
                :label="$t('help.form.titleEN')"
                v-model="form.data.title_en"
                :error-messages="errors.title_en"
              />
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn
                color="secondary"
                class="elevation-0 mr-5"
                @click="handleSubmit"
                :loading="$wait.is(loaders.createCategory)"
              >
                {{ $t('common.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { required } from 'vuelidate/lib/validators'
import { CREATE_FAQ_CATEGORY } from '@/store/loading-types'
import validation from '@/mixins/validate'

export default {
  name: 'CreateFaqCategory',

  mixins: [validation],

  data() {
    return {
      isShown: false,
      form: {
        data: {
          title_de: '',
          title_en: '',
        },
        validationRules: {
          title_de: {
            required,
          },
          title_en: {
            required,
          },
        },
      },
      loaders: {
        createCategory: CREATE_FAQ_CATEGORY,
      },
    }
  },
  methods: {
    ...mapActions('help', ['createCategory']),

    ...mapWaitingActions('help', {
      createCategory: CREATE_FAQ_CATEGORY,
    }),

    transformData() {
      const data = this.getFormData()

      return {
        active: data.active !== '',
        en: {
          title: data.title_en,
        },
        de: {
          title: data.title_de,
        },
      }
    },

    async handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (await this.createCategory(this.transformData())) {
        this.clearFormData()
        this.hide()
      }
    },

    show() {
      this.clearFormData()

      this.$nextTick(() => {
        this.$v.$reset()
      })

      this.isShown = true
    },

    hide() {
      this.isShown = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
