<template>
  <div>
    <v-card
        class="elevation-0 rounded-0 mb-0 grey lighten-1"
        style="position: sticky;"
    >
      <v-row class="p-2 mx-0 my-0">
        <v-col cols="8">
          <v-card-text>
            <h3 class="white--text">
              <v-icon class="mr-3 white--text">fal fa-eye</v-icon>
              {{ $t('participation.registrationFormPreview') }}
            </h3>
          </v-card-text>
        </v-col>
        <v-col cols="2">
          <v-select
              v-model="price"
              :items="prices"
              item-value="type"
              item-text="name"
              class="p-0"
              style="height: 25px; min-height: 25px"
              filled
              @change="handlePriceFormChange"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
              v-model="language"
              :items="languages"
              item-value="locale"
              item-text="name"
              class="p-0"
              style="height: 25px; min-height: 25px"
              filled
              @change="handleLanguageFormChange"
          ></v-select>
        </v-col>
      </v-row>
    </v-card>

    <application-form :fair="fair" :mode="registrationModes.preview"/>
  </div>
</template>

<script>
import ApplicationForm from '@/components/ApplicationForm'
import {REGISTRATION_MODES} from '@/enums/registrationModes'
import {mapActions, mapGetters} from 'vuex'
import {PRICE_TYPES} from '@/enums/priceTypes'

export default {
  name: 'RegistrationFormPreview',

  components: {
    ApplicationForm,
  },

  props: {
    fair: {
      type: Object,
    },
  },

  data() {
    return {
      language: null,

      registrationModes: {
        preview: REGISTRATION_MODES.PREVIEW,
      },

      price: PRICE_TYPES.PRICE_SPONSORED,

      prices: [
        {type: PRICE_TYPES.PRICE_SPONSORED, name: this.$t('forms.fields.priceSponsored')},
        {type: PRICE_TYPES.PRICE_UNSPONSORED, name: this.$t('forms.fields.priceUnsponsored')},
        {type: PRICE_TYPES.PRICE_PUBLICSECTOR, name: this.$t('forms.fields.pricePublicsector')},
      ]
    }
  },

  computed: {
    ...mapGetters('languages', [
      'languages',
    ]),

    ...mapGetters('participation', [
      'participationFormLanguagePreview',
    ]),
  },

  async created() {
    await this.clearParticipation();

    this.language = this.participationFormLanguagePreview;
  },

  methods: {
    ...mapActions('participation', [
      'clearParticipation',
      'setParticipationFormLanguagePreview',
      'setParticipationFormPricePreview',
    ]),

    handleLanguageFormChange(locale) {
      this.setParticipationFormLanguagePreview(locale);
    },

    handlePriceFormChange(price) {
      this.setParticipationFormPricePreview(price);
    }
  }
}
</script>
