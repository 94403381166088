import { api } from '@/boot/api'
import {
  SET_ASSOCIATIONS,
} from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({
  associations: [],
})

const state = defaultState()

const actions = {
  async fetchAssociations({commit}) {
    try {

      const associations = await api.get('/associations')
      commit(SET_ASSOCIATIONS, associations)

      return true
    } catch (error) {
      console.error('API error (fetch associations): ', error)
      return false;
    }
  },
}

const mutations = {
  [SET_ASSOCIATIONS] (state, associations) {
    state.associations = associations
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
