export const LOGIN = 'LOGIN'
export const REGISTER = 'REGISTER'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SEND_EMAIL_VERIFICATION = 'SEND_EMAIL_VERIFICATION'
export const RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION'
export const UPDATE_USER_CREDENTIALS = 'UPDATE_USER_CREDENTIALS'
export const ATTACH_PARTICIPATION_PACKAGE = 'ATTACH_PARTICIPATION_PACKAGE'
export const ATTACH_PARTICIPATION_AREA = 'ATTACH_PARTICIPATION_AREA'
export const CREATE_GOODS_ITEM = 'CREATE_GOODS_ITEM'
export const UPDATE_GOODS_ITEM = 'UPDATE_GOODS_ITEM'
export const CREATE_CO_EXHIBITOR = 'CREATE_CO_EXHIBITOR'
export const UPDATE_CO_EXHIBITOR = 'UPDATE_CO_EXHIBITOR'
export const CREATE_PARTICIPATION = 'CREATE_PARTICIPATION'
export const UPDATE_PARTICIPATION = 'UPDATE_PARTICIPATION'
export const CREATE_PARTICIPATION_SPACE = 'CREATE_PARTICIPATION_SPACE'
export const SAVE_REGISTER_EXCERPT = 'SAVE_REGISTER_EXCERPT'
export const UPLOAD_REGISTER_EXCERPT = 'UPLOAD_REGISTER_EXCERPT'
export const SAVE_PUBLIC_PARTICIPATION = 'SAVE_PUBLIC_PARTICIPATION'
export const UPDATE_COMPANY_PREFERENCES = 'UPDATE_COMPANY_PREFERENCES'
export const CREATE_ACCREDITATION = 'CREATE_ACCREDITATION'
export const SUBMIT_ACCREDITATION = 'SUBMIT_ACCREDITATION'
export const GET_TRADE_FAIRS = 'GET_TRADE_FAIRS'
export const GET_PUBLIC_TRADE_FAIRS = 'GET_PUBLIC_TRADE_FAIRS'
export const GET_USERS = 'GET_USERS'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_ORGANIZER = 'UPDATE_ORGANIZER'
export const ACCEPT_TEMPLATE = 'ACCEPT_TEMPLATE'
export const ASSIGN_TRADE_FAIR = 'ASSIGN_TRADE_FAIR'
export const UNASSIGN_TRADE_FAIR = 'UNASSIGN_TRADE_FAIR'
export const DEACTIVATE_USER = 'DEACTIVATE_USER'
export const ACTIVATE_USER = 'ACTIVATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const FETCH_TRADE_FAIR = 'FETCH_TRADE_FAIR'
export const UPDATE_TRADE_FAIR = 'UPDATE_TRADE_FAIR'
export const FETCH_PARTICIPATION = 'FETCH_PARTICIPATION'
export const FETCH_PARTICIPATIONS = 'GET_PARTICIPATIONS'
export const FETCH_PARTICIPATION_PACKAGE = 'FETCH_PARTICIPATION_PACKAGE'
export const FETCH_PARTICIPATION_SPACE = 'FETCH_PARTICIPATION_SPACE'
export const FETCH_PARTICIPATION_AREA = 'FETCH_PARTICIPATION_AREA'
export const FETCH_PARTICIPATION_GOODS = 'FETCH_PARTICIPATION_GOODS'
export const FETCH_PARTICIPATION_COEXHIBITORS = 'FETCH_PARTICIPATION_COEXHIBITORS'
export const FETCH_PARTICIPATION_SERVICES = 'FETCH_PARTICIPATION_SERVICES'
export const FETCH_TRADE_FAIR_SERVICES = 'FETCH_TRADE_FAIR_SERVICES'
export const FETCH_NOTED_TRADEFAIRS = 'GET_PARTICIPATIONS'
export const FETCH_PREFERRED_TRADE_FAIRS = 'FETCH_PREFERRED_TRADE_FAIRS'
export const SAVE_PARTICIPATION_BILLING_ADDRESS = 'SAVE_PARTICIPATION_BILLING_ADDRESS'
export const SET_TRADE_FAIR_DEADLINE = 'SET_TRADE_FAIR_DEADLINE'
export const OPEN_TRADE_FAIR_REGISTRATION = 'OPEN_TRADE_FAIR_REGISTRATION'
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION'
export const FETCH_COMPANIES = 'FETCH_COMPANIES'
export const FETCH_COMPANY = 'FETCH_COMPANY'

export const DELETE_COMPANY = 'DELETE_COMPANY'
export const FETCH_FAQ_ARTICLES = 'FETCH_FAQ_ARTICLES'
export const UPDATE_FAQ_ARTICLE = 'UPDATE_FAQ_ARTICLE'
export const DELETE_FAQ_ARTICLE = 'DELETE_FAQ_ARTICLE'
export const CREATE_FAQ_ARTICLE = 'CREATE_FAQ_ARTICLE'
export const FETCH_FAQ_CATEGORIES ='FETCH_FAQ_CATEGORIES'
export const CREATE_FAQ_CATEGORY ='CREATE_FAQ_CATEGORY'
export const DELETE_FAQ_CATEGORY ='DELETE_FAQ_CATEGORY'
export const FETCH_ACCREDITATIONS = 'FETCH_ACCREDITATIONS'
export const APPROVE_ACCREDITATION = 'APPROVE_ACCREDITATION'
export const REJECT_ACCREDITATION = 'REJECT_ACCREDITATION'
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
export const FETCH_STATES = 'FETCH_STATES'
export const FETCH_ORGANIZERS = 'FETCH_ORGANIZERS'
export const FETCH_ASSOCIATIONS = 'FETCH_ASSOCIATIONS'
export const SUBMIT_PARTICIPATION = 'SUBMIT_PARTICIPATION'
export const FETCH_HELP_ARTICLES = 'FETCH_HELP_ARTICLES'
export const UPDATE_HELP_ARTICLE = 'UPDATE_HELP_ARTICLE'
export const DELETE_HELP_ARTICLE = 'DELETE_HELP_ARTICLE'
export const CREATE_HELP_ARTICLE = 'CREATE_HELP_ARTICLE'

