<template>
  <div>
    <v-stepper-step
        :step="step"
        :editable="isEditable"
        :rules="[() => !isStepsErrored]"
        @click="$emit('click')"
    >
      <span
          v-if="participation?.relationships?.area"
          class="stepper-abstract"
      >
        {{ participation?.relationships?.area.attributes.name[lang] }}
      </span>
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{ $t('participation.exhibitionArea.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">{{ $t('participation.stepsIsErrored', lang) }}</small>
    </v-stepper-step>

    <v-stepper-content
        :step="step"
        class="pb-10 pt-0"
    >
      <v-row>
        <v-col
            cols="6"
            class="pr-10"
        >
          <v-radio-group
              v-if="fair.relationships.exhibition_areas.length"
              v-model="form.data.area"
              :error-messages="errors.area"
              :readonly="isReadonlyMode"
          >
            <v-radio
                v-for="area in fair.relationships.exhibition_areas"
                :key="area.id"
                :label="area.attributes.name[lang]"
                :value="area"
            />
          </v-radio-group>
          <span v-else>
            {{
              $t('participation.exhibitionArea.thereIsNoExhibitionArea', lang)
            }}
          </span>
        </v-col>

        <v-col cols="6"/>

        <submit-button
            :is-readonly="!isSubmittedForm"
            :is-loading="form.loading"
            @clicked="handleSubmit"
        />

      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import SubmitButton from '@/components/exhibitor/Participation/SubmitButton'
import {requiredIf} from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import participationStep from '@/mixins/participationStep'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'ExhibitionArea',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  components: {
    SubmitButton,
  },

  mixins: [
    validation,
    participationStep,
    participationMode,
  ],

  data() {
    return {
      form: {
        loading: false,

        data: {
          area: null,
        },

        validationRules: {
          area: {
            requiredIf: requiredIf(function () {
              return this.fair.relationships.exhibition_areas.length
            })
          },
        },

        isValid: true,
      },
    }
  },

  created() {
    this.setInitialFormData()
  },

  methods: {
    ...mapActions('participation', [
      'attachArea',
    ]),

    setInitialFormData() {
      this.form.data.area = this.fair.relationships.exhibition_areas.find((el) => el.id === this.participation?.relationships?.area?.id)
    },

    async handleSubmit() {
      if (!this.fair.relationships.exhibition_areas.length) {
        this.$emit('submit')
        return
      }

      if (!this.checkIfFormFieldsAreValid()) {
        this.form.isValid = false
        return
      }

      this.form.isValid = true

      if (await this.attachArea({ fair: this.fair, participation: this.participation, area: this.form.data.area })) {
        this.$emit('submit')
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
