<template>
  <div>
    <v-file-input
      :label="$t('forms.fields.registerExcerpt')"
      :messages="$t('company.extractFromRegisterCopy')"
      :error-messages="errors.document"
      accept="application/pdf"
      v-model="form.data.document"
      prepend-icon=""
      prepend-inner-icon="fal fa-file-pdf"
      dense
      filled
    />
    <v-row>
      <v-col>
        <v-chip
          v-if="form.data.document && isFileUploaded"
          color="primary"
          outlined
          pill
        >
          {{ form.data.document.name | shortenHard(40) }} ({{ form.data.document.size | BtoMB | twoDecimals }}MB)
        </v-chip>
        <v-chip
          v-if="!form.data.document && serverFile"
          color="primary"
          outlined
          pill
        >
          {{ serverFile | shortenHard(40) }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row align-content="end">
      <v-col>
        <v-btn
          class="primary--text elevation-0"
          @click="handleUpload"
          text
        >
          <span v-if="!form.loading">
            {{ $t('forms.buttons.upload') }}
            <v-icon
              size="20"
              class="ml-3"
            >
              fal fa-arrow-circle-up
            </v-icon>
          </span>
          <span v-else>
            <v-progress-circular
              :rotate="-90"
              indeterminate
              size="20"
              width="2"
            />
            <span v-if="form.total > 0">
              {{ parseInt(Math.round(( form.loaded / form.total ) * 100 )) }}% ({{ form.loaded | BtoMB | twoDecimals }}MB / {{ form.total | BtoMB | twoDecimals }}MB)
            </span>
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { maxFileSize } from '@/utilities/helpers/validation'
import validation from '@/mixins/validate'

export default {
  name: 'UploadRegisterExcerpt',

  mixins: [
    validation,
  ],

  props: {
    accreditation: Object,

    serverFile: {
      type: String,
      required: false,
      default: null,
    }
  },

  data() {
    return {
      form: {
        loading: false,

        data: {
          document: null,
        },

        loaded: 0,
        total: 0,

        validationRules: {
          document: {
            required: requiredIf(function () {
              return this.isRequiredForm
            }),
            maxFileSize: maxFileSize(30)
          },
        },

        custom: {
          validationSubstitutions: {
            document: {
              maxFileSize: {
                limit: '30 MB'
              }
            }
          },
        },
      },
      isFileUploaded: false,
    }
  },

  computed: {
    isRequiredForm() {
      return this.accreditation.relationships.country.iso_3166_2 == 'DE'
    },
  },

  methods: {
    validate() {
      if (this.serverFile) {
        return true;
      }

      this.$v.$touch()

      return !this.$v.$invalid
    },

    async handleUpload() {
      if (!this.validate()) {
        return
      }
      this.isFileUploaded = false

      if (!this.form.data.document) {
        return
      }

      let form = new FormData()
      form.append('document', this.form.data.document, this.form.data.document?.name)

      this.form.loading = true
      try {
        await this.$axios.post(`/api/accreditations/${this.accreditation.id}/documents`, form, {
          onUploadProgress: event => {
            this.form.loaded = event.loaded
            this.form.total = event.total
          }
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
