import { api } from '@/boot/api'
import { SET_COUNTRIES } from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({
  countries: [],
})

const state = defaultState()

const actions = {
  async fetchCountries({ commit, state }, payload = {}) {
    try {
      if (state.countries.length && !payload.isForced) {
        return true
      }

      const countries = await api.get('/countries')
      commit(SET_COUNTRIES, countries)

      return true
    } catch (error) {
      console.error('API error (fetch countries): ', error)
      return false
    }
  },
}

const mutations = {
  [SET_COUNTRIES] (state, countries) {
    state.countries = countries
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,

  sortedCountries: state => [...state.countries].sort((a, b) => a.country.localeCompare(b.country)),

  prioritizedCountries: (state, getters) => {
    const prioritizedCountryNames = ['Germany', 'Deutschland']
    const prioritizedCountries = []
    const nonPrioritizedCountries = []

    getters.sortedCountries.forEach(country => {
      if (prioritizedCountryNames.includes(country.country)) {
        prioritizedCountries.push(country)
      } else {
        nonPrioritizedCountries.push(country)
      }
    })

    return [...prioritizedCountries, ...nonPrioritizedCountries]
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
