<template>
  <v-dialog
      v-model="display"
      max-width="1000"
  >
    <v-card class="elevation-1 rounded-0">
      <v-card-title style="justify-content: space-between">
        <h4 class="primary--text">{{ $t('dashboard.defineTeam') }} - {{ fair.nameDisplay }}</h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider/>
      <v-data-table
          :headers="headers"
          :loading="form.loading"
          :items="users"
          :page.sync="form.page"
          :options.sync="options"
          :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
          :server-items-length="form.total"
          item-key="id"
      >
        <template v-slot:item.assigned="{ item }">
          <v-simple-checkbox
              :value="item.relationships.trade_fairs.map(el => el.id).includes(fair.id)"
              :ripple="false"
              :disabled="form.loading"
              @click="say(item)"
          />
        </template>

        <template v-slot:item.first_name="{ item }">
          <span>{{ item.attributes.full_name }}</span>
        </template>

        <template v-slot:item.email="{ item }">
          <span>{{ item.attributes.email }}</span>
        </template>

        <template v-slot:item.projectsAmount="{ item }">
          {{ item.relationships.trade_fairs ? item.relationships.trade_fairs.length : 0 }}
        </template>

        <template v-slot:item.locked_at="{ item }">
          <v-icon
              :class="!item.attributes.locked_at ? 'green--text' : 'red--text'"
              size="10"
          >
            fas fa-circle
          </v-icon>
        </template>

        <template v-slot:item.isAdmin="{ item }">
          <v-icon size="16">fal fa-fw
            {{ item.relationships.roles[0].role === USER_ROLES.ORGANIZER ? 'fa-check' : 'fa-times' }}
          </v-icon>
        </template>

      </v-data-table>
    </v-card>
    <v-divider/>
    <v-card>
      <v-col
          cols="12"
          class="text-right"
      >
        <v-btn
            class="elevation-0 secondary align-center"
            @click="hide"
        >
          {{ $t('common.close') }}
        </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from 'vuex'
import {USER_ROLES} from "@/enums/userRole";

export default {
  name: 'AssignUsers',

  data() {
    return {
      display: false,
      options: {},

      users: [],

      form: {
        loading: false
      },

      fair: {
        id: 0,
        name: ''
      },
      USER_ROLES,
    }
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('users.userManagement.assignedTo'),
          sortable: false,
          value: 'assigned',
        },
        {
          text: this.$t('forms.fields.name'),
          sortable: true,
          value: 'first_name',
        },
        {
          text: this.$t('users.userManagement.active'),
          sortable: true,
          value: 'locked_at',
        },
        {
          text: this.$t('users.userManagement.assignedProjects'),
          sortable: false,
          value: 'projectsAmount',
        },
        {
          text: this.$t('forms.fields.email'),
          value: 'email',
          sortable: true,
        },
        {
          text: this.$t('users.userManagement.admin'),
          value: 'isAdmin',
          sortable: false,
        },
      ]
    },
  },

  methods: {
    ...mapActions('organizer', [
      'fetchTradeFairs',
    ]),

    show(fair) {
      this.fair = fair
      this.display = true
    },

    hide() {
      this.display = false
    },

    async say(item) {
      this.form.loading = true

      if (item.relationships.trade_fairs.map(el => el.id).includes(this.fair.id)) {
        await this.$axios.delete(`/api/users/${item.id}/trade-fairs/${this.fair.id}`)
      } else {
        await this.$axios.post(`/api/users/${item.id}/trade-fairs/${this.fair.id}`)
      }

      this.load()
      this.fetchTradeFairs({force: true})
    },

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get('/api/users', {
        params: {
          'page[number]': this.options.page,
          'page[size]': this.options.itemsPerPage,
          'include': [
            'roles',
            'organizer',
            'association',
            'language',
            'trade_fairs',
          ],
          'sort': sort,
        },
      })).data

      this.users = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },
  },

  created() {
    this.load()
  },

  watch: {
    'options'() {
      this.load()
    },
  },
}
</script>
