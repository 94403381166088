<template>
  <v-row>
    <v-col cols="3" class="pl-10">
      <FairSearch v-model="filters" />
    </v-col>

    <v-col cols="9" class="pa-7 mt-3">
      <v-card class="elevation-1 rounded-0">
        <v-card-title class="px-5 py-5">
          <h4 class="primary--text">{{ $t('fairs.findTradeFairParticipations') }}</h4>
        </v-card-title>
        <v-card-text>
          <FairList :filters="filters"/>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FairSearch from '@/components/exhibitor/Fair/Search'
import FairList from '@/components/exhibitor/Fair/FairList'

export default {
  name: 'ExhibitorFairs',

  components: {
    FairSearch,
    FairList
  },

  data() {
    return {
      filters: {},
    }
  }
}
</script>
