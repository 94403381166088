<template>
  <v-app>
    <v-container class="mx-3" fluid>
      <div class="language-picker">
        <language-picker />
      </div>

      <v-row style="min-height: 100vh" class="pa-0 my-0">
        <v-col cols="6" class="primary d-flex align-center">
          <v-row justify="center">
            <v-col cols="9">
              <h1 class="mb-4 white--text">
                {{ $t('login.loginForImplementationCompanies') }}
              </h1>
              <p class="white--text">
                {{ $t('login.contactIfDontHaveAccessData') }}
                <a :href="`mailto:${supportEmail}`" class="white--text">
                  {{ supportEmail }}
                </a>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="px-10 d-flex align-center">
          <v-row justify="center">
            <v-col cols="10">
              <organizer-login
                v-if="isLogin"
                @forgotPasswordRequested="isLogin = false"
              />
              <organizer-forgot-password
                v-if="!isLogin"
                @loginRequested="isLogin = true"
              />
            </v-col>

            <v-col cols="10">
              <logos />
            </v-col>

            <v-col cols="10">
              <v-row justify="center" class="mt-5">
                <documents-list />
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import OrganizerLogin from '@/components/organizer/Auth/Login'
import OrganizerForgotPassword from '@/components/organizer/Auth/ForgotPassword'
import LanguagePicker from '@/components/base/LanguagePicker'
import DocumentsList from '@/components/common/Documents/List'
import Logos from '@/components/base/Logos/Logos'

export default {
  name: 'AppOrganizerLogin',

  components: {
    OrganizerLogin,
    OrganizerForgotPassword,
    LanguagePicker,
    Logos,
    DocumentsList,
  },

  data() {
    return {
      isLogin: true,
      supportEmail: 'support@german-pavilion.com',
    }
  },
}
</script>

<style scoped>
.language-picker {
  position: absolute;
  top: 10px;
  right: 10px;
}
.v-application .mx-3 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.container {
  padding: 0;
}
</style>
