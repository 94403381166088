export default {
  public: {
    fairs: {
      applyFilters: 'Filter anwenden',
      findTradeFairs: 'Messen finden',
      findTradeFairsDisclaimer: '<p>Auf dieser Seite erhalten Sie einen Überblick über die geplanten Veranstaltungen der Auslandsmesseprogramme des Bundesminsteriums für Wirtschaft und Klimaschutz und des Bundesministeriums für Ernährung und Landwirtschaft.</p>\n' +
          '<p>Bitte erstellen Sie zunächst ein Nutzerkonto um sich für Messebeteiligungen anzumelden oder loggen Sie sich ein, wenn Sie bereits registriert sind.</p>',
      findTradeFairsDisclaimerButton: 'Registrieren und Nutzerkonto erstellen',
      customizeFilters: 'Filter anpassen',
    },
  },
}
