<template>
  <v-menu bottom offset-y class="elevation-1" max-height="500">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" class="ml-5">
        <v-icon size="20" class="primary--text"> fa-bell </v-icon>
        <v-badge
            v-if="notifications.length"
            :content="notifications.length"
            color="accent"
        />
      </v-btn>
    </template>

    <v-list>
      <div class="text-center">
        <v-btn color="primary" :to="{ name: notifications_link }" text>
          {{ $t('header.allNotifications') }}
        </v-btn>
      </div>

      <v-divider v-if="notifications.length" class="my-3" />

      <div
          v-for="(notification, index) in notifications"
          :key="notification.id"
      >
        <v-list-item :to="{ name: notification_link, params: { id: notification.id } }" three-line>
          <v-list-item-content>
            <v-list-item-title
                v-html="notification.attributes.data[$i18n.locale].subject"
            />
            <v-list-item-subtitle>
              {{ notification.attributes.created_at | dateTimeByLocale }}
              <br />
              {{ $t('header.by') }}:
              {{ notification.attributes.data[$i18n.locale].from }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.prevent="handleRead(notification)">
              <v-icon size="18" color="primary"> fal fa-check </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index != notifications.length - 1"></v-divider>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'NotificationMenu',

  props: {
    notification_link: String,
    notifications_link: String,
  },

  computed: {
    ...mapGetters('notification', [
      'notifications'
    ]),
  },

  created() {
    this.fetchNotifications()
  },

  methods: {
    ...mapActions('notification', [
      'fetchNotifications',
    ]),

    async handleRead(notification) {
      this.form.loading = true

      await this.$axios.post(`/api/notifications/${notification.id}/read`, {})

      this.fetchNotifications()
    },
  },
}
</script>
