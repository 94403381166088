<template>
  <v-app>
    <v-container class="mx-3" fluid>
      <div class="language-picker">
        <language-picker />
      </div>
      <v-row style="min-height: 100vh" class="pa-0 my-0">
        <v-col cols="6" class="primary d-flex align-center">
          <v-row justify="center">
            <v-col cols="8">
              <p class="white--text">{{ $t('login.description.title') }}</p>

              <v-list class="text-white" style="background: none">
                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>{{ $t('login.description.worldwideOverview') }}</h3>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>{{ $t('login.description.attractiveConditions') }}</h3>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>{{ $t('login.description.experiencedProcessing') }}</h3>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-icon>
                    <v-icon class="secondary--text">far fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="white--text">
                    <h3>{{ $t('login.description.onlineBooking') }}</h3>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="px-10 d-flex align-center">
          <v-row justify="center">
            <v-col cols="10">
              <exhibitor-login
                v-if="isLogin && !isResetPassword"
                @forgotPasswordRequested="isLogin = false"
              />
              <exhibitor-forgot-password
                v-if="!isLogin && !isResetPassword"
                @loginRequested="isLogin = true"
              />
              <exhibitor-reset-password
                v-if="isResetPassword"
                :email="email"
                :token="token"
                @loginRequested=";(isLogin = true), (token = ''), (email = '')"
              />
            </v-col>

            <v-col cols="10">
              <logos />
            </v-col>

            <v-col cols="10">
              <v-row justify="center" class="mt-5">
                <documents-list />
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import ExhibitorLogin from '@/components/exhibitor/Auth/Login'
import ExhibitorForgotPassword from '@/components/exhibitor/Auth/ForgotPassword'
import ExhibitorResetPassword from '@/components/exhibitor/Auth/ResetPassword'

import LanguagePicker from '@/components/base/LanguagePicker'
import Logos from '@/components/base/Logos/Logos'
import DocumentsList from '@/components/common/Documents/List'

export default {
  name: 'AppExhibitorLogin',

  data() {
    return {
      isLogin: true,
      email: '',
      token: '',
    }
  },

  components: {
    LanguagePicker,
    ExhibitorLogin,
    ExhibitorForgotPassword,
    ExhibitorResetPassword,
    Logos,
    DocumentsList,
  },
  computed: {
    isResetPassword() {
      return this.email !== '' && this.token !== ''
    },
  },
  created() {
    if (
      typeof this.$route.params.email !== 'undefined' &&
      typeof this.$route.params.token !== 'undefined'
    ) {
      this.isLogin = false
      this.email = this.$route.params.email
      this.token = this.$route.params.token
      this.$router.push({ name: 'exhibitor.home' })
    }
  },
}
</script>

<style scoped>
.language-picker {
  position: absolute;
  top: 10px;
  right: 10px;
}
.v-application .mx-3 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.container {
  padding: 0;
}
</style>
