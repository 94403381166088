<template>
  <v-dialog v-model="display" max-width="1000">
    <v-card v-if="service.id">
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ service.attributes.title[lang] }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider/>
      <v-container>
        <v-col cols="12" v-html="service.attributes.description[lang]">
        </v-col>

        <v-row class="justify-space-between ma-5">
          <v-btn
              class="elevation-0 col-2 ml-auto"
              @click="hide"
          >
            {{ $t('common.close', lang) }}
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import participationMode from '@/mixins/participationMode'

export default {
  name: 'ServiceDescription',

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  mixins: [
    participationMode,
  ],

  data() {
    return {
      display: false,

      service: {},
    }
  },

  methods: {
    show(service) {
      this.service = service

      this.display = true
    },

    hide() {
      this.display = false
    },
  },
}
</script>
