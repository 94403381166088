<template>
  <v-dialog
    max-width="900"
    class="rounded-0"
    :value="display"
    @click:outside="hide"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 v-if="space" class="primary--text">
          {{ $t('participation.configureRegistrationForm.exhibitionSpace.editExhibitionSpace') }}
        </h4>
        <h4 class="primary--text" v-else>
          {{ $t('participation.configureRegistrationForm.exhibitionSpace.addExhibitionSpace') }}
        </h4>
        <v-btn @click="hide" icon>
          <v-icon size="20" color="primary">fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <v-form>
          <v-row>
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.designation_de')"
                :error-messages="errors.title"
                v-model="form.data.title.de"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.designation_en')"
                :error-messages="errors.title"
                v-model="form.data.title.en"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.sizeOn')"
                type="number"
                v-model="form.data.size_from"
                :error-messages="errors.size_from"
                suffix="㎡"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.sizeUp')"
                type="number"
                v-model="form.data.size_to"
                :error-messages="errors.size_to"
                suffix="㎡"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.partialPayer')"
                :error-messages="errors.price_sponsored"
                type="number"
                v-model="form.data.price_sponsored"
                suffix="€"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.fullPayer')"
                :error-messages="errors.price_unsponsored"
                type="number"
                v-model="form.data.price_unsponsored"
                suffix="€"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.publicHand')"
                :error-messages="errors.price_publicsector"
                type="number"
                v-model="form.data.price_publicsector"
                suffix="€"
              ></v-text-field>
            </v-col>
          </v-row><v-row>
          <v-col cols="4">
            <v-checkbox
                class="mt-2"
                v-model="form.data.allow_subexhibitor"
                :label="$t('participation.configureRegistrationForm.allowSubexhibitorCheckbox')"
                :error-messages="errors.allow_subexhibitor"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="8" class="pt-4">
              <span>
              {{ $t('participation.configureRegistrationForm.allowSubexhibitorStandSpace') }}
              </span>
          </v-col>
        </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="elevation-0 col-2" @click="hide" color="secondary" outlined text>
          {{ $t('participation.configureRegistrationForm.cancel') }}
        </v-btn>
        <v-btn
          color="secondary"
          @click="handleSubmit"
          class="elevation-0 col-2 ml-auto"
        >
          {{ $t('participation.configureRegistrationForm.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validation from '@/mixins/validate'
import { maxValue, minValue, required } from 'vuelidate/lib/validators'

export default {
  name: 'CustomizeRegistrationSpaceEdit',

  mixins: [
      validation
  ],

  data() {
    return {
      display: false,

      spaces: [],
      fair: null,
      space: null,

      form: {
        loading: false,

        construction_included: false,

        data: {
          id: null,
          title: {},
          size_from: 0,
          size_to: 0,
          price_sponsored: 0,
          price_unsponsored: 0,
          price_publicsector: 0,
          construction_included: false,
          allow_subexhibitor: true,
        },

        validationRules: {
          id: {},
          title: {},
          size_from: {
            required: required,
            minValue: minValue(1),
            maxValue: maxValue(1),
          },
          size_to: {
            required: required,
            minValue: minValue(1),
            maxValue: maxValue(1),
          },
          price_sponsored: {
            required: required,
            minValue: minValue(1),
          },
          price_unsponsored: {
            required: required,
            minValue: minValue(1),
          },
          price_publicsector: {
            required: required,
            minValue: minValue(1),
          },
          construction_included: {},
          allow_subexhibitor: {},
        },
        
        custom: {
          validationSubstitutions: {
            size_from: {
              minValue: {
                limit: `'${this.$t('forms.fields.spaceMinSize')}'`,
              },
              maxValue: {
                limit: `'${this.$t('forms.fields.spaceMaxSize')}'`,
              },
            },
            size_to: {
              minValue: {
                limit: `'${this.$t('forms.fields.spaceMinSize')}'`,
              },
              maxValue: {
                limit: `'${this.$t('forms.fields.spaceMaxSize')}'`,
              },
            },
            price_sponsored: {
              minValue: {
                limit: 0,
              },
            },
            price_unsponsored: {
              minValue: {
                limit: 0,
              },
            },
            price_publicsector: {
              minValue: {
                limit: 0,
              },
            },
          },
        },
      },
    }
  },

  computed: {
    tradefair_spaces_with_construction() {
      return this.spaces.filter((el) => { return el.attributes.construction_included === true; }).sort((a, b) => a.attributes.size_from - b.attributes.size_from);
    },

    tradefair_spaces_without_construction() {
      return this.spaces.filter((el) => { return el.attributes.construction_included === false; }).sort((a, b) => a.attributes.size_from - b.attributes.size_from);
    },
  },

  methods: {
    async show(fair, item, construction_included) {
      this.fair = fair
      this.space = item
      this.spaces = fair.relationships.spaces
      this.form.construction_included = construction_included

      this.setInitialFormData(JSON.parse(JSON.stringify(item?.attributes ?? { allow_subexhibitor: true })))

      if (!item) {
        this.form.data.title = { de: '', en: ''}
      }

      this.$v.$reset()
      this.display = true
    },

    hide() {
      this.display = false
    },

    async handleSubmit() {
      this.resetServerErrors()
      this.setValidationRules()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.checkCollisions()) {
        this.errors.size_from = this.errors.size_to = this.$t(
          'participation.configureRegistrationForm.exhibitionSpace.errorCollision'
        )
        return
      }

      this.form.loading = true
      try {
        if (this.space?.id) {
          await this.$axios.put(`/api/trade-fairs/${this.fair.id}/spaces/${this.space?.id}`, {
            ...this.getFormData(),
            construction_included: this.form.construction_included,
          })
        } else {
          await this.$axios.post(`/api/trade-fairs/${this.fair.id}/spaces`, {
            ...this.getFormData(),
            construction_included: this.form.construction_included,
          })
        }

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')

        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },

    setValidationRules() {
      this.form.validationRules.size_from.minValue = minValue(
        this.fair.attributes.space_min_size
      )
      this.form.validationRules.size_from.maxValue = maxValue(
        this.fair.attributes.space_max_size
      )
      this.form.validationRules.size_to.minValue = minValue(
        this.fair.attributes.space_min_size
      )
      this.form.validationRules.size_to.maxValue = maxValue(
        this.fair.attributes.space_max_size
      )

      this.form.custom.validationSubstitutions.size_from.maxValue.limit = `'${this.$t(
        'forms.fields.spaceMaxSize'
      )}'`

      if (this.form.data.size_from > this.form.data.size_to) {
        this.form.validationRules.size_from.maxValue = maxValue(
          this.form.data.size_to
        )
        this.form.custom.validationSubstitutions.size_from.maxValue.limit = `'${this.$t(
          'forms.fields.size_to'
        )}'`
      }
    },

    checkCollisions() {
      var spaces = this.form.construction_included
        ? this.tradefair_spaces_with_construction
        : this.tradefair_spaces_without_construction

      for (var space of spaces) {
        if (space.id === this.space?.id) {
          continue
        }

        if (
          (this.form.data.size_from >= space.attributes.size_from &&
            this.form.data.size_from <= space.attributes.size_to) ||
          (this.form.data.size_to >= space.attributes.size_from &&
            this.form.data.size_to - 1 <= space.attributes.size_to)
        ) {
          return space
        }
      }

      return false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
