<template functional>
  <fragment>
    <p>
      <small v-html="props.title" />
    </p>
    <img
      :src="props.src"
      :alt="`${props.name} logo`"
      class="logo"
      :class="`logo-${props.name}`"
    />
  </fragment>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '&nbsp;',
    },

    src: {
      type: String,
      required: false,
    },

    name: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.logo {
  max-height: 80px;
  width: auto;
  display: block;
}
</style>
