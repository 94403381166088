export default {
    header: {
        federalForeignTradeFairProgram: 'Das Auslandsmesseprogramm des Bundes',
        eventOverviewAndExhibitorRegistration: 'Veranstaltungsübersicht und Ausstelleranmeldung',
        helpAdviceAndSupport: 'Hilfe, Ratgeber und Support',
        yourBookmarkedTradeFairs: 'Ihre vorgemerkten Messen',
        yourRegistrationHasBeenConfirmed: 'Ihre Anmeldung wurde bestätigt',
        by: 'Von',
        allNotifications: 'Alle Benachrichtigungen',
        changePassword: 'Passwort ändern',
        dataAndSettings: 'Daten & Einstellungen',
        logOut: 'Abmelden',
        search: 'Suche …',
        searchFavorite: 'Suchen Sie nach Ihrer Lieblingsmesse',
        noResults: 'Keine Ergebniss für Ihre Anfrage.'
    },
};
