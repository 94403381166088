<template>
  <div>
    <small
      v-for="document in documents"
      :key="document.name"
      class="mr-10"
    >
      <router-link :to="document.route" target="_blank">{{ document.name }}</router-link>
    </small>
  </div>
</template>

<script>

export default {
  name: 'DocumentsList',

  computed: {
    documents() {
      return [
        {
          route: {name: 'documents.imprint'},
          name: this.$t('common.documents.imprint'),
        },
        {
          route: {name: 'documents.privacyPolicy'},
          name: this.$t('common.documents.privacyPolicy'),
        },
      ];
    }
  }
}
</script>
