import {camelCase} from "eslint-plugin-vue/lib/utils/casing";

export default {
  computed: {
    errors() {
      const errors = {}
      const fields = Object.keys(this.form.data)

      for (const field of fields) {
        errors[field] = this.getValidationErrors(
          field,
          this.form.translationPath || 'forms.fields',
          this.form?.custom?.validationSubstitutions,
          this.form?.custom?.validationRulesMap,
          this.form?.customFieldsMap,
        )
      }

      errors.general = this.getServerValidationErrors('general')

      return errors
    },
  },

  methods: {
    resetServerErrors() {
      this.$store.commit('errors/RESET_ERRORS')
    },

    setServerErrors(errors) {
      this.$store.commit('errors/SET_ERRORS', errors)
    },

    getValidationErrors(fieldName, translationPath, validationParams, validationRulesMap) {
      return [
        ...this.getClientValidationErrors(fieldName, translationPath, validationParams, validationRulesMap),
        ...this.getServerValidationErrors(this.getFieldKey(fieldName))
      ]
    },

    getClientValidationErrors(fieldName, translationPath, validationSubstitutions, validationRulesMap) {
      const errors = []

      if (!this.$v.form.data[fieldName].$dirty) {
        return errors
      }

      for (const rule in this.form.validationRules[fieldName]) {
        if (!this.$v.form.data[fieldName][rule]) {
          const hasRuleNameReplacement = validationRulesMap &&
            Object.prototype.hasOwnProperty.call(validationRulesMap, fieldName) &&
            Object.prototype.hasOwnProperty.call(validationRulesMap[fieldName], rule)
          const translationRule = hasRuleNameReplacement ? validationRulesMap[fieldName][rule] : rule

          const translation = {
            string: `validation.${translationRule}`,
            substitutions: {
              /** TODO: fix validation field translation */
              //field: this.$t(`${translationPath}.${fieldName}`),
              field: this.$t(`${translationPath}.${camelCase(fieldName)}`),
            },
          }

          const hasSubstitutions = validationSubstitutions &&
            Object.prototype.hasOwnProperty.call(validationSubstitutions, fieldName) &&
            Object.prototype.hasOwnProperty.call(validationSubstitutions[fieldName], rule)
          if (hasSubstitutions) {
            translation.substitutions = {...translation.substitutions, ...validationSubstitutions[fieldName][rule]}
          }

          errors.push(this.$t(translation.string, translation.substitutions))
        }
      }

      return errors
    },

    getServerValidationErrors(fieldName) {
      const serverErrors = this.$store.getters['errors/errors']

      return serverErrors && serverErrors[fieldName] ? serverErrors[fieldName] : [];
    },

    getFormData() {
      const formData = {};
      for (const field in this.form.data) {
        let isCustomNameForCurrentField = Object.keys(this.form).includes('customFieldsMap') &&
          Object.keys(this.form.customFieldsMap).includes(field)

        let fieldKey = isCustomNameForCurrentField ? this.form.customFieldsMap[field] : field
        formData[fieldKey] = this.form.data[field]

        if (isCustomNameForCurrentField) {
          formData[this.form.customFieldsMap[field]] = this.form.data[field]
        } else {
          formData[field] = this.form.data[field]
        }
      }

      return {
        ...formData,
        ...this.form.additionalParams,
        ...this.additionalFormData,
      }
    },

    setInitialFormData(fetchedData) {
      const fields = Object.keys(this.form.data)

      for (const field of fields) {
        let fieldKey = this.getFieldKey(field)
        this.form.data[field] = fetchedData[fieldKey]
      }
    },

    clearFormData() {
      const fields = Object.keys(this.form.data)

      for (const field of fields) {
        this.form.data[field] = ''
      }
    },

    getFieldKey(field) {
      let isCustomNameForCurrentField = Object.keys(this.form).includes('customFieldsMap') &&
        Object.keys(this.form.customFieldsMap).includes(field)

      return isCustomNameForCurrentField ? this.form.customFieldsMap[field] : field
    },
  }
}
