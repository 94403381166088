<template>
  <v-dialog v-model="isShown" max-width="700">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{
            $t(
                'participation.checkAndSend.submitSuccessModal.title',
                lang
            )
          }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <p class="mt-5">
          {{
            $t(
                'participation.checkAndSend.submitSuccessModal.message',
                lang
            )
          }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
          <v-btn color="secondary" @click="hide">
            {{ $t('common.close', lang) }}
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import participationMode from '@/mixins/participationMode'

export default {
  name: 'SubmitSuccess',

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isShown: false,
    }
  },

  mixins: [participationMode],

  methods: {
    show() {
      this.isShown = true
    },

    hide() {
      this.isShown = false
    },
  },
}
</script>
