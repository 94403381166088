<template>
  <forgot-password-form
    :hide-title="true"
    @loginRequested="$emit('loginRequested')"
  />
</template>

<script>
import ForgotPasswordForm from '@/components/base/Auth/ForgotPasswordForm'

export default {
  name: 'OrganizerForgotPassword',

  components: {
    ForgotPasswordForm,
  }
}
</script>
