import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import store from '@/boot/store'
import { camelise, snakify } from '@/utilities/functions'
import { prefixUrl } from '@/utilities/helpers/urls'
import { SNACKBAR_TYPES } from '@/enums/snackbar'
import { REQUEST_METHODS } from '@/enums/http'
import i18n from '@/boot/i18n'
import router from "@/boot/router";

Vue.use(Vuex)

export function addApiHeaders(headers) {
  return addJsonHeaders(addAuthHeaders(headers))
}

function addAuthHeaders(headers) {
  const token = store.state.auth.token

  if (token) {
    headers['Authorization'] = 'Bearer ' + token
  }

  return headers
}

function addJsonHeaders(headers) {
  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json'
  }

  headers['Accept'] = 'application/json'

  return headers
}

export const api = (() => {
  const requestMethods = [
    REQUEST_METHODS.GET,
    REQUEST_METHODS.POST,
    REQUEST_METHODS.PUT,
    REQUEST_METHODS.PATCH,
    REQUEST_METHODS.DELETE,
  ].map(method => method.toLowerCase())

  const requests = {}
  for (const method of requestMethods) {
    requests[method] = (url, data, options, axiosOptions) => request(
      url,
      {
        method,
        data,
        ...options,
      },
      axiosOptions
    )
  }

  return requests
})()

function getBody(data, stringify, snakifyOutgoing = true) {
    if (!data) {
        return ''
    }

    if (typeof data === 'string' || data instanceof FormData) {
        if (data instanceof FormData) {
            data.append('lang', store.state.languages.language.locale);
        }

        return data
    }

    const additionalParams = {
        lang: store.state.languages.language.locale,
    }

    const body = (process.env.VUE_APP_SNAKIFY_OUTGOING && snakifyOutgoing)
        ? snakify({...data, ...additionalParams})
        : {...data, ...additionalParams}

    return stringify ? JSON.stringify(body) : body
}

function getUrl({ prefix, url }) {
  return prefixUrl(prefixUrl(url, prefix), process.env.VUE_APP_API_ROOT_URL)
}

function isResponseCSV(response) {
  return response.headers['content-type'] === 'text/csv'
}

function isResponseXLS(response) {
  return response.headers['content-type'] === 'application/vnd.ms-excel'
}

function isResponseXLSX(response) {
  return response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

function isResponsePDF(response) {
  return response.headers['content-type'] === 'application/pdf'
}

function isResponseZIP(response) {
  return response.headers['content-type'] === 'application/zip' || response.headers['content-type'] === 'application/x-zip' || response.headers['content-type'] === 'application/octet-stream'
}

async function request(
  url,
  {
    auth = true,
    data,
    snakifyOutgoing = true,
    headers = {},
    method = REQUEST_METHODS.GET,
    prefix = 'api',
    raw = false,
    showSuccessNotifications = false,
    showErrorNotifications = false,
    params = {},
    file = {}
  } = {},
  axiosOptions = {}
) {
  if (!url) {
    throw 'API needs a valid target URL'
  }

  const options = {
    method,
    url: getUrl({ prefix, url }),
    data: getBody(data, !auth, snakifyOutgoing),
    headers: auth ? addApiHeaders(headers) : addJsonHeaders(headers),
    params: (process.env.VUE_APP_SNAKIFY_OUTGOING && snakifyOutgoing) ? snakify(params) : params,
    // pass through additional options last to override previous options where necessary
    ...axiosOptions,
  }
  store.commit('errors/RESET_ERRORS')

  return await axios(options)
    .then(response => {
      if (isResponseCSV(response) || isResponseXLS(response) || isResponseXLSX(response) || isResponsePDF(response) || isResponseZIP(response)) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;

        if (response.headers['content-disposition']) {
            link.setAttribute('download', decodeURI(response.headers['content-disposition']
                .replaceAll('*=UTF-8\'\'', '=')
                .split('filename=')[1]
                .replaceAll('"', '')));
        } else {
            link.setAttribute('download', `${file.name}.${file.type}`);
        }

        link.click();
        window.URL.revokeObjectURL(url);
      }

      if (showSuccessNotifications) {
        store.commit('snackbar/SET_SNACKBAR', { message: i18n.t('common.successApiMessage'), type: SNACKBAR_TYPES.SUCCESS })
      }

      response = raw ? response.data : response.data.data

      return process.env.VUE_APP_CAMELISE_INCOMING ? camelise(response) : response
    })
    .catch(error => {
      const { response } = process.env.VUE_APP_CAMELISE_INCOMING ? camelise(error) : error
      const errors = response?.data?.errors

      let errorMessage = ''
      if (errors?.general?.length) {
        errorMessage = errors.general[0]
      } else {
        errorMessage = response.statusText
      }

      if (response.status == 401) {
          router.push({name:'exhibitor.login'})
          return;
      }

      if (response.status >= 500 || !response.status) {
          router.push({name:'serverError'})
          return;
      }

      store.commit('errors/SET_ERRORS', errors)

      const isGetRequest = options.method === REQUEST_METHODS.GET.toLowerCase()
      if (showErrorNotifications || (isGetRequest && axiosOptions.showErrorNotifications !== false)) {
        errorMessage = isGetRequest ? i18n.t('common.errorFetchApiMessage') : i18n.t('common.errorApiMessage')
        store.commit('snackbar/SET_SNACKBAR', { message: errorMessage, type: SNACKBAR_TYPES.ERROR })
      }

      throw response
    })
}
