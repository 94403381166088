import { api } from '@/boot/api'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({})

const state = defaultState()

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async fetchNotes({}, { companyId, payload }) {
    try {
      const notes = await api.get(`/notes?company_id=${companyId}`, payload)

      return notes
    } catch (error) {
      console.error('API error (fetch notes): ', error)
      return false;
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async showNote({}, { id, payload }) {
    try {
      const result = await api.show(`/notes/${id}`, payload)

      return result
    } catch (error) {
      console.error('API error (show note): ', error)
      return false;
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async createNote({}, { payload }) {
    try {
      const result = await api.post('/notes', payload,
          {
            showSuccessNotifications: true,
            showErrorNotifications: true
          }
      )

      return result
    } catch (error) {
      console.error('API error (create note): ', error)
      return false;
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async updateNote({}, { id, payload }) {
    try {
      const result = await api.put(`/notes/${id}`, payload,
          {
            showSuccessNotifications: true,
            showErrorNotifications: true
          })

      return result
    } catch (error) {
      console.error('API error (update note): ', error)
      return false;
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async deleteNote({}, { id, payload }) {
    try {
      const result = await api.delete(`/notes/${id}`, payload,
          {
            showSuccessNotifications: true,
            showErrorNotifications: true
          })

      return result
    } catch (error) {
      console.error('API error (delete note): ', error)
      return false;
    }
  },
}

const mutations = {}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
