<template>
  <div>
    <v-stepper-step
        :step="step"
        :editable="isEditable"
        :rules="[() => !isStepsErrored]"
        @click="$emit('click')"
    >
      <span class="stepper-abstract">
        {{
          $t('participation.costOverview.totalCosts', lang)
        }}
        {{ cost.total | money }}
      </span>
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{ $t('participation.costOverview.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">
        {{
          $t('participation.stepsIsErrored', lang)
        }}
      </small>
    </v-stepper-step>

    <v-stepper-content :step="step" class="pb-10">
      <v-row v-if="mode == 'preview'">
        <v-col cols="6">
          <v-alert dense outlined type="warning">
            {{ $t('participation.registrationFormAlertTitle') }}<br/>
            <small>
              {{ $t('participation.registrationFormAlert') }}
            </small>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pr-10">
          <table class="table-costs mb-5">
            <thead>
            <tr>
              <th>#</th>
              <th>
                {{
                  $t('participation.costOverview.position', lang)
                }}
              </th>
              <th>
                {{
                  $t('participation.equipment.quantity', lang)
                }}
              </th>
              <th>
                {{
                  $t('participation.equipment.unit', lang)
                }}
              </th>
              <th class="text-right">
                {{
                  $t('participation.equipment.unitPrice', lang)
                }}
              </th>
              <th class="text-right">
                {{
                  $t('participation.equipment.totalPrice', lang)
                }}
              </th>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td colspan="6">
                <strong>
                  {{
                    $t('participation.costOverview.participationPrice', lang)
                  }}
                </strong>
              </td>
            </tr>
            <cost-row
                v-for="(fee, index) in general"
                :key="`general-${index + 1}`"
                :index="index + 1"
                :title="fee.title"
                :amount="Number(fee.amount)"
                :amount-unit="fee.amountUnit"
                :price="Number(fee.price)"
                :locale="lang"
            />
            <tr v-if="services.length">
              <td colspan="6">
                <strong>
                  {{
                    $t('participation.equipment.title', lang)
                  }}
                </strong>
              </td>
            </tr>
            <cost-row
                v-for="(item, index) in services"
                :key="`service-${index + 1}`"
                :index="index + 1"
                :title="item.title"
                :amount="Number(item.amount)"
                :amount-unit="item.amountUnit"
                :price="Number(item.price)"
                :locale="lang"
            />
            </tbody>

            <tfoot>
            <tr>
              <td colspan="5">
                {{ $t('common.total', lang) }}
              </td>
              <td style="white-space: nowrap" class="text-right">
                {{ cost.total | money }}
              </td>
            </tr>
            </tfoot>
          </table>

          <p class="text-small">
            
            <span v-html="$t('participation.costOverview.pricesDontIncludeTaxes', lang)"></span>
            <span v-if="participation?.attributes?.declared_sponsored" v-html="$t('participation.costOverview.noFurtherFunds', lang)"></span>
            <span v-else v-html="$t('participation.costOverview.furtherFunds', lang)"></span>
            <span v-if="participation?.attributes?.declared_less_then_five_participations" v-html="$t('participation.costOverview.lessThenFive', lang)"></span>
            <span v-else v-html="$t('participation.costOverview.moreThenFour', lang)"></span>
            <span v-if="participation?.relationships?.company?.attributes?.declared_public_sector_involvement" v-html="$t('participation.costOverview.noInstitutional', lang)"></span>
            <span v-else v-html="$t('participation.costOverview.institutional', lang)"></span>
            <span v-if="participation?.relationships?.company?.attributes?.declared_public_sector_institution" v-html="$t('participation.costOverview.noAuthority', lang)"></span>
            <span v-else v-html="$t('participation.costOverview.authority', lang)"></span>
            <span v-if="participation?.relationships?.company?.attributes?.declared_public_sector_majority" v-html="$t('participation.costOverview.noReligion', lang)"></span>
            <span v-else v-html="$t('participation.costOverview.religion', lang)"></span>

          </p>
          <p class="text-small">
            {{
              $t('participation.costOverview.waiver', lang)
            }}
          </p>
        </v-col>

        <v-col cols="5"/>

        <v-col cols="12" class="text-right">
          <v-btn
              class="elevation-0 secondary"
              @click="handleSubmit"
              :disabled="!isSubmittedForm"
          >
            {{ $t('forms.buttons.saveAndContinue', lang) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import participationMode from '@/mixins/participationMode'
import CostRow from '@/components/exhibitor/Participation/CostRow'

export default {
  name: 'CostOverview',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  components: {
    CostRow,
  },

  mixins: [participationMode],

  computed: {
    ...mapGetters('participation', ['cost']),

    general() {
      let fees = []
      let coexhibitors = this.participation?.relationships?.coexhibitors?.map((el) => el.attributes.company).join(', ')

      if (this.cost.lines.package) {
        fees.push({
          title: this.$t('participation.costOverview.fees.packageFee', this.lang),
          amount: this.cost.lines.package.amount,
          amountUnit: null,
          price: this.cost.lines.package.price,
        })
      }

      if (this.cost.lines.space) {
        for (let space of this.cost.lines.space) {
          fees.push({
            title: this.$t('participation.costOverview.fees.hallSpace', this.lang),
            amount: space.amount,
            amountUnit: 'm<sup>2</sup>',
            price: space.price,
          })
        }
      }

      if (this.cost.lines.coExhibitorFee) {
        fees.push({
          title: this.cost.lines.coExhibitorFee.title[this.lang] + ` (${coexhibitors})`,
          amount: this.cost.lines.coExhibitorFee.amount,
          amountUnit: null,
          price: this.cost.lines.coExhibitorFee.price,
        })
      }

      if (this.cost.lines.coExhibitorExternalFee) {
        fees.push({
          title: this.cost.lines.coExhibitorExternalFee.title[this.lang] + ` (${coexhibitors})`,
          amount: this.cost.lines.coExhibitorExternalFee.amount,
          amountUnit: null,
          price: this.cost.lines.coExhibitorExternalFee.price,
        })
      }

      if (this.cost.lines.coExhibitorExternalFee2) {
        fees.push({
          title: this.cost.lines.coExhibitorExternalFee2.title[this.lang] + ` (${coexhibitors})`,
          amount: this.cost.lines.coExhibitorExternalFee2.amount,
          amountUnit: null,
          price: this.cost.lines.coExhibitorExternalFee2.price,
        })
      }

      if (this.cost.lines.fees) {
        for (let fee of this.cost.lines.fees) {
          fees.push({
            title: fee.title[this.lang],
            amount: fee.amount,
            amountUnit: fee.unit[this.lang],
            price: fee.price,
          })
        }
      }

      return fees
    },

    services() {
      let fees = []

      if (this.cost.lines.services) {
        for (let service of this.cost.lines.services) {
          fees.push({
            title: service.title[this.lang],
            amount: service.amount,
            amountUnit: service.unit[this.lang],
            price: service.price,
          })
        }
      }

      return fees
    },
  },

  methods: {
    handleSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
