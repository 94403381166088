<template>
  <v-dialog v-model="display" max-width="1000" v-if="!!coexhibitor">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('participation.subExhibitors.viewSubExhibitors', lang) }}
        </h4>
        <v-btn @click="hide" color="primary" icon>
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-container>
        <v-form>
          <v-row>
            <v-col cols="6" class="px-7 pb-0 mt-5">
              <v-list-item-title class="font-weight-bold">
                {{
                  $t('participation.subExhibitors.title', lang)
                }}
              </v-list-item-title>
              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.company"
                  :label="$t('forms.fields.company', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.street"
                  :label="$t('forms.fields.street', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.postalcode"
                  :label="$t('forms.fields.postalcode', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.city"
                  :label="$t('forms.fields.city', lang)"
                  class="rounded-0"
                  filled
              />

              <v-select
                  readonly
                  v-model="coexhibitor.attributes.country_id"
                  :label="$t('forms.fields.country', lang)"
                  :items="prioritizedCountries"
                  :item-text="`translations.${lang}.country`"
                  item-value="id"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.federal_State"
                  :label="$t('forms.fields.federalState', lang)"
                  :items="states"
                  :item-text="`translations.${$i18n.locale}.name`"
                  item-value="id"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.phone"
                  :label="$t('forms.fields.phone', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.fax"
                  :label="$t('forms.fields.fax', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.email"
                  :label="$t('forms.fields.email', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.website"
                  :label="$t('forms.fields.website', lang)"
                  class="rounded-0"
                  filled
              />
            </v-col>

            <v-col cols="6" class="px-7 pb-0 mt-5">
              <v-list-item-title class="font-weight-bold">
                {{
                  $t('participation.subExhibitors.contactPerson', lang)
                }}
              </v-list-item-title>
              <v-select
                  readonly
                  v-model="coexhibitor.attributes.contact_gender"
                  :items="genders"
                  item-value="type"
                  item-text="label"
                  :label="$t('forms.fields.contactGender', lang)"
                  filled
                  class="rounded-0"
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.contact_title"
                  :label="$t('forms.fields.contactTitle', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.contact_firstname"
                  :label="$t('forms.fields.contactFirstname', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.contact_name"
                  :label="$t('forms.fields.contactName', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.contact_email"
                  :label="$t('forms.fields.contactEmail', lang)"
                  class="rounded-0"
                  filled
              />

              <v-text-field
                  readonly
                  v-model="coexhibitor.attributes.contact_phone"
                  :label="$t('forms.fields.contactPhone', lang)"
                  class="rounded-0"
                  filled
              />
            </v-col>
          </v-row>

          <v-row class="justify-space-between ma-5">
            <v-btn
                class="elevation-0 col-2 ml-auto"
                @click="hide"
            >
              {{ $t('common.close', lang) }}
            </v-btn>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {GENDERS} from '@/enums/genders'
import participationMode from "@/mixins/participationMode";

export default {
  name: 'ViewSubExhibitor',

  mixins: [
    participationMode,
  ],

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },
  },

  data() {
    return {
      display: false,

      coexhibitor: null,
    }
  },

  computed: {
    ...mapGetters('states', ['states']),

    ...mapGetters('countries', ['prioritizedCountries']),

    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`, this.lang),
      }))
    },
  },

  created() {
    this.fetchCountries()
    this.fetchStates()
  },

  methods: {
    ...mapActions('states', ['fetchStates']),

    ...mapActions('countries', ['fetchCountries']),

    show(coexhibitor) {
      this.coexhibitor = coexhibitor

      this.display = true
    },

    hide() {
      this.display = false
    },
  },
}
</script>
