<template>
  <div>
    <v-stepper-step
        :step="step"
        :editable="isEditable"
        :rules="[() => !isStepsErrored]"
        @click="$emit('click')"
    >
      <span
          class="stepper-abstract"
      >
        {{
          $tc('participation.equipment.servicesSelected', assigned.length, lang, {amount: assigned.length})
        }}
      </span>
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{ $t('participation.equipment.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">{{ $t('participation.stepsIsErrored', lang) }}</small>
    </v-stepper-step>

    <v-stepper-content
        :step="step"
        class="pb-10"
    >
      <v-row>
        <v-col cols="11">
          <table
              v-if="singles.length"
              class="table-equipment"
          >
            <thead>
            <tr>
              <th/>
              <th>{{ $t('participation.equipment.quantity', lang) }}</th>
              <th>{{ $t('participation.equipment.unit', lang) }}</th>
              <th>{{ $t('participation.equipment.description', lang) }}</th>
              <th>{{ $t('participation.equipment.specification', lang) }}</th>
              <th></th>
              <th>{{ $t('participation.equipment.unitPrice', lang) }}</th>
              <th>{{ $t('participation.equipment.totalPrice', lang) }}</th>
            </tr>
            </thead>
            <tbody>
              <single-selectable-item-row
                v-for="item in singles"
                :key="item.id"
                :service="item"
                :participation="participation"
                :company="company"
                :fair="fair"
                :mode="mode"
                :is-editable="isEditable"
                @description="showModalDescription"
              />
            </tbody>
          </table>

          <multi-selectable-item-row
              v-for="item in multiples"
              :key="item.id"
              :service="item"
              :participation="participation"
              :company="company"
              :fair="fair"
              :mode="mode"
              :is-editable="isEditable"
              @description="showModalDescription"
          />
        </v-col>

        <v-col
            cols="12"
            class="text-right"
        >
          <v-btn
              class="elevation-0 secondary"
              @click="handleSubmit"
              :disabled="!isSubmittedForm"
          >
            {{ $t('forms.buttons.saveAndContinue', lang) }}
          </v-btn>
        </v-col>

      </v-row>
    </v-stepper-content>
    <service-description ref="modal" :mode="mode"></service-description>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import participationMode from '@/mixins/participationMode'
import ServiceDescription from '@/components/exhibitor/Participation/Modals/ServiceDescription'
import SingleSelectableItemRow from '@/components/exhibitor/Participation/SingleSelectableItemRow'
import MultiSelectableItemRow from '@/components/exhibitor/Participation/MultiSelectableItemRow'

export default {
  name: 'Services',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  components: {
    ServiceDescription,
    SingleSelectableItemRow,
    MultiSelectableItemRow,
  },

  mixins: [
    participationMode,
  ],

  computed: {
    services() {
      return this.fair.relationships.services.filter((el) => !el.attributes.fee)
    },

    singles() {
      return this.services.filter((el) => !el.attributes.multiselectable)
    },

    multiples() {
      return this.services.filter((el) => el.attributes.multiselectable)
    },

    assigned() {
      return this.participation?.relationships?.services.filter((el) => !el.attributes.fee) ?? []
    },
  },

  methods: {
    ...mapActions('participation', [
      'attachService',
      'updateService',
      'detachService',
    ]),

    showModalDescription(service) {
      this.$refs.modal.show(service)
    },

    handleSubmit() {
      this.$emit('submit')
    },
  },
}
</script>

<style>
.table-equipment {
  width: 100%;
  border-collapse: collapse;
}

.table-equipment tr th, .table-equipment tr td {
  padding: .5rem 1.5rem .5rem 0;
}

.table-equipment tr th {
  text-align: left;
  font-size: .9rem;
  border-bottom: 1px solid #1AA8C0;
}

.table-equipment tbody tr td {
  border-bottom: 1px solid #E9E9E9;
  vertical-align: top;
  padding-bottom: 0;
}

.table-equipment foot tr td {
  padding: .5rem 1.5rem .5rem 0;
  vertical-align: top;
}
</style>
