<template>
  <v-dialog v-model="isShown" max-width="1000">
    <v-card>
      <v-card-title style="justify-content: space-between">
        <h4 class="primary--text">{{ $t('help.modal.newEntryTitle') }}</h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-card-text>
        {{ $t('help.modal.newEntrySubtitleTitle') }}
      </v-card-text>
      <v-divider />
      <v-form>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="px-7 pb-0">
              <v-switch
                v-model="form.data.active"
                :label="$t('help.form.entryActive')"
              />
            </v-col>

            <v-col cols="12" class="px-7">
              <h3>{{ $t('help.modal.germanEntry') }}</h3>
              <v-text-field
                :label="$t('help.form.headlineDE')"
                v-model="form.data.headline_de"
                :error-messages="errors.headline_de"
              />

                <vue-editor
                  :placeholder="$t('help.form.summaryDE')"
                  v-model="form.data.abstract_de"
                  :error-messages="errors.abstract_de"
                />
                <div class="v-text-field__details">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div
                        class="v-messages__message"
                        v-for="(error, index) in errors.abstract_de"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>

                <vue-editor
                  :placeholder="$t('help.form.textDE')"
                  v-model="form.data.text_de"
                />
                <div class="v-text-field__details">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div
                        class="v-messages__message"
                        v-for="(error, index) in errors.text_de"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
            </v-col>

           
            <v-col cols="12" class="px-7">
              <h3>{{ $t('help.modal.englishEntry') }}</h3>
              <v-text-field
                :label="$t('help.form.headlineEN')"
                v-model="form.data.headline_en"
                :error-messages="errors.headline_en"
              />

                <vue-editor
                  :placeholder="$t('help.form.summaryEN')"
                  v-model="form.data.abstract_en"
                />
                <div class="v-text-field__details">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div
                        class="v-messages__message"
                        v-for="(error, index) in errors.abstract_en"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>

                <vue-editor
                  :placeholder="$t('help.form.textEN')"
                  v-model="form.data.text_en"
                />
                <div class="v-text-field__details">
                  <div class="v-messages theme--light error--text" role="alert">
                    <div class="v-messages__wrapper">
                      <div
                        class="v-messages__message"
                        v-for="(error, index) in errors.text_en"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </div>
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn
                color="secondary"
                class="elevation-0 mr-5"
                @click="handleSubmit"
                :loading="$wait.is(loaders.createFaqArticle)"
              >
                {{ $t('common.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { required } from 'vuelidate/lib/validators'
import { CREATE_FAQ_ARTICLE } from '@/store/loading-types'
import validation from '@/mixins/validate'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'CreateFaq',

  components: {
    VueEditor,
  },

  mixins: [validation],

  props: {
    helpCategoryId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isShown: false,
      form: {
        data: {
          active: false,
          headline_de: '',
          headline_en: '',
          abstract_de: '',
          abstract_en: '',
          text_de: '',
          text_en: '',
        },
        validationRules: {
          active: {},
          headline_de: {
            required,
          },
          headline_en: {
            required,
          },
          abstract_de: {
            required,
          },
          abstract_en: {
            required,
          },
          text_de: {
            required,
          },
          text_en: {
            required,
          },
        },
      },
      loaders: {
        createFaqArticle: CREATE_FAQ_ARTICLE,
      },
    }
  },
  methods: {
    ...mapActions('help', ['createFaqArticle']),

    ...mapWaitingActions('help', {
      createFaqArticle: CREATE_FAQ_ARTICLE,
    }),

    transformData() {
      const data = this.getFormData()

      return {
        active: data.active !== '',
        helpCategoryId: this.helpCategoryId,
        en: {
          headline: data.headline_en,
          abstract: data.abstract_en,
          text: data.text_en,
        },
        de: {
          headline: data.headline_de,
          abstract: data.abstract_de,
          text: data.text_de,
        },
      }
    },

    async handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (await this.createFaqArticle(this.transformData())) {
        this.clearFormData()
        this.hide()
      }
    },

    show() {
      this.clearFormData()

      this.$nextTick(() => {
        this.$v.$reset()
      })

      this.isShown = true
    },

    hide() {
      this.isShown = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
