import { api } from '@/boot/api'
import {
  SET_LANGUAGES,
  SET_LANGUAGE,
} from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'
import { setLocaleFromLocalStorage } from '@/utilities/helpers/i18n'
import config from '@/config/index'
import { getCurrentLocale } from '@/utilities/helpers/i18n'

const defaultState = () => ({
  languages: [],
  language: {},
})

const state = defaultState()

const actions = {
  async fetchLanguages({ commit, state }) {
    try {
      if (state.languages.length) {
        return true
      }

      const languages = await api.get('languages')
      const currentLocale = getCurrentLocale()

      commit(SET_LANGUAGES, languages)
      commit(SET_LANGUAGE, languages.find(language => language.locale === currentLocale))

      return true
    } catch (error) {
      console.error('API error (fetch languages): ', error)
      return false
    }
  },

  async changeLanguage({ commit, rootGetters }, language) {
    try {
      const isAuthorized = rootGetters['auth/isAuthorized']
      if (isAuthorized) {
        const user = rootGetters['auth/user']
        await api.patch(`users/${user.id}`, { languageId: language.id })
      }

      commit(SET_LANGUAGE, language)
      setLocaleFromLocalStorage(language);

      return true
    } catch (error) {
      console.error('API error (update user language): ', error)
      return false
    }
  },
}

const mutations = {
  [SET_LANGUAGES] (state, languages) {
    state.languages = languages
  },

  [SET_LANGUAGE] (state, language) {
    state.language = language;
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,

  defaultLanguage: state => state.languages.find(language => language.locale === config.defaultLocale),
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
