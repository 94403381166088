<template>
  <v-form>
    <v-text-field
      v-model="form.data.company"
      :error-messages="errors.company"
      :label="$t('forms.fields.company')"
    >
    </v-text-field>

    <v-text-field
      v-model="form.data.email"
      :error-messages="errors.email"
      :label="$t('forms.fields.email')"
    >
    </v-text-field>

    <v-text-field
      type="password"
      v-model="form.data.password"
      :error-messages="errors.password"
      :label="$t('forms.fields.password')"
      :hint="$t('forms.hints.password')"
    >
    </v-text-field>

    <v-text-field
      type="password"
      v-model="form.data.passwordConfirmation"
      :error-messages="errors.passwordConfirmation"
      :label="$t('forms.fields.passwordConfirmation')"
      :hint="$t('forms.hints.password')"
    >
    </v-text-field>
    <v-checkbox
        v-model="form.data.termsOfUse"
        :error-messages="errors.termsOfUse"
    >
      <template v-slot:label>
        <div>
          {{ $t('forms.fields.termsOfUseAdoption.before') }}
          <a target="_blank" :href="privacyPolicyLink" @click.stop>{{ $t('forms.fields.termsOfUseAdoption.link') }}</a>
          {{ $t('forms.fields.termsOfUseAdoption.after') }}
        </div>
      </template>
    </v-checkbox>

    <div class="mb-10">
      <v-btn
        class="primary mr-5 elevation-0"
        @click="handleRegistration"
        :loading="$wait.is(loaders.register)"
      >
        {{ $t('auth.register') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { REGISTER } from '@/store/loading-types'
import validation from '@/mixins/validate'

export default {
  name: 'AppExhibitorSignup',

  mixins: [
    validation,
  ],

  data() {
    return {
      form: {
        data: {
          company: '',
          email: '',
          password: '',
          passwordConfirmation: '',
          termsOfUse: false,
        },
        validationRules: {
          company: {
            required,
          },
          email: {
            required,
            email,
          },
          password: {
            required,
            minLength: minLength(6),
          },
          passwordConfirmation: {
            required,
            minLength: minLength(6),
            sameAsPassword: sameAs('password'),
          },
          termsOfUse: {
            sameAs: sameAs( () => true )
          },
        },
        custom: {
          validationRulesMap: {
            termsOfUse: {
              sameAs: 'checkboxRequired',
            }
          },
          validationSubstitutions: {
            passwordConfirmation: {
              sameAsPassword: {
                sameAsField: this.$t('forms.fields.password'),
              },
              minLength: {
                length: 6,
              },
            },
            password: {
              minLength: {
                length: 6,
              },
            },
          },
        },
      },
      privacyPolicyLink: this.$router.resolve({name: 'documents.privacyPolicy'}).href,
      loaders: {
        register: REGISTER,
      }
    }
  },

  computed: {
    ...mapGetters('languages', [
      'language',
    ]),
  },

  methods: {
    ...mapActions('auth', [
      'register',
    ]),

    ...mapWaitingActions('auth', {
      register: REGISTER,
    }),

    async handleRegistration() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (await this.register(this.getFormData())) {
        await this.$router.push({ name: 'exhibitor.onboard' })
        this.$i18n.locale = this.language.locale
        this.$vuetify.lang.current = this.language.locale
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>

