<template>
  <div>
    <v-stepper-step
        :step="step"
        :editable="available && isEditable"
        :rules="[() => !isStepsErrored]"
        @click="$emit('click')"
    >
      <span
          class="stepper-abstract"
          v-if="available"
      >
        {{
          $tc('participation.exhibitionGoods.exhibitsSpecified', goods.length, lang, {amount: goods.length})
        }}
      </span>
      <span class="stepper-abstract" v-else>
        {{ $t('forms.hints.notAvailable', lang) }}
      </span>

      <h3 :class="!isStepsErrored && available ? 'primary--text' : ''">
        {{ $t('participation.exhibitionGoods.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">{{ $t('participation.stepsIsErrored', lang) }}</small>
    </v-stepper-step>

    <v-stepper-content
        :step="step"
        class="pb-10"
    >
      <v-row>
        <v-col
            cols="6"
            v-for="good in goods"
            :key="good.id"
            class="pb-0"
        >
          <div class="grey lighten-4 pa-4 mb-4">
            <v-row>
              <v-col cols="8">
                {{ good.attributes.title }}
                <br />
                {{ $t('forms.fields.weight') }} {{ good.attributes.weight }} {{ $t('common.kg') }}
                <br />
                {{ sizes(good) }} ({{ dimensions() }})
              </v-col>
              <v-col
                  cols="4"
                  class="py-2 d-flex justify-end"
              >
                <v-btn
                    text
                    color="secondary"
                    class="elevation-0"
                    :disabled="isReadonlyMode"
                    @click="showEditModal(good)"
                >
                  <span class="hidden-sm-and-down">{{ $t('common.edit') }}</span>
                </v-btn>

                <v-btn
                    text
                    color="secondary"
                    class="elevation-0 ml-2"
                    :disabled="isReadonlyMode"
                    @click="showDeleteModal(good)"
                >
                  <span class="hidden-sm-and-down">{{ $t('common.delete') }}</span>
                </v-btn>

              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12">
          <v-btn
              @click="showCreateModal"
              :disabled="isReadonlyMode || !available"
              text
              outlined
              color="secondary"
          >
            <v-icon
                size="16"
                class="mr-2"
            >
              fal fa-plus
            </v-icon>
            {{ $t('forms.buttons.addEntry', lang) }}
          </v-btn>

          <create-goods-modal
              ref="create"
              :participation="participation"
              :company="company"
              :fair="fair"
              :mode="mode"
          />
        </v-col>

        <v-col
            cols="12"
            class="text-right"
        >
          <v-btn
              class="elevation-0 secondary"
              @click="handleSubmit"
              :disabled="!isSubmittedForm || !available"
          >
            {{ $t('forms.buttons.saveAndContinue', lang) }}
          </v-btn>
        </v-col>

        <confirmation-modal
            ref="delete"
            @confirm="handleDelete"
        >
          {{ $t('common.deleteConfirmation', {item: itemToDelete.title}) }}
        </confirmation-modal>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import CreateGoodsModal from '@/components/exhibitor/Participation/Modals/CreateGoods'
import ConfirmationModal from '@/components/base/ConfirmationModal'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'ExhibitionGoods',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  components: {
    CreateGoodsModal,
    ConfirmationModal,
  },

  mixins: [
    participationMode,
  ],

  data() {
    return {
      itemToDelete: {}
    }
  },

  computed: {
    goods() {
      return this.participation?.relationships?.goods ?? []
    },

    available() {
      return !this.isBookingTypePackage;
    }
  },

  methods: {
    ...mapActions('participation', [
      'deleteGoodsItem',
    ]),

    handleSubmit() {
      this.$emit('submit')
    },

    showCreateModal() {
      this.$refs.create.show()
    },

    showEditModal(good) {
      this.$refs.create.show(good)
    },

    showDeleteModal(good) {
      this.itemToDelete = good

      this.$refs.delete.show()
    },

    dimensions() {
      return this.$options.filters.firstLetter(this.$t('forms.fields.width'), true)
          + ' x '
          + this.$options.filters.firstLetter(this.$t('forms.fields.height'), true)
          + ' x '
          + this.$options.filters.firstLetter(this.$t('forms.fields.depth'), true)
    },

    sizes(good) {
      return this.$options.filters.sizeToMeters(good.attributes.width) + ' '
          + this.$options.filters.firstLetter(this.$t('common.meter'))
          + ' x '
          + this.$options.filters.sizeToMeters(good.attributes.height) + ' '
          + this.$options.filters.firstLetter(this.$t('common.meter'))
          + ' x '
          + this.$options.filters.sizeToMeters(good.attributes.depth) + ' '
          + this.$options.filters.firstLetter(this.$t('common.meter'))
    },

    async handleDelete() {
      await this.deleteGoodsItem({ participation: this.participation, good: this.itemToDelete })
    },
  },
}
</script>
