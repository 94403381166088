<template>
  <v-app-bar class="elevation-1"
    app
    clipped-left
    elevation="1"
    fixed
    color="white"
    height="80"
  >

    <v-img
      src="/img/logos/Made-in-Germany.svg"
      max-height="60"
      max-width="80"
      class="mx-6"
      contain
    />

    <v-spacer />

    <language-picker />

    <notification-menu notification_link="admin.notification" notifications_link="admin.notifications"></notification-menu>

    <documents-menu class="ml-5" />

    <v-divider
      vertical
      class="ml-10 mr-5"
    />

    <v-menu open-on-click>
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-on="on"
          class="text--primary"
        >
          <span class="text-capitalize font-weight-regular text--primary">{{ userName }}</span>
          <v-icon
            right
            size="22"
            class="ml-4 primary--text"
          >
            fas fa-fw fa-user-circle
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="handleLogout">
          <v-list-item-title>{{ $t('header.logOut') }}</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-menu>

  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LanguagePicker from '@/components/base/LanguagePicker'
import DocumentsMenu from '@/components/common/Documents/Menu'
import NotificationMenu from "@/components/common/Notification/NotificationMenu.vue";

export default {
  name: 'AdminHeader',

  components: {
    NotificationMenu,
    LanguagePicker,
    DocumentsMenu,
  },

  computed: {
    ...mapGetters('auth', [
      'userName',
    ]),
  },

  methods: {
    ...mapActions('auth', [
      'logOut',
    ]),

    async handleLogout() {
      if (await this.logOut()) {
        window.location.href = await this.$router.resolve({ name: 'login' }).href
      }
    },
  },
}
</script>
