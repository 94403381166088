import routes from '@/routes/index'
import store from '@/boot/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/boot/i18n'
import { USER_ROLES } from '@/enums/userRole'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', process.env.VUE_APP_SENTRY_DOMAIN, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

router.beforeEach((to, from, next) => {
  store.commit('errors/RESET_ERRORS')

  Vue.nextTick(() => {
    document.title = to.meta.title || i18n.messages[i18n.locale].common.title

    let meta = document.createElement('meta')
    let title = document.getElementsByTagName("title")[0]
    meta.setAttribute('name', 'description');
    meta.content = to.meta.description || i18n.messages[i18n.locale].common.description
    title.parentNode.insertBefore(meta, title.nextSibling)
  });

  const isAuthorized = store.getters['auth/isAuthorized']

  const redirectRoute = { name: isAuthorized ? store.getters['auth/startPage'] : 'login'}

  const nonExhibitorRoles = [USER_ROLES.ORGANIZER, USER_ROLES.ADMIN, USER_ROLES.SUPERVISOR]
  const emptyNonExhibitorRoutePaths = [
    ...nonExhibitorRoles.map(role => `/${role}`),
    ...nonExhibitorRoles.map(role => `/${role}/`),
  ]
  if (emptyNonExhibitorRoutePaths.includes(to.path)) {
    next(redirectRoute)
  }

  const exhibitorRoles = [USER_ROLES.EXHIBITOR]
  const emptyExhibitorRoutePaths = [
    ...exhibitorRoles.map(role => `/${role}`),
    ...exhibitorRoles.map(role => `/${role}/`),
  ]
  if (emptyExhibitorRoutePaths.includes(to.path)) {
    next(redirectRoute)
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthorized) {
      localStorage.setItem('unauthorized', window.location.href)
      if (to.name.includes('exhibitor.')) {
        next({ name: 'exhibitor.home' })
      } else {
        next({ name: 'login' })
      }
    } else {
      if (to.name.includes('exhibitor.')) {
        if (!store.getters['auth/isExhibitorAppAllowed']) {
          next({ name: 'login'})
        }
      }

      const loginRoute = { name: store.getters['auth/isExhibitorAppAllowed'] ? 'exhibitor.home' : 'login'}

      if (to.name.includes('organizer.')) {
        if (!store.getters['auth/isOrganizerAppAllowed']) {
          next(loginRoute)
        }
      }

      if (to.name.includes('admin.')) {
        if (!store.getters['auth/isAdminAppAllowed']) {
          next(loginRoute)
        }
      }

      if (to.name.includes('supervisor.')) {
        if (!store.getters['auth/isSupervisorAppAllowed']) {
          next(loginRoute)
        }
      }
    }
  }

  if (to.matched.some(record => record.meta.onlyOrganizer)) {
    if (store.getters['auth/isProjectManager']) {
      next({ name: 'organizer.dashboard' })
    }
  }

  next()
})


export default router
