<template>
  <company-form
    ref="form"
    v-if="!$wait.is(loaders.updateUserCredentials)"
    @next="$emit('submit')"
    :additional-form-data="additionalFormData"
    :is-onboarding="true"
  >
    <template v-slot:header>
      <v-col
        cols="12"
        class="text-center"
      >
        <h3>{{ $t('onboarding.steps.companyData.title') }}</h3>
      </v-col>
    </template>

    <template v-slot="scope">
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          @click="scope.submit"
          color="primary"
          class="elevation-0"
          :disabled="!($refs.form && $refs.form.isAvailableToSubmit)"
          :loading="$wait.is(loaders.createAccreditation)"
        >
          {{ $t('forms.buttons.continue') }}
        </v-btn>
      </v-col>
    </template>
  </company-form>
</template>

<script>
import { mapGetters } from 'vuex'
import CompanyForm from '@/components/exhibitor/Company/CompanyForm'
import {CREATE_ACCREDITATION, UPDATE_USER_CREDENTIALS} from '@/store/loading-types'
import {mapWaitingActions} from "vue-wait";

export default {
  name: 'OnboardingCompany',

  components: {
    CompanyForm,
  },

  data() {
    return {
      loaders: {
        updateUserCredentials: UPDATE_USER_CREDENTIALS,
        createAccreditation: CREATE_ACCREDITATION,
      }
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user',
    ]),

    additionalFormData() {
      return {
        companyId: this.user.companyId,
      }
    },
  },

  methods: {
    ...mapWaitingActions('company', {
      createAccreditation: CREATE_ACCREDITATION,
    }),
  }
}
</script>
