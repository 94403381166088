<template>
  <v-row>
    <v-col cols="12">
      <h3 class="mb-0">{{ $t('accreditation.steps.registerExcerpt.subTitle') }}</h3>
      <small>{{ $t('accreditation.steps.registerExcerpt.notice') }}</small>
      <p class="mt-5">{{ $t('accreditation.steps.registerExcerpt.description') }}</p>
    </v-col>

    <v-col
      cols="6"
      class="pr-5"
    >
      <v-form>
        <v-text-field
          v-model="form.data.registry_court"
          :error-messages="errors.registry_court"
          :label="$t('forms.fields.registryCourt')"
          dense
          filled
        />

        <v-text-field
          v-model="form.data.registry_number"
          :error-messages="errors.registry_number"
          :label="$t('forms.fields.registryNumberEntry')"
          dense
          filled
        />

        <v-text-field
          v-model="form.data.vat_id"
          :error-messages="errors.vat_id"
          :label="$t('forms.fields.vatIdNo')"
          dense
          filled
        />
      </v-form>
    </v-col>

    <v-col
      cols="6"
      class="pl-5 text-right"
    >
      <upload-register-excerpt ref="uploadRegisterExcerpt" :accreditation="accreditation" :server-file="accreditation.included.name_document"/>
    </v-col>

    <v-row
        class="mx-0 mb-4 px-3"
    >
      <v-btn
          v-if="!accreditation.attributes.accreditation_approved_at && accreditation_old.attributes.accreditation_approved_at"
          @click="$emit('cancel')"
          color="error"
          class="elevation-0"
          :loading="form.loading"
      >
        {{ $t('common.cancel') }}
      </v-btn>

      <v-btn
          @click="handleSubmit"
          color="primary"
          class="elevation-0 ml-auto"
          :loading="form.loading"
      >
        {{ $t('forms.buttons.continue') }}
      </v-btn>
    </v-row>
  </v-row>
</template>

<script>
import { required, minLength, requiredIf } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import UploadRegisterExcerpt from '@/components/exhibitor/Accreditation/UploadRegisterExcerpt'

export default {
  name: 'RegisterExcerpt',

  mixins: [
    validation,
  ],

  components: {
    UploadRegisterExcerpt,
  },

  props: {
    accreditation: Object,
    accreditation_old: Object,
  },

  data() {
    return {
      form: {
        loading: false,

        customFieldsMap: {
          vatIdNo: 'vat_id',
        },

        data: {
          registry_court: '',
          registry_number: '',
          vat_id: '',
        },

        validationRules: {
          registry_court: {
            required: requiredIf(function () {
              return this.isRequiredForm
            }),
          },
          registry_number: {
            required: requiredIf(function () {
              return this.isRequiredForm
            }),
          },
          vat_id: {
            required,
            minLength: minLength(5),
          },
        },
        custom: {
          validationSubstitutions: {
            vat_id: {
              minLength: {
                length: 5,
              },
            },
          },
        },
      },
    }
  },

  computed: {
    isRequiredForm() {
      return this.accreditation.relationships.country.iso_3166_2 == 'DE'
    },
  },

  async created() {
    this.setInitialFormData(this.accreditation.attributes)
  },

  methods: {
    isDocumentValid() {
      return this.$refs.uploadRegisterExcerpt.validate() &&
        this.$refs.uploadRegisterExcerpt.errors.document.length === 0
    },

    async handleSubmit() {
      this.resetServerErrors()
      if (!this.form.data.vat_id) {
        this.setServerErrors({ vat_id: [this.$t('accreditation.vatIdRequired', this.$i18n.locale)] })
        return
      }

      this.$v.$touch()
      if (!this.$refs.uploadRegisterExcerpt.isFileUploaded) {
        await this.$refs.uploadRegisterExcerpt.handleUpload()
      }


      if (this.$v.$invalid || !this.isDocumentValid()) {
        return
      }

      this.form.loading = true
      try {
        await this.$axios.patch(`/api/accreditations/${this.accreditation.id}`, {
          ...this.getFormData(),
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
