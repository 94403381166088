<template>
  <v-form>
    <v-row justify="center">

      <slot name="header" />

      <v-col :cols="isCentered ? 8 : 12" :class="{ 'text-center': isCentered }">
        <v-autocomplete
          :label="$t('onboarding.steps.countriesAndIndustries.fields.countries')"
          v-model="form.data.countries"
          :error-messages="errors.countries"
          :items="filteredCounties"
          :item-text="`translations.${$i18n.locale}.country`"
          item-value="id"
          class="rounded-0"
          :filter="searchCountry"
          multiple
          small-chips
          filled
          :search-input.sync="searchCountries"
        />

        <v-autocomplete
          :label="$t('onboarding.steps.countriesAndIndustries.fields.industrySectors')"
          v-model="form.data.industrySectors"
          :error-messages="errors.industrySectors"
          :items="sortedSectors"
          :item-text="`translations.${$i18n.locale}.name`"
          item-value="id"
          class="rounded-0"
          multiple
          small-chips
          filled
          :search-input.sync="searchSectors"
        />
      </v-col>

      <slot :submit="handleSubmit" />

    </v-row>
  </v-form>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import map from 'lodash/map'
import {required} from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import {UPDATE_COMPANY_PREFERENCES} from "@/store/loading-types";
import {mapWaitingActions} from "vue-wait";

export default {
  name: 'InterestProfileForm',

  props: {
    isCentered: {
      type: Boolean,
      required: false,
      default: true,
    },
    isOnboarding: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mixins: [
    validation,
  ],

  data() {
    return {
      searchCountries: null,
      searchSectors: null,
      form: {
        data: {
          countries: [],
          industrySectors: [],
        },
        validationRules: {
          countries: {
            required,
          },
          industrySectors: {
            required,
          },
        },
        serverErrors: {
          countries: [],
          industrySectors: [],
          general: [],
        },
        translationPath: 'onboarding.steps.countriesAndIndustries.fields',
      },
      loaders: {
        updateCompanyPreferences: UPDATE_COMPANY_PREFERENCES,
      }
    }
  },

  computed: {
    ...mapGetters('company', [
      'companyPreferences',
    ]),
    ...mapGetters('countries', [
      'countries',
      'prioritizedCountries',
    ]),
    ...mapGetters('sectors', [
      'sectors',
    ]),

    filteredCounties() {
      return this.prioritizedCountries.filter((el) => {
        return el.iso31662 !== 'DE'
      })
    },

    sortedSectors() {
      return [...this.sectors].sort((a, b) => a.name.localeCompare(b.name))
    }
  },

  async created() {
    this.fetchCountries();
    this.fetchSectors();

    if (!this.isOnboarding) {
      if (await this.fetchCompanyPreferences()) {
        this.form.data.countries = map(this.companyPreferences.countries, 'id')
        this.form.data.industrySectors = map(
          this.companyPreferences.industrySectors.sort((a, b) => a.name[this.$i18n.locale].localeCompare(b.name[this.$i18n.locale])), 'id')
      }
    }
  },

  methods: {
    ...mapActions('company', [
      'fetchCompanyPreferences',
      'updateCompanyPreferences',
    ]),

    ...mapActions('countries', [
      'fetchCountries',
    ]),

    ...mapActions('sectors', [
      'fetchSectors',
    ]),

    ...mapWaitingActions('company', {
      updateCompanyPreferences: UPDATE_COMPANY_PREFERENCES,
    }),

    searchCountry (item, queryText, itemText) {
      return itemText.toLowerCase().startsWith(queryText.toLowerCase())
    },

    async handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.resetServerErrors()

      if (await this.updateCompanyPreferences(this.getFormData())) {
        this.$emit('next')
      }
    },
  },

  watch: {
    'form.data.countries'() {
      this.searchCountries = null;
    },

    'form.data.industrySectors'() {
      this.searchSectors = null;
    }
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  }
}
</script>
