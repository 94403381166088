export default {
  fairs: {
    findTradeFairParticipations: 'Find trade fair participations',
    tradeFairName: 'Trade fair name',
    country: 'Country',
    year: 'Year',
    branch: 'Industry sector',
  },
  sponsor: {
    bmwi: 'Federal Ministry of Economic Affairs and Climate Action',
    bmel: 'Federal Ministry of Food and Agricultrue',
  }
};
