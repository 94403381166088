<template>
  <div>
    <login-form
      :dynamic-redirect="true"
      :showRegister="false"
      @forgotPasswordRequested="$emit('forgotPasswordRequested')"
    />
  </div>
</template>

<script>
import LoginForm from '@/components/base/Auth/LoginForm'

export default {
  name: 'ExhibitorLogin',

  components: {
    LoginForm,
  }
}
</script>
