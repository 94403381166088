<template>
  <v-dialog
    max-width="900"
    class="rounded-0"
    :value="display"
    @click:outside="hide"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 v-if="form.data.id" class="primary--text">
          {{ $t('participation.configureRegistrationForm.fees.editServiceFees') }}
        </h4>
        <h4 class="primary--text" v-else>
          {{ $t('participation.configureRegistrationForm.fees.addServiceFees') }}
        </h4>
        <v-btn @click="hide" icon>
          <v-icon size="20" color="primary">fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <v-form>
          <v-row>
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.fees.designation_de')"
                :error-messages="errors.title"
                v-model="form.data.title.de"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.fees.designation_en')"
                :error-messages="errors.title"
                v-model="form.data.title.en"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <vue-editor
                  :placeholder="$t('participation.configureRegistrationForm.fees.coexhibitorFeeDescription_de')"
                  rows="3"
                  class="mb-2"
                  v-model="form.data.description.de"
              ></vue-editor>
              <validation-general-error class="mt-2" :errors="errors.description" />
            </v-col>
            <v-col cols="6">
              <vue-editor
                  :placeholder="$t('participation.configureRegistrationForm.fees.coexhibitorFeeDescription_en')"
                  rows="3"
                  class="mb-2"
                  v-model="form.data.description.en"
              ></vue-editor>
              <validation-general-error class="mt-2" :errors="errors.description" />
            </v-col>
            <v-col cols="4">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.fees.perMainExhibitorFee')"
                :error-messages="errors.price"
                type="number"
                v-model="form.data.price"
                suffix="€"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="elevation-0 col-2" @click="hide" color="secondary" outlined text>
          {{ $t('participation.configureRegistrationForm.cancel') }}
        </v-btn>

        <v-btn
          color="secondary"
          class="elevation-0 col-2 ml-auto"
          @click="handleSubmit"
        >
          {{ $t('participation.configureRegistrationForm.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validation from '@/mixins/validate'
import { VueEditor } from "vue2-editor";
import { translation } from '@/utilities/helpers/validation'
import { minValue, required } from 'vuelidate/lib/validators'
import ValidationGeneralError from '@/components/base/ValidationGeneralError.vue'

export default {
  name: 'CustomizeRegistrationFeesEdit',

  components: {
    VueEditor,
    ValidationGeneralError
  },

  mixins: [validation],

  props: {
    fair: {
      type: Object,
    },
  },

  data() {
    return {
      display: false,

      service: {},

      form: {
        loading: false,
        
        data: {
          title: {},
          description: {},
          specification_label: {},
          unit: {},
          price: 0,
        },
        validationRules: {
          title: {
            translation: translation(),
          },
          description: {},
          specification_label: {},
          unit: {},
          price: {
            required: required,
            minValue: minValue(0),
          },
        },
        
        custom: {
          validationSubstitutions: {
            price: {
              minValue: {
                limit: 0,
              },
            },
          },
        },
      },
    }
  },

  methods: {
    show(item) {
      this.service = item

      this.setInitialFormData(JSON.parse(JSON.stringify(item?.attributes ?? {})))

      if (!item) {
        this.form.data.title = { de: '', en: ''}
        this.form.data.description = { de: '', en: ''}
        this.form.data.unit = { de: '', en: ''}
        this.form.data.specification_label = { de: '', en: ''}
      }

      this.$v.$reset()
      this.display = true
    },

    hide() {
      this.display = false
    },
    
    async handleSubmit() {
      this.resetServerErrors()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        if (this.service?.id) {
          await this.$axios.put(`/api/trade-fairs/${this.fair.id}/services/${this.service?.id}`, {
            ...this.getFormData(),
            fee: true,
            mandatory: true,
            amount_selectable: false,
            multiselectable: false,
            specifiable: false,
          })
        } else {
          await this.$axios.post(`/api/trade-fairs/${this.fair.id}/services`, {
            ...this.getFormData(),
            fee: true,
            mandatory: true,
            amount_selectable: false,
            multiselectable: false,
            specifiable: false,
          })
        }

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')

        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
