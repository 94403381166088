export default {
  registration: {
    title: 'Registration',
    description: 'Registration and use are free of charge. Please note: The user account is created for your company.',
    userAccess: 'As a registered user, you have access to the trade fair data and participation conditions of the federal foreign trade fair program.',
    userCan: 'As a registered user you can:',
    possibilities: {
      findFairs: 'find interesting trade fair participations for your company',
      earlyRegister: 'register early for participation',
      bookParticipation: 'book your trade fair participation securely and conveniently online',
      informYourself: 'inform yourself in detail about the process of your participation in the trade fair',
    },
  },
}
