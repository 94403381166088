export default {
  tradefairsummary: {
    title: 'Messekurzbericht',
    deadlineAlert:
      'Der Bericht ist unmittelbar nach der Messe zu übersenden. Der Bericht darf nur zu internen Zwecken verwendet werden. Eine Veröffentlichung ist nicht gestattet.',
    tradeFairName: 'Messename',
    date: 'Datum',
    location: 'Stadt, Land',
    venue: 'Veranstaltungsgelände',
    organizer: 'Durchführungsgesellschaft',
    contactPerson: 'Ansprechpartner',
    contactEmail: 'Ansprechpartner E-Mail',
    contactPhone: 'Ansprechpartner Telefon',
    enterText: 'Hier zur Texteingabe klicken',
    exhibitors: {
      title: 'Aussteller',
      total: 'Aussteller insgesamt',
      totalHA: 'Hauptaussteller German Pavilion insgesamt',
      thereofImporters: 'davon Importeure',
      totalUA: 'Unteraussteller German Pavilion insgesamt',
    },
    spaces: {
      title: 'Ausstellungsflächen',
      total: 'Gesamtfläche in ㎡',
      exhibitorSpace: 'Fläche d. Aussteller in ㎡',
      exhibitorSpaceHint: 'ggf. mit Freifläche',
    },
    participations: {
      title: 'Beteiligungen',
      whichHalls: 'Beteiligung in welchen Hallen (Sektor und ㎡)',
      whichStates: 'Beteiligung von Bundesländern',
      fiveBiggest: 'Fünf größte Beteiligungen anderer Staaten',
      germanCommunity: 'Beteiligung deutscher Unternehmen ',
      germanCommunityHint: 'außerhalb d. deutschen Gemeinschaftstandes',
    },
    visitors: {
      title: 'Besucher',
      preliminary: 'Vorläufige Besucherzahl',
      foreign: 'davon ausländisch',
      vip: 'VIP-Besuche am German Pavilion',
    },
    supportingEvents: {
      title: 'Begleitveranstaltungen',
      exhibitorEvening: 'Ausstellerabend',
      exhibitorEveningHint: 'ca. Anzahl d. Gäste insgesamt / VIPs / Importeure',
      getTogether: 'Get together',
      showcooking: 'Showcooking',
      storeCheckTour: 'Store-Check-Tour',
      matchmaking: 'B2B-Matchmaking',
      flowristicShow: 'Floristische Show',
      degustationCounter: 'Degustationstheke',
      infoEvent: 'Infoveranstaltung',
      other: 'Sonstiges',
    },
    general: {
      title: 'Allgemeines',
      openingBy: 'Eröffnung durch',
      reception: 'BMEL-/BMWK-Empfang',
      architect: 'Architekturbüro',
      commentsStandConstruction: 'Anmerkungen zum Standbau',
      commentsCatering: 'Anmerkungen zum Catering',
      commentsCateringHint: 'Art, ggf. Probleme',
      commentsCooperation:
        'Anmerkungen zur Zusammenarbeit mit dem Messeveranstalter',
      recommendation: 'Empfehlung einer weiteren Teilnahme und Gründe',
      germanEffect:
        'Wirkung des deutschen Gemeinschaftsstandes ggü. anderen Gemeinschaftsständen',
      companyFeedback: 'Feedback der ausstellenden Unternehmen',
      supplementaryRemarks: 'Ergänzende Bemerkungen',
    },
    images: {
      title: 'Bilder upload',
      alert:
        'Bitte übermitteln Sie jeweils ein aussagekräftiges Bild der Lounge und des Messegeländes sowie zwei aussagekräftige Bilder des Firmengemeinschaftsstandes.',
      charaterization: 'Bildbeschreibung',
      choseFiles: 'Dateien auswählen',
      hint: 'Maximal 15 Bilder hochladen, Max. Dateigröße pro Bild: 10 MB',
      upload: 'Hochladen',
      delete: 'Bild löschen'
    },
    checkAndSend: {
      title: 'Überprüfen und absenden',
      privacyPolicy:
        'Ich versichere, dass sämtliche von mir hochgeladenen Bilder nicht die Urheber- und/ oder Persönlichkeitsrechte Dritter verletzen und ich allein berechtigt bin über die Rechte an den veröffentlichten Bildern uneingeschränkt und frei von Rechten Dritter verfügen zu können. Ich erkläre ferner, keine diesen Nutzungsbedingungen zuwiderlaufende Verfügung über die Rechte getroffen zu haben oder zukünftig zu treffen. Ich räume dem Seitenbetreiber in Bezug auf die übermittelten Bilder ein zeitlich und örtlich unbeschränktes, einfaches Nutzungsrecht bezüglich aller Nutzungsarten für die Veröffentlichung auf der Online Seite www.german-pavilion.com ein. Dem Seitenbetreiber wird das Recht zur Erteilung von einfachen Unterlizenzen eingeräumt. Ferner wird dem Seitenbetreiber ein Bearbeitungsrecht an den von mir übermittelten Bildern eingeräumt, dass den Seitenbetreiber berechtigt, die Vorlage unter Verwendung analoger, digitaler oder sonstiger Bearbeitungsmöglichkeiten unter Wahrung des Urheberrechts zu bearbeiten und/ oder umzustellen.',
      alert:
        "Bitte prüfen Sie ihre Eingabe gewissenhaft. Sie können Ihre Einträge beliebig oft Speichern, wenn Sie den Messekurzbericht allerdings 'Senden' ist dies final.",
    },
    submitted: {
      title: 'Ihr Kurzbericht wurde eingereicht',
      description:
        'Sie können Ihre Eingaben nicht mehr ändern. Sie haben aber die Möglichkeit das eingereichte PDF herunterzuladen und Ihre hochgeladenen Bilder zu begutachten.',
      downloadTradefairSummary: 'Messekurzbericht herunterladen',
      downloadTradefairImages: 'Alle Bilder herunterladen',
      errorSummaryForm:
        'Nicht alle Pflichtfelder sind ausgefüllt, bitte prüfen Sie das Formular',
      errorFlagRegistrationIsOpen:
        'Die Messe muss zur Registrierung geöffnet gewesen sein.',
      errorTradeFairDate:
        'Die Messe muss begonnen haben oder in der Vergangenheit liegen.',
      errorFlagCancelled: 'Die Messe darf nicht abgesagt sein.',
      errorSummarySubmittedAt:
        'Der Kurzbericht kann nur einmal versandt werden.',
      errorSummaryImages: 'Es müssen mindestens vier Bilder hochgeladen werden',
      errorSummaryTooManyImages: 'Maximal 15 Bilder hochladen',
      errorSummaryTooSizeImages: 'Max. Dateigröße pro Bild: 10 MB',
    },
  },
}
