<template>
  <v-dialog v-if="company" v-model="display" max-width="1024px">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('participation.accreditation.data') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-card-subtitle>
        <p class="mt-5" v-html="$t('participation.accreditation.dataHint')">
        </p>
      </v-card-subtitle>
      <v-divider/>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-row>
              <v-col cols="4">
                <small>{{ $t('company.address') }}</small>
                <p>
                  {{ company.attributes.company }}
                  <br/>
                  {{ company.attributes.street }}
                  <br/>
                  <span v-if="company.attributes.address_supplement">
                      {{ company.attributes.address_supplement }}
                      <br/>
                    </span>
                  {{ company.attributes.postalcode }} {{ company.attributes.city }}
                  <br/>
                  {{ company.relationships.federal_state?.attributes.name[$i18n.locale] }}
                  <br/>
                  {{ company.attributes.country ? company.relationships.country?.translations[$i18n.locale].country : '' }}
                </p>
              </v-col>

              <v-col cols="4">
                <small>{{ $t('company.contactDetails') }}</small>
                <p>
                  <i class="fas fa-phone primary--text mr-3"/>
                  {{ company.attributes.phone }}
                  <br/>
                  <i class="fas fa-fax primary--text mr-3"/>
                  {{ company.attributes.fax }}
                  <br/>
                  <i class="fas fa-envelope primary--text mr-3"/>
                  <a :href="'mailto:' + company.attributes.email">{{ company.attributes.email }}</a>
                  <br/>
                  <i class="fas fa-home primary--text mr-3"/>
                  <a :href="company.attributes.website" target="_blank">{{ company.attributes.website }}</a>
                </p>
                <div
                    v-if="
                      company.attributes.contact_first_name ||
                      company.attributes.contact_last_name ||
                      company.attributes.contact_email ||
                      company.attributes.contact_phone
                    "
                >
                  <small>{{
                      $t('tables.accreditation.contactPerson')
                    }}</small>
                  <p>
                    <i class="fas fa-address-book primary--text mr-3"/>
                    <span>
                        {{
                        $t(`common.salutation.${company.attributes.contact_gender}`) +
                        ' ' +
                        company.attributes.contact_first_name +
                        ' ' +
                        company.attributes.contact_last_name
                      }}
                      </span>
                    <br/>
                    <i class="fas fa-phone primary--text mr-3"/>
                    <span v-if="company.attributes.contact_phone">{{
                        company.attributes.contact_phone
                      }}</span>
                    <br/>
                    <i class="fas fa-envelope primary--text mr-3"/>
                    <a
                        v-if="company.attributes.contact_email"
                        :href="'mailto:' + company.attributes.contact_email"
                    >{{ company.attributes.contact_email }}</a
                    >
                    <br/>
                  </p>
                </div>
              </v-col>

              <v-col cols="4" v-if="company.attributes.accreditation_approved_at">
                <small>{{ $t('company.registerData') }}</small>
                <p>
                  {{ $t('forms.fields.vatIdNo') }}: {{ company.attributes.vat_id }}
                  <br/>
                  {{ $t('company.hrNumber') }}: {{ company.attributes.registry_number }}
                  <br/>
                  {{ $t('company.hrRegisterCourt') }}:
                  {{ company.attributes.registry_court }}
                  <br/>
                  <span :hidden="!company.included.has_document">
                      <i class="fas fa-arrow-to-bottom primary--text mr-2" />
                      <a @click="downloadDocument(company.id)">{{
                          $t('company.downloadRegisterExcerpt')
                        }}</a>
                    </span>
                </p>
              </v-col>

              <v-col cols="12" v-if="company.attributes.accreditation_approved_at">
                <p>
                  <small>{{ $t('accreditation.status') }}</small>
                </p>

                <p>
                  <v-icon class="primary--text mb-1 mr-3" size="18">
                    fal fa-calendar-alt fa-fw
                  </v-icon>
                  <span
                  >{{ $t('accreditation.registeredSince') }}:
                      {{ company_model.created_at | dateByLocale }}
                    </span>
                </p>

                <p>
                  <v-icon class="primary--text mb-1 mr-3" size="18">
                    fal fa-calendar-check fa-fw
                  </v-icon>
                  <span
                  >{{ $t('accreditation.grantedOn') }}
                      {{ company.attributes.accreditation_approved_at | dateByLocale }}</span
                  >
                </p>

                <p v-if="company.attributes.accreditation_rejected_at">
                  <v-icon class="error--text mb-1 mr-3" size="18">
                    fal fa-calendar-times fa-fw
                  </v-icon>
                  <span class="error--text"
                  >{{ $t('accreditation.revokedOn') }}
                      {{ company.attributes.accreditation_rejected_at | dateByLocale }}</span
                  >
                </p>

                <p>
                  <v-icon class="primary--text mb-1 mr-3" size="18">
                    fal
                    {{
                      !company.attributes.declared_public_sector_involvement
                          ? 'fa-check'
                          : 'fa-times'
                    }}
                    fa-fw
                  </v-icon>
                  <span>{{
                      $t('accreditation.underPublicLaw.publicSectorInvolvement')
                    }}</span>
                </p>

                <p>
                  <v-icon class="primary--text mb-1 mr-3" size="18">
                    fal
                    {{
                      !company.attributes.declared_public_sector_institution
                          ? 'fa-check'
                          : 'fa-times'
                    }}
                    fa-fw
                  </v-icon>
                  <span>{{
                      $t(
                          'accreditation.underPublicLaw.publicSectorInstitution'
                      )
                    }}</span>
                </p>

                <p>
                  <v-icon class="primary--text mb-1 mr-3" size="18">
                    fal
                    {{
                      !company.attributes.declared_public_sector_majority
                          ? 'fa-check'
                          : 'fa-times'
                    }}
                    fa-fw
                  </v-icon>
                  <span>{{
                      $t('accreditation.underPublicLaw.publicSectorMajority')
                    }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row
                v-if="
                  company.attributes.is_foreign_branch || company.attributes.is_foreign_representation
                "
            >
              <v-col cols="12">
                <p>
                  <v-icon class="primary--text mb-1 mr-3" size="18">
                    fal
                    {{ company.attributes.is_foreign_branch ? 'fa-check' : 'fa-times' }}
                    fa-fw
                  </v-icon>
                  <span>{{ $t('tables.accreditation.foreignBranch') }}</span>
                </p>

                <p>
                  <v-icon class="primary--text mb-1 mr-3" size="18">
                    fal
                    {{
                      company.attributes.is_foreign_representation
                          ? 'fa-check'
                          : 'fa-times'
                    }}
                    fa-fw
                  </v-icon>
                  <span>{{
                      $t('tables.accreditation.foreignRepresentation')
                    }}</span>
                </p>

                <p>
                    <span>
                      {{
                        $t('tables.accreditation.connectedCompany') +
                        ': ' +
                        company.attributes.related_company
                      }}
                    </span>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="secondary" @click="hide">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AccreditationDetailModal',

  data() {
    return {
      display: false,
      company: null,
      company_model: null,
    }
  },

  methods: {
    ...mapActions('accreditation', ['downloadDocument']),

    show(company, company_model) {
      this.company = company
      this.company_model = company_model

      this.display = true
    },

    hide() {
      this.display = false
    },
  },
}
</script>
