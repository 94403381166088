<template>
  <v-row justify="center">

    <v-col cols="8" class="text-center">
      <h3 class="mb-4">{{ $t('onboarding.steps.tradeFairs.title') }}</h3>
      <p>{{ $t('onboarding.steps.tradeFairs.description') }}</p>
    </v-col>

    <v-col cols="8" class="text-center">
      <v-autocomplete
        :label="$t('onboarding.steps.tradeFairs.fields.tradeFairs')"
        v-model="form.data.tradeFairs"
        :error-messages="errors.tradeFairs"
        :items="datasets.tradeFairs"
        item-text="nameDisplay"
        item-value="id"
        class="rounded-0"
        multiple
        small-chips
        :search-input.sync="search"
        filled
      >
      </v-autocomplete>
    </v-col>

    <v-col cols="12" class="text-right">
      <v-btn
        color="primary"
        @click="handleSubmit()"
        class="mt-5"
        :loading="$wait.is(loaders.updateCompanyPreferences)"
      >
        {{ $t('common.continue') }}
      </v-btn>
    </v-col>

  </v-row>
</template>

<script>
import { api } from '@/boot/api'
import validation from '@/mixins/validate'
import {UPDATE_COMPANY_PREFERENCES} from "@/store/loading-types";
import {mapWaitingActions} from "vue-wait";

export default {
  name: 'TradeFairs',

  mixins: [
    validation,
  ],

  data() {
    return {
      datasets: {
        tradeFairs: [],
      },
      search: null,
      form: {
        data: {
          tradeFairs: [],
        },
        validationRules: {
          tradeFairs: {},
        },
        serverErrors: {
          tradeFairs: [],
          general: [],
        },
        translationPath: 'onboarding.steps.tradeFairs.fields',
      },
      loaders: {
        updateCompanyPreferences: UPDATE_COMPANY_PREFERENCES,
      }
    }
  },

  created() {
    this.fetchTradeFairs()
  },

  methods: {
    ...mapWaitingActions('company', {
      updateCompanyPreferences: UPDATE_COMPANY_PREFERENCES,
    }),

    fetchTradeFairs() {
      api.get('/trade-fairs')
        .then(tradeFairs => {
          this.datasets.tradeFairs = tradeFairs
        })
        .catch(error => {
          this.$root.vtoast.show({message: error.statusText, color: 'error'})
        })
    },

    async handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.resetServerErrors()

      if (!this.form.data.tradeFairs.length) {
        this.$emit('submit')
        return
      }

      if (await this.updateCompanyPreferences(this.getFormData())) {
        this.$emit('submit')
      }
    },
  },
  watch: {
    'form.data.tradeFairs'() {
      this.search = null;
    }
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  }
}
</script>
