<template>
  <v-app>
    <public-header />
    <v-main>
      <v-container class="pa-9" fluid>
        <v-card class="elevation-1 rounded-0">
          <v-card-title>
            <h4 class="primary--text">
              {{ $t("header.federalForeignTradeFairProgram") }}
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle>
            <h3>
              {{ $t("common.incorrectDisplayTitle") }}
            </h3>
          </v-card-subtitle>
          <v-card-text>
            {{ $t("common.incorrectDisplayBody") }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" @click="sendLinkToEmailClient">
              {{ $t("common.incorrectDisplayButton") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-main>
    <v-footer>
      <logos />
    </v-footer>
  </v-app>
</template>

<script>
import Logos from "@/components/base/Logos/Logos";
import PublicHeader from "@/components/public/Header.vue";

export default {
  name: "IncorrectDisplay",
  components: { Logos, PublicHeader },
  methods: {
    sendLinkToEmailClient() {
      window.location.href = `mailto:user@example.com?subject=${this.$t(
        "common.incorrectDisplayButton"
      )}&body=${window.location.href}`;
    },
  },
};
</script>

<style scoped>
.v-card__actions {
  padding: 16px;
}
.theme--light.v-application {
  background-color: #f5f5f5;
}
.v-application .v-application--wrap {
  display: flex;
  justify-content: space-between;
}
</style>
