<template>
  <v-snackbar
    v-model="state.isShown"
    :color="settings.type"
    class="notification"
    timeout="4000"
    bottom
    right
    elevation="2"
    multi-line
  >
    <v-icon v-if="icon" size="30" left>{{ icon }}</v-icon>

    {{ settings.message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        color="white"
        @click="hideSnackbar"
      >
        {{ $t('common.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SNACKBAR_TYPES } from '@/enums/snackbar';

export default {
  name: 'Snackbar',

  data() {
    return {
      state: {
        isShown: false,
      },
      settings: {
        message: '',
        type: ''
      },
      defaults: {
        message: 'no message',
        type: SNACKBAR_TYPES.ERROR,
        icon: '',
      },
    }
  },

  computed: {
    ...mapGetters('snackbar', [
      'message',
      'type'
    ]),

    icon() {
      if(this.settings.type == 'error') {
        return 'fal fa-circle-exclamation'
      }
      if(this.settings.type == 'success') {
        return 'fal fa-circle-check'
      }
      return null
    }
  },

  created() {
    this.$store.watch(state => state.snackbar.message, () => {
      if (!this.message) {
        this.clearSnackbar()
        return
      }

      this.showSnackbar()
      this.settings.message = this.message || this.defaults.message
      this.settings.type = this.type || this.defaults.type
      this.settings.icon = this.icon || this.defaults.icon
      this.clearSnackbar()
    })
  },

  methods: {
    ...mapActions('snackbar', [
      'clearSnackbar',
    ]),

    showSnackbar() {
      this.state.isShown = true
    },

    hideSnackbar() {
      this.state.isShown = false
    },
  },
}
</script>
