<template>
  <tr>
    <td>#{{ index }}</td>
    <td>{{ title }}</td>
    <td>{{ amount }} </td>
    <td><span v-html="amountUnit"></span></td>
    <td style="white-space: nowrap;" class="text-right">{{ price | money(locale) }}</td>
    <td style="white-space: nowrap;" class="text-right">{{ totalPrice | money(locale) }}</td>
  </tr>
</template>

<script>

export default {
  name: 'ServiceRow',

  props: {
    index: {
      type: Number,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    locale: {
      type: String,
      required: false,
    },

    amount: {
      type: Number,
      required: false,
      default: 1,
    },

    amountUnit: {
      type: String,
      required: false,
    },

    price: {
      type: Number,
      required: true,
    },
  },

  computed: {
    totalPrice() {
      return this.price * this.amount
    },
  },

}
</script>
