<template>
  <v-row>
    <v-col cols="12" lg="6" md="6" sm="12"
      v-for="(logo, index) in logos"
      :key="index"
    >
      <component
        :is="logo"
        cols="12"
        lg="4"
        md="6"
      />
    </v-col>
  </v-row>
</template>

<script>
import BMELBMWKLogo from '@/components/base/Logos/BMELBMWK'
import AUMALogo from '@/components/base/Logos/AUMA'

export default {
  name: 'Logos',

  components: {
    BMELBMWKLogo,
    AUMALogo,
  },

  data() {
    return {
      logos: [
        BMELBMWKLogo,
        AUMALogo,
      ],
    }
  },
}
</script>

