<template>
  <div>
    <h1
      v-if="!hideTitle"
      class="mb-4 primary--text"
    >
      {{ $t('common.federalForeignTradeFairProgram')}}
    </h1>
    <p v-if="!hideTitle">{{ $t('login.eventOverviewAndExhibitorRegistration') }}</p>

    <v-text-field
      v-model="form.data.email"
      :error-messages="errors.email"
      :label="$t('forms.fields.email')"
      @input="onEmailChange"
    />

    <validation-general-error :errors="errors.general" />
    <success-message :messages="messages" />

    <div class="mb-10 text-right">
      <p>
        <small>
          <v-btn
            plain
            x-small
            @click="$emit('loginRequested')"
          >
            {{ $t('auth.logIn') }}
          </v-btn>
        </small>
      </p>
    </div>

    <div class="my-10">
      <v-btn
        color="secondary"
        class="mr-5 elevation-0"
        @click="handleForgotPassword"
        :loading="$wait.is(loaders.forgotPassword)"
        :disabled="!form.isSubmitEnabled"
      >
        {{ $t('auth.forgotPassword') }}
      </v-btn>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { required, email } from 'vuelidate/lib/validators'
import { FORGOT_PASSWORD } from '@/store/loading-types'
import validation from '@/mixins/validate'
import ValidationGeneralError from '@/components/base/ValidationGeneralError'
import SuccessMessage from '@/components/base/Messages/SuccessMessage'

export default {
  name: 'AppExhibitorForgotPassword',

  props: {
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    ValidationGeneralError,
    SuccessMessage,
  },

  mixins: [
    validation,
  ],

  data() {
    return {
      form: {
        data: {
          email: '',
        },
        validationRules: {
          email: {
            required,
            email,
          },
        },
        isSubmitEnabled: true,
      },
      loaders: {
        forgotPassword: FORGOT_PASSWORD,
      },
      messages: [],
    }
  },

  mounted() {
    this.$root.vtoast = this.$refs.snackbar
  },

  methods: {
    ...mapActions('auth', [
      'forgotPassword',
    ]),

    ...mapWaitingActions('auth', {
      forgotPassword: FORGOT_PASSWORD,
    }),

    async handleForgotPassword() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (await this.forgotPassword(this.getFormData())) {
        this.messages = [this.$t('auth.passwordResetLinkSent')]
      }

      this.form.isSubmitEnabled = false
    },

    onEmailChange() {
      this.form.isSubmitEnabled = true
      this.resetServerErrors()
      this.messages = []
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
