<template>
  <v-dialog max-width="900" v-model="display" @click:outside="hide">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 v-if="form.data.id" class="primary--text">
          {{ $t('participation.configureRegistrationForm.servicePackage.editServicePackage') }}
        </h4>
        <h4 class="primary--text" v-else>
          {{ $t('participation.configureRegistrationForm.servicePackage.addServicePackage') }}
        </h4>
        <v-btn @click="hide" icon>
          <v-icon size="20" color="primary">fal fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <v-form>
          <v-row class="mb-5">
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.designation_de')"
                :error-messages="errors.title"
                v-model="form.data.title.de"
              ></v-text-field>
              <vue-editor
                :placeholder="$t('participation.configureRegistrationForm.service_description_de')"
                rows="3"
                class="mb-2"
                v-model="form.data.services.de"
              ></vue-editor>
              <validation-general-error class="mt-2" :errors="errors.services" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.designation_en')"
                :error-messages="errors.title"
                v-model="form.data.title.en"
              ></v-text-field>
              <vue-editor
                :placeholder="$t('participation.configureRegistrationForm.service_description_en')"
                rows="3"
                class="mb-2"
                v-model="form.data.services.en"
              ></vue-editor>
              <validation-general-error :errors="errors.services" />
            </v-col>
            <v-col cols="4">
              <v-text-field
                  :label="$t('participation.configureRegistrationForm.partialPayer')"
                :error-messages="errors.price_sponsored"
                suffix="€"
                type="number"
                v-model="form.data.price_sponsored"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.fullPayer')"
                :error-messages="errors.price_unsponsored"
                suffix="€"
                type="number"
                v-model="form.data.price_unsponsored"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :label="$t('participation.configureRegistrationForm.publicHand')"
                :error-messages="errors.price_publicsector"
                suffix="€"
                type="number"
                v-model="form.data.price_publicsector"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-checkbox
                  class="mt-2"
                  v-model="form.data.allow_subexhibitor"
                  :label="$t('participation.configureRegistrationForm.allowSubexhibitorCheckbox')"
                  :error-messages="errors.allow_subexhibitor"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="8" class="pt-4">
              <span>
              {{ $t('participation.configureRegistrationForm.allowSubexhibitor') }}
              </span>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="elevation-0 col-2" @click="hide" color="secondary" outlined text>
          {{ $t('participation.configureRegistrationForm.cancel') }}
        </v-btn>
        <v-btn :loading="form.loading" color="secondary" @click="handleSubmit" class="elevation-0 col-2 ml-auto">
          {{ $t('participation.configureRegistrationForm.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { minValue, required } from 'vuelidate/lib/validators'
import { VueEditor } from 'vue2-editor'
import validation from '@/mixins/validate'
import ValidationGeneralError from '@/components/base/ValidationGeneralError'

export default {
  name: 'CustomizeRegistrationPackagesEdit',

  mixins: [validation],

  components: {
    VueEditor,
    ValidationGeneralError
  },

  props: {
    fair: {
      type: Object,
    },
  },

  data() {
    return {
      display: false,

      package: {},

      form: {
        loading: false,

        data: {
          title: { de: '', en: ''},
          services: { de: '', en: ''},
          price_sponsored: 0,
          price_unsponsored: 0,
          price_publicsector: 0,
          allow_subexhibitor: false,
        },
        validationRules: {
          title: {
            required: required,
          },
          services: {
            required: required,
          },
          price_sponsored: {
            required: required,
            minValue: minValue(1),
          },
          price_unsponsored: {
            required: required,
            minValue: minValue(1),
          },
          price_publicsector: {
            required: required,
            minValue: minValue(1),
          },
          allow_subexhibitor: {},
        },

        custom: {
          validationSubstitutions: {
            price_sponsored: {
              minValue: {
                limit: 0,
              },
            },
            price_unsponsored: {
              minValue: {
                limit: 0,
              },
            },
            price_publicsector: {
              minValue: {
                limit: 0,
              },
            },
          },
        },
      },
    }
  },

  methods: {
    show(item) {
      this.package = item

      this.setInitialFormData(JSON.parse(JSON.stringify(item?.attributes ?? { allow_subexhibitor: false })))

      if (!item) {
        this.form.data.title = { de: '', en: ''}
        this.form.data.services = { de: '', en: ''}
      }

      this.$v.$reset()
      this.display = true
    },

    hide() {
      this.display = false
    },

    async handleSubmit() {
      this.resetServerErrors()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        if (this.package?.id) {
          await this.$axios.put(`/api/trade-fairs/${this.fair.id}/packages/${this.package?.id}`, {
            ...this.getFormData(),
          })
        } else {
          await this.$axios.post(`/api/trade-fairs/${this.fair.id}/packages`, {
            ...this.getFormData(),
          })
        }

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')

        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
