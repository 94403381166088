export const SET_TOKEN = 'SET_TOKEN'
export const UNSET_TOKEN = 'UNSET_TOKEN'
export const SET_USER = 'SET_USER'
export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES'
export const UNSET_USER = 'UNSET_USER'
export const REMOVE_USER = 'REMOVE_USER'
export const SET_LANGUAGES = 'SET_LANGUAGES'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_PARTICIPATIONS = 'SET_PARTICIPATIONS'
export const SET_PARTICIPATION = 'SET_PARTICIPATION'
export const SET_PARTICIPATION_COST = 'SET_PARTICIPATION_COST'
export const SET_PARTICIPATION_PACKAGE = 'SET_PARTICIPATION_PACKAGE'
export const SET_PARTICIPATION_AREA = 'SET_PARTICIPATION_AREA'
export const SET_PARTICIPATION_GOODS = 'SET_PARTICIPATION_GOODS'
export const ADD_PARTICIPATION_GOODS_ITEM = 'ADD_PARTICIPATION_GOODS_ITEM'
export const SET_PARTICIPATION_PREVIEW_MODE = 'SET_PARTICIPATION_PREVIEW_MODE'
export const SET_PARTICIPATION_PREVIEW_LANGUAGE = 'SET_PARTICIPATION_PREVIEW_LANGUAGE'
export const SET_PARTICIPATION_PREVIEW_PRICE = 'SET_PARTICIPATION_PREVIEW_PRICE'
export const SET_PARTICIPATION_SPACE = 'SET_PARTICIPATION_SPACE'
export const SET_PARTICIPATION_SERVICES = 'SET_PARTICIPATION_SERVICES'
export const SET_PARTICIPATION_STEPS_ERRORED = 'SET_PARTICIPATION_STEPS_ERRORED'
export const UPDATE_PARTICIPATION_GOODS_ITEM = 'UPDATE_PARTICIPATION_GOODS_ITEM'
export const DELETE_PARTICIPATION_GOODS_ITEM = 'DELETE_PARTICIPATION_GOODS_ITEM'
export const SET_PARTICIPATION_COEXHIBITORS = 'SET_PARTICIPATION_COEXHIBITORS'
export const ADD_PARTICIPATION_COEXHIBITORS = 'ADD_PARTICIPATION_COEXHIBITORS'
export const UPDATE_PARTICIPATION_COEXHIBITOR = 'UPDATE_PARTICIPATION_COEXHIBITORS'
export const DELETE_PARTICIPATION_COEXHIBITOR = 'DELETE_PARTICIPATION_COEXHIBITORS'
export const ADD_PARTICIPATION_SERVICE = 'ADD_PARTICIPATION_SERVICE'
export const UPDATE_PARTICIPATION_SERVICE = 'UPDATE_PARTICIPATION_SERVICE'
export const DELETE_PARTICIPATION_SERVICE = 'DELETE_PARTICIPATION_SERVICE'
export const SET_SPACES_PRICE_TYPE = 'SET_SPACES_PRICE_TYPE'
export const SET_PREFERRED_TRADE_FAIRS = 'SET_PREFERRED_TRADE_FAIRS'
export const SET_NOTED_TRADE_FAIR = 'SET_NOTED_TRADE_FAIR'
export const UNSET_NOTED_TRADE_FAIR = 'UNSET_NOTED_TRADE_FAIR'
export const SET_ACCREDITATION = 'SET_ACCREDITATION'
export const SET_REGISTER_EXTRACT = 'SET_REGISTER_EXTRACT'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_COMPANY = 'SET_COMPANY'
export const SET_COMPANIES = 'SET_COMPANIES'
export const SET_COMPANY_PREFERENCES = 'SET_COMPANY_PREFERENCES'
export const SET_SNACKBAR = 'SET_SNACKBAR'
export const UNSET_SNACKBAR = 'UNSET_SNACKBAR'
export const SET_SECTORS = 'SET_SECTORS'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_STATES = 'SET_STATES'
export const SET_ERRORS = 'SET_ERRORS'
export const SET_ERROR = 'SET_ERROR'
export const RESET_ERRORS = 'RESET_ERRORS'
export const SET_FAQ_ARTICLES = 'SET_FAQ_ARTICLES'
export const SET_FAQ_ARTICLE = 'SET_FAQ_ARTICLE'
export const ADD_FAQ_ARTICLE = 'ADD_FAQ_ARTICLE'
export const DELETE_FAQ_ARTICLE = 'DELETE_FAQ_ARTICLE'
export const SET_FAQ_CATEGORIES = 'SET_FAQ_CATEGORIES'
export const ADD_FAQ_CATEGORY = 'ADD_FAQ_CATEGORY'
export const DELETE_FAQ_CATEGORY = 'DELETE_FAQ_CATEGORY'
export const SET_HELP_ARTICLES = 'SET_HELP_ARTICLES'
export const SET_HELP_ARTICLE = 'SET_HELP_ARTICLE'
export const ADD_HELP_ARTICLE = 'ADD_HELP_ARTICLE'
export const DELETE_HELP_ARTICLE = 'DELETE_HELP_ARTICLE'
export const SET_CURRENT_HELP_ARTICLE = 'SET_CURRENT_HELP_ARTICLE'
export const SET_TEMPLATES = 'SET_TEMPLATES'
export const SET_TRADE_FAIRS = 'SET_TRADE_FAIRS'
export const SET_TRADE_FAIR = 'SET_TRADE_FAIR'
export const SET_TRADE_FAIR_ID = 'SET_TRADE_FAIR_ID'
export const SET_ASSIGNED_TRADE_FAIRS = 'SET_ASSIGNED_TRADE_FAIRS'
export const SET_TRADE_FAIR_PACKAGES = 'SET_TRADE_FAIR_PACKAGES'
export const SET_TRADE_FAIR_AREAS = 'SET_TRADE_FAIR_AREAS'
export const SET_TRADE_FAIR_SPACES = 'SET_TRADE_FAIR_SPACES'
export const SET_TRADE_FAIR_SERVICES = 'SET_TRADE_FAIR_SERVICES'
export const SET_INDUSTRY_SECTORS = 'SET_INDUSTRY_SECTORS'
export const UPDATE_TRADE_FAIR_PACKAGES = 'UPDATE_TRADE_FAIR_PACKAGES'
export const UPDATE_TRADE_FAIR_AREAS = 'UPDATE_TRADE_FAIR_AREAS'
export const UPDATE_TRADE_FAIR_SPACES = 'UPDATE_TRADE_FAIR_SPACES'
export const UPDATE_TRADE_FAIR_SERVICES = 'UPDATE_TRADE_FAIR_SERVICES'
export const DELETE_TRADE_FAIR_PACKAGES = 'DELETE_TRADE_FAIR_PACKAGES'
export const DELETE_TRADE_FAIR_AREAS = 'DELETE_TRADE_FAIR_AREAS'
export const DELETE_TRADE_FAIR_SPACES = 'DELETE_TRADE_FAIR_SPACES'
export const DELETE_TRADE_FAIR_SERVICES = 'DELETE_TRADE_FAIR_SERVICES'
export const CREATE_TRADE_FAIR_PACKAGES = 'CREATE_TRADE_FAIR_PACKAGES'
export const CREATE_TRADE_FAIR_AREAS = 'CREATE_TRADE_FAIR_AREAS'
export const CREATE_TRADE_FAIR_SPACES = 'CREATE_TRADE_FAIR_SPACES'
export const CREATE_TRADE_FAIR_SERVICES = 'CREATE_TRADE_FAIR_SERVICES'
export const SET_YEARS = 'SET_YEARS'
export const SET_ORGANIZER = 'SET_ORGANIZER'
export const SET_ORGANIZERS = 'SET_ORGANIZERS'
export const SET_USERS = 'SET_USERS'
export const ADD_USER = 'ADD_USER'
export const SET_USER_ORGANIZER = 'SET_USER_ORGANIZER'
export const GET_TRADE_FAIRS = 'GET_TRADE_FAIRS'
export const GET_PUBLIC_TRADE_FAIRS = 'GET_PUBLIC_TRADE_FAIRS'
export const SET_ACCREDITATIONS = 'SET_ACCREDITATIONS'
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'
export const SET_ASSOCIATIONS = 'SET_ASSOCIATIONS'
export const SET_TRADE_FAIR_FORM_LOADED = 'SET_TRADE_FAIR_FORM_LOADED'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
