<template>
  <v-container class="pa-9" fluid>

    <v-card class="elevation-1 rounded-0 mb-5 primary--text">
      <v-card-title>
        <h3>{{ $t('settings.dataAndSettings') }}</h3>
      </v-card-title>
      <v-card-text>
        <p class="text--primary">{{ $t('settings.manageSettings') }}</p>
      </v-card-text>
    </v-card>

    <v-expansion-panels
      class="mb-10 elevation-0 rounded-0"
      :value="panels"
      multiple
      flat
    >

      <v-expansion-panel class="elevation-1 rounded-0">
        <v-expansion-panel-header>
          <h3>{{ $t('settings.sections.userCredentials.title') }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <user-credentials-settings />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="elevation-1 rounded-0">
        <v-expansion-panel-header v-if="organizer && organizer.name">
          <h3>{{ $t('settings.sections.companyData.title') }} – {{ organizer.name }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-header v-else>
          <h3>{{ $t('settings.sections.companyData.title') }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <company-settings />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="elevation-1 rounded-0">
        <v-expansion-panel-header>
          <h3>{{ $t('settings.sections.assignedProjects.title') }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <assigned-projects />
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import UserCredentialsSettings from '@/components/organizer/Settings/UserCredentials'
import CompanySettings from '@/components/organizer/Settings/Company'
import AssignedProjects from '@/components/organizer/Settings/AssignedProjects'

export default {
  name: 'OrganizerSettings',

  components: {
    UserCredentialsSettings,
    CompanySettings,
    AssignedProjects,
  },

  data() {
    return {
      panels: [],
    }
  },

  computed: {
    ...mapGetters('organizer', [
      'organizer',
    ]),
  }
}
</script>
