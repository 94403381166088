<template>
  <v-expansion-panel class="rounded-0">
    <v-expansion-panel-header>
      <h4>{{ $t('participation.configureRegistrationForm.exhibitionArea.title') }}</h4>
    </v-expansion-panel-header>
    <v-expansion-panel-content>

      <p v-html="$t('participation.configureRegistrationForm.exhibitionArea.warning')"></p>

      <v-sheet class="my-2 pa-4" v-for="(item) in areas" :key="item.id" outlined>
        <v-row>
          <v-col cols="5" class="">
            <small class="text--secondary">{{ $t('participation.configureRegistrationForm.exhibitionArea.title_de') }}</small><br>
            <span>{{ item.attributes.name.de }}</span>
          </v-col>
          <v-col cols="5" class="">
            <small class="text--secondary">{{ $t('participation.configureRegistrationForm.exhibitionArea.title_en') }}</small><br>
            <span>{{ item.attributes.name.en }}</span>
          </v-col>

          <v-col cols="2" class="text-right">
            <v-btn color="secondary" @click="edit(item)" icon>
              <v-icon size="18">fal fa-pencil</v-icon>
            </v-btn>
            <v-btn color="secondary" @click="remove(item)" icon>
              <v-icon size="18">fal fa-trash-alt</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>

      <v-row class="mx-0 my-2">
        <v-btn color="secondary" @click="add" class="mt-5 mb-5" text outlined>
          <i class="fal fa-plus mr-2"></i> {{ $t('participation.configureRegistrationForm.add') }}
        </v-btn>
      </v-row>
      <v-btn color="secondary" class="px-10" elevation="0" @click="save">
        {{ $t('accreditation.steps.complete.title') }}
      </v-btn>


    </v-expansion-panel-content>
    <customize-registration-exhibitionareas-edit ref="edit" :fair="fair" @submit="load"></customize-registration-exhibitionareas-edit>
  </v-expansion-panel>
</template>

<script>
import CustomizeRegistrationExhibitionareasEdit from './CustomizeRegistrationExhibitionareasEdit';

export default {
  name: 'CustomizeRegistrationExhibitionareas',

  components: {
    CustomizeRegistrationExhibitionareasEdit
  },


  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      areas: [],

      form: {
        loading: false,
      },
    }
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      this.form.areas = true

      let response = (await this.$axios.get(`/api/trade-fairs/${this.fair.id}/areas`, {
        params: {
          'languages': '*',
          'include': [],
        },
      })).data

      this.areas = response.data

      this.form.loading = false
    },

    async remove(item) {
      if (confirm(this.$t('participation.configureRegistrationForm.exhibitionArea.alertDeleteExhibitionArea'))) {
        await this.$axios.delete(`/api/trade-fairs/${this.fair.id}/areas/${item.id}`, {})

        this.$snackbar(this.$t('common.successApiMessage'))

        this.load()
      }
    },

    edit(item) {
      this.$refs.edit.show(item)
    },

    add() {
      this.$refs.edit.show()
    },

    save() {
      this.$emit('submit')
    }
  },
}
</script>

<style>
table {
  width: 100%;
}

table tr td {
  padding: 0 1rem 0 0;
  vertical-align: middle;
}
</style>
