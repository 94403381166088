import {VueEditor} from "vue2-editor";
import Vue from "vue";

const toolbar = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{
        list: "ordered"
    }, {
        list: "bullet"
    }],
    ["link"],
    ["clean"],
];

VueEditor.props.editorToolbar.default = function () {
    return toolbar
};

Vue.component('VueEditor', VueEditor)

Vue.use(VueEditor)

export default VueEditor