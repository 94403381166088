<template>
  <fragment>
    <v-col cols="12">
      <v-checkbox
        v-model="form.data.declared_data_protection"
        :error-messages="errors.declared_data_protection"
        :label="$t('accreditation.steps.conditions.dataProtectionDeclaration.label')"
      />

      <p>
        <router-link
          :to="{ name: 'documents.privacyPolicy' }"
          target="_blank"
        >
          {{ $t('accreditation.steps.conditions.dataProtectionDeclaration.link') }}
        </router-link>
      </p>
    </v-col>

    <v-row
        class="mx-0 mb-2 px-3"
    >
      <v-btn
          v-if="!accreditation.attributes.accreditation_approved_at && accreditation_old.attributes.accreditation_approved_at"
          @click="$emit('cancel')"
          color="error"
          class="elevation-0"
      >
        {{ $t('common.cancel') }}
      </v-btn>

      <v-btn
          @click="handleSubmit"
          color="primary"
          class="elevation-0 ml-auto"
      >
        {{ $t('forms.buttons.continue') }}
      </v-btn>
    </v-row>
  </fragment>
</template>

<script>
import { sameAs } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'

export default {
  name: 'Conditions',

  props: {
    accreditation: Object,
    accreditation_old: Object,
  },

  mixins: [
    validation,
  ],

  data() {
    return {
      form: {
        loading: false,
        
        data: {
          declared_data_protection: false,
        },
        
        validationRules: {
          declared_data_protection: {
            sameAs: sameAs(() => true)
          },
        },
        
        custom: {
          validationRulesMap: {
            declared_data_protection: {
              sameAs: 'checkboxRequired',
            },
          },
        },
      },
    }
  },

  async created() {
    this.form.data.declared_data_protection = !!this.accreditation.attributes.declared_data_protection
  },

  methods: {
    async handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        await this.$axios.patch(`/api/accreditations/${this.accreditation.id}`, {
          ...this.getFormData(),
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
