<template>
  <v-app>
    <organizer-header />
    <organizer-menu-bar />

    <v-main class="grey lighten-4">
      <v-container fluid class="px-0 py-0">
        <router-view />
      </v-container>
    </v-main>

    <Snackbar ref="snackbar" />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import OrganizerHeader from '@/components/organizer/Header'
import OrganizerMenuBar from '@/components/organizer/MenuBar'
import Snackbar from '@/components/base/Snackbar'
import { GET_TRADE_FAIRS } from '@/store/loading-types'

export default {
  name: 'AppOrganizer',

  components: {
    OrganizerHeader,
    OrganizerMenuBar,
    Snackbar,
  },

  created() {
    this.fetchTradeFairs()
    this.fetchHelpArticles()
  },

  mounted() {
    this.$root.vtoast = this.$refs.snackbar
  },

  methods: {
    ...mapActions('organizer', [
      'fetchTradeFairs',
      'fetchCurrentTradeFair',
    ]),

    ...mapActions('help', [
      'fetchHelpArticles'
    ]),

    ...mapWaitingActions('organizer', {
      fetchTradeFairs: GET_TRADE_FAIRS,
    }),
  }
}
</script>
