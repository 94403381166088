<template>
  <v-container class="pa-9" fluid>
    <v-card class="elevation-1 rounded-0">
      <v-card-title>
        <h4 class="primary--text">{{ $t('users.userManagement.title') }}</h4>
      </v-card-title>
      <v-card-subtitle>{{ $t('users.userManagement.description') }}</v-card-subtitle>

      <v-divider/>

      <v-toolbar class="elevation-0">
        <v-tooltip
            color="secondary"
            bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="showCreateUsersModal"
                v-bind="attrs"
                v-on="on"
                class="ml-auto"
                outlined
                color="secondary"
            >
            {{ $t('users.actions.create') }}
            <v-icon right size="20" color="secondary"> fal fa-user-plus </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('users.actions.create') }}</span>
        </v-tooltip>

      </v-toolbar>

      <v-divider/>

      <v-data-table
          :headers="headers"
          :loading="form.loading"
          :items="users"
          :page.sync="form.page"
          :options.sync="options"
          :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
          :server-items-length="form.total"
          item-key="id"
          show-expand
          single-expand
          @item-expanded="handleUserExpanding"
      >
        <template v-slot:item.first_name="{ item }">
          <span>{{ item.attributes.full_name }}</span>
        </template>

        <template v-slot:item.locked_at="{ item }">
          <v-icon
              :class="!item.attributes.locked_at ? 'green--text' : 'red--text'"
              size="10"
          >
            fas fa-circle
          </v-icon>
        </template>

        <template v-slot:item.email="{ item }">
          <span>{{ item.attributes.email }}</span>
        </template>

        <template v-slot:item.projectsAmount="{ item }">
          {{ item.relationships.trade_fairs ? item.relationships.trade_fairs.length : 0 }}
        </template>

        <template v-slot:item.isAdmin="{ item }">
          <v-icon size="16">fal fa-fw {{ item.relationships.roles[0].role === USER_ROLES.ORGANIZER ? 'fa-check' : 'fa-times' }}</v-icon>
        </template>

        <template
            v-slot:item.actions="{ item }"
        >
          <v-menu
              open-on-click
              bottom
              left
              offset-x
          >
            <template v-slot:activator="{ on }">
              <v-btn
                  color="secondary"
                  icon
                  v-on="on"
              >
                <v-icon>fal fa-ellipsis-v</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-if="item.attributes.locked_at === null"
                  @click="showDeactivateUserModal(item)"
              >
                <v-list-item-title>{{ $t('users.actions.deactivate') }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  v-else
                  @click="showActivateUserModal(item)"
              >
                <v-list-item-title>{{ $t('users.actions.activate') }}</v-list-item-title>
              </v-list-item>

              <v-list-item @click="showDeleteUserModal(item)">
                <v-list-item-title>{{ $t('users.actions.delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>

          </v-menu>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td
              :colspan="headers.length"
              class="elevation-1 px-1 grey lighten-5"
          >
            <v-container fluid>
              <v-row>

                <v-col
                    cols="12"
                    class="px-7 pb-0"
                >
                  <h3>{{ item.attributes.first_name }}</h3>
                </v-col>

                <v-col
                    cols="6"
                    class="px-7"
                >
                  <v-form>
                    <v-checkbox
                        v-model="form.data.isAdmin"
                        :label="$t('users.userManagement.canManage')"
                        small
                    />

                    <v-text-field
                        v-model="form.data.first_name"
                        :error-messages="errors.first_name"
                        :label="$t('forms.fields.firstName')"
                    />

                    <v-text-field
                        v-model="form.data.last_name"
                        :error-messages="errors.last_name"
                        :label="$t('forms.fields.lastName')"
                    />

                    <v-select
                        v-model="form.data.gender"
                        :items="genders"
                        item-value="type"
                        item-text="label"
                        :label="$t('forms.fields.gender')"
                        :error-messages="errors.gender"
                        class="rounded-0"
                    />

                    <v-text-field
                        v-model="form.data.email"
                        :error-messages="errors.email"
                        :label="$t('forms.fields.email')"
                        autocomplete="off"
                    />

                    <v-text-field
                        type="password"
                        v-model="form.data.password"
                        :error-messages="errors.password"
                        :label="$t('forms.fields.password')"
                        :hint="$t('forms.hints.password')"
                        autocomplete="new-password"
                    />

                    <v-text-field
                        type="password"
                        v-model="form.data.password_confirmation"
                        :error-messages="errors.password_confirmation"
                        :label="$t('forms.fields.passwordConfirmation')"
                        :hint="$t('forms.hints.password')"
                        autocomplete="new-password"
                    />

                    <v-text-field
                        v-model="form.data.phone"
                        :error-messages="errors.phone"
                        :label="$t('forms.fields.phone')"
                    />

                    <v-text-field
                        v-model="form.data.fax"
                        :error-messages="errors.fax"
                        :label="$t('forms.fields.fax')"
                    />
                  </v-form>

                </v-col>

                <v-col
                    cols="6"
                    class="px-7"
                    v-if="!item.isAdmin"
                >
                  <p>{{ $t('users.userManagement.assignedProjects') }}</p>
                  <v-row>
                    <v-col cols="10">
                      <v-autocomplete
                          :label="$t('users.userManagement.chooseProject')"
                          :items="tradeFairsToAssign"
                          item-text="nameDisplay"
                          item-value="id"
                          v-model="tradeFairIdToAssign"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                          icon
                          :disabled="!tradeFairIdToAssign"
                          @click="handleTradeFairAssign"
                          :loading="form.loading"
                          class="mt-4"
                      >
                        <v-icon
                            size="20"
                            color="secondary"
                        >
                          fal fa-plus-circle
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-list
                      color="transparent"
                      v-if="activeUser"
                  >
                    <v-list-item
                        class="grey lighten-4 mb-2"
                        v-for="tradeFair in activeUser.relationships.trade_fairs"
                        :key="tradeFair.id"

                    >
                      <v-list-item-title>
                        {{ tradeFair.name_display | shorten(40, ' ', '...') }}
                        <br/>
                        <small>
                          {{ tradeFair.start_date_display[$i18n.locale] }}
                          <span v-if="tradeFair.end_date">- {{ tradeFair.end_date | dateByLocale }}</span>
                        </small>
                      </v-list-item-title>
                      <v-list-item-icon>
                        <v-btn
                            icon
                            @click="showUnassignConfirmationModal(tradeFair)"
                        >
                          <v-icon
                              color="secondary"
                              size="20"
                          >
                            fal fa-minus-circle
                          </v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col
                    cols="12"
                    class="text-right"
                >
                  <v-btn
                      color="secondary"
                      class="elevation-0"
                      @click="handleUserUpdate"
                      :loading="form.loading"
                  >
                    {{ $t('common.save') }}
                  </v-btn>
                </v-col>

              </v-row>
            </v-container>
          </td>
        </template>

      </v-data-table>
    </v-card>
    <confirmation-modal
        ref="confirmationTradeFairUnassignModal"
        @confirm="handleTradeFairUnassign"
        :loading="form.loading"
        :async-hide="true"
    >
      {{ $t('common.deleteConfirmation', {item: fair.nameDisplay}) }}
    </confirmation-modal>

    <confirmation-modal
        ref="confirmationUserDeactivateModal"
        @confirm="handleUserDeactivate"
        :loading="form.loading"
        :async-hide="true"
    >
      {{ $t('common.generalConfirmation', {action: $t('users.actions.deactivate').toLowerCase()}) }}
    </confirmation-modal>

    <confirmation-modal
        ref="confirmationUserDeleteModal"
        @confirm="handleUserDelete"
        :loading="form.loading"
        :async-hide="true"
    >
      {{ $t('common.generalConfirmation', {action: $t('users.actions.delete').toLowerCase()}) }}
    </confirmation-modal>

    <confirmation-modal
        ref="confirmationUserActivateModal"
        @confirm="handleUserActivate"
        :loading="form.loading"
        :async-hide="true"
    >
      {{ $t('common.generalConfirmation', {action: $t('users.actions.activate').toLowerCase()}) }}
    </confirmation-modal>

    <create-user-modal
        @submit="load"
        ref="createUsersModal"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { USER_ROLES } from '@/enums/userRole'
import { GENDERS } from '@/enums/genders'
import validation from '@/mixins/validate'
import ConfirmationModal from '@/components/base/ConfirmationModal'
import CreateUserModal from '@/components/organizer/Users/Modals/CreateUserModal'
import date from '@/mixins/date'
import pick from 'lodash/pick'

export default {
  name: 'OrganizerUsers',

  components: {
    ConfirmationModal,
    CreateUserModal,
  },

  mixins: [
    validation,
    date
  ],

  data() {
    return {
      options: {},
      users: [],

      form: {
        loading: false,
        data: {
          first_name: null,
          last_name: null,
          gender: null,
          email: null,
          password: null,
          password_confirmation: null,
          phone: null,
          fax: null,
          isAdmin: null,
        },
        validationRules: {
          first_name: {
            required,
          },
          last_name: {
            required,
          },
          gender: {
            required,
          },
          email: {
            email,
          },
          password: {
            minLength: minLength(6),
          },
          password_confirmation: {
            minLength: minLength(6),
            sameAsPassword: sameAs('password'),
          },
          phone: {
            minLength: minLength(6),
          },
          fax: {
            minLength: minLength(6),
          },
          isAdmin: {},
        },
        custom: {
          validationSubstitutions: {
            password_confirmation: {
              sameAsPassword: {
                sameAsField: this.$t('forms.fields.password'),
              },
              minLength: {
                length: 6,
              },
            },
            password: {
              minLength: {
                length: 6,
              },
            },
            phone: {
              minLength: {
                length: 6,
              },
            },
            fax: {
              minLength: {
                length: 6,
              },
            },
          },
        },
      },

      fair: {},
      userId: null,
      tradeFairIdToAssign: null,
      USER_ROLES,
    }
  },

  computed: {
    ...mapGetters('organizer', [
      'tradeFairs',
    ]),

    headers() {
      return [
        {
          text: '',
          value: 'data-table-expand'
        },
        {
          text: this.$t('forms.fields.name'),
          value: 'first_name',
          sortable: true,
        },
        {
          text: this.$t('users.userManagement.active'),
          value: 'locked_at',
          sortable: true,
        },
        {
          text: this.$t('users.userManagement.assignedProjects'),
          sortable: false,
          value: 'projectsAmount',
        },
        {
          text: this.$t('forms.fields.email'),
          value: 'email',
          sortable: true,
        },
        {
          text: this.$t('users.userManagement.admin'),
          value: 'isAdmin',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ]
    },

    activeUser() {
      return this.users.find(user => user.id === this.userId)
    },

    genders() {
      return Object.values(GENDERS).map(gender => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`),
      }))
    },

    tradeFairsToAssign() {
      if (!this.activeUser) {
        return this.tradeFairs
      }

      let fairs = this.activeUser.relationships.trade_fairs.map(tradeFair => tradeFair.id)

      return this.tradeFairs.filter(tradeFair => !fairs.includes(tradeFair.id))
    },
  },

  methods: {
    ...mapActions('organizer', [
      'extractFromUser',
      'fetchTradeFairs',
    ]),

    handleUserExpanding({ item: user, value: isOpened }) {
      if (!isOpened) {
        this.clearFormData()
      } else {
        this.userId = user.id

        this.form.data = { ...pick(this.users.find((el) => el.id === user.id).attributes, Object.keys(this.form.data)) }

        this.form.data.isAdmin = user.relationships.roles[0].role === USER_ROLES.ORGANIZER
      }
    },

    async handleUserUpdate() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        await this.$axios.patch(`/api/users/${this.userId}`, { ...this.form.data, role: this.form.data.isAdmin ? USER_ROLES.ORGANIZER : USER_ROLES.PROJECT_MANAGER })

        if (this.tradeFairIdToAssign) {
          await this.handleTradeFairAssign()
        }

        this.$snackbar(this.$t('common.successApiMessage'))
      } catch (e) {
        if (e?.response?.status === 422) {
          this.form.errors = e?.response?.data?.errors ?? {}
        }
      }

      this.load()
    },

    async handleTradeFairUnassign() {
      this.form.loading = true

      await this.$axios.delete(`/api/users/${this.activeUser.id}/trade-fairs/${this.fair.id}`)

      this.$refs.confirmationTradeFairUnassignModal.hide()

      this.load()
    },

    async handleTradeFairAssign() {
      this.form.loading = true

      await this.$axios.post(`/api/users/${this.activeUser.id}/trade-fairs/${this.tradeFairIdToAssign}`)

      this.tradeFairIdToAssign = null

      this.load()
    },

    async handleUserDeactivate() {
      this.form.loading = true

      await this.$axios.post(`/api/users/${this.userId}/lock`)

      this.load()

      this.$refs.confirmationUserDeactivateModal.hide()
    },

    async handleUserActivate() {
      this.form.loading = true

      await this.$axios.post(`/api/users/${this.userId}/unlock`)

      this.load()

      this.$refs.confirmationUserActivateModal.hide()
    },

    async handleUserDelete() {
      this.form.loading = true

      await this.$axios.delete(`/api/users/${this.userId}`)

      this.load()

      this.$refs.confirmationUserDeleteModal.hide()
    },

    showUnassignConfirmationModal(tradeFair) {
      this.fair = tradeFair

      this.$refs.confirmationTradeFairUnassignModal.show()
    },

    showCreateUsersModal() {
      this.$refs.createUsersModal.show()
    },

    showDeactivateUserModal(user) {
      this.userId = user.id

      this.$refs.confirmationUserDeactivateModal.show()
    },

    showActivateUserModal(user) {
      this.userId = user.id

      this.$refs.confirmationUserActivateModal.show()
    },

    showDeleteUserModal(user) {
      this.userId = user.id

      this.$refs.confirmationUserDeleteModal.show()
    },

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get('/api/users', {
        params: {
          'page[number]': this.options.page,
          'page[size]': this.options.itemsPerPage,
          'include': [
            'roles',
            'organizer',
            'association',
            'language',
            'trade_fairs',
          ],
          'sort': sort,
        },
      })).data

      this.users = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },
  },

  created() {
    this.load()

    this.fetchTradeFairs()
    this.extractFromUser()
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },

  watch: {
    'options'() {
      this.load()
    },
  },
}
</script>
