<template>
  <div>
    <h1 class="mb-4 primary--text">{{ $t('common.federalForeignTradeFairProgram')}}</h1>
    <p>{{ $t('login.eventOverviewAndExhibitorRegistration') }}</p>

    <login-form
      :dynamic-redirect="false"
      @forgotPasswordRequested="$emit('forgotPasswordRequested')"
    />
  </div>
</template>

<script>
import LoginForm from '@/components/base/Auth/LoginForm'

export default {
  name: 'ExhibitorLogin',

  components: {
    LoginForm,
  }
}
</script>
