<template>
  <div class="text-center">
    <v-menu
      top
      min-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          class="mr-5"
        >
          <v-icon size="20" class="primary--text">fal fa-globe</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="language in languages"
          :key="language.id"
          :disabled="isLanguageActive(language)"
          @click="handleLanguageChange(language)"
        >
          <v-list-item-title>{{ language.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { FETCH_COUNTRIES } from '@/store/loading-types'

export default {
  name: 'LanguagePicker',

  computed: {
    ...mapGetters('languages', [
      'language',
      'languages',
    ]),
  },

  async created() {
    await this.fetchLanguages()
    this.$i18n.locale = this.language.locale;
  },

  methods: {
    ...mapActions('auth', [
      'isAuthorized',
    ]),

    ...mapActions('languages', [
      'fetchLanguages',
      'changeLanguage',
    ]),

    ...mapActions('countries', [
      'fetchCountries',
    ]),

    ...mapWaitingActions('countries', {
      fetchCountries: FETCH_COUNTRIES,
    }),

    async handleLanguageChange(language) {
      this.$i18n.locale = language.locale
      this.$vuetify.lang.current = language.locale
      document.title = this.$route.meta.title || this.$t('common.title')

      await this.changeLanguage(language)

      if (this.isAuthorized) {
        this.fetchCountries({ isForced: true })
      }
    },

    isLanguageActive({ locale }) {
      return this.language.locale === locale
    },
  }
}
</script>
