<template>
  <v-container class="pa-9" fluid>
    <v-card class="elevation-1 rounded-0">
      <v-toolbar flat>
        <v-toolbar-title>
          <h4 class="primary--text">
            {{ $t('interest.interestedPersons') }}
          </h4>
        </v-toolbar-title>

        <v-spacer/>
        <v-tooltip open-on-hover bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn :disabled="!previous?.id || moment(previous.attributes?.end_date).isAfter() || !!currentTradeFair.interestedImportedAt" text plain x-small @click="handleImport">
                {{ $t('interest.importButton') }}
                <v-icon>fal fa-file-import</v-icon>
              </v-btn>
            </div>
          </template>
          <span v-if="previous?.id">
            {{ moment(previous?.attributes?.end_date).isAfter()
              ? $t('interest.importRunning')
              : (
                  currentTradeFair.interestedImportedAt
                  ? $t('interest.importAlready', { count: currentTradeFair.interestedImportedCount, date: currentTradeFair.interestedImportedAt })
                  : previous.attributes?.name_display
              )
            }}
          </span>
          <span v-else>
            {{ $t('interest.importNoPrevious') }}
          </span>
        </v-tooltip>
        <v-btn text plain x-small @click="download">
          {{ $t('interest.exportAsExcelSheet') }}
          <v-icon>fal fa-file-arrow-down fa-fw</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider class="primary"/>

      <v-data-table
          show-expand
          :headers="headers"
          :loading="form.loading"
          :items="companies"
          :page.sync="form.page"
          :options.sync="options"
          :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
          :server-items-length="form.total"
      >
        <template v-slot:item.accreditation_approved_at="{ item }">
          <span v-if="!item.relationships.accreditation?.accreditation_rejected_at">{{
              item.relationships.accreditation?.accreditation_approved_at | dateByLocale
            }}</span>
        </template>

        <template v-slot:item.company="{ item }">
          {{ item.relationships.accreditation?.company }}
        </template>

        <template v-slot:item.city="{ item }">
          {{ item.relationships.accreditation?.city }}
        </template>

        <template v-slot:item.email="{ item }">
          <a :href="'mailto:' + item.relationships.accreditation?.email">{{
              item.relationships.accreditation?.email
            }}</a>
        </template>

        <template v-slot:item.website="{ item }">
          <a :href="item.relationships.accreditation?.website" target="_blank">
            {{ item.relationships.accreditation?.website }}
          </a>
        </template>

        <template v-slot:item.accreditation="{ item }">
          <v-icon size="16"
          >fal fa-fw
            {{
              (item.relationships.accreditation?.accreditation_approved_at && !item.relationships.accreditation?.accreditation_rejected_at) ? 'fa-check' : 'fa-times'
            }}fa-check
          </v-icon
          >
        </template>

        <template v-slot:item.reserved_on="{ item }">
          <span>{{ item.attributes.created_at | dateByLocale }}</span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="elevation-1 px-1 grey lighten-5">
            <v-container fluid>
              <v-row>
                <v-col cols="4">
                  <small>{{ $t('interest.addressAndContactDetails') }}</small>
                  <p>
                    {{ item.relationships.accreditation?.company }}
                    <br/>
                    {{ item.relationships.accreditation?.street }}<br/>
                    <span v-if="item.relationships.accreditation?.address_supplement">{{
                        item.relationships.accreditation?.address_supplement
                      }}<br/></span>
                    {{ item.relationships.accreditation?.postalcode }}
                    {{ item.relationships.accreditation?.city }}
                  </p>

                  <p>
                    {{ $t('interest.email') }}: {{ item.relationships.accreditation?.email }}
                    <br/>
                    {{ $t('interest.website') }}:
                    <a :href="item.relationships.accreditation?.website" target="_blank">
                      {{ item.relationships.accreditation?.website }}
                    </a>
                    <br/>
                    {{ $t('interest.phone') }}: {{ item.relationships.accreditation?.phone }}
                  </p>

                  <small>{{ $t('interest.contactPerson') }}</small>
                  <p>
                    <span v-if="item.relationships.accreditation?.contact_gender">
                      {{
                        $t(`common.salutation.${item.relationships.accreditation?.contact_gender}`)
                      }} {{ item.relationships.accreditation?.contact_first_name }} {{ item.relationships.accreditation?.contact_last_name }}
                      <br/>
                    </span>
                    {{ $t('interest.email') }}:
                    <a :href="'mailto:' + item.relationships.accreditation?.contact_email">{{ item.relationships.accreditation?.contact_email }}</a>
                    <br/>
                    {{ $t('interest.phone') }}:
                    {{ item.relationships.accreditation?.contact_phone }}
                  </p>
                </v-col>

                <v-col cols="8" class="py-0">
                  <table class="facts">
                    <tbody>
                    <tr v-if="item.relationships.countries">
                      <td class="pl-0">
                        <strong>
                          {{ $t('interest.countryInterest') }}:
                        </strong><br/>
                        <div v-if="item.relationships.countries.length < 20">
                          <span
                              v-for="country in item.relationships.countries"
                              :key="country.id"
                          >
                            {{ country.translations[$i18n.locale].country }}
                            <br/>
                          </span>
                        </div>
                        <div v-else class="row mx-0 mt-2 mb-4">
                          <span class="col-4 py-0 px-0 my-0 mx-0" v-for="country in item.relationships.countries" :key="country.id">
                            {{ country.translations[$i18n.locale].country }}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="item.relationships.industry_sectors">
                      <td class="pl-0">
                        <strong>
                          {{ $t('interest.industryInterest') }}:
                        </strong><br/>
                        <div v-if="item.relationships.industry_sectors.length < 20">
                          <span v-for="industrySector in item.relationships.industry_sectors" :key="industrySector.id">
                            {{ industrySector.translations[$i18n.locale].name }}<br/>
                          </span>
                        </div>
                        <div v-else class="row mx-0 mt-2 mb-4">
                          <span class="col-6 py-0 px-0 my-0 mx-0" v-for="industrySector in item.relationships.industry_sectors" :key="industrySector.id">
                            {{ industrySector.translations[$i18n.locale].name }}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="item.relationships.trade_fairs">
                      <td class="align-start">
                        <small>
                          {{ $t('interest.bookmarkedTradeFairs') }}:
                        </small>
                        <br/>
                        <span
                            v-for="tradeFair in item.relationships.trade_fairs"
                            :key="tradeFair.id"
                        >
                            {{ tradeFair.attributes.name_alternative ? tradeFair.attributes.name_alternative : tradeFair.attributes.name }}
                            <br/>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="item.relationships.submitted_participations">
                      <td class="align-start">
                        <small>
                          {{ $t('interest.participations') }}:
                        </small>
                        <br/>
                        <span
                            v-for="participation in item.relationships.submitted_participations"
                            :key="participation.id"
                        >
                          {{
                            participation.trade_fair.name_alternative ? participation.trade_fair.name_alternative : participation.trade_fair.name
                          }}                            
                            <br/>
                          </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import moment from "moment/moment";

export default {
  name: 'OrganizerInterest',

  data() {
    return {
      companies: [],
      options: {},

      form: {
        total: 0,
        loading: false,
      },

      previous: {},

      filters: {},
    }
  },

  computed: {
    moment() {
      return moment
    },
    ...mapGetters('organizer', ['currentTradeFair']),

    headers() {
      return [
        {
          text: '',
          value: 'data-table-expand',
        },
        {
          text: this.$t('interest.company'),
          value: 'company',
          sortable: false,
        },
        {
          text: this.$t('interest.location'),
          value: 'city',
          sortable: false,
        },
        {
          text: this.$t('interest.accredited'),
          value: 'accreditation_approved_at',
          sortable: false,
        },
        {
          text: this.$t('interest.email'),
          value: 'email',
          sortable: false,
        },
        {
          text: this.$t('interest.website'),
          value: 'website',
          sortable: false,
        },
        {
          text: this.$t('interest.reservedOn'),
          value: 'reserved_on',
          sortable: false,
          dataType: 'Date',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ]
    },
  },

  async created() {
    await this.fetchCurrentTradeFair()

    this.load()
  },

  methods: {
    ...mapActions('organizer', [
      'fetchCurrentTradeFair',
      'downloadTradeFairInterestedCompanies',
    ]),

    async handleImport() {
      this.form.loading = true

      let response = (await this.$axios.post(`/api/trade-fairs/${this.currentTradeFair.id}/import-interested-companies`, {})).data.data

      this.$snackbar(this.$t('interest.importMessage', { count: response.count }))
      this.load()
    },



    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get(`/api/trade-fairs/${this.currentTradeFair.id}/interested-companies`, {
        params: {
          'languages': '*',
          'include': [
            'users',
            'tradeFairs',
            'industrySectors',
            'countries',
            'accreditation',
            'accreditationIgnoreApprove',
            'submittedParticipations',
          ],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      this.companies = response.data
      this.companies = this.companies.map((el) => {
        el.relationships.accreditation = el.relationships.accreditation ?? el.relationships.accreditationIgnoreApprove

        return el
      })

      this.previous = (await this.$axios.get(`/api/trade-fairs/${this.currentTradeFair.id}/previous`)).data.data
      this.fetchCurrentTradeFair()

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },

    async download() {
      await this.downloadTradeFairInterestedCompanies({
        id: this.currentTradeFair.id,
        name: this.currentTradeFair.nameDisplay + ' interested companies'
      })
    }
  },

  watch: {
    'options'() {
      this.load()
    },

    'filters': {
      handler() {
        this.options.page = 1

        this.load()
      },
      deep: true
    },
  },
}
</script>
