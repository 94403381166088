<template>
  <company-form
    :is-readonly="isProjectManager"
    v-slot="scope"
  >
    <v-col
      v-if="!isProjectManager"
      cols="12"
    >
      <v-btn
        @click="scope.submit"
        color="secondary"
        class="elevation-0"
        :loading="$wait.is(loaders.updateOrganizer)"
      >
        {{ $t('common.save') }}
      </v-btn>
    </v-col>
  </company-form>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
import CompanyForm from '@/components/organizer/Company/CompanyForm'
import { mapWaitingActions } from 'vue-wait'
import { UPDATE_ORGANIZER } from '@/store/loading-types'

export default {
  name: 'CompanySettings',

  data() {
    return {
      loaders: {
        updateOrganizer: UPDATE_ORGANIZER,
      },
    }
  },

  components: {
    CompanyForm,
  },

  methods: {
    ...mapActions('organizer', [
      'updateOrganizer',
    ]),

    ...mapWaitingActions('organizer', {
      updateOrganizer: UPDATE_ORGANIZER,
    }),
  },

  computed: {
    ...mapGetters('auth', [
      'isProjectManager',
    ]),
  },
}
</script>
