<template>
  <v-dialog
    v-if="article.id"
    v-model="isOverlayShown"
    persistent
    max-width="900px"
    @click:outside="closeOverlay"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ article.translations[$i18n.locale].headline }}
        </h4>
        <v-icon size="17" @click="closeOverlay">fal fa-times</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div
          v-html="article.translations[$i18n.locale].abstract"
          class="mt-5 mb-0 abstract pa-5 grey lighten-3"
        ></div>
        <div
          class="mt-5"
          v-html="article.translations[$i18n.locale].text"
        ></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" @click="closeOverlay">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import eventBus from '@/eventBus'

export default {
  name: 'HelpOverlay',

  data() {
    return {
      isOverlayShown: false,
    }
  },

  computed: {
    ...mapGetters('help', ['article']),
  },

  created() {
    eventBus.$on('help-overlay', () => {
      this.showOverlay()
    })
  },

  methods: {
    showOverlay() {
      this.isOverlayShown = true
    },

    closeOverlay() {
      this.isOverlayShown = false
    },
  },
}
</script>
