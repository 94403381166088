<template>
  <div>
    <v-toolbar flat>
      <v-btn icon @click="hasHistory() ? $router.go(-1) : $router.push('/')">
        <v-icon>fal fa-angle-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <h5>{{ $t('notification.notifications') }}</h5>
      </v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <notifications></notifications>
  </div>
</template>

<script>
import Notifications from '@/components/common/Notification/Notifications'

export default {
  name: 'OrganizerNotifications',

  components: {
    Notifications
  },

  methods: {
    hasHistory() {
      return window.history.length > 2
    },
  }
}
</script>
