<template>
    <v-icon :size="iconSize" @click="setCurrentArticle(helpEntryId), showHelpOverlay()" class="primary--text ma-0 pa-0">fal fa-question-circle</v-icon>
</template>

<script>
import { mapActions} from 'vuex'
import eventBus from '@/eventBus'

export default {
  name: 'HelpButton',
  props: {
    helpEntryId: {
        type: Number,
        default: null
    },
    iconSize: {
      type: Number,
      default: 18
    }
  },
  components: {

  },
  methods: {
    ...mapActions('help', [
        'setCurrentArticle'
      ]
    ),
    showHelpOverlay () {
      eventBus.$emit('help-overlay')
    }
  },
}
</script>
