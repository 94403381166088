<template>
  <v-card class="elevation-1 rounded-0 mb-5">
    <v-card-title class="px-5 py-5">
      <h4 class="primary--text">{{ $t('participation.openOnlineRegistration') }}</h4>
      <v-chip
        class="ml-auto"
        small
      >
        {{ $t('participation.statuses.inPreparation') }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-stepper
        class="elevation-0"
        v-model="currentStep"
      >
        <v-stepper-header class="elevation-0">
          <v-stepper-step step="1" :editable="isEditableStep(1)">
            <h4>{{ $t('participation.editSteps.projectManagement.title') }}</h4>
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="2" :editable="isEditableStep(2)">
            <h4>{{ $t('participation.editSteps.configurationRegistration.title') }}</h4>
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="3" :editable="isEditableStep(3)">
            <h4>{{ $t('participation.editSteps.deadline.title') }}</h4>
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="4" :editable="isEditableStep(4)">
            <h4>{{ $t('participation.editSteps.approvalAndPublication.title') }}</h4>
          </v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            step="1"
            class="text--primary"
          >
            <contact-person-picker
              :manager="projectManager"
              @submit="handleSubmit(1)"
            />
          </v-stepper-content>

          <v-stepper-content
            step="2"
            class="text--primary"
          >
            <registration-configure-form
                :fair="fair"
                @template="handleAcceptTemplate"
                @submit="handleSubmit(2)"
            />
          </v-stepper-content>

          <v-stepper-content
            step="3"
            class="text--primary"
          >
            <registration-deadline
              :fair="fair"
              @submit="handleSubmit(3)"
            />
          </v-stepper-content>

          <v-stepper-content
            step="4"
            class="text--primary"
          >
            <open-registration
                @update="$emit('update')"
                :fair="fair"
                @submit="handleSubmit(4)"
            />
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import ContactPersonPicker from '@/components/organizer/Application/ContactPersonPicker'
import RegistrationDeadline from '@/components/organizer/Application/RegistrationDeadline'
import RegistrationConfigureForm from '@/components/organizer/Application/RegistrationConfigureForm'
import OpenRegistration from '@/components/organizer/Application/OpenRegistration'

export default {
  name: 'EditInactiveRegistration',

  components: {
    ContactPersonPicker,
    RegistrationDeadline,
    RegistrationConfigureForm,
    OpenRegistration,
  },

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentStep: 1,
    }
  },

  computed: {
    projectManager() {
      return (this.fair.relationships.access_users && this.fair.relationships.access_users.length) ? this.fair.relationships.access_users[this.fair.relationships.access_users.length - 1] : null
    },
  },

  methods: {
    isEditableStep(step) {
      return this.currentStep >= step;
    },

    handleAcceptTemplate() {
      this.$emit('update')
      this.$emit('customized', false)

      this.$nextTick(() => {
        this.$emit('customized', this.currentStep == 2)
      })
    },

    handleSubmit(step) {
      this.currentStep = step + 1;

      this.$emit('customized', this.currentStep == 2)
    }
  },

  watch: {
    currentStep() {
      this.$emit('customized', this.currentStep == 2)
    }
  }
}
</script>
