<template>
  <v-app-bar
    class="elevation-1"
    app
    clipped-left
    elevation="1"
    fixed
    color="white"
    height="80"
  >

    <v-img
      src="/img/logos/Made-in-Germany.svg"
      max-height="60"
      max-width="80"
      class="mx-6"
      contain
    />

    <v-toolbar-title class="align-center pl-10">
      <h4 class="primary--text">{{ $t('header.federalForeignTradeFairProgram') }}</h4>
      <h6>{{ $t('header.eventOverviewAndExhibitorRegistration') }}</h6>
    </v-toolbar-title>

    <v-spacer />

    <language-picker />

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :to="{ name: 'exhibitor.helps' }"
          class="primary--text"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="20">far fa-fw fa-question-circle</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('header.helpAdviceAndSupport') }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          :to="{ name: 'exhibitor.participation.overview' }"
          v-bind="attrs"
          v-on="on"
          class="ml-5"
        >
          <v-icon
            size="20"
            class="primary--text"
          >
            fa-star
          </v-icon>
          <v-badge
            v-if="notedTradeFairs.length"
            :content="notedTradeFairs.length"
            color="secondary"
          />
        </v-btn>
      </template>
      <span>{{ $t('header.yourBookmarkedTradeFairs') }}</span>
    </v-tooltip>

    <notification-menu notification_link="exhibitor.notification" notifications_link="exhibitor.notifications"></notification-menu>

    <documents-menu class="ml-5" />

    <v-divider
      vertical
      class="ml-10 mr-5"
    />

    <v-menu open-on-click>
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-on="on"
          class="text--primary"
        >
          <span class="font-weight-regular text--primary" style="text-transform: none;">{{ company.company }}</span>
          <v-icon
            right
            size="22"
            class="ml-4 primary--text"
          >
            fas fa-fw fa-user-circle
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item :to="{ name: 'exhibitor.settings' }">
          <v-list-item-title>{{ $t('header.changePassword') }}</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'exhibitor.settings' }">
          <v-list-item-title>{{ $t('header.dataAndSettings') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleLogout">
          <v-list-item-title>{{ $t('header.logOut') }}</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import LanguagePicker from '@/components/base/LanguagePicker'
import DocumentsMenu from '@/components/common/Documents/Menu'
import { FETCH_COMPANY } from '@/store/loading-types'
import NotificationMenu from "@/components/common/Notification/NotificationMenu.vue";

export default {
  name: 'ExhibitorHeader',

  components: {
    NotificationMenu,
    LanguagePicker,
    DocumentsMenu,
  },

  computed: {
    ...mapGetters('participation', [
      'notedTradeFairs',
    ]),

    ...mapGetters('company', [
      'company',
    ]),
  },

  created() {
    this.fetchCompany()
  },

  methods: {
    ...mapActions('auth', [
      'logOut',
    ]),

    ...mapActions('company', [
      'fetchCompany',
    ]),

    ...mapWaitingActions('company', {
      fetchCompany: FETCH_COMPANY,
    }),

    async handleLogout() {
      await this.logOut()
      window.location.href = await this.$router.resolve({ name: 'login' }).href
    },
  },

  beforeDestroy(){
    localStorage.removeItem('vuex');
  }
}
</script>
