<template>
  <v-card-text>
    <v-row align-content="end">

      <v-col>
        <v-switch
            :label="$t('tradeFair.filters.futureEventsOnly')"
            v-model="filters.future"
            :disabled="loading"
        />
      </v-col>

      <v-col>
        <v-autocomplete
            :label="$t('tradeFair.filters.name')"
            v-model="filters.names"
            :items="fairs"
            item-text="attributes.name_display"
            item-value="id"
            :loading="loading"
            multiple
            clearable
        />
      </v-col>

      <v-col>
        <v-select
            :label="$t('tradeFair.filters.year')"
            v-model="filters.years"
            :items="years"
            :loading="loading"
            multiple
            clearable
        />
      </v-col>

      <v-col>
        <v-select
            :label="$t('tradeFair.filters.program')"
            v-model="filters.sponsors"
            :items="sponsors"
            :loading="loading"
            multiple
            clearable
        />
      </v-col>

    </v-row>
  </v-card-text>
</template>

<script>
import {SPONSORS} from '@/enums/sponsors'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'FairSearch',

  data() {
    return {
      loading: false,

      filters: {
        names: [],
        years: [],
        sponsors: [],
        future: true,
      },

      fairs: [],
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user',
      'preferences',
    ]),

    sponsors() {
      return [
        {text: this.$t('forms.sponsors.bmwi'), value: SPONSORS.BMWI},
        {text: this.$t('forms.sponsors.bmel'), value: SPONSORS.BMEL},
      ]
    },

    years() {
      let years = {}

      this.fairs.map(function (item) {
        years[item.attributes.year] = item.attributes.year
      })

      return Object.values(years)
    },
  },

  async created() {
    this.load()

    if (this.preferences.supervisor_fair_filters) {
      this.filters = this.preferences.supervisor_fair_filters
    } else {
      this.$emit('input', this.filters)
    }
  },

  methods: {
    ...mapActions('auth', [
      'fetchUserPreferences',
      'setUserPreferences',
    ]),

    async load() {
      let page = 1
      let response = null

      do {
        response = (await this.$axios.get('/api/trade-fairs', {
          params: {
            'languages': '*',
            'include': [],
            'page[number]': page,
            'page[size]': 500,
            'fields': [
              'name',
              'name_alternative',
              'start_date',
            ],
          },
        })).data

        page += 1
        this.fairs = this.fairs.concat(response.data)
      } while (response.data.length)
    },
  },

  watch: {
    'filters': {
      handler(value) {
        this.setUserPreferences({
          key: 'supervisor_fair_filters',
          value: value,
          user_id: this.user.id
        })

        this.$emit('input', value)
      },
      deep: true
    },
  }
}
</script>
