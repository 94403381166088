export default {
  auth: {
    username: 'Username',
    password: 'Password',
    passwordConfirmation: 'Password confirmation',
    email: 'E-mail address',
    emailUsername: 'E-mail address (username)',
    company: 'Company',
    logIn: 'Log in',
    register: 'Register',
    forgotPassword: 'Forgot password?',
    passwordResetLinkSent: 'A password reset link was sent to your email',
    termsOfUse: 'Terms of use',
    termsOfUseAdoption: 'I have read the terms of use and the data protection declaration and I agree to them',
    confirmEmailAddress: 'Confirm email address',
    resendEmailAddress: 'Resend verification email',
    successfullyConfirmingYourEmail: 'Thank you for successfully confirming your email address.',
    emailAddressCouldNotBeConfirmed: 'Your email address is not confirmed. Please check your inbox and verify your email address or contact our support team at',
    userAccountDeleted: 'Your account has been deleted. You can reactivate it yourself through email verification or contact our support team via <a href="mailto:support@german-pavilion.com">support@german-pavilion.com</a>. for further help on this issue',
  },
}
