<template>
  <div v-if="isCorrectDisplay">
    <router-view />
    <help-overlay />
  </div>
  <incorrect-display v-else></incorrect-display>
</template>

<script>
import HelpOverlay from '@/components/base/HelpOverlay'
import IncorrectDisplay from "@/views/IncorrectDisplay";
import {mapActions} from "vuex";

export default {
  name: 'App',

  data: () => ({
    isCorrectDisplay: false
  }),

  components: {
    HelpOverlay,
    IncorrectDisplay,
  },

  mounted() {
    this.onResize()

    window.addEventListener('resize', this.onResize, {passive: true})

    window.addEventListener('storage', this.onUpdatedVuex);
  },

  methods: {
    ...mapActions('auth', [
      'fetchUser',
    ]),

    onUpdatedVuex(event) {
      if (event.key === 'verified') {
          this.fetchUser()
      }
    },

    onResize() {
      if (this.$route.meta.ignoreSize) {
        this.isCorrectDisplay = true
      } else {
        this.isCorrectDisplay = window.innerWidth >= 1024
      }
    }
  },
}
</script>

<style lang="scss">
@import 'scss/app-custom.scss';
</style>
