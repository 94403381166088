import { mapActions } from 'vuex'
import { NOTIFICATION_TYPES } from '@/enums/notificationType'

export default {
  methods: {
    ...mapActions('snackbar', [
      'setSnackbar',
    ]),

    showNotification(message, type) {
      this.setSnackbar({
        message,
        type,
      })
    },

    showSuccessNotification(message) {
      this.setSnackbar({
        message,
        type: NOTIFICATION_TYPES.SUCCESS,
      })
    },

    showWarningNotification(message) {
      this.setSnackbar({
        message,
        type: NOTIFICATION_TYPES.WARNING,
      })
    },

    showErrorNotification(message) {
      this.setSnackbar({
        message,
        type: NOTIFICATION_TYPES.ERROR,
      })
    },
  },
}
