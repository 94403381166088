export default {
  dashboard: {
    myTradeFairParticipations: 'My current trade fair participations',
    tradeFairParticipationsOverview: 'Trade fair participations overview',
    theseTradeFairParticipationsCouldBeInterestingForYou: 'These trade fair participations could be interesting for you',
    findMoreTradeFairs: 'Find more trade fairs',
    projectOverview: 'Project overview',
    tradeFair: 'Trade fair',
    team: 'Team',
    registrationPhaseIsRunning: 'Registration is running',
    registrationDeadline: 'Registration deadline',
    totalRegistrations: 'Total registrations',
    registrationUnconfirmed: 'Registration unconfirmed',
    interestedPersons: 'Interested persons',
    defineTeam: 'Define team',
    toTheProject: 'To the project',
    showPastEvents: 'Show past events',
    supervisor: {
      title: 'Online exhibitor registration',
      description: {
        welcome: 'Welcome to the registration platform for the federal trade fair programs abroad. Here you will find an overview of the events assigned to you.',
        questions: 'If you have any questions about using this platform, please contact the support team at',
      },
      actions: {
        overviewParticipations: 'Overview of the trade fair participations assigned to you',
        getLinks: 'Get direct links to the online registration of an event',
        viewStatus: 'View registration status and interested parties',
      },
      exhibitionOverview: 'Exhibition overview',
    },
  },
};
