<template>
  <v-expansion-panel class="rounded-0">
    <v-expansion-panel-header>
      <h4>{{ $t('participation.servicePackage.title') }}</h4>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-sheet class="mb-5 pa-4" v-for="(item) in packages" :key="item.id" outlined>
        <v-row>

          <v-col cols="10">
            <p>
              <strong v-html="item.attributes.title[$i18n.locale]"></strong>
            </p>
          </v-col>

          <v-col cols="2" class="text-right">
            <v-btn color="secondary" @click="edit(item)" icon>
              <v-icon size="18">fal fa-pencil</v-icon>
            </v-btn>
            <v-btn color="secondary" @click="handleDelete(item)" icon>
              <v-icon size="18">fal fa-trash-alt</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="4">
            <small>{{ $t('participation.configureRegistrationForm.servicePackage.title_de') }}</small><br/>
            <p v-if="item.attributes.title.de" v-html="item.attributes.title.de"></p>
            <p v-else>-</p>

            <small>{{ $t('participation.configureRegistrationForm.servicePackage.services_de') }}</small><br/>
            <div v-html="item.attributes.services.de"></div>
          </v-col>
          <v-col cols="4">
            <small>{{ $t('participation.configureRegistrationForm.servicePackage.title_en') }}</small><br/>
            <p v-if="item.attributes.title.en" v-html="item.attributes.title.en"></p>
            <p v-else>-</p>

            <small>{{ $t('participation.configureRegistrationForm.servicePackage.services_en') }}</small><br/>
            <div v-html="item.attributes.services.en"></div>
          </v-col>
          <v-col cols="4">
            <small>{{ $t('participation.configureRegistrationForm.price') }}</small><br/>
            <table class="prices">
              <tr>
                <td>{{ $t('participation.configureRegistrationForm.partialPayer') }}</td>
                <td>
                  <span v-if="item.attributes.price_sponsored">
                    {{item.attributes.price_sponsored | money}}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('participation.configureRegistrationForm.fullPayer') }}</td>
                <td>
                  <span v-if="item.attributes.price_unsponsored">
                    {{item.attributes.price_unsponsored | money}}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('participation.configureRegistrationForm.publicHand') }}</td>
                <td>
                  <span v-if="item.attributes.price_publicsector">
                    {{item.attributes.price_publicsector | money}}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
              <tr class="mt-6">
                <td><br>{{ $t('participation.configureRegistrationForm.allowSubexhibitorCheckbox') }}</td>
                <td><br>{{ item.attributes.allow_subexhibitor ? $t('common.yes') : $t('common.no') }}</td>
              </tr>
            </table>
          </v-col>

        </v-row>
      </v-sheet>
      <v-row class="mx-0 my-2">
        <v-btn color="secondary" @click="add" text outlined>
          <v-icon size="16" class="mr-2">fal fa-plus</v-icon>
          <td>{{ $t('participation.configureRegistrationForm.addPackage') }}</td>
        </v-btn>
      </v-row>
      <v-btn color="secondary" class="mt-5 px-10" elevation="0" @click="save">
        {{ $t('forms.buttons.continue') }}
      </v-btn>

    </v-expansion-panel-content>
    <customize-registration-packages-edit ref="edit" :fair="fair" @submit="load"></customize-registration-packages-edit>
  </v-expansion-panel>
</template>

<script>
import CustomizeRegistrationPackagesEdit from '@/components/organizer/Application/Customize/CustomizeRegistrationPackagesEdit';

export default {
  name: 'CustomizeRegistrationPackages',

  components: {
    CustomizeRegistrationPackagesEdit
  },

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      packages: [],
      
      form: {
        loading: false,
      },
    }
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/trade-fairs/${this.fair.id}/packages`, {
        params: {
          'languages': '*',
          'include': [],
        },
      })).data

      this.packages = response.data

      this.form.loading = false
    },

    async handleDelete(item) {
      if(confirm(this.$t('participation.configureRegistrationForm.servicePackage.alertDeletePackage'))) {
        await this.$axios.delete(`/api/trade-fairs/${this.fair.id}/packages/${item.id}`, {})

        this.$snackbar(this.$t('common.successApiMessage'))

        this.load()
      }
    },

    edit(item) {
      this.$refs.edit.show(item)
    },

    add() {
      this.$refs.edit.show()
    },

    save() {
      this.$emit('submit')
    }
  },
}
</script>

<style scoped>
  table.prices tr td { padding: .1rem 1rem .1rem 0; vertical-align: top; border-top: 1px solid #E9E9E9; }
</style>
