<template>
  <v-app style="background-color: #f9f9f9">
    <public-header />
    <v-main>
      <v-container class="py-10">
        <v-sheet class="pa-5 pa-md-10 elevation-1">
          <v-btn
            class="float-right"
            color="secondary"
            elevation="0"
            icon
            @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          >
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>
          <template v-if="$i18n.locale == 'de'">
            <h2 class="mb-5">
              Datenschutzerklärung Online-Ausstelleranmeldung
            </h2>
            <p><strong>1. Einleitung</strong></p>
            <p>
              Für das Bundesministerium für Wirtschaft und Klimaschutz (BMWK)
              hat ein verantwortungsbewusster Umgang mit personenbezogenen Daten
              hohe Priorität. Wir möchten, dass Nutzerinnen und Nutzer wissen,
              wann welche Daten durch das BMWK erhoben und verwendet werden.
            </p>
            <p>
              Personenbezogene Daten werden von uns nur im notwendigen Umfang
              verarbeitet. Welche Daten zu welchem Zweck und auf welcher
              Grundlage benötigt und verarbeitet werden, richtet sich maßgeblich
              nach der Art der Leistung, die von Ihnen in Anspruch genommen
              wird, beziehungsweise ist abhängig davon, für welchen Zweck diese
              benötigt werden.<br />
              Wir haben technische und organisatorische Maßnahmen getroffen, die
              sicherstellen, dass die Vorschriften über den Datenschutz sowohl
              von uns als auch von unseren externen Dienstleistern beachtet
              werden.
            </p>
            <p>
              Die Verarbeitung personenbezogener Daten im BMWK erfolgt in
              Übereinstimmung mit der&nbsp;<a
                href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1527147390147&from=EN"
                target="_blank"
                title="Öffnet ein neues Fenster."
                >Europäischen Datenschutz-Grundverordnung (DSGVO)</a
              >&nbsp;und dem&nbsp;<a
                href="http://www.gesetze-im-internet.de/bdsg_2018/"
                target="_blank"
                title="Öffnet ein neues Fenster."
                >Bundesdatenschutzgesetz (BDSG)</a
              >.&nbsp;
            </p>
            <p>
              Diese Datenschutzerklärung informiert Sie über Art und Umfang der
              Verarbeitung personenbezogener Daten bei der Nutzung des
              Internetangebotes registration.german-pavilion.com für die
              Ausstelleranmeldung des Auslandsmesseprogramms des BMWK.
            </p>
            <p><strong>Grundlagen</strong></p>
            <p><strong>Verantwortlicher und Datenschutzbeauftragter</strong></p>
            <p>
              Verantwortlich für die Verarbeitung von personenbezogenen Daten
              ist das<br />
              Bundesministerium für Wirtschaft und Klimaschutz<br />
              Scharnhorststr. 34-37<br />
              10115 Berlin<br />
              Postanschrift: 11019 Berlin<br />
              E-Mail:&nbsp;<a
                href="mailto:info@bmwk.bund.de"
                target="_blank"
                title="Öffnet ein neues Fenster."
                >info@bmwk.bund.de</a
              ><br />
              Internet:&nbsp;<a
                href="https://www.bmwk.de/Navigation/DE/Home/home.html"
                target="_blank"
                title="Öffnet ein neues Fenster."
                >www.bmwk.de</a
              ><br />
              Telefon: +49 (0)30 18615 0
            </p>
            <p>
              Bei konkreten Fragen zum Schutz Ihrer Daten im BMWK wenden Sie
              sich bitte an die Datenschutzbeauftragte im BMWK:<br />
              Beauftragte für den Datenschutz im BMWK<br />
              Scharnhorststr. 34-37<br />
              10115 Berlin<br />
              E-Mail:&nbsp;<a
                href="mailto:datenschutzbeauftragte@bmwk.bund.de"
                target="_blank"
                title="Öffnet ein neues Fenster."
                >datenschutzbeauftragte@bmwk.bund.de</a
              >
            </p>
            <p><strong>1.2 Personenbezogene Daten</strong></p>
            <p>
              Personenbezogene Daten sind alle Informationen, die sich auf eine
              identifizierte oder identifizierbare natürliche Person beziehen.
              Als identifizierbar wird eine natürliche Person angesehen, die
              direkt oder indirekt – insbesondere mittels Zuordnung zu einer
              Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
              einer Online-Kennung – identifiziert werden kann.
            </p>
            <p><strong>1.3 Minderjährigenschutz</strong></p>
            <p>
              Personen unter 16 Jahren sollten ohne Zustimmung der Eltern oder
              Erziehungsberechtigten keine personenbezogenen Daten an uns
              übermitteln.&nbsp; Sollten dennoch Personen unter 16 Jahren
              Kontakt mit dem BMWK per E-Mail, Kontaktformular, Brief o.ä.
              aufnehmen, gelten die Punkte 3.1, 3.3 bis 3.5.
            </p>
            <p>
              <strong
                >1.4 Rechtsgrundlage für die Verarbeitung personenbezogener
                Daten</strong
              >
            </p>
            <p>
              Das BMWK verarbeitet bei der Wahrnehmung der ihm obliegenden im
              öffentlichen Interesse liegenden Aufgaben personenbezogene Daten.
              Zu den öffentlichen Aufgaben des BMWK gehört insbesondere auch die
              Öffentlichkeitsarbeit und dabei unter anderem die Bereitstellung
              von Informationen für die Öffentlichkeit im Rahmen des
              vorliegenden Internetauftritts. Rechtsgrundlage der Verarbeitung
              ist hier Art. 6 Abs. 1 lit. e DSGVO in Verbindung mit der
              entsprechenden innerstaatlichen oder europäischen Aufgabennorm
              beziehungsweise in Verbindung mit § 3 BDSG. Soweit eine
              Verarbeitung personenbezogener Daten im Einzelfall zur Erfüllung
              einer rechtlichen Verpflichtung erforderlich sein sollte, dient
              zudem Art. 6 Abs. 1 lit. c DSGVO in Verbindung mit der
              entsprechenden Rechtsvorschrift, aus der sich die rechtliche
              Verpflichtung ergibt, als Rechtsgrundlage.
            </p>
            <p>
              Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
              Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1
              lit. a DSGVO als Rechtsgrundlage. Bei der Verarbeitung von
              personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
              Vertragspartei die betroffene Person ist, erforderlich ist, dient
              im Einzelfall auch Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage.
              Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung
              vorvertraglicher Maßnahmen erforderlich sind.
            </p>
            <p>
              Für den Fall, dass lebenswichtige Interessen der betroffenen
              Person oder einer anderen natürlichen Person eine Verarbeitung
              personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
              lit. d DSGVO als Rechtsgrundlage.
            </p>
            <p>
              <strong
                >2. Datenverarbeitung bei Besuch und Nutzung der
                Online-Ausstelleranmeldung</strong
              >
            </p>
            <p><strong>2.1 Server-Protokolldateien</strong></p>
            <p>
              Bei jedem Zugriff eines Nutzers auf unser Internet-Angebot und bei
              jedem Abruf einer Datei werden Daten über diesen Vorgang
              vorübergehend in einer Protokolldatei verarbeitet.<br />
              Im Einzelnen werden über jeden Zugriff/Abruf folgende Daten
              gespeichert:
            </p>
            <ul>
              <li>
                Datum und Uhrzeit des Abrufs (Zeitstempel), sowie die IP-Adresse
                des zugreifenden Geräts beziehungsweise Servers
              </li>
              <li>
                Anfragedetails und Zieladresse (Protokollversion, HTTP-Methode,
                Referer, UserAgent-String),
              </li>
              <li>
                Name der abgerufenen Datei und übertragene Datenmenge
                (angefragte URL inklusive Query-String, Größe in Byte) sowie
              </li>
              <li>Meldung, ob der Abruf erfolgreich war (HTTP Status Code).</li>
            </ul>
            <br />
            <p>
              Wir sind auf Grundlage von Artikel 6 Abs. 1 lit. e DSGVO in
              Verbindung mit § 5 BSI-Gesetz zur Speicherung der Daten zum Schutz
              vor Angriffen auf die Internetinfrastruktur des BMWK und der
              Kommunikationstechnik des Bundes über den Zeitpunkt Ihres Besuches
              hinaus verpflichtet. Diese Daten werden analysiert und im Falle
              von Angriffen auf die Kommunikationstechnik zur Einleitung einer
              Rechts- und Strafverfolgung benötigt. Die Daten werden gelöscht,
              sobald sie nicht mehr zur Aufgabenerfüllung benötigt werden.<br />
              Daten, die beim Zugriff auf das Internetangebot des BMWK
              protokolliert wurden, werden an Dritte nur übermittelt, soweit wir
              rechtlich dazu verpflichtet sind oder die Weitergabe im Falle von
              Angriffen auf die Kommunikationstechnik des Bundes zur Rechts-
              oder Strafverfolgung erforderlich ist. Eine Weitergabe in anderen
              Fällen erfolgt nicht. Eine Zusammenführung dieser Daten mit
              anderen Datenquellen erfolgt durch das BMWK nicht.&nbsp;
            </p>
            <p><strong>2.2 Session Cookies</strong></p>
            <p>
              Auf unseren Seiten kommen Cookies zum Einsatz, welche für die Zeit
              des Besuchs auf der Website gültig sind. Dies erfolgt auf
              Grundlage des Art. 6 Abs. 1 lit. e DSGVO in Verbindung mit § 3
              BDSG im Rahmen der Öffentlichkeitsarbeit zur bedarfsorientierten
              Bereitstellung von Informationen zu den dem BMWK übertragenen
              Aufgaben. Im Zusammenhang mit der Nutzung der
              Online-Ausstelleranmeldung werden Session-Cookies verwendet, um
              den Login in die Online-Ausstelleranmeldung zu ermöglichen.
            </p>
            <p>
              Session-Cookies sind kleine Informationseinheiten, die ein
              Anbieter im Arbeitsspeicher des Computers des Besuchers speichert.
              In einem Session-Cookie wird eine zufällig erzeugte eindeutige
              Identifikationsnummer abgelegt, eine sogenannte Session-ID.
              Außerdem enthält ein Cookie die Angabe über seine Herkunft und die
              Speicherfrist. Diese Cookies können keine anderen Daten
              speichern.&nbsp;
            </p>
            <p>
              Die eingesetzten Session-Cookies werden gelöscht, wenn Sie die
              Sitzung beenden.&nbsp;<br />
              Sie können sich mit jedem Internetbrowser anzeigen lassen, wenn
              Cookies gesetzt werden und was sie enthalten. Detaillierte
              Informationen bietet der Internetauftritt des Bundesbeauftragten
              für den Datenschutz und die Informationsfreiheit und des
              Bundesamtes für Sicherheit in der Informationstechnik an.
            </p>
            <p>
              Die meisten Browser sind so eingestellt, dass sie Cookies
              automatisch akzeptieren. Das Speichern von Cookies kann jedoch
              deaktiviert oder der Browser so eingestellt werden, dass Cookies
              nur für die Dauer der jeweiligen Verbindung zum Internet
              gespeichert werden.
            </p>
            <p><strong>2.3. Registrierung</strong></p>
            <p>
              Nutzer können sich registrieren und einen unternehmensbezogenen
              Nutzeraccount erstellen. Hierzu ist nur die Angabe der Firma,
              einer E-Mail-Adresse und die Festlegung eines Passwortes
              erforderlich.
            </p>
            <p><strong>2.4 Akkreditierung als Aussteller</strong></p>
            <p>
              Um sich für eine Messebeteiligung im Auslandsmesseprogramm
              anmelden zu können, ist eine einmalige Akkreditierung als
              Unternehmen erforderlich, bei der zum Teil auch die Angabe von
              personenbezogenen Daten erforderlich sein kann. Die eingehenden
              Akkreditierungen werden durch das BMWK bzw. den beauftragten
              Dienstleister (leapfrog gmbh/wohlgemuth+team gmbh) gesichtet und
              geprüft. Die Akkreditierungsdaten werden bei der Anmeldung zu
              einer Messeteilnahme verwendet und weitergegeben (siehe Ziffer
              2.6). Folgende personenbezogene Daten werden bei der
              Akkreditierung erhoben:
            </p>
            <ul>
              <li>Firma</li>
              <li>Adressdaten</li>
              <li>Telefon</li>
              <li>Fax</li>
              <li>E-Mail</li>
              <li>Website</li>
              <li>HR-Auszug</li>
              <li>HR-Nummer</li>
              <li>Steuernummer</li>
              <li>USt-ID</li>
            </ul>
            <br />
            <p>
              <strong>2.5. Interessensbekundung an einer Messeteilnahme</strong>
            </p>
            <p>
              Aussteller können Ihr Interesse an einer Messebeteiligung hinterlegen (&bdquo;Messe vormerken&ldquo;). Dabei können auch Daten des Ansprechpartners im Unternehmen angegeben werden. Die mit der Umsetzung der Messebeteiligung beauftragte Durchführungsgesellschaft (DFG) erhält Zugriff auf die grundlegenden Unternehmensdaten sowie ggf. die Daten von Ansprechpartnern und ist berechtigt, das Unternehmen zu kontaktieren. Im Falle einer Messebeteiligung für die Nachfolgeveranstaltung einer Auslandsmesse erhält die damit beauftragte DFG Zugriff auf die Daten der Interessenten für die vorherige Messeveranstaltung und ist berechtigt, diese Unternehmen zu kontaktieren, um für die Messeteilnahme zu werben.
            </p>
            <p><strong>2.6. Anmeldung zu einer Messeteilnahme</strong></p>
            <p>
              Bei der Anmeldung zu einer Messebeteiligung werden folgende Daten
              erhoben, verarbeitet und übermittelt:<br />
            </p>
            <ul>
              <li>
                Unternehmensdaten aus der Akkreditierung (siehe Ziffer 2.4)
              </li>
              <li>
                Kontaktdaten des Ansprechpartners für diese Messebeteiligung im
                Unternehmen (Name, E-Mail, Telefon)
              </li>
              <li>ggf. Daten des Unterausstellers</li>
              <li>ggf. abweichende Daten zur Rechnungsstellung</li>
            </ul>
            <br />
            <p>Auf diese Daten erhalten Zugriff:<br /></p>
            <ul>
              <li>
                die mit der Umsetzung der Messebeteiligung beauftragte
                Durchführungsgesellschaft (DFG) zum Zweck der
                Auftragsdurchführung
              </li>
              <li>
                die Dienstleister leapfrog/w+t zum Betrieb und Nutzersupport
                (Bearbeitung telefonischer Anfragen oder Anfragen per E-Mail
                über
                <a href="mailto:support@german-pavilion.com"
                  >support@german-pavilion.com</a
                >) der Anmeldeplattform sowie zur Einsicht der an den Messen
                beteiligten Unternehmen
              </li>
              <li>
                das Bundesministerium für Wirtschaft und Klimaschutz (BMWK) bzw.
                das Bundesministerium für Ernährung und Landwirtschaft (BMEL)
                als Veranstalter der Messebeteiligung
              </li>
              <li>
                das Bundesamt für Wirtschaft und Ausfuhrkontrolle (BAFA) zu
                Auswertungs-, Dokumentations- und Abrechnungszwecken
              </li>
              <li>
                der Ausstellungs- und Messe-Ausschuss der Deutschen Wirtschaft
                e.V. und die an der Umsetzung der jeweiligen Messebeteiligung
                beteiligten Wirtschaftsorganisationen zur Einsicht des
                Anmeldestandes (nur Unternehmensdaten)
              </li>
              <li>
                im Falle einer Messebeteiligung für die Nachfolgeveranstaltung
                einer Auslandsmesse erhält die damit beauftragte DFG Zugriff auf
                die Ausstellerdaten der vorherigen Messeveranstaltung und ist
                berechtigt, diese Unternehmen zu kontaktieren, um für eine
                erneute Messeteilnahme zu werben.
              </li>
            </ul>
            <br />
            <p><strong>2.7 Webanalyse</strong></p>
            <p>
              Auf Grundlage des Art. 6 Abs. 1 lit. e DSGVO in Verbindung mit § 3
              BDSG werten wir im Rahmen der Öffentlichkeitsarbeit und zur
              bedarfsorientierten Bereitstellung von Informationen zu den von
              uns wahrzunehmenden Aufgaben Nutzungsinformationen zu
              statistischen Zwecken aus. Dies erfolgt mit dem Webanalysedienst
              Matomo.
            </p>
            <p>
              Werden Einzelseiten unserer Website aufgerufen, so werden folgende
              Daten gespeichert:
            </p>
            <ul>
              <li>
                Zwei Bytes der IP-Adresse des aufrufenden Systems des Nutzers
                (anonym)
              </li>
              <li>Die aufgerufene Webseite</li>
              <li>
                Die Website, von der der Nutzer auf die aufgerufene Webseite
                gelangt ist (Referrer)
              </li>
              <li>
                Die Unterseiten, die von der aufgerufenen Webseite aus
                aufgerufen werden
              </li>
              <li>Die Verweildauer auf der Webseite</li>
              <li>Die Häufigkeit des Aufrufs der Webseite</li>
            </ul>
            <br />
            <p>
              Die Software ist so eingestellt, dass die IP-Adressen nicht
              vollständig gespeichert, sondern 2 Bytes der IP-Adresse maskiert
              werden (Bsp.: 192.168.xxx.xxx). Auf diese Weise ist eine Zuordnung
              der gekürzten IP-Adresse zum aufrufenden Rechner nicht mehr
              möglich, so dass Sie als Nutzer anonym bleiben.
            </p>
            <p>
              Die Software läuft dabei ausschließlich auf den Webservern der
              Dienstleister &bdquo;leapfrog gmbh &ldquo; und der
              &bdquo;wohlgemuth + team gmbh&ldquo; im Auftrag des BMWK. Eine
              Speicherung der Nutzungsinformationen findet nur dort statt. Eine
              Weitergabe der Daten an Dritte erfolgt nicht.
            </p>
            <p>
              Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem
              Besuch nicht einverstanden sind, dann können Sie der Speicherung
              und Nutzung nachfolgend per Mausklick jederzeit widersprechen.<br />
              In diesem Fall wird in Ihrem Browser ein sog. Opt-Out-Cookie
              abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten
              erhebt. Bitte beachten Sie, dass die vollständige Löschung Ihrer
              Cookies zur Folge hat, dass auch das Opt-Out-Cookie gelöscht wird
              und ggf. von Ihnen erneut aktiviert werden muss.
            </p>
            <p>
              Sie haben die Möglichkeit zu verhindern, dass von Ihnen hier
              getätigte Aktionen analysiert und verknüpft werden. Dies wird Ihre
              Privatsphäre schützen, aber wird auch den Besitzer daran hindern,
              aus Ihren Aktionen zu lernen und die Bedienbarkeit für Sie und
              andere Benutzer zu verbessern.
            </p>
            <p><strong>3. Ihre Rechte</strong></p>
            <p>
              Sie haben gegenüber dem BMWK folgende Rechte hinsichtlich der Sie
              betreffenden personenbezogenen Daten:
            </p>
            <ul>
              <li>
                Recht auf Auskunft, Art. 15 DSGVO<br />
                Mit dem Recht auf Auskunft erhält der Betroffene eine umfassende
                Einsicht in die ihn angehenden Daten und einige andere wichtige
                Kriterien wie beispielsweise die Verarbeitungszwecke oder die
                Dauer der Speicherung. Es gelten die in § 34 BDSG geregelten
                Ausnahmen von diesem Recht.
              </li>
              <li>
                Recht auf Berichtigung, Art. 16 DSGVO<br />
                Das Recht auf Berichtigung beinhaltet die Möglichkeit für den
                Betroffenen, unrichtige ihn angehende personenbezogene Daten
                korrigieren zu lassen.
              </li>
              <li>
                Recht auf Löschung, Art. 17 DSGVO<br />
                Das Recht auf Löschung beinhaltet die Möglichkeit für den
                Betroffenen, Daten beim verantwortlichen löschen zu lassen. Dies
                ist allerdings nur dann möglich, wenn die ihn angehenden
                personenbezogenen Daten nicht mehr notwendig sind, rechtswidrig
                verarbeitet werden oder eine diesbezügliche Einwilligung
                widerrufen wurde. Es gelten die in § 35 BDSG geregelten
                Ausnahmen von diesem Recht.
              </li>
              <li>
                Recht auf Einschränkung der Verarbeitung, Art. 18 DSGVO<br />
                Das Recht auf Einschränkung der Verarbeitung beinhaltet die
                Möglichkeit für den Betroffenen, eine weitere Verarbeitung der
                ihn angehenden personenbezogenen Daten vorerst zu verhindern.
                Eine Einschränkung tritt vor allem in der Prüfungsphase anderer
                Rechtewahrnehmungen durch den Betroffenen ein.
              </li>
              <li>
                Recht auf Widerspruch gegen die Erhebung, Verarbeitung und
                beziehungsweise oder Nutzung, Art. 21 DSGVO<br />
                Das Recht auf Widerspruch beinhaltet die Möglichkeit, für
                Betroffene, in einer besonderen Situation der weiteren
                Verarbeitung ihrer personenbezogenen Daten zu widersprechen,
                soweit diese durch die Wahrnehmung öffentlicher Aufgaben oder
                öffentlicher sowie privater Interessen rechtfertigt ist. Es
                gelten die in § 36 BDSG geregelten Ausnahmen von diesem Recht.
              </li>
              <li>
                Recht auf Datenübertragbarkeit, Art. 20 DSGVO<br />
                Das Recht auf Datenübertragbarkeit beinhaltet die Möglichkeit
                für den Betroffenen, die ihn angehenden personenbezogenen Daten
                in einem gängigen, maschinenlesbaren Format vom Verantwortlichen
                zu erhalten, um sie ggf. an einen anderen Verantwortlichen
                weiterleiten zu lassen. Gemäß Art. 20 Abs. 3 Satz 2 DSGVO steht
                dieses Recht aber dann nicht zur Verfügung, wenn die
                Datenverarbeitung der Wahrnehmung öffentlicher Aufgaben dient.
              </li>
              <li>
                Recht auf Widerruf der Einwilligung, Art. 13 und 14 DSGVO<br />
                Soweit die Verarbeitung der personenbezogenen Daten auf
                Grundlage einer Einwilligung erfolgt, kann der Betroffene diese
                jederzeit für den entsprechenden Zweck widerrufen. Die
                Rechtmäßigkeit der Verarbeitung aufgrund der getätigten
                Einwilligung bleibt bis zum Eingang des Widerrufs unberührt.
              </li>
            </ul>
            <br />
            <p>
              Die vorgenannten Rechte können Sie unter den unter Ziffer 1.1
              genannten Erreichbarkeiten schriftlich geltend machen. Ihnen steht
              zudem gemäß Art. 77 DSGVO ein Beschwerderecht bei der
              datenschutzrechtlichen Aufsichtsbehörde, der&nbsp;<a
                href="http://www.bfdi.bund.de/"
                target="_blank"
                title="Öffnet ein neues Fenster."
                >Bundesbeauftragten für den Datenschutz und die
                Informationsfreiheit</a
              >, zu.
            </p>
            <p>
              Sie können sich mit Fragen und Beschwerden auch an die unter
              Ziffer 1.1 genannte Datenschutzbeauftragte im BMWK wenden.
            </p>
            <p><strong>4. Anpassungen</strong></p>
            <p>
              Wir behalten uns vor, diese Datenschutzerklärung von Zeit zu Zeit
              zu aktualisieren.
            </p>
          </template>

          <template v-if="$i18n.locale == 'en'">
            <h2 class="mb-5">
              Privacy policy for the online exhibitor registration
            </h2>
            <p><strong>1. Introduction </strong></p>
            <p>
              Responsible handling of personal data is a priority for the
              Federal Ministry for Economic Affairs and Climate Action
              (&ldquo;the Ministry&rdquo;). We want our users to know when the
              Federal Ministry for Economic Affairs and Energy is collecting
              what data and for what purpose.
            </p>
            <p>
              We do not process personal data beyond what is necessary. What
              data we will need and process on what basis and for what purpose
              will much depend on the type of service you are using, and on the
              purpose for which the data is required. <br />
              We have taken technical and organisational measures to ensure that
              both we as well as external service providers comply with the
              provisions governing data protection.
            </p>
            <p>
              The processing of personal data in the Economic Affairs Ministry
              is handled in accordance with the European General Data Protection
              Regulation and the Federal Data Protection Act.&nbsp;
            </p>
            <p>
              This privacy notice informs you about the type and scope of the
              processing of personal data when you use the online
              registration.german-pavilion.com service to register as an
              exhibitor in the Economic Affairs Ministry&rsquo;s foreign trade
              fair programme.
            </p>
            <p><strong>Basic information </strong></p>
            <p>
              <strong>Responsibility and Data Protection Officer </strong
              ><strong>&nbsp;</strong>
            </p>
            <p>
              Responsibility for the processing of personal data lies with the
              <br />
              Federal Ministry for Economic Affairs and Climate Action<br />
              Scharnhorststr. 34-37<br />
              10115 Berlin<br />
              Postal address: 11019 Berlin<br />
              Email:&nbsp;<a
                href="mailto:info@bmwk.bund.de"
                target="_blank"
                title="Opens page in a new window."
                >info@bmwk.bund.de</a
              ><br />
              Internet:&nbsp;<a
                href="https://www.bmwk.de/Navigation/DE/Home/home.html"
                target="_blank"
                title="Opens page in a new window."
                >www.bmwk.de</a
              ><br />
              Phone: +49 (0)30 18615 0
            </p>
            <p>
              If you have any specific questions about the protection of your
              data at the Federal Ministry for Economic Affairs and Climate
              Action, please contact the Ministry&rsquo;s Data Protection
              Officer:
              <br />
              Data Protection Officer for the Federal Ministry for Economic
              Affairs and Energy<br />
              Scharnhorststr. 34-37<br />
              10115 Berlin<br />
              Email:&nbsp;<a
                href="mailto:datenschutzbeauftragte@bmwk.bund.de"
                target="_blank"
                title="Opens page in a new window."
                >datenschutzbeauftragte@bmwk.bund.de</a
              >
            </p>
            <p><strong>1.2 Personal data </strong></p>
            <p>
              &quot;Personal data&quot; is any information relating to an
              identified or identifiable natural person. An identifiable natural
              person is someone who can be identified, directly or indirectly,
              in particular by reference to an identifier such as a name, an
              identification number, location data, and/or an online identifier.
            </p>
            <p><strong>1.3 Protection of minors </strong></p>
            <p>
              Children and persons under 16 years of age should not transmit any
              personal data to us without the consent of their parents or legal
              guardians. Should it happen that persons under 16 years of age do
              contact the Federal Ministry for Economic Affairs and Climate
              Action by email, contact form, letter etc., Points 3.1, 3.3
              through 3.5 shall apply.
            </p>
            <p>
              <strong
                >1.4 Legislation governing the processing of personal
                data</strong
              >
            </p>
            <p>
              The Federal Ministry for Economic Affairs and Climate Action
              processes personal data as it carries out the tasks of public
              interest that are assigned to it. These public duties particularly
              include public relations work, which also involves providing
              information for the public on this website. The processing of
              personal data in this context is based on Art. 6 (1) (e) General
              Data Protection Regulation (GDPR) in conjunction with the relevant
              national or European standards, or in conjunction with Section 3
              of the Federal Data Protection Act. Insofar as the processing of
              personal data is, in individual cases, required in order to fulfil
              a legal obligation, this is based on Article 6 (1) (c ) GDPR in
              conjunction with the relevant legal provisions under which the
              legal obligation arises.
            </p>
            <p>
              Insofar as we obtain the consent of the data subject to process
              his/her personal data, this is based on Art. 6 (1) (a) GDPR. In
              individual cases, the processing of personal data required for the
              performance of a contract to which the data subject is a party is
              based on Art. 6 (1) (b) GDPR. This also applies to processing
              operations necessary for the implementation of pre-contractual
              measures.
            </p>
            <p>
              In the event that vital interests of the data subject or another
              natural person make it necessary to process personal data, this is
              based on Art. 6 (1) (d) GDPR.
            </p>
            <p>
              <strong
                >2. Data processing in connection to visits to and use of the
                online exhibitor registration service</strong
              >
            </p>
            <p><strong>2.1 Data capturing</strong></p>
            <p>
              Whenever a user accesses our website and whenever a file is
              accessed, data relating to this is temporarily processed in a log
              file.<br />
              More specifically, the following data is stored for every single
              access/download:
            </p>
            <ul>
              <li>
                Date and time (time stamp), IP address of the device or server
                used
              </li>
              <li>
                Details of the request and target address (log version, HTTP
                method, referrer, user-agent string),
              </li>
              <li>
                Name of the file accessed, volume of data transferred (URL
                including query string, file size in bytes) and
              </li>
              <li>
                confirmation as to whether the request was successful (HTTP
                status code).
              </li>
            </ul>
            <br />
            <p>
              We are obliged under Article 6 (1) (e) GDPR in conjunction with
              Section 5 Federal Office for Information Security Act to store
              data that is necessary to protect the Ministry&rsquo;s internet
              infrastructure and the federal communications technology system
              from attack; such data must be stored beyond the time of your
              visit. This data is analysed and may be used for legal and
              criminal proceedings in the event of an attack on the
              communications technology system. The data will be deleted as soon
              as it is no longer needed for the intended purpose.
            </p>
            <p>
              Data collected from visits to the Ministry&rsquo;s web resources
              and stored on log files is transmitted to third parties only when
              required by law, or if attacks on the federal communications
              technology system make it necessary to forward such data for
              purposes of legal action or criminal prosecution. Data will not be
              transferred for any other reason. The Ministry does not collate
              this data with data from other sources.&nbsp;
            </p>
            <p><strong>2.2 Session cookies</strong></p>
            <p>
              We use session cookies on our website which are valid for the
              duration of your visit to the website. Article 6 (1) (e) GDPR in
              conjunction with Section 3 Federal Data Protection Act provides
              the legal basis for the use of these session cookies as part of
              the Ministry&rsquo;s PR activities, which are designed to meet the
              demand for information about the Ministry&rsquo;s work in the
              areas entrusted to it. In the context of the use of the online
              exhibitor registration service, session cookies are used to enable
              the log-in to the service.
            </p>
            <p>
              Session cookies are small information units that a provider stores
              in the active memory of the visiting user&rsquo;s computer. A
              randomly generated, unique ID number – known as a session ID – is
              stored in a session cookie. Cookies also contain information
              regarding their origin and the period of time for which they are
              to be stored on a computer. This type of cookie cannot store any
              other data.&nbsp;
            </p>
            <p>
              Session cookies are deleted as soon as you end your
              session.&nbsp;<br />
              You can use any internet browser to be informed whenever a cookie
              is set and to see what information is stored in them. For more
              detailed information, visit the websites of the Federal
              Commissioner for Data Protection and Freedom of Information and of
              the Federal Office for Information Security.
            </p>
            <p>
              Most browsers&rsquo; default settings will result in cookies being
              accepted automatically. It is, however, possible to disable the
              use of cookies or to change your browser settings to
              &lsquo;session cookies only&rsquo;, which means that all cookies
              are deleted as soon as you disconnect from the internet.
            </p>
            <p><strong>2.3. Registration</strong></p>
            <p>
              Users can register and set up a company-based user account. This
              requires the provision of name of the company, an email address
              and a password.
            </p>
            <p><strong>2.4 Accreditation as an exhibitor</strong></p>
            <p>
              In order to be able to register a company to take part in the
              foreign trade fair programme, a one-off accreditation of the
              company is required, and this may entail the provision of some
              personal data. The accreditations are viewed and checked by the
              Economic Affairs Ministry or the service provider commissioned by
              it (leapfrog/w+t). The accreditation data are used and forwarded
              when the registration takes place (cf. figure 2.6). The following
              personal data are collected in the accreditation process:
            </p>
            <ul>
              <li>Company</li>
              <li>Address data</li>
              <li>Phone number</li>
              <li>Fax</li>
              <li>Email</li>
              <li>Website</li>
              <li>Commercial register excerpt</li>
              <li>Commercial register number</li>
              <li>Tax number</li>
              <li>VAT ID</li>
            </ul>
            <br />
            <p>
              <strong
                >2.5. Expression of interest in participation in a trade
                fair</strong
              >
            </p>
            <p>
              Exhibitors can give notice of their interest in participating in a
              trade fair in advance (&ldquo;Messe vormerken&rdquo;). This may
              require the citing of the data of the contact person in the
              company. The implementing agency entrusted with the implementation
              of the trade fair participation is given access to the basic
              company information and possibly also to the data of contact
              persons, and is authorised to contact the company. 
              In the case of trade fair participation for the follow-up event to a foreign trade fair, the implementing agency (DFG) commissioned with this shall be given access to the data of interested parties for the previous trade fair event and shall be authorised to contact these companies in order to advertise their participation in the trade fair.
            </p>
            <p>
              <strong
                >2.6. Registration of participation at a trade fair</strong
              >
            </p>
            <p>
              The following data are collected, processed and transmitted when a
              company registers its wish to participate in a trade fair:<br />
            </p>
            <ul>
              <li>Company data from the accreditation (cf. figure 2.4)</li>
              <li>
                Contact data (name, email, phone) of the person responsible in
                the company for this trade fair participation
              </li>
              <li>possibly data of the sub-contractor</li>
              <li>possibly different data for the invoicing</li>
            </ul>
            <br />
            <p>These data can be accessed by:<br /></p>
            <ul>
              <li>
                the implementing agency (DFG) entrusted with the implementation
                of the trade fair participation for the purpose of handling the
                mandate
              </li>
              <li>
                the service provider leapfrog/w+t for operation and user support
                (handling of queries by phone or email to
                <a href="mailto:support@german-pavilion.com"
                  >support@german-pavilion.com</a
                >) for the registration website and for viewing the companies
                taking part in the trade fair
              </li>
              <li>
                the Federal Ministry for Economic Affairs and Climate Action
                (BMWK) and/or the Federal Ministry of Food and Agriculture
                (BMEL) as the host of the trade fair participation
              </li>
              <li>
                the Economic Affairs Ministry as the host of the trade fair
                participation
              </li>
              <li>
                the Federal Office for Economic Affairs and Export Control
                (BAFA) for the purpose of evaluation, documentation and
                invoicing
              </li>
              <li>
                the AUMA (Association of the German Trade Fair Industry) and the
                economic organizations involved in organizing the specific trade
                fair participation for the purpose of viewing the registration
                status (only company data)
              </li>
              <li>
                in the case of a trade fair participation for the follow-up
                event of a foreign trade fair, the DFG commissioned with the
                task of organizing the German Pavilion receives access to the
                exhibitor data of the previous trade fair event and is entitled
                to contact these companies in order to advertise a renewed
                participation in the trade fair.
              </li>
            </ul>
            <br />

            <p><strong>2.7 Web analysis</strong></p>
            <p>
              As part of our PR work and for the purposes of meeting demand for
              information about our work, we conduct use analyses for
              statistical purposes and in line with Article 6 (1) (e) GDPR in
              conjunction with Section 3 Federal Data Protection Act. We use the
              Matomo web analysis service for this purpose.
            </p>
            <p>
              When individual pages of our website are visited, the following
              data are stored:
            </p>
            <ul>
              <li>
                Two bytes of the IP address of the user&rsquo;s system opening
                the page (anonymous)
              </li>
              <li>The visited page</li>
              <li>
                The website from which the user was directed to the page
                (referrer)
              </li>
              <li>The sub-pages which are visited from the page</li>
              <li>The time spent visiting the page</li>
              <li>The frequency of visits to the page</li>
            </ul>
            <br />
            <p>
              The software is programmed not to store the whole IP address, but
              to mask 2 bytes of the IP address (e.g. 192.168.xxx.xxx). In this
              way, it is not possible to assign the abbreviated IP address to
              host computers, so that you remain anonymous as a user.
            </p>
            <p>
              The software is used exclusively on the web servers of the service
              providers &ldquo;leapfrog gmbh &rdquo; and &ldquo;wohlgemuth +
              team gmbh&rdquo; on behalf of the Economic Affairs Ministry. The
              user information is only stored there. It is not forwarded to
              third parties.
            </p>
            <p>
              If you do not accept the storing and evaluation of these data from
              your visit, you can refuse the storage and use at any time by
              clicking the appropriate button.<br />
              In this case, an opt-out cookie is lodged on your browser, meaning
              that Matomo does not collect any data relating to your session.
              Please note that deletion of all your cookies means that the
              opt-out cookie is also deleted, and may have to be reactivated by
              you.
            </p>
            <p>
              You have the possibility to prevent the actions undertaken by you
              from being analysed and linked. This will protect your privacy,
              but will also prevent the host from learning from your actions and
              improving the service quality for you and other users.
            </p>
            <p><strong>3. Your rights</strong></p>
            <p>
              You have the following rights with regard to the personal data
              concerning your person:
            </p>
            <ul>
              <li>
                Right of access, Art. 15 GDPR<br />
                The right of access confers on the data subject a comprehensive
                right of access to the data concerning his/her person and to
                certain important information-related criteria, such as the
                purposes for which it is processed or the duration for which it
                will be stored. The exceptions to this right regulated in
                Section 34 Federal Data Protection Act apply.
              </li>
              <li>
                Right to rectification, Art. 16 GDPR<br />
                The right to rectification includes the possibility for the data
                subject to have inaccurate personal data corrected.
              </li>
              <li>
                Right to erasure, Art. 17 GDPR<br />
                The right to erasure includes the possibility for the data
                subject to have data deleted by the party responsible. However,
                this is only possible if the personal data concerning his/her
                person are no longer needed, are processed unlawfully or if the
                relevant consent has been revoked. The exceptions to this right
                regulated in Section 35 Federal Data Protection Act apply.
              </li>
              <li>
                Right to restriction of processing, Art. 18 GDPR<br />
                The right to restrict the processing includes the possibility
                for the data subject to prevent further processing of personal
                data concerning his/her person for the time being. A restriction
                particularly occurs pending verification of the exercise of
                other rights of the data subject.
              </li>
              <li>
                Right to object to collection, processing and/or use, Art. 21
                GDPR<br />
                The right to object includes the possibility for data subjects
                to object, in a particular situation, to the further processing
                of their personal data, insofar as this is justified by the
                exercise of public functions or of public or private interests.
                The exceptions to this right regulated in Section 36 Federal
                Data Protection Act apply.
              </li>
              <li>
                Right to data portability, Art. 20 GDPR<br />
                The right to data portability includes the possibility for the
                data subject to obtain the personal data concerning his/her
                person from the person responsible in a standard,
                machine-readable format, in order to be able to forward them to
                another person responsible if necessary. According to Art. 20
                (3) sentence 2 GDPR, however, this right does not apply if the
                data processing serves the performance of public tasks.
              </li>
              <li>
                Right to revoke consent, Art. 13 and 14 GDPR<br />
                If personal data is processed on the basis of consent, the data
                subject may revoke such consent at any time for the purpose for
                which it was given. The lawfulness of the processing undertaken
                on the basis of this consent remains unaffected until receipt of
                the revocation.
              </li>
            </ul>
            <br />
            <p>
              You can assert the aforementioned rights in writing using the
              contact details set out in Section 1.1. Pursuant to Art. 77 GDPR,
              you also have the right to appeal to the data protection
              supervisory authority: the
              <a
                href="http://www.bfdi.bund.de/"
                target="_blank"
                title="Opens page in a new window."
                >Federal Commissioner for Data Protection and Freedom of
                Information</a
              >.
            </p>
            <p>
              You can also contact the Ministry&rsquo;s Data Protection Officers
              under Section 1.1 if you have any questions or complaints.
            </p>
            <p><strong>4. Alterations</strong></p>
            <p>
              We reserve the right to update this privacy policy from time to
              time.
            </p>
          </template>
          <v-btn
            class="float-right"
            color="secondary"
            elevation="0"
            icon
            @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          >
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>
        </v-sheet>
      </v-container>
    </v-main>
    <v-footer>
      <logos />
    </v-footer>
  </v-app>
</template>

<script>
import Logos from '@/components/base/Logos/Logos'
import PublicHeader from '@/components/public/Header.vue'

export default {
  components: { Logos, PublicHeader },
  methods: {
    hasHistory() {
      return window.history.length > 2
    },
  },
}
</script>
