<template>
  <v-container class="d-flex pa-0" style="min-height: 100%;" fluid no-gutters>

    <v-row>

      <v-col cols="3" class="pa-0">
        <v-navigation-drawer class="grey lighten-5 px-0 py-4 ma-0" width="100%">
          <v-list
            v-if="!$wait.is([loaders.fetchFaqArticles, loaders.fetchCategories])"
          >
            <v-list-item
              @click="showCreateCategoryModal"
              style="cursor: pointer"
            >
              <v-list-item-icon class="mx-0">
                <v-icon size="16" class="primary--text ml-5">fal fa-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="pl-4"><h4>{{ $t('help.createNewCategory') }}</h4></v-list-item-title>
            </v-list-item>

            <v-list-item
              v-for="category in adminCategories"
              :key="category.id"
              :to="`/admin/faq/${category.id}`"
              @click="setCurrentCategory(category.id)"
            >
              <v-list-item-icon class="mx-0">
                <v-icon size="16" class="primary--text ml-5">fal fa-arrow-right</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="pl-4">
                <h4>
                  {{ category.translations[$i18n.locale].title }}
                  <v-btn
                    v-if="!faqsByCategory.length && category.id === currentCategoryId"
                    @click="deleteCategory({ id: category.id })"
                    class="secondary--text"
                    icon
                  >
                    <v-icon size="18">fal fa-trash-alt</v-icon>
                  </v-btn>
                </h4>
              </v-list-item-title>
            </v-list-item>
          </v-list>

        </v-navigation-drawer>
      </v-col>

      <v-col cols="9" class="pa-8">

        <v-card class="elevation-1 rounded-0 mb-5">
          <v-card-title>
            <h4 class="primary--text">{{ $t('help.helpAndAdvice') }}</h4>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="10">
                <v-autocomplete
                  :label="$t('help.search')"
                  :no-data-text="$t('common.noDataAvailable')"
                  v-model="searchFaq"
                  :items="faqsByCategory"
                  :filter="customSearch"
                  @click:clear="applySearch = false"
                  @change="applySearch = true"
                  spellcheck="false"
                  :item-text="`translations.${$i18n.locale}.headline`"
                  item-value="id"
                  multiple
                  clearable
                  small-chips
                >
                  <v-icon slot="prepend" size="18" class="primary--text">far fa-search</v-icon>
                  <template slot='item' slot-scope='{ item }'>
                    <div>
                      <span style="display: inline-block"><b>{{ item.translations[$i18n.locale].headline }}</b></span>&nbsp;
                      <span style="display: inline-block" v-html="item.translations[$i18n.locale].abstract" />&nbsp;
                      <span style="display: inline-block" v-html="item.translations[$i18n.locale].text" />&nbsp;
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-btn
                  class="mt-3 elevation-0 secondary rounded-0"
                  v-on:click="applySearch = true"
                >
                  {{ $t('help.search') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-btn
          v-if="currentCategoryId !== null"
          @click="showCreateFaqModal"
          class="mb-3 elevation-0 secondary rounded-0"
        >
          <i class="fal fa-plus mr-3"></i>{{ $t('help.newEntry') }}
        </v-btn>

        <v-expansion-panels flat>
          <v-expansion-panel
              v-for="item in faqsByCategoryAndSearch"
              :key="item.id"
              class="elevation-1 rounded-0"
          >
            <v-expansion-panel-header>
              <h4> {{item.translations[$i18n.locale].headline }}</h4>
              <div class="text-right">
                <v-chip class="success rounded-3 mr-10" v-if="item.active" small>{{ $t('help.table.active') }}</v-chip>
                <v-chip class="grey lighten-2 rounded-3 mr-10" v-else small>{{ $t('help.table.notActive') }}</v-chip>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content v-if="item.id !== currentFaqId">
              <p><small>#ID {{item.id}}</small></p>
              <p v-html="item.translations[$i18n.locale].abstract"/>
              <p v-html="item.translations[$i18n.locale].text"/>
              <v-btn class="secondary--text" icon>{{ $i18n.locale }}</v-btn>
              <v-btn class="secondary--text" @click="handleEdit(item)" icon><v-icon size="18">fal fa-pencil</v-icon></v-btn>
              <v-btn
                :loading="$wait.is(loaders.deleteFaqArticle)"
                @click="showDeleteFaqModal(item)"
                class="secondary--text"
                icon
              >
                <v-icon size="18">fal fa-trash-alt</v-icon>
              </v-btn>
            </v-expansion-panel-content>

            <v-expansion-panel-content v-if="item.id === currentFaqId">
              <v-row>
                <v-col cols="12">
                  <p><small>#ID {{ item.id }}</small></p>
                  <v-switch :label="$t('help.table.active')" v-model="form.data.active" />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    :label="$t('help.form.questionDE')"
                    v-model="form.data.headline_de"
                    class="rounded-0" filled
                  />

                  <label class="v-label theme--light">{{ $t('help.form.summaryDE') }}
                    <vue-editor
                      :placeholder="$t('help.form.summaryDE')"
                      v-model="form.data.abstract_de"
                      :error-messages="errors.abstract_de"
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message" v-for="(error ,index) in errors.abstract_de" :key="index">{{ error }}</div>
                        </div>
                      </div>
                    </div>
                  </label>

                  <label class="v-label theme--light">{{ $t('help.form.textDE') }}
                    <vue-editor
                      :placeholder="$t('help.form.textDE')"
                      v-model="form.data.text_de"
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message" v-for="(error ,index) in errors.text_de" :key="index">{{ error }}</div>
                        </div>
                      </div>
                    </div>
                  </label>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    :label="$t('help.form.questionEN')"
                    v-model="form.data.headline_en"
                    class="rounded-0"
                    filled
                  />

                  <label class="v-label theme--light">{{ $t('help.form.summaryEN') }}
                    <vue-editor
                      :placeholder="$t('help.form.summaryEN')"
                      v-model="form.data.abstract_en"
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message" v-for="(error ,index) in errors.abstract_en" :key="index">{{ error }}</div>
                        </div>
                      </div>
                    </div>
                  </label>

                  <label class="v-label theme--light">{{ $t('help.form.textEN') }}
                    <vue-editor
                      :placeholder="$t('help.form.textEN')"
                      v-model="form.data.text_en"
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message" v-for="(error ,index) in errors.text_en" :key="index">{{ error }}</div>
                        </div>
                      </div>
                    </div>
                  </label>
                </v-col>

                <v-col cols="6">
                  <v-btn
                    :loading="$wait.is(loaders.updateFaqArticle)"
                    @click="handleFaqUpdate"
                    class="secondary rounded-0 elevation-0"
                  >
                    {{ $t('common.save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>

          </v-expansion-panel>
        </v-expansion-panels>

      </v-col>

    </v-row>
    <create-category-modal
      ref="createCategoryModal"
    />
    <create-faq-modal
      :help-category-id="currentCategoryId"
      ref="createFaqModal"
    />
    <confirmation-modal
      ref="confirmationFaqDeleteModal"
      @confirm="handleFaqDelete"
      :loading="$wait.is(loaders.deleteFaqArticle)"
      :async-hide="true"
    >
      {{ $t('common.generalConfirmation', { action: $t('help.action.delete').toLowerCase() } )}}
    </confirmation-modal>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import validation from '@/mixins/validate'
import { FETCH_FAQ_ARTICLES, UPDATE_FAQ_ARTICLE, DELETE_FAQ_ARTICLE, FETCH_FAQ_CATEGORIES }  from '@/store/loading-types'
import { required } from 'vuelidate/lib/validators'
import ConfirmationModal from '@/components/base/ConfirmationModal'
import CreateCategoryModal from '@/components/admin/FAQ/Modals/CreateFaqCategory'
import CreateFaqModal from '@/components/admin/FAQ/Modals/CreateFaq'
import { VueEditor } from "vue2-editor";

export default {
  name: 'AdminFAQ',

  mixins: [
    validation,
  ],

  components: {
    ConfirmationModal,
    CreateCategoryModal,
    CreateFaqModal,
    VueEditor
  },

  data: function() {
    return {
      currentCategoryId: null,
      currentFaqId: null,
      deleteFaqId: null,
      applySearch: false,
      searchFaq: [],
      form: {
        data: {
          active: false,
          headline_en: '',
          headline_de: '',
          abstract_en: '',
          abstract_de: '',
          text_en: '',
          text_de: '',
        },
        validationRules: {
          active: {},
          headline_en: {
            required
          },
          headline_de:  {
            required
          },
          abstract_en:  {
            required
          },
          abstract_de:  {
            required
          },
          text_en:  {
            required
          },
          text_de:  {
            required
          },
        },
      },
      loaders: {
        fetchFaqArticles: FETCH_FAQ_ARTICLES,
        updateFaqArticle: UPDATE_FAQ_ARTICLE,
        deleteFaqArticle: DELETE_FAQ_ARTICLE,
        fetchCategories: FETCH_FAQ_CATEGORIES
      },
    }
  },

  computed: {
    ...mapGetters('help', [
      'adminCategories',
      'faqs'
    ]),

    faqsByCategory() {
     return  this.faqs.filter((faq) => (faq.category?.id === this.currentCategoryId))
    },

    faqsByCategoryAndSearch() {
      let faqs = this.faqs.filter((faq) => (faq.category?.id === this.currentCategoryId))
      let searchFaqs = this.searchFaq

      if (this.applySearch && this.searchFaq.length) {
        faqs = faqs.filter(function (faq) {
          return searchFaqs.includes(faq.id)
        })
      }
      return faqs
    }
  },

  methods: {
    ...mapActions('help', [
      'fetchCategories',
      'deleteCategory',
      'fetchFaqArticles',
      'updateFaqArticle',
      'deleteFaqArticle'
    ]),

    ...mapWaitingActions('help', {
      fetchFaqArticles: FETCH_FAQ_ARTICLES,
      updateFaqArticle: UPDATE_FAQ_ARTICLE,
      deleteFaqArticle: DELETE_FAQ_ARTICLE,
      fetchCategories: FETCH_FAQ_CATEGORIES
    }),

    customSearch(item, queryText) {
      const data = item.headline.toLowerCase() +
        item.abstract.toLowerCase() +
        item.text.toLowerCase()

      const searchText = queryText.toLowerCase()

      return data.indexOf(searchText) > -1
    },

    setCurrentCategory(id) {
      this.currentCategoryId = id
      this.currentFaqId = null
    },

    fillInForm(item) {
      this.form.data = {
        active: item.active,
        headline_en: item.translations['en'].headline,
        abstract_en: item.translations['en'].abstract,
        text_en: item.translations['en'].text,
        headline_de: item.translations['de'].headline,
        abstract_de: item.translations['de'].abstract,
        text_de: item.translations['de'].text,
      }
    },

    handleEdit(item) {
      this.currentFaqId = item.id;
      this.fillInForm(item)
    },

    transformDataForUpdate() {
      const data = this.getFormData()
      return {
        active: data.active,
        helpCategoryId: this.currentCategoryId,
        en: {
          headline: data.headline_en,
          abstract: data.abstract_en,
          text: data.text_en,
        },
        de: {
          headline: data.headline_de,
          abstract: data.abstract_de,
          text: data.text_de,
        }
      }
    },

    async handleFaqUpdate() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (await this.updateFaqArticle({data: this.transformDataForUpdate(), id: this.currentFaqId})) {
        this.currentFaqId = null
      }
    },

    async handleFaqDelete() {
      await this.deleteFaqArticle({id: this.deleteFaqId})
      this.deleteFaqId = null
      this.$refs.confirmationFaqDeleteModal.hide()
    },

    showDeleteFaqModal(faq) {
      this.deleteFaqId = faq.id
      this.$refs.confirmationFaqDeleteModal.show()
    },

    showCreateFaqModal() {
      this.$refs.createFaqModal.show()
    },

    showCreateCategoryModal() {
      this.$refs.createCategoryModal.show()
    },
  },

  created() {
    this.currentCategoryId = typeof this.$route.params.id !== 'undefined' ? parseInt(this.$route.params.id) : null
    this.searchFaq = []
    this.fetchFaqArticles()
    this.fetchCategories()
  },

  watch: {
    '$route' (to, from) {
      if (to.params.id !== from.params.id && typeof to.params.id === 'undefined') {
        this.currentCategoryId = null
      }
      if (to.params.id !== from.params.id) {
        this.searchFaq = []
      }
    }
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>