<template>
  <div>
    {{ $t('participation.editSteps.projectManagement.contactPersonInRegistrationForm') }}

    <v-autocomplete
      :items="managers"
      :item-text="item =>`${item.attributes.first_name} ${item.attributes.last_name}`"
      item-value="id"
      v-model="form.data.manager"
      :error-messages="errors.manager"
      :loading="form.loading"
      @change="handleChange"
    />

    <submit-button
      v-if="isSubmitAvailable"
      :is-loading="form.loading"
      @clicked="handleSubmit"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import SubmitButton from '@/components/base/SubmitButton'

export default {
  props: {
    manager: {
      type: Object,
      required: false,
    },

    isSubmitAvailable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  components: {
    SubmitButton,
  },

  mixins: [
    validation,
  ],

  data() {
    return {
      users: [],
      form: {
        loading: false,
        data: {
          manager: null,
        },
        validationRules: {
          manager: {
            required,
          },
        },
      },
    }
  },

  computed: {
    ...mapGetters('organizer', [
      'currentTradeFair',
    ]),

    managers() {
      var managers = this.users;

      return managers.sort((a,b) => (a.attributes.last_name > b.last_name) ? 1 : ((b.attributes.last_name > a.attributes.last_name) ? -1 : 0));
    },

    isManagerDifferent() {
      if (!this.manager || !this.manager.id) {
        return true
      }

      return this.form.data.manager !== this.manager.id
    },
  },

  async created() {
    await this.load()

    if (this.manager) {
      this.form.data.manager = this.manager.id

      this.$emit('managerChanged', this.users.find((el) => el.id === this.form.data.manager))
    }
  },

  methods: {
    ...mapActions('organizer', [
      'updateTradeFair',
      'fetchCurrentTradeFair',
    ]),

    async handleChange() {
      this.$emit('managerChanged', this.users.find((el) => el.id === this.form.data.manager))
    },

    async handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.isManagerDifferent) {
        this.form.loading = true

        await this.$axios.post(`/api/users/${this.form.data.manager}/trade-fairs/${this.currentTradeFair.id}`)
      }

      var manager = this.users.find((el) => el.id === this.form.data.manager)

      await this.$axios.patch(`/api/trade-fairs/${this.currentTradeFair.id}`,  {
        contact_name: manager.attributes.first_name + ' ' + manager.attributes.last_name,
        contact_phone: manager.attributes.phone,
        contact_email: manager.attributes.email,
      })

      await this.fetchCurrentTradeFair()

      this.form.loading = false

      this.$emit('submit')
    },

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get('/api/users', {
        params: {
          'page[size]': 100,
          'include': [
              'roles',
          ],
        },
      })).data

      this.users = response.data
      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
