export default {
  registration: {
    title: 'Registrieren',
    description: 'Die Registrierung und Nutzung ist kostenfrei. Bitte beachten Sie: Das Nutzerkonto wird für Ihr Unternehmen erstellt.',
    userAccess: 'Als registrierter Nutzer erhalten Sie Zugang zu den Messedaten und Beteiligungskonditionen des Auslandsmesseprogramms des Bundes.',
    userCan: 'Als registrierter Nutzer können Sie:',
    possibilities: {
      findFairs: 'für Ihr Unternehmen interessante Messebeteiligungen finden',
      earlyRegister: 'sich für die Teilnahme frühzeitig vormerken lassen',
      bookParticipation: 'Ihre Messeteilnahme sicher und komfortabel online buchen',
      informYourself: 'sich ausführlich über den Ablauf Ihrer Messebeteiligung informieren',
    },
  },
}
