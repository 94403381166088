import { mapGetters, mapActions } from 'vuex'
import { REGISTRATION_MODES } from '@/enums/registrationModes'

export default {
  computed: {
    ...mapGetters('participation', [
      'participationExists',
    ]),

    ...mapGetters('organizer', [
      'currentTradeFair',
    ]),

    tradeFairID() {
      if (this.mode === REGISTRATION_MODES.READONLY || this.mode === REGISTRATION_MODES.PREVIEW) {
        return this.currentTradeFair.id
      }

      return parseInt(this.$route.params.id)
    },
  },

  methods: {
    ...mapActions('snackbar', [
      'setSnackbar',
    ]),

    checkIfParticipationExists() {
      if (!this.participationExists) {
        this.setSnackbar(this.$t('validation.participationIsNotCreated'))
        return false
      }

      return true
    },

    checkIfFormFieldsAreValid() {
      this.$v.$touch()

      return !this.$v.$invalid
    },
  },
}
