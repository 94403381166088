<template>
  <tr>
    <td>
      <v-checkbox
        v-model="form.data.isEnabled"
        @change="handleItemStatusUpdate"
        :readonly="isReadonlyMode || !isSubmittedForm || isMandatory"
        class="my-0 ml-3"
      />
    </td>
    <td style="width: 150px;">
      <v-text-field
          v-if="isAmountSelectable"
          class="rounded-0 mt-1"
          v-model="form.data.amount"
          type="number"
          @change="handleItemAmountUpdate"
          :label="$t('participation.equipment.quantity')"
          :readonly="isReadonlyMode || !isSubmittedForm"
          filled
          dense
      />
      <span v-else>
        -
      </span>
    </td>
    <td>
      <p>{{ service.attributes.unit[lang] }}</p>
    </td>
    <td>
      <p>{{ service.attributes.title[lang] }}</p>
    </td>
    <td style="width: 150px;">
        <v-text-field
            v-if="service.attributes.specifiable"
            class="rounded-0 mt-1"
            v-model="form.data.specification"
            @change="handleItemLabelUpdate"
            :label="service.attributes.specification_label[lang]"
            :readonly="isReadonlyMode || !isSubmittedForm"
            filled
            dense
        />
        <span v-else>
        -
        </span>
    </td>
    <td>
      <v-icon
          size="20"
          style="cursor: pointer"
          class="primary--text"
          @click.prevent="$emit('description', service)"
      >
        fal fa-question-circle
      </v-icon>
    </td>
    <td>
      <p>{{ service.attributes.price | money }}</p>
    </td>
    <td>
      <p>{{ totalPrice | money }}</p>
    </td>
  </tr>
</template>

<script>
import {mapActions} from 'vuex'
import participationMode from '@/mixins/participationMode'
import validation from '@/mixins/validate'

export default {
  name: 'SingleSelectableItemRow',

  mixins: [
    validation,
    participationMode,
  ],

  props: {
    service: {
      type: Object,
    },

    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  data() {
    return {
      form: {
        data: {
          isEnabled: false,
          amount: 1,
          specification: '',
        },
        validationRules: {
          isEnabled: {},
          amount: {},
          specification: {},
        }
      },
    }
  },

  computed: {
    services() {
      return this.fair.relationships.services.filter((el) => !el.attributes.fee)
    },

    assigned() {
      return this.participation?.relationships?.services?.find(item => item.attributes.tradefair_service_id === this.service.id)
    },

    isMandatory() {
      return this.service.attributes.mandatory;
    },

    isAmountSelectable() {
      return this.service.attributes.amount_selectable;
    },

    isItemAttachedToParticipation() {
      return !!this.assigned
    },

    totalPrice() {
      return this.service.attributes.price * this.form.data.amount
    },
  },

  created() {
    this.setInitialFormData()
  },

  methods: {
    ...mapActions('participation', [
      'attachService',
      'updateService',
      'detachService',
    ]),

    setInitialFormData() {
      this.form.data.amount = this.assigned?.attributes?.amount
      this.form.data.specification = this.assigned?.attributes?.specification
      this.form.data.isEnabled = this.isItemAttachedToParticipation

      if (this.isMandatory) {
        this.form.data.amount = this.form.data.amount ? this.form.data.amount : 1
        this.form.data.isEnabled = true
      }
    },

    handleItemAmountUpdate() {
      if (!this.form.data.isEnabled) {
        return
      }

      if (this.form.data.amount < 1) {
        this.form.data.amount = 1
      }

      if (!this.isItemAttachedToParticipation) {
        this.attachService({ participation: this.participation, service: this.service, data: { ...this.getFormData(), amount: this.form.data.amount > 1 ? this.form.data.amount : 1} })
      } else {
        this.updateService({ participation: this.participation, assigned: this.assigned, service: this.service, data: this.getFormData() })
      }
    },

    handleItemStatusUpdate() {
      if (this.form.data.amount < 1) {
        this.form.data.amount = 1
      }

      if (this.form.data.isEnabled) {
        this.attachService({ participation: this.participation, service: this.service, data: { ...this.getFormData(), amount: this.form.data.amount > 1 ? this.form.data.amount : 1} })
      } else {
        this.detachService({ participation: this.participation, assigned: this.assigned, data: { ...this.getFormData(), amount: this.form.data.amount > 1 ? this.form.data.amount : 1} })
      }
    },

    handleItemLabelUpdate() {
      if (this.isItemAttachedToParticipation) {
        this.updateService({ participation: this.participation, assigned: this.assigned, service: this.service, data: { ...this.getFormData(), amount: this.form.data.amount > 1 ? this.form.data.amount : 1} })
      }
    },
  },

  watch: {
    participation() {
      this.setInitialFormData();
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
