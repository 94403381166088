<template>
  <v-container class="pa-9" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-1 rounded-0">
          <v-card-title>
            <h4 class="primary--text">{{ $t('dashboard.projectOverview') }}</h4>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    v-model="filters.query"
                    :label="$t('forms.buttons.search')"
                />
              </v-col>
              <v-col cols="6">
                <v-switch
                    v-model="filters.future"
                    :label="$t('dashboard.showPastEvents')"
                    class="ml-10 mr-5"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-data-table
              :headers="computedHeaders"
              :loading="form.loading"
              :items="fairs"
              :page.sync="form.page"
              :options.sync="options"
              :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
              :item-class="itemRowBackground"
              :server-items-length="form.total"
          >
            <template v-slot:item.name="{ item }">
              <h3 class="primary--text">{{ item.attributes.name_display }}</h3>
              <span v-if="item.attributes.flag_cancelled">{{ $t('tables.tradeFair.statuses.canceled') }}</span>
            </template>

            <template v-slot:item.start_date="{ item }">
              <span>{{ item.attributes.start_date_display[$i18n.locale] }}</span>
            </template>

            <template v-slot:item.registration_deadline="{ item }">
              <span v-if="item.attributes.registration_deadline">
                {{ item.attributes.registration_deadline | dateByLocale }}
              </span>
              <span v-else>
                {{ $t('tables.tradeFair.notYetKnown') }}
              </span>
            </template>

            <template
                v-if="isOrganizer"
                v-slot:item.team="{ item }"
            >
              <div class="my-2">
                <div
                    v-for="user in item.relationships.access_users"
                    :key="user.id"
                >
                  {{ user.first_name }} {{ user.last_name }}
                </div>
                <div>
                  <v-btn
                      color="secondary"
                      class="elevation-0 mt-2"
                      x-small
                      @click="showAssignUsersModal(item)"
                  >
                    {{ $t('dashboard.defineTeam') }}
                  </v-btn>
                </div>
              </div>
            </template>

            <template v-slot:item.registration_type="{ item }">
              <registration-status-chip
                :tradefair="item.attributes"
              />
            </template>

            <template v-slot:item.flag_registration_is_open="{ item }">
              <v-icon v-if="item.attributes.flag_registration_is_open" size="18">fal fa-check</v-icon>
              <v-icon v-else size="18">fal fa-times</v-icon>
            </template>

            <template v-slot:item.participations_was_submitted_count="{ item }">
              <router-link
                  v-if="item.id === currentTradeFair.id"
                  :to="{ name: 'organizer.participants' }">{{ item.included.participations_was_submitted_count }}
              </router-link>
              <span v-else>
                  {{ item.included.participations_was_submitted_count }}
              </span>
            </template>

            <template v-slot:item.interested_companies_count="{ item }">
              <router-link
                  v-if="item.id === currentTradeFair.id"
                  :to="{ name: 'organizer.interest' }"
              >
                {{ item.included.interested_companies_count }}
              </router-link>
              <span v-else>
                  {{ item.included.interested_companies_count }}
                </span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                  color="secondary"
                  class="elevation-0"
                  small
                  @click="setCurrentTradeFair(item.id)"
              >
                <v-icon size="18" class="mr-2">fal fa-exchange</v-icon>
                {{ $t('dashboard.toTheProject') }}
              </v-btn>
            </template>

          </v-data-table>

        </v-card>
      </v-col>

    </v-row>
    <assign-users-modal ref="assignUsersModal" />
  </v-container>
</template>

<script>
import debounce from 'lodash/debounce'
import {mapGetters, mapActions} from 'vuex'
import AssignUsersModal from "@/components/organizer/Dashboard/Modals/AssignUsers";
import RegistrationStatusChip from '@/components/base/RegistrationStatusChip.vue';

export default {
  name: 'OrganizerHome',

  components: {
    AssignUsersModal,
    RegistrationStatusChip
  },

  data() {
    return {
      fairs: [],
      options: {},

      form: {
        total: 0,
        loading: false,
      },

      filters: {
        future: false,
        query: null,
      },
    }
  },

  computed: {
    ...mapGetters('organizer', [
      'currentTradeFair',
      'organizer',
    ]),

    ...mapGetters('auth', [
      'isOrganizer',
    ]),

    headers() {
      return [
        {
          text: this.$t('tables.tradeFair.startAt'),
          sortable: true,
          value: 'start_date'
        },
        {
          text: this.$t('dashboard.tradeFair'),
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('dashboard.team'),
          sortable: false,
          value: 'team'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'registration_type'
        },
        {
          text: this.$t('dashboard.registrationPhaseIsRunning'),
          sortable: true,
          value: 'flag_registration_is_open'
        },
        {
          text: this.$t('dashboard.registrationDeadline'),
          sortable: true,
          value: 'registration_deadline'
        },
        {
          text: this.$t('participation.registrations'),
          sortable: true,
          value: 'participations_was_submitted_count'
        },
        {
          text: this.$t('dashboard.interestedPersons'),
          sortable: true,
          value: 'interested_companies_count'
        },
        {
          text: '',
          sortable: false,
          value: 'actions'
        },
      ]
    },

    computedHeaders() {
      let headers = this.headers
      if (!this.isOrganizer) {
        headers.splice(2, 1)
      }
      return headers
    },
  },

  created() {
    this.load()
  },

  methods: {
    ...mapActions('organizer', [
      'setCurrentTradeFair',
    ]),

    itemRowBackground: function (item) {
      return item.id === this.currentTradeFair.id ? 'selected' : '';
    },

    showAssignUsersModal(tradeFair) {
      this.$refs.assignUsersModal.show(tradeFair)
    },

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get('/api/trade-fairs', {
        params: {
          'languages': '*',
          'filter[name_display]': this.filters.query,
          'filter[future]': !this.filters.future,
          'include': [
            'organizer',
            'accessUsers',
            'participationsWasSubmittedCount',
            'interestedCompaniesCount',
          ],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      this.fairs = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },

    loadDebounce: debounce(function() {
      this.load()
    }, 500),
  },

  watch: {
    'options'() {
      this.load()
    },

    'filters': {
      handler() {
        this.options.page = 1

        this.loadDebounce()
      },
      deep: true
    },
  },
}
</script>

<style>
.selected {
  background-color: #eeeeee;
}
</style>
