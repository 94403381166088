<template>
  <v-form>
    <v-row>
      <slot name="header" />

      <v-col
        cols="6"
        class="pr-10"
      >
        <v-text-field
          v-model="form.data.name"
          :error-messages="errors.name"
          :label="$t('forms.fields.name')"
          disabled
        />

        <v-text-field
          v-model="form.data.address"
          :error-messages="errors.address"
          :label="$t('forms.fields.address')"
          disabled
        />

        <v-text-field
            v-model="form.data.postalCode"
            :error-messages="errors.postalCode"
            :label="$t('forms.fields.postalcode')"
            disabled
            :loading="$wait.is(loaders.updateOrganizer)"
        />

        <v-text-field
            v-model="form.data.city"
            :error-messages="errors.city"
            :label="$t('forms.fields.city')"
            disabled
            :loading="$wait.is(loaders.updateOrganizer)"
        />

        <v-text-field
          v-model="form.data.website"
          :error-messages="errors.website"
          :label="$t('forms.fields.website')"
          @blur="formatWebsite"
          disabled
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="form.data.phone"
          :error-messages="errors.phone"
          :label="$t('forms.fields.phone')"
          :readonly="isReadonly"
          :loading="$wait.is(loaders.updateOrganizer)"
        />

        <v-text-field
          v-model="form.data.fax"
          :error-messages="errors.fax"
          :label="$t('forms.fields.fax')"
          :readonly="isReadonly"
          :loading="$wait.is(loaders.updateOrganizer)"
        />

        <v-text-field
          v-model="form.data.email"
          :error-messages="errors.email"
          :label="$t('forms.fields.email')"
          :readonly="isReadonly"
          :loading="$wait.is(loaders.updateOrganizer)"
        />
      </v-col>

      <slot :submit="handleSubmit" />
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { required, email, url } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import { UPDATE_ORGANIZER } from '@/store/loading-types'
import { addProtocolIfMissing } from '@/utilities/helpers/urls'

export default {
  name: 'CompanyForm',

  props: {
    additionalFormData: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mixins: [
    validation,
  ],

  data() {
    return {
      form: {
        data: {
          name: '',
          address: '',
          postalCode: '',
          city: '',
          phone: '',
          fax: '',
          email: '',
          website: '',
        },
        validationRules: {
          name: {
            required,
          },
          address: {
            required,
          },
          postalCode: {},
          city: {},
          phone: {
            required,
          },
          fax: {},
          email: {
            required,
            email,
          },
          website: {
            required,
            url,
          },
        },
      },
      loaders: {
        updateOrganizer: UPDATE_ORGANIZER,
      },
    }
  },

  async created() {
    if (await this.fetchUser()) {
      this.extractFromUser()
      this.setInitialFormData(this.organizer)
      this.form.data.website = addProtocolIfMissing(this.form.data.website)
    }
  },

  computed: {
    ...mapGetters('organizer', [
      'organizer',
    ]),
  },

  methods: {
    ...mapActions('auth', [
      'fetchUser',
    ]),

    ...mapActions('organizer', [
      'updateOrganizer',
      'extractFromUser',
    ]),

    ...mapWaitingActions('organizer', {
      updateOrganizer: UPDATE_ORGANIZER,
    }),

    formatWebsite({ target: { value } }) {
      this.form.data.website = addProtocolIfMissing(value)
    },

    async handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (await this.updateOrganizer(this.getFormData())) {
        this.$emit('next')
        this.fetchUser()
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
