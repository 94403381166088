<template>
  <div>
    <v-toolbar flat>
      <v-btn icon @click="hasHistory() ? $router.go(-1) : $router.push('/')">
        <v-icon>fal fa-angle-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <h5>{{ $t('notification.notifications') }}</h5>
      </v-toolbar-title>
      <v-btn
          color="secondary"
          class="elevation-0 ml-auto"
          :to="{ name: allNotifications }"
      >
        {{ $t('notification.allNotifications') }}
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <notification class="mx-8 my-8" v-if="notification" :notification="notification"></notification>
  </div>
</template>

<script>
import Notification from '@/components/common/Notification/Notification'
import { mapGetters } from "vuex";

export default {
  name: 'OrganizerNotifications',

  components: {
    Notification
  },

  data() {
    return {
      notification: null,

      form: {
        loading: false,
      }
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user',
      'isExhibitor',
      'isOrganizer',
      'isAdmin',
    ]),

    allNotifications() {
      if (this.isExhibitor) {
        return 'exhibitor.notifications'
      }
      if (this.isOrganizer) {
        return 'organizer.notifications'
      }
      if (this.isAdmin) {
        return 'admin.notifications'
      }

      return ''
    },
  },

  created() {
    this.$store.dispatch('auth/fetchUser')
      .then(() => {this.load()})
  },

  methods: {
    hasHistory() {
      return window.history.length > 2
    },

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/notifications/${this.$route.params.id}`, {
        params: {
          'languages': '*',
          'include': [],
        },
      })).data

      this.notification = response.data

      this.form.loading = false
    },
  },

  watch: {
    '$route.params.id'() {
      this.load()
    }
  }
}
</script>
