<template>
  <v-row>
    <v-col cols="6">
      <p>{{ $t('settings.sections.assignedProjects.description') }}</p>

      <spinner v-if="$wait.is(loaders.fetchUsers)" />

      <p
        v-for="organizerAdmin in organizerAdmins"
        :key="organizerAdmin.id"
      >
        <v-icon
          color="primary"
          class="mr-2"
          size="16"
        >
          fal fa-arrow-right
        </v-icon>
        {{ organizerAdmin.fullName }}
      </p>
    </v-col>
    <v-col cols="6">
      <v-list color="transparent">
        <v-list-item
          v-for="tradeFair in assignedTradeFairs"
          :key="tradeFair.id"
          class="grey lighten-4 mb-2"
        >
          <v-list-item-title>
            {{ tradeFair.nameDisplay }}
            <br />
            <small>{{ tradeFair.startDateDisplay[$i18n.locale]}} - {{ tradeFair.endDate | dateByLocale}}</small>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { GET_USERS } from '@/store/loading-types'
import { USER_ROLES } from '@/enums/userRole'
import Spinner from '@/components/base/Spinner'

export default {
  name: 'AssignedProjects',

  components: {
    Spinner,
  },

  data() {
    return {
      loaders: {
        fetchUsers: GET_USERS,
      },
    }
  },

  computed: {
    ...mapGetters('tradefair', [
      'assignedTradeFairs',
    ]),

    ...mapGetters('organizer', [
      'users',
    ]),

    organizerAdmins() {
      return this.users.filter(user =>
        (user.roles && user.roles.length) ? (user.roles[0].role === USER_ROLES.ORGANIZER && user.fullName) : false
      )
    },
  },

  methods: {
    ...mapActions('tradefair', [
      'fetchAssignedTradeFairs',
    ]),

    ...mapActions('organizer', [
      'fetchUsers',
    ]),

    ...mapWaitingActions('organizer', {
      fetchUsers: GET_USERS,
    }),
  },

  created() {
    this.fetchAssignedTradeFairs()

    this.fetchUsers()
  },
}
</script>
