<template>
  <v-container
    class="pa-8"
    fluid
  >

    <v-card class="elevation-1 rounded-0 text--primary">
      <v-card-text class="text--primary pa-7">
        <v-row>

          <v-col cols="6">
            <h1 class="mb-3 primary--text">{{ $t('dashboard.supervisor.title') }}</h1>
            <p>{{ $t('dashboard.supervisor.description.welcome') }}</p>
            <p>{{ $t('dashboard.supervisor.description.questions') }} <a href="mailto:support@german-pavilion.com">support@german-pavilion.com</a>.</p>
            <v-btn
              :to="{ name: 'supervisor.fairs' }"
              class="elevation-0 secondary"
            >
              {{ $t('dashboard.supervisor.exhibitionOverview') }}
            </v-btn>
          </v-col>

          <v-col
            cols="6"
            class="pl-10"
          >
            <v-list
              class="text-white my-0"
              style="background: none;"
            >
              <v-list-item class="px-0">
                <v-list-item-icon>
                  <v-icon class="secondary--text">far fa-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <h3>{{ $t('dashboard.supervisor.actions.overviewParticipations') }}</h3>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0">
                <v-list-item-icon>
                  <v-icon class="secondary--text">far fa-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <h3>{{ $t('dashboard.supervisor.actions.getLinks') }}</h3>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0">
                <v-list-item-icon>
                  <v-icon class="secondary--text">far fa-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <h3>{{ $t('dashboard.supervisor.actions.viewStatus') }}</h3>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'SupervisorDashboard',
}
</script>
