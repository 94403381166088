<template>
  <div>
    <v-stepper-step :step="step" :editable="isEditable" :rules="[() => !isStepsErrored]">
      <span class="stepper-abstract" v-if="participation?.attributes?.booking_type === BOOKING_TYPES.SPACE">
        {{ $t('forms.fields.bookingTypeSpace', lang) }}
      </span>
      <span class="stepper-abstract" v-if="participation?.attributes?.booking_type === BOOKING_TYPES.PACKAGE">
        {{ $t('forms.fields.bookingTypePackage', lang) }}
      </span>
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{ $t('participation.bookingTypeSelect.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">{{
          $t('participation.stepsIsErrored', lang)
        }}</small>
    </v-stepper-step>

    <v-stepper-content :step="step" class="ml-2">
      <v-radio-group
          v-model="form.data.booking_type"
          :error-messages="errors.bookingType"
          :readonly="isReadonlyMode"
      >
        <v-row>
          <v-col cols="auto">
            <v-radio :value="BOOKING_TYPES.SPACE" class="ml-2"/>
          </v-col>
          <v-col>
            <strong>{{ $t('forms.fields.bookingTypeSpace', lang) }}</strong>
            <br/>
            {{ $t('participation.bookingTypeSelect.spaceLabel', lang) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto">
            <v-radio :value="BOOKING_TYPES.PACKAGE" class="ml-2"/>
          </v-col>
          <v-col>
            <strong>{{ $t('forms.fields.bookingTypePackage', lang) }}</strong>
            <br/>
            {{ $t('participation.bookingTypeSelect.packageLabel', lang) }}
            <ul>
              <li v-for="(item, i) in fair.relationships.packages" :key='i' v-html="item.attributes.title[lang]">
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-radio-group>

      <v-row>
        <v-col
            cols="12"
            class="text-right"
        >
          <v-btn
              class="elevation-0 secondary"
              :loading="form.loading"
              @click="handleSubmit"
              :disabled="!isSubmittedForm"
          >
            {{ $t('forms.buttons.saveAndContinue', lang) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import {BOOKING_TYPES} from '@/enums/bookingType'
import {mapActions} from 'vuex'
import validation from '@/mixins/validate'
import participationStep from '@/mixins/participationStep'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'BookingTypeSelect',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  mixins: [validation, participationStep, participationMode],

  data() {
    return {
      form: {
        loading: false,

        data: {
          booking_type: BOOKING_TYPES.SPACE,
        },

        validationRules: {
          booking_type: {}
        },

        isValid: true,
      },

      BOOKING_TYPES
    }
  },

  created() {
    this.setInitialFormData(this.participation?.attributes ?? {})
  },

  methods: {
    ...mapActions('participation', [
      'updateParticipation',
    ]),

    async handleSubmit() {
      if (await this.updateParticipation({ fair: this.fair, participation: this.participation, data: this.getFormData() })) {
        this.$nextTick(() => {
          this.$emit('submit')
        })
      }
    }
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
