<template>
  <v-row>
    <v-col
      cols="6"
      class="pr-5"
    >
      <v-text-field
        v-model="form.data.email"
        :error-messages="errors.email"
        :label="$t('forms.fields.email')"
      />

      <v-text-field
        type="password"
        v-model="form.data.password"
        :error-messages="errors.password"
        :label="$t('forms.fields.password')"
        :hint="$t('forms.hints.password')"
      />

      <v-text-field
        type="password"
        v-model="form.data.passwordConfirmation"
        :error-messages="errors.passwordConfirmation"
        :label="$t('forms.fields.passwordConfirmation')"
        :hint="$t('forms.hints.password')"
      />

      <validation-general-error :errors="errors.general" />

      <v-btn
        color="secondary"
        class="elevation-0"
        @click="handleSubmit"
        :loading="$wait.is(loaders.updateUserCredentials)"
      >
        {{ $t('common.save') }}
      </v-btn>
    </v-col>
    <v-col
      cols="6"
      class="pl-5"
    >
      <p>{{ $t('settings.sections.userCredentials.subTitle') }}</p>
      <v-alert dense outlined type="warning">{{ $t('settings.sections.userCredentials.warning') }}</v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators';
import { UPDATE_USER_CREDENTIALS } from '@/store/loading-types'
import validation from '@/mixins/validate'
import ValidationGeneralError from '@/components/base/ValidationGeneralError'

export default {
  name: 'UserCredentials',

  components: {
    ValidationGeneralError,
  },

  mixins: [
    validation,
  ],

  data() {
    return {
      form: {
        data: {
          email: '',
          password: '',
          passwordConfirmation: '',
        },
        validationRules: {
          email: {
            required,
            email,
          },
          password: {
            minLength: minLength(6),
          },
          passwordConfirmation: {
            minLength: minLength(6),
            sameAsPassword: sameAs('password'),
          },
        },
        serverErrors: {
          email: [],
          password: [],
          passwordConfirmation: [],
          general: [],
        },
        custom: {
          validationSubstitutions: {
            passwordConfirmation: {
              sameAsPassword: {
                sameAsField: this.$t('forms.fields.password'),
              },
              minLength: {
                length: 6,
              },
            },
            password: {
              minLength: {
                length: 6,
              },
            },
          },
        },
      },
      loaders: {
        updateUserCredentials: UPDATE_USER_CREDENTIALS,
      },
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user',
    ]),
  },

  created() {
    this.setInitialFormData()
  },

  methods: {
    ...mapActions('auth', [
      'updateCredentials',
    ]),

    ...mapWaitingActions('auth', {
      updateCredentials: UPDATE_USER_CREDENTIALS,
    }),

    setInitialFormData() {
      this.form.data.email = this.user.email
    },

    handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.resetServerErrors()

      var form = this.getFormData();

      if (!this.form.data.password) {
        delete form.password;
        delete form.passwordConfirmation;
      }

      this.updateCredentials(form)
          .catch(error => {
            this.setServerErrors(error.data.errors)
          })
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
