import {helpers} from 'vuelidate/src/validators'


export function minFileSize(param) {
    return helpers.withParams(
        {type: 'minFileSize', value: param},
        (value) => !helpers.req(value) || value.size / 1024 / 1024 > param
    )
}

export function maxFileSize(param) {
    return helpers.withParams(
        {type: 'maxFileSize', value: param},
        (value) => !helpers.req(value) || value.size / 1024 / 1024 < param
    )
}

export function translation() {
    return helpers.withParams(
        {type: 'translation'},
        (value) => helpers.req(value?.en) && helpers.req(value?.de)
    )
}
