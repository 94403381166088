<template>
  <v-app>
    <admin-header />
    <admin-menu-bar />

    <v-main class="grey lighten-4">
      <v-container fluid class="px-0 py-0">
        <router-view />
      </v-container>
    </v-main>

    <snackbar ref="snackbar" />

  </v-app>
</template>

<script>
import AdminHeader from '@/components/admin/Header'
import AdminMenuBar from '@/components/admin/MenuBar'
import Snackbar from '@/components/base/Snackbar'

export default {
  name: 'AdminApp',

  components: {
    AdminHeader,
    AdminMenuBar,
    Snackbar,
  },
}
</script>
