<template>
  <div class="text-center">
    <v-menu
      top
      min-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            size="20"
            class="primary--text"
          >
            fal fa-info-circle
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="document in documents"
          :key="document.name"
          :to="document.route"
        >
          <v-list-item-title>{{ document.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: 'DocumentsMenu',

  data() {
    return {
      documents: [
        {
          route: { name: 'documents.imprint' },
          name: this.$t('common.documents.imprint'),
        },
        {
          route: { name: 'documents.privacyPolicy' },
          name: this.$t('common.documents.privacyPolicy'),
        },
      ],
    }
  },
}
</script>
