import { api } from '@/boot/api'
import {
  SET_FAQ_ARTICLES,
  SET_FAQ_ARTICLE,
  ADD_FAQ_ARTICLE,
  DELETE_FAQ_ARTICLE,
  SET_FAQ_CATEGORIES,
  ADD_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORY,
  SET_HELP_ARTICLES,
  SET_HELP_ARTICLE,
  ADD_HELP_ARTICLE,
  DELETE_HELP_ARTICLE,
  SET_CURRENT_HELP_ARTICLE,
} from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({
  faqs: [],
  articles: [],
  adminCategories: [],
  currentArticleId: null
})

const state = defaultState()

const actions = {
  async fetchFaqArticles({commit}) {
    try {
      const faqs = await api.get('/faq')
      commit(SET_FAQ_ARTICLES, faqs)

      return true
    } catch (error) {
      console.error('API error (fetch faq articles): ', error)
      return false
    }
  },

  async createFaqArticle({ commit }, payload) {
    try {
      const article = await api.post('/faq', payload, { showSuccessNotifications: true })
      commit(ADD_FAQ_ARTICLE, article)

      return true
    } catch (error) {
      console.error('API error (create faq article): ', error)
      return false;
    }
  },

  async updateFaqArticle({ commit }, payload) {
    try {
      const article = await api.patch(`/faq/${payload.id}`, payload.data, { showSuccessNotifications: true })
      commit(SET_FAQ_ARTICLE, article)

      return true
    } catch (error) {
      console.error('API error (update faq article): ', error)
      return false;
    }
  },

  async deleteFaqArticle({ commit }, { id }) {
    try {
      await api.delete(`/faq/${id}`, null, { showSuccessNotifications: true })
      commit(DELETE_FAQ_ARTICLE, id)

      return true
    } catch (error) {

      console.error('API error (delete faq article): ', error)
      return false;
    }
  },

  async fetchCategories({commit}) {
    try {
      const categories = await api.get('/faq-categories')

      commit(SET_FAQ_CATEGORIES, categories)

      return true
    } catch (error) {
      console.error('API error (fetch faq articles): ', error)
      return false
    }
  },

  async createCategory({ commit }, payload) {
    try {
      const category = await api.post('/faq-categories', payload, { showSuccessNotifications: true })
      commit(ADD_FAQ_CATEGORY, category)

      return true
    } catch (error) {
      console.error('API error (create faq category): ', error)
      return false;
    }
  },

  async deleteCategory({ commit }, { id }) {
    try {
      await api.delete(`/faq-categories/${id}`, null, { showSuccessNotifications: true })
      commit(DELETE_FAQ_CATEGORY, id)

      return true
    } catch (error) {

      console.error('API error (delete faq category): ', error)
      return false;
    }
  },

  async setCurrentArticle({commit}, id) {
    commit(SET_CURRENT_HELP_ARTICLE, id)
  },

  async fetchHelpArticles({commit}) {
    try {
      const articles = await api.get('/help-articles')
      commit(SET_HELP_ARTICLES, articles)

      return true
    } catch (error) {
      console.error('API error (fetch help articles): ', error)
      return false
    }
  },

  async updateArticle({ commit }, payload) {
    try {
      const article = await api.patch(`/help-articles/${payload.id}`, payload.data, { showSuccessNotifications: true })
      commit(SET_HELP_ARTICLE, article)

      return true
    } catch (error) {
      console.error('API error (update help article): ', error)
      return false;
    }
  },

  async createArticle({ commit }, payload) {
    try {
      const article = await api.post('/help-articles', payload, { showSuccessNotifications: true })
      commit(ADD_HELP_ARTICLE, article)

      return true
    } catch (error) {
      console.error('API error (create help article): ', error)
      return false;
    }
  },

  async deleteArticle({ commit }, { id }) {
    try {
      await api.delete(`/help-articles/${id}`, null, { showSuccessNotifications: true })
      commit(DELETE_HELP_ARTICLE, id)

      return true
    } catch (error) {

      console.error('API error (delete help article): ', error)
      return false;
    }
  },

}

const mutations = {
  [SET_FAQ_ARTICLES] (state, faqs) {
    state.faqs = faqs
  },

  [SET_FAQ_ARTICLE] (state, articleToUpdate) {
    const articleIndex = state.faqs.findIndex(article => article.id === articleToUpdate.id)
    state.faqs.splice(articleIndex, 1, articleToUpdate)
  },

  [ADD_FAQ_ARTICLE] (state, article) {
    state.faqs.push(article)
  },

  [DELETE_FAQ_ARTICLE] (state, articleId) {
    const articleIndex = state.faqs.findIndex(article => article.id === articleId)
    state.faqs.splice(articleIndex, 1)
  },

  [SET_FAQ_CATEGORIES] (state, categories) {
    state.adminCategories = categories
  },

  [ADD_FAQ_CATEGORY] (state, category) {
    state.adminCategories.push(category)
  },

  [DELETE_FAQ_CATEGORY] (state, categoryId) {
    const categoryIndex = state.adminCategories.findIndex(category => category.id === categoryId)
    state.adminCategories.splice(categoryIndex, 1)
  },

  [SET_HELP_ARTICLES] (state, articles) {
    state.articles = articles
  },

  [SET_CURRENT_HELP_ARTICLE] (state, id) {
    state.currentArticleId = id
  },

  [SET_HELP_ARTICLE] (state, articleToUpdate) {
    const articleIndex = state.articles.findIndex(article => article.id === articleToUpdate.id)
    state.articles.splice(articleIndex, 1, articleToUpdate)
  },

  [ADD_HELP_ARTICLE] (state, article) {
    state.articles.push(article)
  },

  [DELETE_HELP_ARTICLE] (state, articleId) {
    const articleIndex = state.articles.findIndex(article => article.id === articleId)
    state.articles.splice(articleIndex, 1)
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,

  categories: (state, getters, rootState) => {
    let categories = {}
    let locale = rootState.languages.language.locale
    state.faqs.map(function (item) {
      if (item.active) {
        categories[item.category.id] = {
          id: item.category.id,
          title: item.category.translations[locale].title
        }
      }
    })

    return Object.values(categories);
  },

  article: (state) => {
    const id = state.currentArticleId
    const article = state.articles.find(article  => article.id === id)

    if (typeof article === 'undefined') {
      if ( state.articles.length) {
        return state.articles[0]
      }
      return []
    }

    return article
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
