<template>
  <div>
    <h1
      class="mb-4 primary--text"
    >
      {{ $t('common.federalForeignTradeFairProgram')}}
    </h1>
    <p>{{ $t('login.resetPassword') }}</p>

    <v-form
      v-if="!success"
    >
      <v-text-field
        v-model="form.data.email"
        disabled
        :error-messages="errors.email"
        :label="$t('forms.fields.email')"
      />

      <v-text-field
        type="password"
        v-model="form.data.password"
        :error-messages="errors.password"
        :label="$t('forms.fields.password')"
      />

      <v-text-field
        type="password"
        v-model="form.data.passwordConfirmation"
        :error-messages="errors.passwordConfirmation"
        :label="$t('forms.fields.passwordConfirmation')"
      />
    </v-form>

    <validation-general-error :errors="errors.general" />
    <success-message :messages="messages" />

    <div class="mb-10 text-right">
      <p>
        <small>
          <v-btn
            v-if="!success"
            plain
            x-small
            @click="$emit('loginRequested')"
          >
            {{ $t('auth.logIn') }}
          </v-btn>
        </small>
      </p>
    </div>

    <div class="my-10">
      <v-btn
        v-if="success"
        color="primary"
        class="mr-5 elevation-0"
        @click="handleLoginAction"
        :loading="$wait.is(loaders.resetPassword)"
      >
        {{ $t('auth.logIn') }}
      </v-btn>
      <v-btn
        v-else
        color="secondary"
        class="mr-5 elevation-0"
        @click="handleResetPassword"
        :loading="$wait.is(loaders.resetPassword)"
      >
        {{ $t('login.reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { mapWaitingActions } from 'vue-wait'
  import {required, email, minLength, sameAs} from 'vuelidate/lib/validators'
  import { RESET_PASSWORD } from '@/store/loading-types'
  import validation from '@/mixins/validate'
  import ValidationGeneralError from '@/components/base/ValidationGeneralError'
  import SuccessMessage from '@/components/base/Messages/SuccessMessage'

  export default {
    name: 'ResetPasswordForm',

    props: {
      email: {
        type: String,
        required: true,
      },
      token: {
        type: String,
        required: true,
      },
    },

    components: {
      ValidationGeneralError,
      SuccessMessage,
    },

    mixins: [
      validation,
    ],

    data() {
      return {
        form: {
          data: {
            email: '',
            token: '',
            password: '',
            passwordConfirmation: ''
          },
          validationRules: {
            email: {
              required,
              email,
            },
            password: {
              required,
              minLength: minLength(6),
            },
            passwordConfirmation: {
              required,
              minLength: minLength(6),
              sameAsPassword: sameAs('password'),
            },
            token: {}
          },
        },
        loaders: {
          resetPassword: RESET_PASSWORD,
        },
        messages: [],
        success: false
      }
    },

    mounted() {
      this.$root.vtoast = this.$refs.snackbar
    },

    async created() {
      if (!await this.checkToken({email: this.email})) {
        this.$emit('loginRequested')
        return
      }
      this.form.data.email = this.email
      if (this.token.length !== 64) {
        this.$emit('loginRequested')
      }
      this.form.data.token = this.token
    },

    computed: {
      ...mapGetters('auth', [
        'isExhibitorAppAllowed',
      ])
    },

    methods: {
      ...mapActions('auth', [
        'resetPassword',
        'checkToken',
      ]),

      ...mapWaitingActions('auth', {
        resetPassword: RESET_PASSWORD,
      }),

      async handleResetPassword() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        if (await this.resetPassword(this.getFormData())) {
          this.messages = [this.$t('auth.passwordChanged')]
          this.success = true
        }
      },

      handleLoginAction() {
        if (this.isExhibitorAppAllowed) {
          this.$emit('loginRequested')
        } else {
          this.$router.push({ name : 'login' })
        }
      }
    },

    validations() {
      return {
        form: {
          data: {
            ...this.form.validationRules,
          },
        },
      }
    },
  }
</script>
