<template>
  <v-form>
    <v-row>
      <v-col md="2" xl="1">
        <v-select
            v-model="form.data.contact_gender"
            :items="genders"
            item-value="type"
            item-text="label"
            :label="$t('forms.fields.contactGender')"
            :error-messages="errors.contact_gender"
            class="rounded-0"
        />
      </v-col>
      <v-col md="5" xl="2">
        <v-text-field
          v-model="form.data.contact_first_name"
          :loading="form.loading"
          :label="$t('forms.fields.firstName')"
          :error-messages="errors.contact_first_name"
        >
        </v-text-field>
      </v-col>
      <v-col md="5" xl="2">
        <v-text-field
          v-model="form.data.contact_last_name"
          :loading="form.loading"
          :label="$t('forms.fields.lastName')"
          :error-messages="errors.contact_last_name"
        >
        </v-text-field>
      </v-col>
      <v-col md="6" xl="3">
        <v-text-field
          v-model="form.data.contact_email"
          :loading="form.loading"
          :label="$t('forms.fields.email')"
          :error-messages="errors.contact_email"
        >
        </v-text-field>
      </v-col>
      <v-col md="6" xl="3">
        <v-text-field
          v-model="form.data.contact_phone"
          :loading="form.loading"
          :label="$t('forms.fields.phone')"
          :error-messages="errors.contact_phone"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-btn :loading="form.loading" @click="handleSubmit" color="secondary" class="elevation-0">
      {{ $t('common.save') }}
    </v-btn>
  </v-form>
</template>

<script>
import { email } from 'vuelidate/lib/validators'
import { GENDERS } from '@/enums/genders'
import { mapGetters } from 'vuex'
import validation from '@/mixins/validate'

export default {
  name: 'ContactForm',

  mixins: [validation],

  data() {
    return {
      accreditation: false,
      
      form: {
        loading: false,

        data: {
          contact_gender: '',
          contact_first_name: '',
          contact_last_name: '',
          contact_email: '',
          contact_phone: '',
        },
        validationRules: {
          contact_gender: {},
          contact_first_name: {},
          contact_last_name: {},
          contact_email: {
            email
          },
          contact_phone: {},
        },
      },
    }
  },

  async created() {
    await this.load()
    
    this.setInitialFormData(this.accreditation.attributes)
  },

  computed: {
    ...mapGetters('auth', ['user']),

    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`),
      }))
    },
  },

  methods: {
    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/companies/${this.user.companyId}?ignoreApprove=1`, {
        params: {
          'languages': '*',
          'include': [
            'country',
            'company',
          ],
        },
      })).data
      
      this.accreditation = response.data

      this.form.loading = false
    },
    
    async handleSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        if (this.errors.federal_state) {
          this.errors.federal_state = this.$t('forms.fields.federalStateRequired')
        }

        return
      }

      this.form.loading = true
      try {
        await this.$axios.put(`/api/accreditations/${this.accreditation.id}`, {
          ...this.getFormData(),
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
