<template>
  <div>
    <v-stepper-step
        :step="step"
        :editable="isEditable"
        :rules="[() => !isStepsErrored]"
        @click="$emit('click')"
    >
      <span class="stepper-abstract"/>
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{ $t('participation.financialSupport.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">{{ $t('participation.stepsIsErrored', lang) }}</small>
    </v-stepper-step>

    <v-stepper-content
        :step="step"
        class="pb-10"
    >
      <v-row>
        <v-col cols="10">
          <p>
            <strong>{{ $t('participation.financialSupport.declaration.title', lang) }}</strong>
          </p>

          <v-row>
            <v-col cols="1">
              <v-checkbox
                  disabled
                  v-model="form.data.declared_public_sector_involvement"
                  class="my-0 pl-2"
              />
            </v-col>
            <v-col>
              <small>{{
                  $t('participation.financialSupport.declaration.fromAccreditation', lang)
                }}</small><br>
              {{ $t('participation.financialSupport.declaration.list.notReceiveFunding', lang) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              <v-checkbox
                  disabled
                  v-model="form.data.declared_public_sector_institution"
                  class="my-0 pl-2"
              />
            </v-col>
            <v-col>
              <small>{{
                  $t('participation.financialSupport.declaration.fromAccreditation', lang)
                }}</small><br>
              {{
                $t('participation.financialSupport.declaration.list.companyIsNotAuthority', lang)
              }}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              <v-checkbox
                  disabled
                  v-model="form.data.declared_public_sector_majority"
                  class="my-0 pl-2"
              />
            </v-col>
            <v-col>
              <small>{{
                  $t('participation.financialSupport.declaration.fromAccreditation', lang)
                }}</small><br>
              {{
                $t('participation.financialSupport.declaration.list.noReligionInterestInCompany', lang)
              }}
            </v-col>
          </v-row>

          <v-row class="pl-4 pt-2">
            <validation-general-error :errors="form.errors"/>
          </v-row>

        </v-col>

        <v-col
            cols="12"
            class="text-right"
        >
          <v-btn
              class="elevation-0 secondary"
              @click="handleSubmit"
              :disabled="!isSubmittedForm"
          >
            {{ $t('forms.buttons.saveAndContinue', lang) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import ValidationGeneralError from '@/components/base/ValidationGeneralError'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'FinancialSupport',

  components: {
    ValidationGeneralError,
  },

  mixins: [
    participationMode,
  ],

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  data() {
    return {
      form: {
        loading: false,

        data: {
          declared_public_sector_involvement: false,
          declared_public_sector_institution: false,
          declared_public_sector_majority: false,
        },

        errors: [],
        isValid: true,
      },
    }
  },

  created() {
    this.setInitialFormData()
  },

  methods: {
    setInitialFormData() {
      if (this.participation?.relationships) {
        this.form.data.declared_public_sector_involvement = this.participation.relationships.company?.attributes?.declared_public_sector_involvement
        this.form.data.declared_public_sector_institution = this.participation.relationships.company?.attributes?.declared_public_sector_institution
        this.form.data.declared_public_sector_majority = this.participation.relationships.company?.attributes?.declared_public_sector_majority
      } else if (this.company) {
        this.form.data.declared_public_sector_involvement = this.company.attributes?.declared_public_sector_involvement
        this.form.data.declared_public_sector_institution = this.company.attributes?.declared_public_sector_institution
        this.form.data.declared_public_sector_majority = this.company.attributes?.declared_public_sector_majority
      }
    },

    handleSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
