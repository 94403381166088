<template>
  <v-progress-circular
    indeterminate
    :color="color"
    :size="size"
    :rotate="-90"
  />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    size: {
      type: Number,
      required: false,
      default: 32,
    }
  }
}
</script>
