<template>
  <v-expansion-panels class="rounded-0" v-model="activePanel" v-if="display">
    <component
        v-for="(component, index) in components"
        :key="index"
        :is="component"
        :fair="fair"
        @submit="handleSubmit"
        @move="handleMove"
        @reset="handleReset"
        v-on:booking-types="handleBookingType"
    />
  </v-expansion-panels>
</template>

<script>
import CustomizeRegistrationGeneral from '@/components/organizer/Application/Customize/CustomizeRegistrationGeneral';
import CustomizeRegistrationExhibitionareas from '@/components/organizer/Application/Customize/CustomizeRegistrationExhibitionareas'
import CustomizeRegistrationFees from '@/components/organizer/Application/Customize/CustomizeRegistrationFees'
import CustomizeRegistrationServices from '@/components/organizer/Application/Customize/CustomizeRegistrationServices'
import CustomizeRegistrationPackages from '@/components/organizer/Application/Customize/CustomizeRegistrationPackages'
import CustomizeRegistrationSpace from '@/components/organizer/Application/Customize/CustomizeRegistrationSpace'

export default {
  name: 'OrganizerCustomizeRegistration',

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  components: {
    CustomizeRegistrationGeneral,
    CustomizeRegistrationServices,
    CustomizeRegistrationFees,
    CustomizeRegistrationExhibitionareas,
    CustomizeRegistrationPackages,
    CustomizeRegistrationSpace
  },

  data() {
    return {
      display: true,

      activePanel: 0,

      bookingTypes: {
        bookingTypeSpace: null,
        bookingTypePackage: null,
      }
    }
  },

  computed: {
    components() {
      let components = [
        CustomizeRegistrationGeneral,
      ]

      if (this.bookingTypes.bookingTypeSpace) {
        components.push(CustomizeRegistrationSpace)
      }
      if (this.bookingTypes.bookingTypePackage) {
        components.push(CustomizeRegistrationPackages)
      }

      components = components.concat([
        CustomizeRegistrationFees,
        CustomizeRegistrationServices,
        CustomizeRegistrationExhibitionareas,
      ])

      return components
    }
  },

  methods: {
    handleBookingType(val) {
      this.bookingTypes = val;
    },

    handleSubmit() {
      this.activePanel += 1
    },

    handleReset() {
      this.display = false

      this.$nextTick(() => {
        this.display = true
      })
    },

    handleMove(component) {
      this.activePanel = this.components.findIndex((el) => el.name === component)
    }
  }
}
</script>
