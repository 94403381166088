import Vue from 'vue'
import App from './App.vue'
import router from '@/boot/router'
import store from '@/boot/store'
import wait from '@/boot/wait'
import axios from 'axios'
import vuetify from '@/boot/vuetify'
import interceptor from '@/boot/interceptor'
import VueI18n from 'vue-i18n'
import Vuelidate from 'vuelidate'
import Fragment from 'vue-fragment'
import VueClipboards from 'vue-clipboards'
import excel from 'vue-excel-export'

import VueEditor from '@/boot/vue-editor'

import i18n from '@/boot/i18n'
import HelpButton from '@/components/base/HelpButton'
import '@/boot/filters'

Vue.config.productionTip = false

Vue.component('HelpButton', HelpButton)

Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(Fragment.Plugin)
Vue.use(VueClipboards)
Vue.use(excel)
Vue.use(interceptor)

new Vue({
  router,
  store,
  axios,
  vuetify,
  VueEditor,
  i18n,
  wait,
  interceptor,
  render: h => h(App)
}).$mount('#app')
