<template>
  <v-dialog v-model="display" max-width="600">
    <v-card>
      <v-card-title style="justify-content: space-between">
        <h4 class="primary--text">
          {{ $t('users.userManagement.actions.createUser') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider/>
      <v-form>
        <v-container class="px-7">
          <v-checkbox
              v-model="form.data.isAdmin"
              :label="$t('users.userManagement.canManage')"
              small
          />
          <v-row>
            <v-col md="6">
              <v-text-field
                  v-model="form.data.first_name"
                  :error-messages="errors.first_name"
                  :label="$t('forms.fields.firstName')"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                  v-model="form.data.last_name"
                  :error-messages="errors.last_name"
                  :label="$t('forms.fields.lastName')"
              />
            </v-col>
          </v-row>
          <v-text-field
              v-model="form.data.email"
              :error-messages="errors.email"
              :label="$t('forms.fields.email')"
              autocomplete="off"
          />

          <v-select
              v-model="form.data.gender"
              :items="genders"
              item-value="type"
              item-text="label"
              :label="$t('forms.fields.gender')"
              :error-messages="errors.gender"
              class="rounded-0"
          />
          <v-row>
            <v-col md="6">
              <v-text-field
                  type="password"
                  v-model="form.data.password"
                  :error-messages="errors.password"
                  :label="$t('forms.fields.password')"
                  :hint="$t('forms.hints.password')"
                  autocomplete="new-password"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                  type="password"
                  v-model="form.data.password_confirmation"
                  :error-messages="errors.password_confirmation"
                  :label="$t('forms.fields.passwordConfirmation')"
                  :hint="$t('forms.hints.password')"
                  autocomplete="new-password"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6">
              <v-text-field
                  v-model="form.data.phone"
                  :error-messages="errors.phone"
                  :label="$t('forms.fields.phone')"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                  v-model="form.data.fax"
                  :error-messages="errors.fax"
                  :label="$t('forms.fields.fax')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-btn
                  class="elevation-0 secondary float-right"
                  :loading="form.loading"
                  @click="handleSubmit"
              >
                {{ $t('common.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import { USER_ROLES } from '@/enums/userRole'
import { GENDERS } from '@/enums/genders'

export default {
  name: 'CreateUserModal',

  mixins: [validation],

  data() {
    return {
      display: false,
      form: {
        loading: false,
        data: {
          first_name: '',
          last_name: '',
          gender: null,
          email: '',
          password: '',
          password_confirmation: '',
          phone: '',
          fax: '',
          isAdmin: null,
        },
        validationRules: {
          first_name: {
            required,
          },
          last_name: {
            required,
          },
          gender: {
            required,
          },
          email: {
            required,
            email,
          },
          password: {
            required,
            minLength: minLength(6),
          },
          password_confirmation: {
            required,
            minLength: minLength(6),
            sameAsPassword: sameAs('password'),
          },
          phone: {
            minLength: minLength(6),
          },
          fax: {
            minLength: minLength(6),
          },
          isAdmin: {},
        },
        custom: {
          validationSubstitutions: {
            password_confirmation: {
              sameAsPassword: {
                sameAsField: this.$t('forms.fields.password'),
              },
              minLength: {
                length: 6,
              },
            },
            password: {
              minLength: {
                length: 6,
              },
            },
            phone: {
              minLength: {
                length: 6,
              },
            },
            fax: {
              minLength: {
                length: 6,
              },
            },
          },
        },
      },
    }
  },

  computed: {
    ...mapGetters('organizer', ['organizer']),

    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`),
      }))
    },
  },

  methods: {
    async handleSubmit() {
      this.resetServerErrors()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        await this.$axios.post(`/api/users`, {
          ...this.form.data,
          organizer_id: this.organizer.id,
          role: this.form.data.isAdmin ? USER_ROLES.ORGANIZER : USER_ROLES.PROJECT_MANAGER
        })

        this.$emit('submit')
        this.$snackbar(this.$t('common.successApiMessage'))
        this.clearFormData()
        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },

    show() {
      this.clearFormData()
      this.resetServerErrors()

      this.$nextTick(() => {
        this.$v.$reset()
      })

      this.display = true
    },

    hide() {
      this.display = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
