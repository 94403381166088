<template>
  <div>
    <v-toolbar flat>
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fal fa-angle-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <h5>{{ $t('common.back') }}</h5>
      </v-toolbar-title>
    </v-toolbar>

    <notes :company="company"></notes>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Notes from '@/components/base/Notes/Notes.vue'

export default {
  name: 'SupervisorNotes',

  components: {
    Notes
  },

  computed: {
    ...mapGetters('company', ['company']),
  },

  async created() {
    await this.fetchCompany({
      id: this.$route.params.id,
      ignoreApprove: true,
    })
  },

  methods: {
    ...mapActions('company', ['fetchCompany']),
  }
}
</script>