export default {
  login: {
    federalForeignTradeFairProgram: 'The Foreign Trade Fair Participation Programme of Germany',
    eventOverviewAndExhibitorRegistration: 'Event overview and exhibitor registration',
    inCooperationWith: 'In cooperation with',
    description: {
      title: 'Your company\'s participation in German joint stands at trade fairs worldwide',
      worldwideOverview: 'Overview of German joint stands at trade fairs worldwide',
      attractiveConditions: 'Your trade fair participation at attractive conditions',
      experiencedProcessing: 'Planning, consulting and handling by experienced trade fair companies from Germany',
      onlineBooking: 'Convenient online booking of your participation at trade fairs abroad',
    },
    loginForImplementationCompanies: 'Login for implementation companies and associations',
    contactIfDontHaveAccessData: 'If you do not yet have access data or need support, please contact us by e-mail at ',
    resetPassword: 'Reset password',
    reset: 'Reset',
  },
}
