<template>
  <v-expansion-panel v-if="companies.length" class="elevation-1 rounded-0">
    <v-expansion-panel-header>
      <div>
        <h3>{{ $t('menu.interestedPersons') }}</h3>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <v-data-table
          :headers="headers"
          :loading="form.loading"
          :items="companies"
          :page.sync="form.page"
          :options.sync="options"
          :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
          :server-items-length="form.total"
      >
        <template v-slot:item.company="{ item }">
          <router-link v-if="isAdmin" class="text-center" :to="{ name: 'admin.company', params: { id: item.id } }">{{ item.relationships.accreditation ? item.relationships.accreditation.company : '' }}</router-link>
          <router-link v-else class="text-center" :to="{ name: 'supervisor.company', params: { id: item.id } }">{{ item.relationships.accreditation ? item.relationships.accreditation.company : '' }}</router-link>
        </template>

        <template v-slot:item.city="{ item }">
          <span>
            {{ item.relationships.accreditation?.city }}
          </span>
        </template>

        <template v-slot:item.accreditation_approved_at="{ item }">
          <span>
            {{ item.relationships.accreditation?.accreditation_approved_at | dateByLocale }}
          </span>
        </template>

        <template v-slot:item.email="{ item }">
          <a :href="'mailto:' + item.relationships.accreditation?.email">
            {{ item.relationships.accreditation?.email }}
          </a>
        </template>

        <template v-slot:item.website="{ item }">
          <a :href="item.relationships.accreditation?.website" target="_blank">
            {{ item.relationships.accreditation?.website }}
          </a>
        </template>

        <template v-slot:item.reserved_on="{ item }">
          <span>{{ item.attributes.created_at | dateByLocale }}</span>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InterestedCompanies',

  data() {
    return {
      companies: [],
      options: {},

      form: {
        total: 0,
        loading: false,
      },

      filters: {},
    }
  },

  computed: {
    ...mapGetters('auth', [
      'isAdmin',
    ]),

    headers() {
      return  [
        {
          text: this.$t('interest.company'),
          value: 'company',
          sortable: true,
        },
        {
          text: this.$t('interest.location'),
          value: 'city',
          sortable: true,
        },
        {
          text: this.$t('interest.accredited'),
          value: 'accreditation_approved_at',
          sortable: true,
        },
        {
          text: this.$t('interest.email'),
          value: 'email',
          sortable: true,
        },
        {
          text: this.$t('interest.website'),
          value: 'website',
          sortable: true,
        },
        {
          text: this.$t('interest.reservedOn'),
          value: 'reserved_on',
          sortable: true,
          dataType: 'Date',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ];
    },
  },

  async created() {
    this.load()
  },

  methods: {
    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get(`/api/trade-fairs/${this.$route.params.id}/interested-companies`, {
        params: {
          'languages': '*',
          'include': [
            'users',
            'tradeFairs',
            'industrySectors',
            'countries',
            'accreditation',
            'accreditationIgnoreApprove',
            'submittedParticipations',
          ],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      this.companies = response.data
      this.companies = this.companies.map((el) => {
        el.relationships.accreditation = el.relationships.accreditation ?? el.relationships.accreditationIgnoreApprove

        return el
      })

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    }
  },

  watch: {
    'options'() {
      this.load()
    },

    'filters': {
      handler() {
        this.options.page = 1

        this.load()
      },
      deep: true
    },
  },
}
</script>
