<template>
  <v-col
    cols="12"
    class="text-right"
  >
    <v-btn
      class="elevation-0 secondary"
      :loading="isLoading"
      :disabled="isReadonly"
      @click="$emit('clicked')"
    >
      {{ $t('forms.buttons.saveAndContinue') }}
    </v-btn>
  </v-col>
</template>

<script>
export default {
  name: 'SubmitButton',

  props: {
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script>
