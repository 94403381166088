<template>
  <v-dialog v-model="isShown" max-width="700">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('accreditation.steps.complete.successModalTitle') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <p class="mt-5">
          {{ $t('accreditation.steps.complete.successModalMessage') }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" @click="hide">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AccreditationSubmitModal',

  data() {
    return {
      isShown: false,
    }
  },

  methods: {
    show() {
      this.isShown = true
    },

    hide() {
      this.isShown = false

      return this.$router.push({ name: 'exhibitor.dashboard' })
    },
  },
}
</script>
