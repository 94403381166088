const USER_ROLES = {
  EXHIBITOR: 'exhibitor',
  ORGANIZER: 'organizer',
  PROJECT_MANAGER: 'project_manager',
  ASSOCIATION: 'association',
  ADMINISTRATOR: 'administrator',
  ADMIN: 'admin',
  SUPERVISOR: 'supervisor',
}

export {
  USER_ROLES,
}
