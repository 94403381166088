<template>
  <v-container fluid>
    <v-card class="elevation-1 rounded-0">
      <v-card-title>
        <h3 class="primary--text">{{ $t('overview.myTradeFairParticipationsOverview') }}</h3>
      </v-card-title>
      <v-card-text>
        <v-switch
          v-model="filters.favorite"
          :label="$t('overview.showFavorite')"
          class="d-inline-block mr-5"
        />

        <v-switch
          v-model="filters.complete"
          :label="$t('overview.showCompleted')"
          class="d-inline-block"
        />

        <ParticipationList @submit="$emit('submit')" :rows="rows" :loading="loading"/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ParticipationList from '@/components/exhibitor/Participation/ParticipationList'

export default {
  name: 'ParticipationOverview',

  props: {
    fairs: Array,
    participations: Array,
    loading: Boolean,
  },

  components: {
    ParticipationList
  },

  data() {
    return {
      filters: {
        favorite: true,
        complete: false,
      }
    }
  },

  computed: {
    rows() {
      let fairs = []

      for (let item of this.participations) {
        fairs.push({ ...item.relationships.trade_fair, participation: item })
      }

      if (this.filters.favorite) {
        for (let item of this.fairs) {
          if (item.included.is_favorite) {
            if (!fairs.find((el) => el.id === item.id)) {
              fairs.push({ ...item })
            }
          }
        }
      }

      if (!this.filters.complete) {
        fairs = fairs.filter((el) => !el.participation || !el.participation.included.is_closed)
      }

      fairs = fairs.sort((a, b) => (a.attributes.start_date > b.attributes.start_date) ? 1 : ((b.attributes.start_date > a.attributes.start_date) ? -1 : 0))
      let current = [].concat(fairs).filter((el) => el.participation && !el.participation.included.is_closed)
      let favorited = [].concat(fairs).filter((el) => el.included.is_favorite).filter((el1) => ![...current].find((el2) => el1.id == el2.id))
      let closed = [].concat(fairs).filter((el) => el.participation && el.participation.included.is_closed).filter((el1) => ![...current, ...favorited].find((el2) => el1.id == el2.id))
      closed = closed.sort((a, b) => (a.attributes.start_date > b.attributes.start_date) ? -1 : ((b.attributes.start_date > a.attributes.start_date) ? 1 : 0))

      return [ ...current, ...favorited, ...closed]
    },
  },
}
</script>
