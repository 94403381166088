<template>
  <v-app-bar class="elevation-1"
    app
    clipped-left
    elevation="1"
    fixed
    color="white"
    height="80"
  >

    <v-app-bar-nav-icon>
      <v-icon class="primary--text hidden-sm-and-up">fal fa-bars</v-icon>
    </v-app-bar-nav-icon>

    <v-img
      src="/img/logos/Made-in-Germany.svg"
      max-height="60"
      max-width="80"
      class="mx-6"
      contain
    />

    <v-toolbar-title class="align-center pl-10">
      <h4 class="primary--text">{{ $t('header.federalForeignTradeFairProgram') }}</h4>
      <h6>{{ $t('header.eventOverviewAndExhibitorRegistration') }}</h6>
    </v-toolbar-title>

    <v-spacer />

    <language-picker />

    <documents-menu />

    <v-divider
      vertical
      class="ml-10 mr-5"
    />

    <v-menu open-on-click>
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-on="on"
          class="text--primary"
        >
          <span class="text-capitalize font-weight-regular text--primary">{{ userName }}</span>
          <v-icon
            right
            size="22"
            class="ml-4 primary--text"
          >
            fas fa-fw fa-user-circle
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item :to="{ name: 'login' }">
          <v-list-item-title>{{ $t('header.logOut') }}</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import LanguagePicker from '@/components/base/LanguagePicker'
import DocumentsMenu from '@/components/common/Documents/Menu'

export default {
  name: 'SupervisorHeader',

  components: {
    LanguagePicker,
    DocumentsMenu,
  },

  computed: {
    ...mapGetters('auth', [
      'userName',
    ]),
  },
}
</script>
