import { SET_SNACKBAR, UNSET_SNACKBAR } from '@/store/mutation-types'
import { makeDefaultGetters, setState } from '@/utilities/helpers/store'
import { NOTIFICATION_TYPES } from '@/enums/notificationType'

const defaultState = () => ({
  message: '',
  type: ''
})

const state = defaultState()

const actions = {
  setSnackbar({ commit }, payload) {
    commit(SET_SNACKBAR, payload)
  },

  clearSnackbar({ commit }, payload) {
    commit(UNSET_SNACKBAR, payload)
  },
}

const mutations = {
  [SET_SNACKBAR] (state, payload) {
    if (typeof payload === 'string' || payload instanceof String) {
      state.message = payload
      return
    }

    if (!payload.message) {
      console.error('Message for notification is missing')
    }

    if (![NOTIFICATION_TYPES.SUCCESS, NOTIFICATION_TYPES.WARNING, NOTIFICATION_TYPES.ERROR].includes(payload.type)) {
      console.error(`Notification type ${payload.type} is not supported`)
    }

    state.message = payload.message || ''
    state.type = payload.type || ''
  },

  [UNSET_SNACKBAR] (state) {
    setState(state, defaultState())
  }
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
