<template>
  <v-app>
    <exhibitor-header />
    <exhibitor-menu-bar />

    <v-main class="grey lighten-4">
      <v-container fluid class="px-0 py-0">
        <router-view />
      </v-container>
    </v-main>

    <snackbar ref="snackbar" />

  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import ExhibitorHeader from '@/components/exhibitor/Header.vue'
import ExhibitorMenuBar from '@/components/exhibitor/MenuBar.vue'
import Snackbar from '@/components/base/Snackbar.vue'
import {mapWaitingActions} from "vue-wait";
import {GET_TRADE_FAIRS} from "@/store/loading-types";

export default {
  name: 'AppExhibitor',

  components: {
    ExhibitorHeader,
    ExhibitorMenuBar,
    Snackbar,
  },

  created () {
    this.fetchHelpArticles()
    this.fetchAllTradeFairs()
  },

  mounted() {
    this.$root.vtoast = this.$refs.snackbar
  },

  methods: {
    ...mapActions('help', [
      'fetchHelpArticles'
    ]),

    ...mapActions('tradefair', [
      'fetchAllTradeFairs'
    ]),

    ...mapWaitingActions('tradefair', {
      fetchAllTradeFairs: GET_TRADE_FAIRS,
    }),
  },
}
</script>
