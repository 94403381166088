import { api } from '@/boot/api'
import { SET_STATES } from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({
  states: [],
})

const state = defaultState()

const actions = {
  async fetchStates({ commit, state }, payload = {}) {
    try {
      if (state.states.length) {
        return
      }

      const states = await api.get('/states', payload)
      commit(SET_STATES, states)

      return true
    } catch (error) {
      console.error('API error (fetch states): ', error)
      return false
    }
  },
}

const mutations = {
  [SET_STATES] (state, states) {
    state.states = states
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
