<template>
  <message
    :messages="messages"
    type="success"
  />
</template>

<script>
import Message from '@/components/base/Messages/Message'

export default {
  components: {
    Message,
  },

  props: {
    messages: {
      type: Array,
      required: false,
    },
  },
}
</script>

