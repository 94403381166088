export default {
  overview: {
    myTradeFairParticipationsOverview : 'My trade fair participations - overview',
    showFavorite: 'Show bookmarked trade fairs',
    showCompleted: 'Show completed trade fair participations',
    currentTradeFairParticipations: 'Current trade fair participations',
    overview: 'Overview',
    noted: 'Bookmarked',
    closed: 'Closed',
  },
}
