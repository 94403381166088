<template>
  <div v-if="fair.id">
    <v-toolbar flat>
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fal fa-angle-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <h5 v-html="fair.attributes.name_display"></h5>
      </v-toolbar-title>
    </v-toolbar>

    <v-divider />

    <v-container class="pa-8" fluid>
      <application-form v-if="init" :fair="fair" :mode="mode"/>
    </v-container>
  </div>
</template>

<script>
import { REGISTRATION_MODES } from '@/enums/registrationModes'
import { mapActions, mapGetters } from 'vuex'
import ApplicationForm from '@/components/ApplicationForm'

export default {
  name: 'ExhibitorParticipationApplication',

  components: {
    ApplicationForm,
  },

  data() {
    return {
      init: false,

      fair: {},

      form: {
        loading: false,
      },

      REGISTRATION_MODES
    }
  },

  computed: {
    ...mapGetters('participation', [
      'participation',
    ]),

    mode() {
      return this.participation?.attributes.submitted_at ? REGISTRATION_MODES.READONLY : REGISTRATION_MODES.LIVE
    }
  },

  async created() {
    await this.load()

    await this.fetchParticipationByTradeFair({ fair: this.fair })

    this.init = true
  },

  methods: {
    ...mapActions('participation', {
      fetchParticipationByTradeFair: 'fetchParticipationByTradeFair',
    }),

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/trade-fairs/${this.$route.params.id}`, {
        params: {
          'languages': '*',
          'include': [
            'accessUsers',
            'associations',
            'country',
            'exhibitionAreas',
            'industrySectors',
            'organizer',
            'packages',
            'services',
            'spaces',
            'type',
            'interestedCompanies',
          ],
        },
      })).data

      this.fair = response.data

      this.form.loading = false
    },
  }
}
</script>

<style>
  .v-application--is-ltr .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
    border: 0;
  }
</style>