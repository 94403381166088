<template>
  <interest-profile-form :is-centered="false">
    <template v-slot:header>
      <v-col cols="12">
        <p>{{ $t('settings.sections.profileOfInterest.description') }}</p>
      </v-col>
    </template>

    <template v-slot="scope">
      <v-col cols="12">
        <v-btn
          @click="scope.submit"
          color="secondary"
          class="elevation-0"
        >
          {{ $t('common.save') }}
        </v-btn>
      </v-col>
    </template>
  </interest-profile-form>
</template>

<script>
import InterestProfileForm from '@/components/exhibitor/Company/InterestProfileForm'

export default {
  name: 'InterestProfile',

  components: {
    InterestProfileForm,
  },
}
</script>
