export default {
  public: {
    fairs: {
      applyFilters: 'Apply filter',
      findTradeFairs: 'Find trade fairs',
      findTradeFairsDisclaimer: '<p>This page provides you with an overview of the events planned for the foreign trade fair programmes of the Federal Ministry for Economic Affairs and Climate Action and the Federal Ministry of Food and Agriculture.</p>\n' +
          '<p>You will receive information on the participation conditions for your company, can make a note of events and register bindingly.</p>',
      findTradeFairsDisclaimerButton: 'Register and create user account',
      customizeFilters: 'Customise filter',
    },
  },
}
