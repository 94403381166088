import { api } from '@/boot/api'
import { SET_SECTORS } from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'

const defaultState = () => ({
  sectors: [],
})

const state = defaultState()

const actions = {
  async fetchSectors({ commit, state }, payload = {}) {
    try {
      if (state.sectors.length) {
        return true
      }

      const sectors = await api.get('/sectors', payload)
      commit(SET_SECTORS, sectors)

      return true
    } catch (error) {
      console.error('API error (fetch sectors): ', error)
      return false;
    }
  },
}

const mutations = {
  [SET_SECTORS] (state, sectors) {
    state.sectors = sectors
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
