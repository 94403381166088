<template>
  <reset-password-form
    :email="email"
    :token="token"
    @loginRequested="$emit('loginRequested')"
  />
</template>

<script>
  import ResetPasswordForm from '@/components/base/Auth/ResetPasswordForm'

  export default {
    name: 'ExhibitorResetPassword',
    props: {
      email: {
        type: String,
        required: true,
      },
      token: {
        type: String,
        required: true,
      },
    },
    components: {
      ResetPasswordForm,
    },
  }
</script>