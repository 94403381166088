<template>
  <v-container class="pa-9" fluid>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1">
          <div class="py-4 px-4">
            <h4>{{ $t('dashboard.myTradeFairParticipations') }}</h4>
          </div>

          <v-divider></v-divider>

          <current-fair-list/>

          <v-divider></v-divider>

          <div class="py-4 px-4">
            <v-btn
                :to="{ name: 'exhibitor.participation.overview'}"
                color="secondary"
                class="elevation-0"
                small
            >
              {{ $t('dashboard.tradeFairParticipationsOverview') }}
            </v-btn>
          </div>

        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet elevation="1">

          <div class="py-4 px-4">
            <h4>{{ $t('dashboard.theseTradeFairParticipationsCouldBeInterestingForYou') }}</h4>
          </div>

          <v-divider></v-divider>

          <preferred-fair-list></preferred-fair-list>

          <v-divider></v-divider>

          <div class="py-4 px-4">
            <v-btn
                :to="{ name: 'exhibitor.fairs'}"
                color="secondary"
                class="elevation-0"
                small
            >
              {{ $t('dashboard.findMoreTradeFairs') }}
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PreferredFairList from '@/components/exhibitor/Fair/PreferredFairList'
import CurrentFairList from '@/components/exhibitor/Fair/CurrentFairList'

export default {
  name: 'ExhibitorHome',

  components: {
    PreferredFairList,
    CurrentFairList,
  },
}
</script>
