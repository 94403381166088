<template>
  <div>
    <v-stepper-step
      :step="step"
      :editable="available && isEditable"
      :rules="[() => !isStepsErrored]"
      @click="$emit('click')"
    >
      <span class="stepper-abstract" v-if="available">
        {{
          $tc(
              'participation.subExhibitors.subExhibitor',
              coexhibitors.length,
              lang,
              {amount: coexhibitors.length}
          )
        }}
      </span>
      <span class="stepper-abstract" v-else>
        {{ $t('forms.hints.notAvailable', lang) }}
      </span>

      <h3 :class="!isStepsErrored && available ? 'primary--text' : ''">
        {{ $t('participation.subExhibitors.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">{{
          $t('participation.stepsIsErrored', lang)
        }}</small>
    </v-stepper-step>

    <v-stepper-content :step="step" class="pb-10">
      <v-row>
        <v-col cols="10">
          <div v-html="$t('participation.subExhibitors.note')"></div>
          <table class="table-costs mt-3" style="width: auto;">
            <thead>
            <tr>
              <th>
                {{ $t('participation.equipment.description') }}
              </th>
              <th class="text-right">
                {{ $t('participation.equipment.unitPrice') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="fair.attributes.coexhibitor_fee">
              <td>
                {{ fair.attributes.coexhibitor_fee_title[lang] }}
              </td>
              <td class="text-right">
                {{ fair.attributes.coexhibitor_fee | money }}
              </td>
            </tr>
            <tr v-if="fair.attributes.coexhibitor_external_fee">
              <td>
                {{ fair.attributes.coexhibitor_external_fee_title[lang] }}
              </td>
              <td class="text-right">
                {{ fair.attributes.coexhibitor_external_fee | money }}
              </td>
            </tr>
            <tr v-if="fair.attributes.coexhibitor_external_fee2">
              <td>
                {{ fair.attributes.coexhibitor_external_fee2_title[lang] }}
              </td>
              <td class="text-right">
                {{ fair.attributes.coexhibitor_external_fee2 | money }}
              </td>
            </tr>
            </tbody>
          </table>
        </v-col>

        <v-col cols="10" class="pr-10">
          <div class="grey lighten-4 pa-4 mb-4"
               v-for="coexhibitor in coexhibitors"
               :key="coexhibitor.id"
          >
            <v-row>
              <v-col cols="8">
                {{ coexhibitor.attributes.company }}
                <br />
                {{ $t('participation.subExhibitors.contactPerson', lang) }}:
                {{ $t(`common.salutation.${coexhibitor.attributes.contact_gender}`, lang) }}
                {{ coexhibitor.attributes.contact_firstname }} {{ coexhibitor.attributes.contact_name }}<br/>
              </v-col>
              <v-col
                  cols="4"
                  class="py-2 d-flex justify-end"
              >
                <v-btn
                    class="elevation-0"
                    @click="showViewModal(coexhibitor)"
                    text
                    color="secondary"
                >
                  <span class="hidden-sm-and-down">{{ $t('common.view')}}</span>
                </v-btn>
                <v-btn
                    class="elevation-0"
                    v-if="!isReadonlyMode"
                    @click="showEditModal(coexhibitor)"
                    text
                    color="secondary"
                >
                  <span class="hidden-sm-and-down">{{ $t('common.edit')}}</span>
                </v-btn>

                <v-btn
                    class="elevation-0"
                    v-if="!isReadonlyMode"
                    @click="showDeleteModal(coexhibitor)"
                    text
                    color="secondary"
                >
                  <span class="hidden-sm-and-down">{{ $t('common.delete')}}</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-btn
            @click="showCreateModal"
            :disabled="!isSubmittedForm || !available"
            text
            outlined
            color="secondary"
          >
            <v-icon size="16" class="mr-2"> fal fa-plus </v-icon>
            {{
              $t('participation.subExhibitors.addSubExhibitors', lang)
            }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="text-right">
          <v-btn
            class="elevation-0 secondary"
            @click="handleSubmit"
            :disabled="!isSubmittedForm || !available"
          >
            {{ $t('forms.buttons.saveAndContinue', lang) }}
          </v-btn>
        </v-col>

        <confirmation-modal
          ref="delete"
          @confirm="handleDelete"
        >
          {{ $t('common.deleteConfirmation', { item: itemToDelete.company }) }}
        </confirmation-modal>
        <create-sub-exhibitor-modal
          :participation="participation"
          :company="company"
          :fair="fair"
          :mode="mode"
          ref="create"
        />
        <view-sub-exhibitor-modal
            :participation="participation"
            :company="company"
            :fair="fair"
            :mode="mode"
            ref="view"
        />
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CreateSubExhibitorModal from '@/components/exhibitor/Participation/Modals/CreateSubExhibitor'
import ViewSubExhibitorModal from '@/components/exhibitor/Participation/Modals/ViewSubExhibitor'
import ConfirmationModal from '@/components/base/ConfirmationModal'
import participationMode from '@/mixins/participationMode'

export default {
  name: 'SubExhibitors',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  components: {
    CreateSubExhibitorModal,
    ViewSubExhibitorModal,
    ConfirmationModal,
  },

  mixins: [participationMode],

  data() {
    return {
      itemToDelete: {},
    }
  },

  computed: {
    available() {
      if (this.isBookingTypePackage && !this.participation?.relationships?.package?.attributes.allow_subexhibitor) {
        return false
      }
      if (this.isBookingTypeSpace && this.participation?.relationships?.spaces?.length) {
        let space = [].concat(this.participation?.relationships?.spaces).sort((a, b) => b.attributes.size_to - a.attributes.size_to)[0]

        return !!space.attributes.allow_subexhibitor
      }

      return true
    },

    coexhibitors() {
      return this.participation?.relationships?.coexhibitors ?? []
    },
  },

  methods: {
    ...mapActions('participation', [
      'deleteCoExhibitor',
    ]),

    showViewModal(coexhibitor) {
      this.$refs.view.show(coexhibitor)
    },

    showCreateModal() {
      this.$refs.create.show()
    },

    showEditModal(coexhibitor) {
      this.$refs.create.show(coexhibitor)
    },

    showDeleteModal(coexhibitor) {
      this.itemToDelete = coexhibitor

      this.$refs.delete.show()
    },

    async handleDelete() {
      await this.deleteCoExhibitor({ participation: this.participation, coexhibitor: this.itemToDelete })
    },

    handleSubmit() {
      this.$emit('submit')
    },
  },
}
</script>
