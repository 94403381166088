<template>
  <v-form>
    <v-text-field
        v-model="form.data.email"
        :error-messages="errors.email"
        :label="$t('forms.fields.email')"
        @keyup.enter="handleLogin"
    />

    <v-text-field
        type="password"
        v-model="form.data.password"
        :error-messages="errors.password"
        :label="$t('forms.fields.password')"
        @keyup.enter="handleLogin"
    />

    <validation-general-error :errors="errors.general" />

    <div class="mb-10 text-right">
      <p>
        <small>
          <v-btn
              plain
              x-small
              @click="$emit('forgotPasswordRequested')"
          >
            {{ $t('auth.forgotPassword') }}
          </v-btn>
        </small>
      </p>
    </div>

    <div class="my-10">
      <v-btn
          color="secondary"
          class="mr-5 elevation-0"
          @click="handleLogin"
          :loading="$wait.is(loaders.logIn)"
      >
        {{ $t('auth.logIn') }}
      </v-btn>

      <v-btn
          v-if="showRegister"
          class="mr-5 elevation-0"
          to="/signup"
          outlined
      >
        {{ $t('auth.register') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { required, email, minLength } from 'vuelidate/lib/validators'
import ValidationGeneralError from '@/components/base/ValidationGeneralError'
import { LOGIN } from '@/store/loading-types'
import validation from '@/mixins/validate'

export default {
  name: 'LoginForm',

  components: {
    ValidationGeneralError,
  },

  mixins: [
    validation,
  ],

  props: {
    showRegister: {
      type: Boolean,
      required: false,
      default: true
    },
    dynamicRedirect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      form: {
        data: {
          email: '',
          password: '',
        },
        validationRules: {
          email: {
            required,
            email,
          },
          password: {
            required,
            minLength: minLength(6),
          },
        },
        custom: {
          validationSubstitutions: {
            password: {
              minLength: {
                length: 6,
              },
            },
          },
        },
      },
      loaders: {
        logIn: LOGIN,
      }
    }
  },

  computed: {
    ...mapGetters('languages', [
      'language',
    ]),

    ...mapGetters('company', [
      'isOnboardCompleted',
    ]),
  },

  methods: {
    ...mapActions('auth', [
      'logIn',
      'getRedirectRouteName'
    ]),

    ...mapActions('company', [
        'fetchCompany'
    ]),

    ...mapWaitingActions('auth', {
      logIn: LOGIN,
    }),

    async handleLogin() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const user = await this.logIn(this.getFormData());

      let oldLink = localStorage.getItem('unauthorized')
      if (oldLink) {
        localStorage.removeItem('unauthorized')
        window.location.href = oldLink
        return
      }

      if (user && user.deletedAt) {
        await this.$router.push({name: 'exhibitor.verify', query: {verified: 0, id: user.id, deleted: 1}});
      } else if (user && !user.emailVerifiedAt) {
        await this.$router.push({name: 'exhibitor.verify', query: {verified: 0, id: user.id}});
      } else if (user && user.emailVerifiedAt) {
        await this.$router.push({name: await this.getRedirectRouteName()});
      }
    },

  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
