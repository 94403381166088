<template>
    <div>
      <div v-if="validEmail">
        <a :href="'mailto:' + email">{{ email }}</a><br />
      </div>
  
      <a
        v-if="website && normalizedWebsite"
        :href="normalizedWebsite"
        target="_blank"
      >
        {{ website }}
      </a>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      email: {
        type: String,
        required: false,
      },
      website: {
        type: String,
        required: false,
      },
    },
    computed: {
      validEmail() {
        return this.isValidEmail(this.email);
      },
      normalizedWebsite() {
        return this.normalizeUrl(this.website);
      },
    },
    methods: {
      isValidEmail(email) {
        if (!email) {
          console.log('No organizer email given');
          return false;
        }
  
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
      },
  
      normalizeUrl(website) {
        if (!website) {
          console.log('No organizer website given');
          return null;
        }
  
        const urlPattern =
          /^(https?:\/\/)?((([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,6})|localhost)(:\d{1,5})?(\/[^\s]*)?$/;
  
        if (!urlPattern.test(website)) {
          console.log('Invalid organizer website given');
          return null;
        }
  
        if (website.startsWith('www.')) {
          return `https://${website}`;
        }
  
        if (website.startsWith('http://') || website.startsWith('https://')) {
          return website;
        }
  
        return `https://${website}`; 
      },
    },
  };
  </script>
  