<template>
  <v-app style="background-color: #f9f9f9">
    <public-header />
    <v-main>
      <v-container class="py-10">
        <v-sheet class="pa-5 pa-md-10 elevation-1">
          <v-btn
            color="secondary"
            class="float-right"
            elevation="0"
            icon
            @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          >
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>

          <h1 class="mb-5">{{ $t('common.documents.imprint') }}</h1>

          <template v-if="$i18n.locale == 'de'">
            <p>
              Bundesministerium für Wirtschaft und Klimaschutz<br />
              Villemombler Straße 76<br />
              53123 Bonn<br />
              Internet:
              <a href="https://www.bmwk.de" target="_blank">www.bmwk.de</a
              ><br />
              E-Mail: <a href="mailto:info@bmwk.bund.de">info@bmwk.bund.de</a>
            </p>
            <p>
              Bundesministerium für Ernährung und Landwirtschaft<br />
              Rochusstraße 1<br />
              53123 Bonn<br />
              Internet:
              <a href="https://www.bmel.de" target="_blank">www.bmel.de</a
              ><br />
              E-Mail:
              <a href="mailto:poststelle@bmel.bund.de"
                >poststelle@bmel.bund.de</a
              ><br />
            </p>

            <p><i>Kooperationspartner:</i></p>
            <p>
              Ausstellungs- und Messeausschuss der Deutschen Wirtschaft e.V.
              (AUMA)<br />
              Littenstraße 9<br />
              10179 Berlin<br />
              Telefon: +49 (0) 30 24 00 0-0<br />
              Fax: +49 (0) 30 24 00 0-330<br />
              E-Mail: <a href="mailto:info@auma.de">info@auma.de</a><br />
              Internet:
              <a href="https://www.auma.de" target="_blank">www.auma.de</a>
            </p>
          </template>

          <template v-if="$i18n.locale == 'en'">
            <p>
              Bundesministerium für Wirtschaft und Klimaschutz<br />
              Villemombler Straße 76<br />
              53123 Bonn<br />
              Germany<br />
              Phone:
              <a href="https://www.bmwk.de" target="_blank">www.bmwk.de</a
              ><br />
              E-mail: <a href="mailto:info@bmwk.bund.de">info@bmwk.bund.de</a>
            </p>
            <p>
              Bundesministerium für Ernährung und Landwirtschaft<br />
              Rochusstraße 1<br />
              53123 Bonn<br />
              Germany<br />
              Phone:
              <a href="https://www.bmel.de" target="_blank">www.bmel.de</a
              ><br />
              E-mail:
              <a href="mailto:poststelle@bmel.bund.de"
                >poststelle@bmel.bund.de</a
              ><br />
            </p>

            <p><i>Kooperationspartner:</i></p>
            <p>
              Ausstellungs- und Messeausschuss der Deutschen Wirtschaft e.V.
              (AUMA)<br />
              Littenstraße 9<br />
              10179 Berlin<br />
              Germany<br />
              Phone: +49 (0) 30 24 00 0-0<br />
              Fax: +49 (0) 30 24 00 0-330<br />
              E-mail: <a href="mailto:info@auma.de">info@auma.de</a><br />
              Internet:
              <a href="https://www.auma.de" target="_blank">www.auma.de</a>
            </p>
          </template>
        </v-sheet>
      </v-container>
    </v-main>
    <v-footer>
      <logos />
    </v-footer>
  </v-app>
</template>

<script>
import Logos from '@/components/base/Logos/Logos'
import PublicHeader from '@/components/public/Header.vue'

export default {
  components: { Logos, PublicHeader },

  methods: {
    hasHistory() {
      return window.history.length > 2
    },
  },
}
</script>
