import { api } from '@/boot/api'
import {
  SET_TOKEN,
  UNSET_TOKEN,
  SET_USER,
  UNSET_USER,
  SET_LANGUAGE, SET_USER_PREFERENCES,
} from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'
import { USER_ROLES } from '@/enums/userRole'
import {$axios} from "@/boot/interceptor";

const defaultState = () => ({
  token: '',
  user: {
    companyId: null,
    organizerId: null,
    associationId: null,
  },
  preferences: {}
})

const state = defaultState()

const actions = {
  async logIn({ commit, dispatch }, payload = {}) {
    try {
      let response = null;
      await api.post('/login', payload, {auth: false})
          .then((res) => {
            response = res;
          })
          .catch((res) => {
            response = res.data.data;
          });


      if (!response.token) {
        if (response.user) {
          return response.user;
        }

        return false;
      }

      commit(SET_TOKEN, response.token)
      commit(SET_USER, response.user)
      commit('languages/' + SET_LANGUAGE, response.user.language, { root: true })

      if (response.user.companyId) {
        dispatch('company/fetchCompany', null, { root: true })
      }

      if (response.user.organizerId) {
        dispatch('organizer/extractFromUser', null, { root: true })
      }

      dispatch('auth/fetchUserPreferences', null, { root: true })

      return response.user
    } catch (error) {
      console.error('API error (login): ', error)
      return false
    }
  },

  async register({ commit }, payload = {}) {
    try {
      const { token, user } = await api.post('/register', payload, { auth: false })

      commit(SET_TOKEN, token)
      commit(SET_USER, user)
      commit('languages/' + SET_LANGUAGE, user.language, { root: true })

      return true
    } catch (error) {
      console.error('API error (sign up): ', error)
      return false
    }
  },

  async logOut({ commit }) {
    try {
      await api.post('/logout')

      commit(UNSET_TOKEN)
      commit(UNSET_USER)

      return true
    } catch (error) {
      console.error('API error (logout): ', error)
      return false
    }

  },

  async fetchUser({ commit, state }) {
    try {
      const { user } = await api.get(`users/${state.user.id}`)

      commit(SET_USER, user)

      return true
    } catch (error) {
      console.error('API error (fetch user): ', error)
      return false
    }
  },

  async fetchUserInfo({ commit }) {
    try {
      const { user } = await api.get(`user/info`)

      commit(SET_USER, user)

      return true
    } catch (error) {
      console.error('API error (fetch user): ', error)
      return false
    }
  },

  async fetchUserPreferences({ commit, state }, user_id = null) {
    try {
      let preferences = await $axios.get(`/api/users/${user_id ?? state.user.id}/preferences`)

      commit(SET_USER_PREFERENCES, preferences.data.data)
      return true
    } catch (error) {
      console.error('API error (fetch user): ', error)
      return false
    }
  },

  async getUserPreferences({ commit, state }, {keys, user_id}) {
    try {
      for (let key of keys) {
        if (!(key in state.preferences)) {
          let preferences = await $axios.get(`/api/users/${user_id ?? state.user.id}/preferences`, {keys});
          commit(SET_USER_PREFERENCES, preferences.data.data)
          break;
        }
      }

      return this.state.preferences;
    } catch (error) {
      console.error('API error (fetch user): ', error)
      return false
    }
  },

  async setUserPreferences({ commit, state }, {key, value, user_id}) {
    try {
      let preferences = await $axios.put(`/api/users/${user_id ?? state.user.id}/preferences`, {key, value})

      commit(SET_USER_PREFERENCES, preferences.data.data)

      return true
    } catch (error) {
      console.error('API error (fetch user): ', error)
      return false
    }
  },

  async forgotPassword(...[, { email }]) {
    try {
      await api.post(
        '/forgot-password',
        {
          email
        },
        {
          auth: false,
          raw: true,
        }
      )

      return true
    } catch (error) {
      console.error('API error (forgot password): ', error)
      return false
    }
  },

  async checkToken(...[, { email }]) {
    try {
      await api.post(
        '/check-token',
        {
          email
        },
        {
          auth: false,
          raw: true,
        }
      )

      return true
    } catch (error) {
      console.error('API error (check token): ', error)
      return false
    }
  },

  async resetPassword({ commit }, payload) {
    try {
      const { user } = await api.post('/reset-password', payload, { auth: false})
      commit(SET_USER, user)
      return true
    } catch (error) {
      console.error('API error (reset password): ', error)
      return false
    }
  },

  async resendEmailVerification(...payload) {
    try {
      await api.get('email/resend/' + payload[1].id, null , {
        showSuccessNotifications: true,
        showErrorNotifications: true
      });

      return true
    } catch (error) {
      console.error('API error (reset password): ', error)
      return false
    }
  },

  async updateCredentials({ commit, state }, payload = {}) {
    try {
      const { data: user } = await api.put(
        `users/${state.user.id}`,
        payload,
        {
          raw: true,
          showSuccessNotifications: true,
        }
      )

      commit(SET_USER, user)

      return true
    } catch (error) {
      console.error('API error (update user credentials): ', error)
      return false
    }
  },

  async getRedirectRouteName({getters, rootGetters, dispatch}) {
    if (!getters.isVerifyEmail) {
      return 'exhibitor.verify'
    }

    if (!this.dynamicRedirect && (getters.isExhibitor)) {
      await dispatch('company/fetchCompany', null, { root: true })

      if (!rootGetters['company/isOnboardCompleted']) {
        return 'exhibitor.onboard'
      }

      return 'exhibitor.dashboard'
    }

    if (getters.isOrganizer || getters.isProjectManager) {
      return 'organizer.dashboard'
    }

    if (getters.isSupervisor || getters.isAssociation) {
      return 'supervisor.dashboard'
    }

    if (getters.isAdmin) {
      return 'admin.dashboard'
    }

    console.error('Unhandled user role')
  },
}

const mutations = {
  [SET_TOKEN] (state, token) {
    state.token = token
  },

  [UNSET_TOKEN] (state) {
    state.token = ''
  },

  [SET_USER] (state, user) {
    state.user = user
  },

  [SET_USER_PREFERENCES] (state, preferences) {
    state.preferences = preferences
  },

  [UNSET_USER] (state) {
    state.user = {}
  },
}

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,

  isAuthorized: state => !!state.token,

  userRoles: state => state.user.roles ? state.user.roles.map(role => role.role) : [],

  isVerifyEmail: state => state.user.emailVerifiedAt,

  isExhibitor: (state, getters) => getters.userRoles.includes(USER_ROLES.EXHIBITOR),

  isOrganizer: (state, getters) => getters.userRoles.includes(USER_ROLES.ORGANIZER),

  isProjectManager: (state, getters) => getters.userRoles.includes(USER_ROLES.PROJECT_MANAGER),

  isSupervisor: (state, getters) => getters.userRoles.includes(USER_ROLES.SUPERVISOR),

  isAssociation: (state, getters) => getters.userRoles.includes(USER_ROLES.ASSOCIATION),

  isAdmin: (state, getters) => getters.userRoles.includes(USER_ROLES.ADMINISTRATOR),

  userName: state => {
    const firstName = state.user.firstName || ''
    const lastName = state.user.lastName || ''

    return `${firstName} ${lastName}`
  },

  isExhibitorAppAllowed: (state, getters) => getters.isExhibitor,

  isOrganizerAppAllowed: (state, getters) => getters.isOrganizer || getters.isProjectManager,

  isAdminAppAllowed: (state, getters) => getters.isAdmin,

  isSupervisorAppAllowed: (state, getters) => getters.isSupervisor || getters.isAssociation,

  startPage: (state, getters) => {
    if (getters.isExhibitorAppAllowed) {
      return 'exhibitor.dashboard'
    }

    if (getters.isOrganizerAppAllowed) {
      return 'organizer.dashboard'
    }

    if (getters.isAdminAppAllowed) {
      return 'admin.dashboard'
    }

    if (getters.isSupervisorAppAllowed) {
      return 'supervisor.dashboard'
    }

    return 'exhibitor.login'
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
